<template>
  <div class="team">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_team.png">团队管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <div class="btn" @click="createTeamShow=true">创建团队</div>
    </div>

    <el-table
      :data="teamList"
      v-loading="teamLoading"
      tooltip-effect="dark"
      style="width: 100%"
      height="calc(100vh - 236px)"
      class="table">
    
      <el-table-column prop="organization_name" label="团队名" min-width="180" show-overflow-tooltip=""></el-table-column>

      <el-table-column label="团队成员" min-width="176" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="member-row flex--cen" v-if="scope.row.total_number">
            <img :src="item || require('../../../assets/images/head.png')" v-for="(item,i) in scope.row.member_list" :key="i">
            <span v-if="scope.row.total_number>5">...</span>
            <span style="margin-left:10px">{{scope.row.total_number}}人</span>
          </div>
          <div v-else>暂无成员</div>
        </template>
      </el-table-column>

      <el-table-column label="创建时间" min-width="140">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="address" label="所属架构" min-width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.structure">{{scope.row.structure}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" min-width="360" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="getMember(20,1,scope)">成员列表</span>

          <span class="btn" @click="editTeam(scope.row)" v-if="scope.$index">修改信息</span>
          <span class="gray" style="margin:0 8px;font-size:13px" v-else>修改信息</span>

          <span class="btn" @click="createReport(scope.row)" v-if="scope.row.total_number>4">生成报告</span>
          <el-tooltip v-else class="gray" content="至少5位成员才能生成报告" placement="top" :open-delay="400">
            <el-button>生成报告</el-button>
          </el-tooltip>

          <span class="btn red" v-if="scope.row.pid!=0" @click="delConfirm(scope.row,'delTeamShow')">解散团队</span>
          <el-tooltip v-else class="gray" content="该团队无法解散" placement="top" :open-delay="400">
            <el-button>解散团队</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="teamPage" @change="getTeam" :total="teamTotal"></pagination>

    <!-- 创建团队弹窗 -->
    <el-dialog
      title="创建团队"
      :visible.sync="createTeamShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4 class="must">团队名</h4>
          <div class="box" :class="{error:errorType==1}">
            <input 
              class="input"
              placeholder='请输入新的团队名' 
              maxlength="24"
              v-model="teamName"
              @input="checkName"
              ref="teamName">
            <div class="input-icon icon-clear" title="清空" v-show="teamName" @click="teamName=''"></div>
            <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4 class="must">所属架构</h4>
          <div class="box" :class="{error:errorType==2}">
            <el-select v-model="structureId" placeholder="请选择架构" class="select-init" @change="setError()">
              <el-option
                v-for="item in structure"
                :key="item.structure_id"
                :label="item.structure_name"
                :value="item.structure_id">
              </el-option>
            </el-select>
            <button class="btnRect" @click="structureShow=true" v-if="structureTotal<3">+</button>
            <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4>添加成员</h4>
          <div class="box">
            <div class="member-row flex--cen" style="flex:1;margin-left:10px">
              <img :src="item.avatar || require('../../../assets/images/head.png')" v-for="(item,i) in appendList" :key="i" v-show="i<8">
              <span v-if="appendList.length>8">...</span>
              <span style="margin-left:10px" v-show="appendList.length">{{appendList.length}}人</span>
            </div>
            <button class="btnRect" @click="getChooseMember()">+</button>
            <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
          </div>
        </div>
        <div class="bottom-btn" @click="createTeam">创 建</div>
      </div>
    </el-dialog>
    <!-- 成员列表弹窗 -->
    <el-dialog
      title="成员列表"
      class="dialog-large"
      @close="closeDialog"
      :visible.sync="memberListShow"
      :close-on-click-modal="false"
      center>
      <div class="table-btn">
        <div class="text" v-if="teamNow"><b>{{teamNow.organization_name}}</b> &nbsp;共{{memberTotal}}人</div>
        <div class="btn" @click="getChooseMember()" v-if="addBtnShow">添加已有成员</div>
        <el-tooltip v-else class="btn btn-disable" content="已添加所有成员" placement="top" :open-delay="400">
            <el-button>添加已有成员</el-button>
        </el-tooltip>
        <div class="btn" @click="inviteShow=true">邀请新成员</div>
      </div>
      <el-table
        v-loading="memberLoading"
        :data="memberList"
        tooltip-effect="dark"
        :height="tableHeight"
        style="width:100%">
        <el-table-column label="姓名" min-width="110" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="性别" min-width="50" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.sex==2">女</span>
            <span v-else>男</span>
          </template>
        </el-table-column>

        <el-table-column label="手机号" min-width="110" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="加入时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="type_name" label="职位类型" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type_name">{{scope.row.type_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column prop="tier_name" label="层级" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.tier_name">{{scope.row.tier_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="评估状态" min-width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>

        <el-table-column label="最新评估时间" min-width="132" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.end_time">{{$formatTime(scope.row.end_time,'y-m-d h:m')}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="190" align="center" v-if="memberList.length">
          <template slot-scope="scope">
            <span class="btn" @click="changeMember(scope.row)" v-if="scope.row.update_tier_count<2">修改层级</span>
            <el-tooltip 
              v-else
              class="gray" 
              effect="dark" 
              content="该成员修改次数已达上限" 
              placement="top"
              :open-delay="300">
              <el-button>修改层级</el-button>
            </el-tooltip>
            <span class="gray" v-if="memberIndex==0">移除成员</span>
            <span class="btn red" v-else @click="delConfirm(scope.row,'delMemberShow')">移除成员</span>
          </template>
        </el-table-column>
      </el-table>

      <pagination v-show="memberList.length>2" ref="memberPage" @change="getMember" :total="memberTotal" :position="'page-botttom'"></pagination>
    </el-dialog>
    <!-- 添加可选成员列表弹窗 -->
    <el-dialog
      title="添加成员"
      class="dialog-large"
      :visible.sync="addMemberShow"
      :close-on-click-modal="false"
      center>
      <div class="table-btn">
        <div class="sortBox flex--cen">
          <h6>层级</h6>
          <el-select v-model="sortTierId" class="select-init" @change="getChooseMember(0,1)">
            <el-option v-for="item in sortTier" :key="item.id" :label="item.name" :value="item.id"/>
          </el-select>
        </div>
        <div class="sortBox flex--cen">
          <h6>职位类型</h6>
          <el-select v-model="sortJobId" class="select-init" @change="getChooseMember(0,1)">
            <el-option v-for="item in sortJob" :key="item.id" :label="item.type" :value="item.id"/>
          </el-select>
        </div>
        <div class="sortBox flex--cen">
          <el-input v-model="searchText" clearable/>
          <div class="btn" @click="getChooseMember(0,1)">搜索</div>
        </div>
        <div class="text"/>
        <div class="btn" @click="addMember">确定添加</div>
      </div>

      <el-table
        v-loading="addMemberLoading"
        :data="addMemberList"
        :height="tableHeight"
        tooltip-effect="dark"
        ref="addTable"
        style="width: 100%">

        <el-table-column type="selection" width="50"></el-table-column>

        <el-table-column label="姓名" min-width="110" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="性别" min-width="50" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.sex==2">女</span>
            <span v-else>男</span>
          </template>
        </el-table-column>

        <el-table-column label="手机号" min-width="110" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="加入时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
          </template>
        </el-table-column>

        <el-table-column label="职位类型" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.type_name">{{scope.row.type_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="层级" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.tier_name">{{scope.row.tier_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="评估状态" min-width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>

        <el-table-column label="最新评估时间" min-width="132" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.end_time">{{$formatTime(scope.row.end_time,'y-m-d h:m')}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      
      <pagination 
        v-show="addMemberList.length>2" 
        ref="addMemberPage"  
        :position="'page-botttom'"
        @change="getChooseMember" 
        :total="addMembeListTotal">
      </pagination>
    </el-dialog>
    <!-- 邀请新成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteShow"
      :close-on-click-modal="false"
      width="560px"
      center>
      <div class="dialog-input-body" style="height:302px">
        <nav class="top-nav">
          <span :class="{current:inviteType==0}" @click="toggle(0)">手机号邀请</span>
          <span :class="{current:inviteType==1}" @click="toggle(1)">小程序码邀请</span>
        </nav>

        <template v-if="inviteType==0">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="16"
                v-model="inviteName"
                @input="setError"
                ref="inviteName">
              <div class="input-icon icon-clear" title="清空" v-show="inviteName" @click="inviteName=''"></div>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==4}">
              <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
                <el-option
                  v-for="(item,code) in $country"
                  :key="code"
                  :label="item.label"
                  :value="code">
                </el-option>
              </el-select>
              <input 
                class="input"
                style="padding-top:2px"
                placeholder='请输入手机号'
                maxlength="11"
                v-model="phone"
                @input="inputChange($event,'phone')"
                ref="phone">
              <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
              <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn" @click="mobileInvite">确 认</div>
        </template>
        <template v-else-if="inviteType==1">
          <div class="input-row" style="margin:60px 0">
            <h4>数量</h4>
            <div class="box" :class="{error:errorType==5}">
              <input 
                class="input"
                placeholder='请输入数量' 
                maxlength="6"
                @input="inputChange($event,'sendNumber')"
                v-model="sendNumber">
              <div class="input-icon icon-clear" title="清空" v-show="sendNumber" @click="sendNumber=''"></div>
              <div class="errText" v-show="errorType==5">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：当前可邀请人数最大上限为{{maxInviteNum}}</p>
          <div class="bottom-btn loading-dot" v-if="creating">生成中<i>.</i><i>.</i><i>.</i></div>
          <div class="bottom-btn" @click="createQrcode" v-else>生成小程序码</div>
        </template>
        <template v-else>
          <div class="qrcode">
            <img :src="qrcode">
            <div class="downloadBtn" style="opacity:.8;cursor:not-allowed;" v-if="qrcodeDownload">已下载</div>
            <div @click="download" class="downloadBtn" v-else>下 载</div>
            <p>提示：被邀请人可通过扫描此小程序码进入评估，可在<router-link to="/company/invite">邀请管理</router-link>中查看邀请详情或结束此邀请</p>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 生成报告弹窗 -->
    <el-dialog
      title="生成报告"
      :visible.sync="createReportShow"
      :close-on-click-modal="false" 
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4>团队名</h4>
          <div class="box gray">{{teamNow.organization_name}}</div>
        </div>
        <div class="input-row">
          <h4>所属架构</h4>
          <div class="box gray">{{teamNow.structure}}</div>
        </div>
        <div class="input-row">
          <h4>团队人数</h4>
          <div class="box gray">{{memberTotal}}人</div>
        </div>
        <div class="input-row" style="margin-bottom:0">
          <h4>完成人数</h4>
          <div class="box">
            <el-select v-model="is_current_member" class="select-init" @change="createReport('')">
              <el-option
                v-for="item in analyze_list"
                :key="item.is_current_member"
                :label="item.option"
                :value="item.is_current_member">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reportNumber" :style="{height:is_current_member==0?'34px':'0'}">失真数据：成员完成评估时间距今已超过180天，生成的团队报告数据不具备参考价值。</div>
        <div class="input-row" style="margin-bottom:10px">
          <h4>分析类型</h4>
          <div class="box">
            <el-select v-model="reportType" class="select-init">
              <el-option
                v-for="item in reportText"
                :key="item.name"
                :label="item.name"
                :value="item.type">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="reportPeople" :style="{height:reportType==2?'40px':'0'}"><span v-for="it in tier_info" :key="it.id">{{it.name}}：{{it.number}}</span></div>
        <div class="bottom-btn" @click="toReport()">确 认</div>
      </div>
    </el-dialog>
    <!-- 修改团队信息弹窗 -->
    <el-dialog
      title="修改团队信息"
      :visible.sync="changeTeamShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4 class="must">团队名</h4>
          <div class="box" :class="{error:errorType==1}">
            <input 
              class="input"
              maxlength="24"
              v-model="newTeamName"
              @input="checkName"
              ref="name">
            <div class="input-icon icon-clear" title="清空" v-show="newTeamName" @click="newTeamName=''"></div>
            <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4 class="must">所属架构</h4>
          <div class="box" :class="{error:errorType==2}">
            <el-select v-model="structureId" placeholder="请选择架构" class="select-init" @change="setError()">
              <el-option
                v-for="item in structure"
                :key="item.structure_id"
                :label="item.structure_name"
                :value="item.structure_id">
              </el-option>
            </el-select>
            <!-- <button class="btnRect" @click="structureShow=true" v-if="structureTotal<3">+</button> -->
            <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
          </div>
        </div>
        <div style="margin-top:70px" class="bottom-btn" @click="updateTeam">确 认</div>
      </div>
    </el-dialog>
    <!-- 添加架构弹窗 -->
    <el-dialog
      title="添加架构"
      :visible.sync="structureShow"
      :close-on-click-modal="false"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4 class="must">架构名</h4>
          <div class="box" :class="{error:errorType==7}">
            <input 
              class="input"
              maxlength="30"
              v-model="newStructureName"
              ref="name">
            <div class="input-icon icon-clear" title="清空" v-show="newStructureName" @click="newStructureName=''"></div>
            <div class="errText" v-show="errorType==7">{{errorMsg}}</div>
          </div>
        </div>
        <p class="tips">提示：企业下最多可建立三层(级)架构</p>
        <div style="margin-top:80px" class="bottom-btn" @click="addStructure">确 认</div>
      </div>
    </el-dialog>
    <!-- 修改成员信息弹窗 -->
    <el-dialog
      title="修改成员信息"
      :visible.sync="changeMemberShow"
      :close-on-click-modal="false"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4 class="must">层级</h4>
          <div class="box" :class="{error:errorType==6}">
            <el-select v-model="memberTierId" placeholder="请选择层级" class="select-init" @change="setError()">
              <el-option v-for="item in tier" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <div class="errText" v-show="errorType==6">{{errorMsg}}</div>
          </div>
        </div>
        <p class="tips">提示：每位成员最多只能修改两次层级</p>
        <div style="margin-top:70px" class="bottom-btn" @click="updateMember">确 认</div>
      </div>
    </el-dialog>
    <!-- 移除团队确认弹窗 -->
    <el-dialog
      title="解散团队"
      class="el-dialog-confirm"
      :visible.sync="delTeamShow"
      :close-on-click-modal="false"
      center>
      <span>确认解散[<b>{{delRow.organization_name}}</b>]？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="removeTeam">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 移除成员确认弹窗 -->
    <el-dialog
      title="移除成员"
      class="el-dialog-confirm"
      :visible.sync="delMemberShow"
      :close-on-click-modal="false"
      center>
      <span>确认移除成员[<b>{{delRow.realname}}</b>]</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="removeName">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination,},
  props: ['structure','tier','maxInviteNum','structureTotal'],
  data(){
    return {
      tableHeight: '85%',
      teamLoading: false,
      memberLoading: false,
      reg: this.$CNReg,          // 当前区号的手机号正则
      country_code: '86',      // 区号
      
      phone: '',                // 手机号码
      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 1：无团队名，2：未选架构，3：无姓名，4：手机号有误，5：邀请数量有误，6：层级有误，7：架构名为空

      structureId: '',
      structureShow: false,
      newStructureName: '',
      memberListShow: false,    //

      createTeamShow: false,    // 创建团队弹窗显隐
      createReportShow: false,  // 生成报告弹窗显隐
      changeTeamShow: false,    // 修改信息显隐

      delTeamShow: false,       // 删除确认框显隐
      delMemberShow: false,     // 删除成员框显隐
      delRow: [],               // 准备删除的列表

      addMemberShow: false,
      addBtnShow: false,
      inviteShow: false,
      inviteName: '',
      inviteType: 0,
      sendNumber: '',            // 发出邀请数
      creating: false,
      qrcode: '',
      qrcodeDownload: false,      // 是否已经下载了小程序码

      analyze_list: [],
      is_current_member: 1,

      reportText: [
        {name: '团队分析报告', type: 1},
        {name:'层级分析报告', type: 2},
      ],
      reportType: 1,

      addMemberList: [],
      addMembeListTotal: 0,
      addMemberLoading: false,
      changeMemberShow: false,    
      sortTier: [],
      sortTierId: '',  
      sortJob: [],
      sortJobId: '',
      searchText: '',

      teamTotal: 0,              // 成员总数
      teamNow: {},
      teamList: [],              // 团队列表
      teamName: '',              // 姓名
      appendList: [],            // 新建团队时添加的成员列表

      memberTotal: 0,
      memberList: [],
      memberTierId: '',
      memberId: '',
      tier_info: [],

      step: 1,
      keyword: '',
      newTeamName: '',
      cStructure: '',
    }
  },
  mounted(){
    // window.onresize = () => {
    //   this.timer && clearTimeout(this.timer)
      setTimeout(() => {
        let h = document.body.clientHeight
        if (h > 840) {
          this.tableHeight = '88.8%'
        } else if (h < 840 && h > 800) {
          this.tableHeight = '87.8%'
        } else if (h < 800 && h > 680) {
          this.tableHeight = '85.7%'
        } else {
          this.tableHeight = '82.8%'
        }
      },300)
    // }
    //获取筛选的层级和职位类型
    this.$request({url:'/Index/getType',
      success: res => {
        if (res.code == 0) {
          this.sortJob = [...[{id:'',type:'全部'}], ...res.data]
          this.sortTier = [...[{id:'',name:'全部'}], ...this.tier]
        }
      }
    })

    this.getTeam()
  },
  methods: {
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    // 获取团队列表
    getTeam(per_page, page){
      let ref = this.$refs.teamPage;
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.teamLoading = true
      this.$request({
        url: '/Team/getTeamList',
        data: {
          // keyword: this.keyword,
          per_page: per_page,
          page: page,
        },
        success: data => {
          if (data.code == 0) {
            this.teamList = data.data.data
            this.teamTotal = data.data.total
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.teamLoading = false
      })
    },
    // 获取团队可选成员
    getChooseMember(per_page,page){
      this.addMemberShow = true
      this.addMemberLoading = true;
      
      let ref = this.$refs.addMemberPage;
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/team/getChooseMembers',
        data:{
          per_page: per_page,
          page: page,
          team_id: (this.teamNow&&this.teamNow.id) || [],
          keyword: this.searchText,
          tier_id: this.sortTierId,
          type_id: this.sortJobId,
        },
        success: (data) => {
          if (data.code == 0) {
            this.addMembeListTotal = data.data.total

            let addData = this.addMemberList = data.data.data
            if (this.appendList.length) {
              this.$nextTick(() => {
                for (let i in this.appendList) {
                  for (let j in this.addMemberList) {
                    if (this.appendList[i].user_id == this.addMemberList[j].user_id)
                      this.$refs.addTable.toggleRowSelection(this.addMemberList[j],true);
                  }
                }
              })
            }
          } else {
            this.$message({message: data.msg})
          }
        },
        complete: () => this.addMemberLoading = false
      })
    },
    // 获取团队里的成员列表
    getMember(per_page, page, {row,$index}){
      this.memberIndex = $index
      this.memberLoading = true;
      this.memberListShow = true;

      let ref = this.$refs.memberPage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url: '/Team/getMemberList',
        data: {
          // keyword: this.keyword,
          per_page: per_page,
          page: page,
          team_id: row ? row.id : this.teamNow.id,
        },
        success: data => {
          if (data.code==0) {
            let member = data.data.member_list
            this.memberList = member.data
            this.memberTotal = member.total
            this.addBtnShow = data.data.chooseable_count
            this.teamNow = row ? row : this.teamNow
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    addMember(){
      let selection = this.$refs.addTable.selection
      if (this.createTeamShow) {
        this.appendList = selection
        this.addMemberShow = false
      } else if (!selection.length) {
        this.$message({duration: 1000, message: '请先选择', type: 'warning'})
      } else if (!this.addMemberList.length) {
        return
      } else {
        let memberInfo = [];
        selection.forEach( item => memberInfo.push([item.user_id, item.exam_id]) )
        this.$request({
          method: 'post',
          url: '/Team/appendMember',
          data: {
            team_id: this.teamNow.id,
            member_info: memberInfo,
          },
          success: data => {
            if (data.code == 0) {
            this.$message({duration: 1000,message: '添加成功', type:'success'})
            this.memberList = [...this.memberList, ...selection]
            this.addMemberShow = false
            this.getTeam()
            } else {
              this.$message({duration: 2000, message: data.msg})
            }
          },
        })
      }
    },
    createTeam(){
      if(!this.teamName){
        this.setError(1,'请先输入团队名')
        return
      } else if (!this.structureId) {
        this.setError(2,'请先选择所属架构')
        return
      }
      let member_info = []
      this.appendList.forEach( item => member_info.push([item.user_id, item.exam_id]) )
      this.$request({
        method: 'post',
        url: '/Team/createTeam',
        data: {
          team_name: this.teamName,
          structure_id: this.structureId,
          member_info: member_info
        },
        success: data => {
          if (!data.code) {
            this.$message({duration: 1000,message: '创建成功', type:'success'})
            this.getTeam()
            this.createTeamShow = false
          } else {
            this.$message({duration: 2000,message: data.msg})
          }
        },
      })
    },
    addStructure(){
      if(!this.newStructureName){
        this.errorType = 7
        this.errorMsg = '请先输入架构名'
        return
      }
      this.$request({url:'/CompanyStructure/addStructure',
        data: {structure_name: this.newStructureName},
        success: (data) => {
          if (data.code == 0) {
            this.$parent.getStructure().then(()=>{
              this.structureShow = false
              this.$message({duration: 2000,message:'添加成功',type:'success'})
              // this.structureId = data.data.structure_id
            })
          } else if (data.code == 3) {
            this.errorType = 7
            this.errorMsg = '架构已存在，请输入新的架构名'
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
      })
    },
    // 删除操作确认
    delConfirm(row,key){ 
      this[key] = true
      this.delRow = row
    },
    // 移除团队
    removeTeam(){
      this.$request({
        url: '/Team/disbandTeam',
        data: {team_id: this.delRow.id},
        success: data => {
          if (!data.code) {
            this.$message({duration: 1000,message: '删除成功', type:'success'})
            this.delTeamShow = false
            this.getTeam()
          } else {
            this.$message({duration: 1500,message: data.msg})
          }
        },
      })
    },
    // 删除成员
    removeName(){
      this.$request({
        url: '/Team/deleteMember',
        data: {
          team_id: this.teamNow.id,
          member_id: this.delRow.id,
        },
        success: data => {
          if (!data.code) {
            this.$message({duration: 2000,message: '移除成功', type:'success'})
            this.delMemberShow = false
            this.getTeam()
            for(let j in this.memberList){
              if(this.delRow.user_id == this.memberList[j].user_id){
                this.memberList.splice(j,1);
                break;
              }
            }
          } else {
            this.$message({duration: 1500,message: data.msg})
          }
        },
      })
    },
    // 切换 短信登录/账号密码登录
    toggle(type){
      if (this.inviteType == type)  return;
      this.inviteType = type
      this.setError()
      this.inviteName = ''
      this.phone = ''
    },
    //选择区号和手机号正则
    selectCountry(val){
      this.reg = this.$country[val].reg
      this.country_code = val
    },
    // 监听输入数字输入内容
    inputChange(e, key = 'phone'){
      let text = e.target.value
      this[key] = text.replace(/\D/g,'')
      if(key == 'sendNumber' && text.charAt(0) == '0')  this.sendNumber = text.substring(1, text.length);
      this.setError()
    },
    //手机号邀请新成员
    mobileInvite(){
      if (!this.inviteName) {
        this.setError(3,'请先输入姓名')
        this.$refs.inviteName.focus()
        return
      } else if (!this.phone) {
        this.setError(4,'请先输入手机号')
        this.$refs.phone.focus()
        return
      } else if (!this.reg.test(this.phone)) {
        this.setError(4,'手机号码格式不对')
        this.$refs.phone.focus()
        return
      }
      this.$request({
        url: '/Team/baseMobileInvite',
        data: {
          team_id: this.teamNow.id,
          realname: this.inviteName,
          mobile: this.phone,
          country_code: this.country_code,
        },
        success: data => {
          if (!data.code) {
            this.$message({duration: 1000,message: '邀请成功', type:'success'})
            this.inviteShow = false
            this.getMember()
            this.$parent.getInfo()
          } else {
            this.$message({duration:2000, message:data.msg, type:'error'})
          }
        },
      })
    },
    // 生成邀请二维码
    createQrcode(){
      if (!this.sendNumber) {
        this.setError(5, '请输入邀请数')
        return
      } else if (this.sendNumber > this.maxInviteNum) {
        this.setError(5, '数量超出可邀请数')
        return
      }
      this.creating = true
      this.$request({
        url:'/Team/codeInvite',
        data: {invite_num: this.sendNumber},
        success: (data) => {
          if (data.code == 0) {
            this.$parent.getInfo()
            this.$request({
              url: this.$planetUrl+'/Common/createQrcode',
              data: {
                param: data.data.id + '=' + data.data.code,
                page: 'pages/TVR/inviteAccept/inviteAccept',
                width: 280,
              },
              success: (e) => {
                if (e.code == 0) {
                  this.inviteType = 2
                  this.qrcode = e.data.qrcode_path
                } else {
                  this.$message({duration: 2000, message: e.msg})
                }
              },
              complete: ()=>{
                this.creating = false
              }
            })
          } else {
            this.creating = false
            this.$message({duration: 2000, message: data.msg})
          }
        },
        error: ()=>{
          this.creating = false
          this.$message.error('网络错误，请稍后再试')
        }
      })
    },
    download(){
      if (this.qrcodeDownload == false) {
        window.open(`${this.$baseRoot}/CompanyInvite/downQrcode?qrcode_path=${this.qrcode}`)
        this.qrcodeDownload = true
      }
    },
    createReport(row){
      this.teamNow = this.teamNow.id ? this.teamNow : row
      // 获取团队可分析数据
      this.$request({
        method: 'post',
        url: '/Team/getTeamAnalysisData',
        data: {
          team_id: this.teamNow.id,
          is_current_member: this.is_current_member,
        },
        success: data => {
          if (data.code == 0) {
            this.analyze_list = data.data.analyze_list
            this.memberTotal = data.data.total_number
            this.createReportShow = true
            this.tier_info = data.data.tier_info
          } else {
            this.$message({duration:2000, message:data.msg, type:'error'})
          }
        },
      })
    },
    toReport(){
      for (let it of this.analyze_list) {
        if (it.is_current_member == this.is_current_member && it.number < 5) {
          this.$message({duration: 2000,type:'error', message: '完成评估人数不足5人'})
          return
        }
      }
      this.$request({
        url: '/TeamReport/create',
        data: {
          team_id: this.teamNow.id,
          type: this.reportType,
          is_current_member: this.is_current_member,
        },
        success: data => {
          if (data.code == 0) {
            sessionStorage.reportTab = this.reportType
            this.$router.push({path:'/tvr/report'})
          } else {
            this.$message({duration:2000, message:data.msg, type:'error'})
          }
        },
        complete: () => this.teamLoading = false
      })
    },
    //修改团队信息
    editTeam(row){
      this.newTeamName = row.organization_name
      this.structureId = row.structure_id || null
      this.changeTeamShow = true
      this.teamNow = row
    },
    checkName(e){
      if (e.target.selectionEnd > 31) {
        this.setError(1,'团队名过长')
      } else {
        this.setError()
      }
    },
    updateTeam(){
      if (this.errorType) {
        return
      } else if (!this.newTeamName) {
        this.setError(1,'请先输入团队名')
        return
      } else if (!this.structureId) {
        this.setError(2,'请先选择所属架构')
        return
      }
      this.$request({
        method: 'post',
        url: '/Team/updateTeam',
        data: {
          team_id: this.teamNow.id,
          team_name: this.newTeamName,
          structure_id: this.structureId,
        },
        success: data => {
          if (!data.code) {
            this.$message({duration: 1000,message: '修改成功', type:'success'})
            this.changeTeamShow = false
            this.getTeam()
          } else {
            this.$message({duration:1500, message:data.msg, type:'error'})
          }
        },
      })
    },
    //修改成员信息
    changeMember(row){
      this.changeMemberShow = true
      this.memberTierId = row.tier_id || ''
      this.memberId = row.id
    },
    updateMember(){
      if(!this.memberTierId){
        this.setError(6,'请先选择层级')
        return
      }
      this.$request({
        method: 'post',
        url: '/Team/updateMember',
        data: {
          team_id: this.teamNow.id,
          member_id: this.memberId,
          tier_id: this.memberTierId,
        },
        success: data => {
          if (!data.code) {
            this.$message({duration: 1000,message: '修改成功', type:'success'})
            this.changeMemberShow = false
            this.getMember()
          } else {
            this.$message({duration:2000, message:data.msg, type:'error'})
          }
        },
      })
    },
    closeDialog(){
      this.setError()
      this.phone = ''
      this.name = ''
      this.addMemberList = []
      this.newTeamName = '',
      this.teamName = ''
      this.structureId = ''
      this.newStructureName = ''
      this.teamNow = {}
      this.delRow = {}
      this.appendList = []
      this.qrcodeDownload = false
      this.inviteType = 0
      this.memberIndex = ''
      this.is_current_member = 1
      this.reportType = 1
    },
  },
}
</script>

<style lang="scss">
.team{
  transition-duration: 0s;
  *{transition-duration: 0s;}
  .member-row{
    padding-left: 6px;
    img{
      overflow: hidden;
      width: 26px;
      height: 26px;
      flex-shrink: 0;
      border-radius: 50%;
      border: 1px solid #fff;
      margin-left: -6px;
      box-shadow:  0 0 2px rgba(50,50,50,.2);
    }
  }
  .el-dialog{
    margin-top: 10vh !important;
    user-select: none;
    .reportNumber{
      margin-left: 95px;
      font-size: 12px;
      color: #ff4747;
      height: 0;
      transition: height .2s;
      overflow: hidden;
      text-align: justify;
    }
    .reportPeople{
      margin-left: 128px;
      font-size: 13px;
      height: 0;
      transition: height .2s;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
      span{width: 50%;}
    }
    .sortBox{
      margin-right: 30px;
      min-width: 120px;
      h6{
        margin-right: 6px;
        white-space: nowrap;
        font-size: 13px;
        font-weight: normal;
      }
      input{
        height: 28px;
      }
      .is-focus .el-input__inner, .el-input__inner:focus{
        border-color: #6b6be5
      }
      .el-input__icon{
        line-height: 28px;
      }
      .btn{margin-left: 6px;}
    }
  }
  .head{
    display: flex;
    align-items: center;
    margin: 0 auto;
    span{
      text-align: left;
      overflow: hidden;
      margin: 0 2px;
      text-overflow: ellipsis;}
  }
  // .total{
  //   font-size: 14px;
  //   text-align: right;
  //   margin-top: 20px;
  // }
  .qrcode{
    img{
      width: 136px;
      height: 136px;
      display: block;
      margin: -10px auto 0;
    }
    .downloadBtn{
      border-radius: 4px;
      width: 100px;
      color: #fff;
      background: #6b6be5;
      text-align: center;
      line-height: 30px;
      height: 30px;
      margin: 20px auto;
      cursor: pointer;
    }
    p{
      color: #868686;
      a{color: #6b6be5;cursor: pointer;}
    }
  }
}

@media (max-width: 1100px) {
  .team .table .head span{width: 60px}
}
</style>