<template>
  <div class="company_header">
    <div class="button" @click="navShow=!navShow" v-show="btnShow">
      <i></i><i></i><i></i>
    </div>
    <router-link to="/" class="logo-row">
      <img src="../../assets/images/logo-small.png">十方实验室
    </router-link>
    <section class="top_path" v-for="(item,i) in history" :key="i" >
      <i class="el-icon-arrow-right" v-if="item.name"></i>
      <p :class="{link:item.path}" @click="tolink(item.path)">{{item.name}}</p>
    </section>
    <div style="flex:1"></div>
    <router-link to="/company/news" class="tool news" :class="{curr:this.$route.name=='消息'}">
      消息<span v-show="unreadNumber">{{unreadNumber}}</span>
    </router-link>
    <div class="user flex-cen-cen">
      <img :src="avatar">
      <div class="text">
        <p v-if="realname">{{realname}}</p>
        <p>{{admin}}</p>
      </div>
      <ul>
        <li @click="tolink('/company')" v-if="authority==1002 || authority==1003 || authority==1004">企业管理中心</li>
        <li @click="tolink('/expert/manage')" v-if="authority==1005 || authority==1004">教练用户中心</li>
        <!-- <li @click="tolink('/userCenter')" v-if="authority==1||authority==3">个人中心</li> -->
        <li @click="loginOut">退出登录</li>
      </ul>
    </div>
    
    <div class="mask" :class="{maskShow:navShow}" @click="navShow=false"></div>
    <div class="drawer" :style="{left: navShow ? 0 : '-220px'}">
      <company_nav v-if="btnShow"></company_nav>
    </div>
  </div>
</template>
<script>
import company_nav from './nav'
export default {
  components: {company_nav},
  props: {
    btnShow: {default: false},
  },
  data(){
    return{
      navShow: false,
      mobile: localStorage.getItem('mobile') || '',
      realname: localStorage.getItem('realname') || '',
      avatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
      unreadNumber: 0,
      admin: '',
      authority: localStorage.getItem('authority') || '', 
      history: [],
    }
  },
  created(){
    // 获取个人用户信息
    this.$request({url:'/User/getUserInfo?header',
      success: d => {
        if (d.code == 0) {
          let admin = ['','超级管理员','普通管理员','账号管理员']
          this.admin = admin[d.data.manage_type]

          if (localStorage.user_id != d.data.user_id) { //与本地信息不一致
            localStorage.user_id = d.data.user_id
            localStorage.mobile = this.mobile = d.data.mobile
            localStorage.realname = this.realname = d.data.realname
            localStorage.avatar = this.avatar = d.data.avatar || require('../../assets/images/head.png')
            //1001有个人权限，1002有企业权限，1003有个人和企业权限，1004有个人和企业及教练权限，1005有个人和教练权限
            let auth = d.data.authority
            if (auth.company.is_auth && auth.coache.is_auth) 
              this.authority = 1004
            else if (auth.coache.is_auth)
              this.authority = 1005
            else if (auth.company.is_auth)
              this.authority = 1003
          }
        }
      }
    })
    // 获取账号权限
    // this.$request({url: '/User/getAuthority',
    //   success: (data) => {
    //     if (data.code == 1001) {  //有个人权限
    //     } else if (data.code == 1002) { //有企业权限
    //     } else if (data.code == 1003) {  //个人和企业权限都有
    //       this.authority = 3
    //     }
    //   },
    // })
    this.getUnread()
    this.listenRoute()
  },
  methods: {
    loginOut(){
      this.$request({url:'/User/loginOut',
        success: ({data}) => {
          localStorage.clear()
          this.$message({duration: 2000,message: '退出成功',type:'success'})
          this.$router.replace({path:'/login'})
        }
      })
    },
    // 获取未读数
    getUnread(){
      return new Promise((resolve)=>{
        this.$request({
          url:'/Message/getList',
          data: {type: 'unread'},
          success: (data) => {
            if (data.code == 0) {
              this.unreadNumber = data.data.total
              resolve(data.data.total)
            }
          },
        })
      })
    },
    tolink(path){
      if (path)
        this.$router.replace({path:path})
    },
    listenRoute(route){
      let {path, matched} = route || this.$route
      if (path.indexOf('/amt') > -1 || path.indexOf('/tvr') > -1 || path.indexOf('/cbc') > -1 || path.indexOf('/lsn') > -1 || path.indexOf('/cognition') > -1 || path.indexOf('/tvs') > -1) {
        this.history = [...[{name:'企业用户中心',path:'/company'}],...matched]
      } else {
        this.history = matched
      }
    },
  },
  watch:{
    $route: {
      handler(val){
        this.listenRoute(val)
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.company_header{
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 48px;
  box-shadow: 0 2px 4px rgba($color: #666, $alpha: 0.2);
  font-size: 14px;
  z-index: 2;
  background: #fff;
  user-select: none;
  .button{
    width: 0px;
    height: 100%;
    background: #6b6be5;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: btn .1s forwards;
    i{
      width: 19px;
      height: 2px;
      background: #fff;
      margin: 2px 0;
    }
    @keyframes btn {
      0%{width: 0};
      100%{width: 50px;}
    }
  }
  .logo-row{
    font-size: 14px;
    width: 100px;
    margin-left: 20px;
    white-space: nowrap;
    img{width: 24px;height: 24px;}
    &:hover{
      font-weight: bold;
    }
  }
  .tool{
    color: #868686;
    line-height: 48px;
    white-space: nowrap;
    padding: 0 30px;
    cursor: pointer;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 4px;
      left: 0;
      bottom: 0;
      background: #6b6be5;
      border-radius: 2px;
      display: none;
    }
    &:hover{
      background: #f0f4fa;
    }
  }
  .news{
    padding: 0 20px;
    height: 100%;
    span{
      position: absolute;
      padding: 0 6px 2px;
      line-height: 14px;
      font-size: 12px;
      color: #fff;
      background: #ff4747;
      border-radius: 8px;
      left: 46px;
      top: 8px;
      transform: scale(.9,.9);
    }
  }
  .curr::before{display: block;}
  .user{
    color: #2d2d2d;
    height: 48px;
    margin: 0 20px;
    position: relative;
    cursor: pointer;
    .text{
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 13px;
      width: 64px;
      p{
        flex-shrink: 0;
        width: 65px;
        white-space:nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &:nth-child(2){font-size: 12px;color: #999}
      }
    }
    img{
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;
      overflow: hidden;
      box-shadow: 0 0 4px rgba(150,150,150,.4);
      flex-shrink: 0;
    }
    &:hover ul{
      transform: scaleY(1);
    }
    ul{
      position: absolute;
      background: #fff;
      text-align: center;
      width: 86px;
      z-index: 100;
      top: 100%;
      color: #868686;
      border-radius: 4px;
      box-shadow: 0 -1px 6px 0 rgba(0,0,0,.12);
      transform-origin: center top;
      transform: scaleY(0);
      transition: transform .1s;
      li{
        line-height: 36px;
        border-radius: 4px;
        cursor: pointer;
        &:hover{
          background: #f5f9ff;
          color: #2b2b2b;
        }
      }
        &::before{
          content: '';
          position: absolute;
          top: -9px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 8px solid rgba(230,230,230,.5);
        }
        &::after{
          content: '';
          position: absolute;
          bottom: 100%;
          left: 50%;
          transform: translateX(-50%);
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 8px solid #fff;
        }
    }
  }
  .el-icon-arrow-right{
    margin: 0 5px;
  }

  .mask{
    position: fixed;
    width: 100%;
    left: 0;
    top: 48px;
    bottom: 0;
    background: rgba(0,0,0,.6);
    display: none;
    z-index: 20;
  }
  .drawer{
    position: fixed;
    height: 100%;
    top: 48px;
    z-index: 21;
    left: -220px;
    transition: left .3s;
  }
  .maskShow{
    display: block;
  }
}
</style>