<template>
  <div class="inviteDetail">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_invite.png">邀请管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <input type="text" placeholder="搜索" class="search-input" v-model="keyword" @input="searchInvite">
      <b style="flex:1"></b>
      <div class="btn" @click="inviteShow=true">邀请成员</div>
    </div>
    <el-table
      :data="inviteList"
      tooltip-effect="dark"
      ref="inviteTable"
      height="calc(100vh - 238px)"
      style="width: 100%;">

      <el-table-column label="管理员姓名" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.invite_avatar || require('../../../assets/images/head.png')">
            <span>{{scope.row.invite_realname || scope.row.invite_nickname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="手机号" min-width="110">
        <template slot-scope="scope">
          <span>{{$mobileToStar(scope.row.invite_mobile)}}</span>
        </template>
      </el-table-column>
      
      <el-table-column label="邀请时间" min-width="132">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="invite_num" label="邀请数量" min-width="80" align="center"></el-table-column>

      <el-table-column prop="active_num" label="激活数量" min-width="80" align="center"></el-table-column>

      <el-table-column prop="complete_num" label="完成数量" min-width="80" align="center"></el-table-column>

      <el-table-column label="邀请状态" min-width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.status==2" class="red">已结束</span>
          <span v-else-if="scope.row.status==302" class="red">已过期</span>
          <span v-else-if="scope.row.status==1" class="blue">进行中</span>
          <span v-else class="grey">已失效</span>
        </template>
      </el-table-column>
      
      <el-table-column label="操作" min-width="265" align="center">
        <template slot-scope="scope">
          <span class="btn" v-if="scope.row.active_num" @click="getMember(20, 1, scope.row)">邀请详情</span>
          <el-tooltip v-else effect="dark" class="gray" content="该邀请无成员加入" placement="top" :open-delay="400">
            <el-button>邀请详情</el-button>
          </el-tooltip>

          <span class="btn" @click="qrcodeShow=true;qrcode=scope.row.qrcode_image" v-if="scope.row.status==1 && scope.row.qrcode_image">小程序码</span>
          <el-tooltip v-else effect="dark" class="gray" content="邀请已结束" placement="top" :open-delay="400">
            <el-button>小程序码</el-button>
          </el-tooltip>
          <!-- <span class="btn-disable" v-else>小程序码</span> -->
          
          <el-tooltip v-if="scope.row.status==0 || scope.row.status==2" effect="dark" class="gray" content="邀请已结束" placement="top" :open-delay="400">
            <el-button>结束邀请</el-button>
          </el-tooltip>
          <el-tooltip v-else-if="scope.row.is_stop_auth==0" effect="dark" class="gray" content="不能结束他人邀请" placement="top" :open-delay="400">
            <el-button>结束邀请</el-button>
          </el-tooltip>
          <span class="btn" @click="endConfirm(scope.row)" v-else>结束邀请</span>
          <!-- <span class="btn-disable" v-else>结束邀请</span> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="invitePage" @change="getInvite" :total="inviteTotal"></pagination>

    <!-- 邀请成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <nav class="top-nav">
          <span :class="{current:inviteType==0}" @click="toggle(0)">手机号邀请</span>
          <span :class="{current:inviteType==1}" @click="toggle(1)">小程序码邀请</span>
        </nav>

        <template v-if="inviteType==0">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="16"
                v-model="inviteName"
                @input="setError"
                ref="inviteName">
              <div class="input-icon icon-clear" title="清空" v-show="inviteName" @click="inviteName=''"></div>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==4}">
              <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
                <el-option
                  v-for="(item,code) in $country"
                  :key="code"
                  :label="item.label"
                  :value="code">
                </el-option>
              </el-select>
              <input 
                class="input"
                style="padding-top:2px"
                placeholder='请输入手机号'
                maxlength="11"
                v-model="phone"
                @input="inputChange($event,'phone')"
                ref="phone">
              <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
              <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：邀请有效期为72小时</p>
          <div class="bottom-btn" @click="mobileInvite">确 认</div>
        </template>
        <template v-else-if="inviteType==1">
          <div class="input-row" style="margin:60px 0">
            <h4>数量</h4>
            <div class="box" :class="{error:errorType==5}">
              <input
                class="input"
                placeholder='请输入数量'
                maxlength="6"
                @input="inputChange($event,'sendNumber')"
                v-model="sendNumber">
              <div class="input-icon icon-clear" title="清空" v-show="sendNumber" @click="sendNumber=''"></div>
              <div class="errText" v-show="errorType==5">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：邀请有效期为72小时</p>
          <div class="bottom-btn loading-dot" v-if="creating">生成中<i>.</i><i>.</i><i>.</i></div>
          <div class="bottom-btn" @click="createQrcode" v-else>生成小程序码</div>
        </template>
        <template v-else>
          <div class="qrcode">
            <img :src="qrcode">
            <div class="downloadBtn" style="opacity:.8;cursor:not-allowed;" v-if="qrcodeDownload">已下载</div>
            <div @click="download" class="downloadBtn" v-else>下 载</div>
            <p>提示：被邀请人可通过扫描此小程序码进入评估，可在列表中查看邀请详情或结束此邀请</p>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 邀请详情框 -->
    <el-dialog
      :title="`邀请详情`"
      class="dialog-large"
      :visible.sync="memberListShow"
      :close-on-click-modal="false"
      center>
      <el-table
        :data="memberList"
        tooltip-effect="dark"
        style="width: 100%">

        <el-table-column label="姓名" min-width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname || scope.row.nickname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="手机号" min-width="110" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>
        
        <el-table-column prop="date" label="激活时间" min-width="132" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.exam_start_time,'y-m-d h:m')||'-'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="层级" min-width="92" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.tier_name">{{scope.row.tier_name}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="自评状态" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="完成时间" min-width="132" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.exam_complete_time,'y-m-d h:m')||'-'}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="other_exam_count" label="他评人数" min-width="80" align="center"></el-table-column>

        <el-table-column prop="other_exam_complete_count" label="他评完成人数" min-width="110" align="center"></el-table-column>

      </el-table>

      <pagination 
        v-show="memberList.length>2" 
        ref="memberPage"  
        :position="'page-botttom'"
        @change="getMember" 
        :total="memberTotal">
      </pagination>
    </el-dialog>
    <!-- 小程序码 -->
    <el-dialog
      title="小程序码"
      :visible.sync="qrcodeShow"
      :close-on-click-modal="false"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="qrcode qrcode-old">
          <img :src="qrcode">
          <div @click="download" class="downloadBtn">下 载</div>
          <p>提示：被邀请人可通过扫描此小程序码进入评估</p>
        </div>
      </div>
    </el-dialog>
    <!-- 结束邀请确认框 -->
    <el-dialog
      title="结束邀请"
      class="el-dialog-confirm"
      :visible.sync="endShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      center>
      <span>结束邀请后小程序码即失效，是否结束本次邀请？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="end">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      loading: false,
      reg: this.$CNReg,           // 当前区号的手机号正则
      country_code: '86',         // 区号
      errorType: 0,
      errorMsg: '',

      endShow: false,
      qrcodeShow: false,
      keyword: '',
      inviteShow: false,
      inviteType: 0,              // 邀请页面（0：手机号邀请，1：小程序码邀请，2：小程序码）
      inviteName: null,
      phone: null,
      sendNumber: null,
      creating: false,            // 正在生成小程序码
      qrcode: '',                 // 生成的小程序码
      qrcodeDownload: false,      // 是否已经下载了小程序码
      
      inviteList: [],
      inviteTotal: 0,

      memberLoading: false,
      memberListShow: false,
      memberList: [],
      memberTotal: 0,
      nowMember: {},
    }
  },
  mounted(){
    this.getInvite(20,1)
  },
  methods: {
    // 获取邀请列表
    getInvite(per_page, page){
      this.loading = true
      let ref = this.$refs.invitePage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/CognitiveDifferences/getInvite',
        data: {
          per_page: per_page,
          page: page,
          keyword: this.keyword,
        },
        success: (data) => {
          if (data.code == 0) {
            this.inviteList = data.data.data
            this.inviteTotal = data.data.total
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear()
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.loading = false
      })
    },
    // 获取邀请详情
    getMember(per_page, page, row){
      this.memberLoading = true;
      this.memberListShow = true;
      let memberPage = this.$refs.memberPage
      per_page = memberPage ? memberPage.per_page : per_page || 20
      page = memberPage ? memberPage.page : page || 1
      this.$request({
        url:'/CognitiveDifferences/getInviteDetail',
        data: {
          invite_id: row ? row.invite_id : this.nowMember.invite_id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.memberList = data.data.data
            this.nowMember = row ? row : this.nowMember
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    // 切换 短信登录/账号密码登录
    toggle(type){
      if (this.inviteType == type)  return;
      else if (this.inviteType==2 && type==1) return
      this.inviteType = type
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
      this.setError()
    },
    searchInvite(e){
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(()=>{
        this.keyword = e.target.value
        this.getInvite(null, 1)
        this.$refs.invitePage.page = 1
      }, 600)
    },
    //选择区号和手机号正则
    selectCountry(val){
      this.reg = this.$country[val].reg
      this.country_code = val
    },
    inputChange(e,key){
      let num = e.target.value.replace(/\D/g,'')
      this[key] = num
      this.errorType = 0
    },
    download(){
      if (this.qrcodeDownload == false) {
        window.open(`${this.$baseRoot}/CognitiveDifferences/downQrcode?qrcode_path=${this.qrcode}`)
        this.qrcodeDownload = true
      }
    },
    //手机号邀请新成员
    mobileInvite(){
      if (!this.inviteName) {
        this.setError(3,'请先输入姓名')
        this.$refs.inviteName.focus()
        return
      } else if (!this.phone) {
        this.setError(4,'请先输入手机号')
        this.$refs.phone.focus()
        return
      } else if (!this.reg.test(this.phone)) {
        this.setError(4,'手机号码格式不对')
        this.$refs.phone.focus()
        return
      }
      this.$request({
        url:'/CognitiveDifferences/baseMobileInvite',
        data: {
          realname: this.inviteName,
          mobile: this.phone,
          country_code: this.country_code,
        },
        success: (data) => {
          if (data.code == 0) {
            this.$message({duration: 1000,message: '邀请成功', type:'success'})
            this.inviteShow = false
            this.getInvite(20, 1)
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    // 生成邀请二维码
    createQrcode(){
      if(!this.sendNumber){
        this.setError(5, '请先输入数量')
        return
      }
      this.creating = true
      this.$request({
        url:'/CognitiveDifferences/codeOrgInvite',
        data: {invite_num: this.sendNumber},
        success: (data) => {
          if (data.code == 0) {
            this.getInvite(null,1)
            this.$request({
              url: this.$planetUrl+'/Common/createQrcode',
              data: {
                param: data.data.id + '=' + data.data.code,
                page: 'pages/CD/accept/accept',
                width: 280,
              },
              success: (e) => {
                if (e.code == 0) {
                  this.getInvite(null, 1)
                  this.inviteType = 2
                  this.qrcode = e.data.qrcode_path
                } else {
                  this.$message({duration: 2000, message: e.msg})
                }
              },
              complete: ()=>{
                this.creating = false
              }
            })
          } else {
            this.creating = false
            this.$message({duration: 2000, message: data.msg})
          }
        },
        error: ()=>{
          this.creating = false
          this.$message.error('网络错误，请稍后再试')
        }
      })
    },
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    //结束邀请
    endConfirm(row){
      this.nowMember = row
      this.endShow = true
    },
    end(){
      this.$request({
        url:'/CognitiveDifferences/updateInviteStatus',
        data: {
          invite_id: this.nowMember.invite_id,
          status: 0,
        },
        success: (data) => {
          this.endShow = false
          if (data.code == 0) {
            this.$message({duration:3000, message:'邀请已结束', type:'success'})
            this.getInvite()
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
      })
    },
    closeDialog(){
      this.nowMember = {}
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
      this.setError()
      this.inviteType = 0
      this.qrcodeDownload = false
    },
  },
}
</script>

<style lang="scss" scoped>
.inviteDetail{
  .qrcode{
    text-align: justify;
    img{
      width: 136px;
      height: 136px;
      display: block;
      margin: 0 auto;
      background: #e5e5e5;
    }
    .downloadBtn{
      border-radius: 4px;
      width: 100px;
      color: #fff;
      background: #6b6be5;
      text-align: center;
      line-height: 30px;
      height: 30px;
      margin: 20px auto;
      cursor: pointer;
    }
    p{
      color: #868686;
      a{color: #6b6be5;cursor: pointer;}
    }
  }
  .qrcode-old{
    padding-top: 30px;
    img{margin: 0 auto;}
    p{margin: 40px 0 10px;}
  }
}

</style>