<template>
  <div class="reportCenter">
    <h1 class="h1_title">
      <img  class="head" src="../../../assets/images/company/nav_report.png">报告管理
    </h1>
    <div class="move-up"/>

    <el-table
      v-loading="loading"
      ref="table"
      class="reportTable"
      :data="report"
      tooltip-effect="dark"
      height="calc(100vh - 208px)"
      style="width: 100%">
      <el-table-column label="报告名称" min-width="200" show-overflow-tooltip>
        <template slot-scope="scope">
          <span class="latest" v-if="scope.row.id==newId">新</span>
          <span class="istop"  v-if="scope.row.istop">顶</span>
          <span>{{scope.row.report_name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="生成时间" min-width="132" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.create_time}}</span>
        </template>
      </el-table-column>
      <el-table-column label="人数" min-width="60" align="center">
        <template slot-scope="scope">
          <span class="num" @click="getMember(20, 1, scope.row)">{{scope.row.total_number}}{{scope.row.state}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <a class="btn" :href="`${$mobileUrl}/#/lsnTeam/${scope.row.id}`" target="_blank">预览报告</a>
          <a class="btn" :href="`${$baseRoot}/Lsn/downTeamReport?report_id=${scope.row.id}`" target="_blank">下载报告</a>
          <span class="btn red" v-if="scope.row.istop" @click="setTop(scope.row,0)">取消置顶</span>
          <span class="btn" v-else @click="setTop(scope.row,1)">置顶报告</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination ref="reportPage" @change="getReport" :total="reportTotal"/>

    <el-dialog :title="'报告成员'" class="dialog-middle" :visible.sync="memberShow" :close-on-click-modal="false" center>
      <el-table :data="member" tooltip-effect="dark" style="width: 100%">

        <el-table-column label="姓名" min-width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname || scope.row.nickname}}</span>
            </div>
          </template>
        </el-table-column>
        
        <!-- <el-table-column prop="date" label="性别" min-width="132" align="center">
        </el-table-column> -->

        <el-table-column label="手机号" min-width="110" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="tier" label="评估层级" min-width="100" align="center"/>

        <el-table-column prop="type" label="职位类型" min-width="90" align="center"/>

        <el-table-column label="最新完成时间" min-width="132" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.complete_time,'y-m-d h:m')||'-'}}</span>
          </template>
        </el-table-column>

      </el-table>
      <pagination v-show="member.length>2" ref="memberPage" :position="'page-botttom'" @change="getMember" :total="memberTotal"/>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination,},
  data(){
    return {
      loading: false,
      newId: '',//判断报告是否为新
      
      report: [],
      reportTotal: 0,

      memberShow: false,
      member: [],
      memberTotal: 0,
      now: {},
    }
  },
  mounted(){
    this.getReport()
  },
  methods: {
    getReport(per_page, page){
      this.loading = true
      let ref = this.$refs.reportPage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
        this.$request({
          method: 'post',
          url: '/Lsn/getReportList',
          data: {
            per_page: per_page,
            page: page,
          },
          success: (data) => {
            if (!data.code) {
              this.report = data.data.data
              this.reportTotal = data.data.total
            } else if (data.code == 101) {
              this.$router.replace({path:'/login',query:{back:this.$route.path}})
              localStorage.clear()
            } else {
              this.$message({duration:2000,message:data.msg,type:'error'})
            }
          },
          complete: () => this.loading = false
        })
    },
    //成员详情
    getMember(per_page, page, row){
      this.memberShow = true

      let memberPage = this.$refs.memberPage
      per_page = memberPage ? memberPage.per_page : per_page || 20
      page = memberPage ? memberPage.page : page || 1
      this.$request({
        url:'/Lsn/getReportMember',
        data: {
          report_id: row ? row.id : this.now.id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.member = data.data.data
            this.memberTotal = data.data.total
            this.now = row ? row : this.now
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
      })
    },
    setTop(row, istop){
      this.loading = true
      this.$http.post('/Lsn/istopReportSet', {
        report_id: row.id,
        istop: istop,
      }).then( ({data}) => {
        this.loading = false
        if (!data.code) {
          this.getReport()
        } else {
          this.$message({duration: 1500, message: data.msg, type:'error'})
        }
      }, () => {
        this.loading = false
        this.$message({duration:1000, type:'error', message:'网络异常'})
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.reportCenter{
  .reportTable{margin-top: 40px}
  .total{
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
  }
  h6{
    font-size: 14px;
    white-space: nowrap;
    margin: -30px 0 20px;
    color: #999;
    font-weight: normal;
    text-align: center;
  }
  .latest{
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    line-height: 18px;
    text-align: center;
    background: #ff4747;
    color: #fff;
    font-size: 12px;
  }
  .istop{
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    line-height: 18px;
    text-align: center;
    background: #f97286;
    color: #fff;
    font-size: 12px;
  }
  .loading{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    z-index: 0;
  }
  .num{
    color: #f39c12;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 6px;
    &:hover{
      background: #f39c12;
      color: #fff;
    }
  }
}
</style>