<template>
  <div class="evaluate" v-if="role=='cp'">
    <h1>咨询已结束，请对本次咨询进行评价</h1>
    <h2>请你为教练评星（1~5星，星级越高代表评价越高）</h2>
    <template v-if="done">
      <div class="starBox">
        <i class="star" v-for="i in 5" :key="i" :class="{star5: i<=star}" />
      </div>
      <p>{{txt||'无更多评价'}}</p>
      <div class="btn">感谢你的评价</div>
    </template>
    <template v-else>
      <div class="starBox">
        <i class="star" v-for="i in 5" :key="i" :class="{star5: i<=star || i<=hover}" @click="star=i" @mouseover="hover=i" @mouseout="hover=star"/>
      </div>
      <textarea placeholder="请你为教练进行评价" v-model="txt"/>
      <div class="btn" @click="companySubmit">提交</div>
    </template>
  </div>
  <div class="evaluate expertNote" v-else-if="role=='ex'">
    <h1>咨询已结束，请完成本次工作记录</h1>
    <h2>1、关键问题</h2>
    <textarea placeholder="请输入该企业出现的问题" v-model="issue"/>
    <h2>2、解决方案</h2>
    <textarea placeholder="你为该企业什么解决方案" v-model="solution	"/>
    <h2>3、其他备注（选填）</h2>
    <textarea placeholder="其他备注" v-model="remark"/>
    <div class="btn" @click="expertSumbit">提交</div>
  </div>
</template>
<script>
export default {
  props: ['mid'],
  data(){
    return {
      back: this.$route.query.back||'',
      role: this.$route.query.role||'cp', //ex:教练, cp:企业
      meetingId: this.$route.query.mid||'',  //会议id
      done: false,
      star: 0,
      txt: '',
      hover: 0,
      issue: '',
      solution: '',
      remark: '',
    }
  },
  created(){
    this.meetingId = this.meetingId || this.mid
    if (this.role == 'cp')
      this.$request({url: '/Chat/getEvaluation',
        data: {meeting_id: this.meetingId},
        success: d => {
          if (d.code == 0) {
            this.done = true
            this.radio = d.data.level
            this.star = d.data.star
            this.txt = d.data.content
          }
        },
      })
  },
  methods:{
    companySubmit() {
      if (!this.star) {
        this.$message({message: '请完成评价', type: 'warning'})
        return
      }
      this.$request({url: '/Chat/addEvaluation',
        data: {
          meeting_id: this.meetingId,
          star: this.star,
          content: this.txt,
        },
        success: d => {
          if (d.code == 0) {
            this.done = true
            this.$message({message: '感谢你的评价', type: 'success'})
            sessionStorage.consultTab = 1
            this.$router.replace({path: '/company/consult'})
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    expertSumbit() {
      if (!this.issue) {
        this.$message({message: '请输入关键问题', type: 'warning'})
        return
      }
      if (!this.solution) {
        this.$message({message: '请输入解决方案', type: 'warning'})
        return
      }
      this.$request({url: '/Coaches/addNote',
        data: {
          meeting_id: this.meetingId,
          issue: this.issue,
          solution: this.solution,
          remark: this.remark,
        },
        success: d => {
          if (d.code == 0) {
            if (this.back)
              this.$router.replace({path: this.back})
            else 
              window.close()
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
  },
}
</script>
<style lang="scss">
.evaluate{
  background: #fff;
  box-shadow: 0 0 10px rgba(102,102,102,.1);
  border-radius: 8px;
  padding: 1px 30px;
  width: 600px;
  user-select: none;
  margin: 20px auto;
  h1{
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    padding: 40px 0 20px;
  }
  h2{
    padding: 20px 0 10px;
    margin-top: 20px;
    border-top: 1px solid #f6f6f6;
    font-size: 14px;
    font-weight: bold;
  }
  .starBox{
    display: flex;
    margin-left: -4px;
    margin-bottom: 40px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f6f6f6;
    .star{
      box-sizing: content-box;
      background-image: url('../../assets/images/expert/expert_star4.png');
      padding: 2px 4px;
      background-size: 20px 20px;
      background-position: center;
      cursor: pointer;
      // &:hover, &:hover~.star{background-image: url('../../assets/images/expert/expert_star5.png');}
    }
    .star5{background-image: url('../../assets/images/expert/expert_star5.png')}
  }
  textarea, p{
    width: 100%;
    text-align: justify;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 24px;
    padding: 4px 6px;
    height: 20vh;
    resize: none;
    outline: none;
    border: none;
    background: #f9f9f9;
    border-radius: 8px;
    font-family: auto;
    &::-webkit-scrollbar{display: none}
    &::placeholder{font-size: 12px;color: #ccc;}
  }
  .btn{
    margin: 30px auto;
    width: 200px;
    text-align: center;
    color: #fff;
    background: #6b6be5;
    border-radius: 4px;
    line-height: 40px;
    cursor: pointer;
  }
  .el-radio__inner:hover{border-color: #6b6be5;}
  .el-radio__input.is-checked+.el-radio__label{color: #6b6be5;}
  .el-radio__input.is-checked .el-radio__inner{border-color: #6b6be5;background: #6b6be5;}
  .el-radio__inner{margin-bottom: 1px;}
  .el-radio__label{padding-left: 8px;}
}
.expertNote{
  margin: 10px auto 0;
  h1{padding: 30px 0 10px}
  textarea{height: 100px;}
  .btn{margin: 20px auto;}
}
</style>