<template>
  <div class="footer" :class="position" :style="{background:bgcolor}">
    <p>Copyright © {{year}} Huayanspace. All Rights Reserved. </p>
    <div class="link flex-cen-cen">
      <a target="_blank" class="a1 flex-cen-cen" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009082">
          <img src="../../assets/images/index/police.png"/>
          <div>粤公网安备 44010602009082号</div>
      </a>
      <a href="https://beian.miit.gov.cn/" target="_blank" class="a2">粤ICP备 20012064号</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    position: {
      type: String,
      default: '',
    },
    bgcolor: {
      type : String,
      default : '#fff'
    }
  },
  data(){
    return {
      year: '',
    }
  },
  created(){
    var year = new Date().getFullYear();
    this.year = `${year-1}-${year}`
  },
}
</script>
<style lang="scss" scoped>
.footer{
  padding: 16px 0;
  color: #939393;
  font-size: 12px;
  line-height: 2;
  white-space: nowrap;
  .link{
    width: 400px;
    margin: 0 auto;
    img{margin-right: 5px;}
    a:hover{text-decoration: underline}
    .a2{
        text-align: right;
        padding-left: 10px;
    }
  }
  p{
    text-align: center;
  }
}
.fixed{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 0;
}
</style>