<template>
  <div class="case">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_case.png">企业案例
    </h1>
    <div class="move-up"></div>
    <el-table :data="list" tooltip-effect="dark" height="calc(100vh - 208px)" style="width:100%;margin-top:40px">
      <el-table-column prop="companyname" label="企业名称" min-width="120" align="center" show-overflow-tooltip/>
      <el-table-column label="管理员" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../assets/images/head.png')">
            <span>{{scope.row.realname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="meeting_time_str" label="咨询时间" min-width="164" align="center"/>
      <el-table-column label="类型" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.type==1 ? '付费' : '免费'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="getNote(scope.row)">工作备忘</span>
          <span class="btn" @click="getLog(scope.row)">会话记录</span>
        </template>
      </el-table-column>
      
    </el-table>
    <pagination ref="casePage" @change="getList" :total="total"/>
    
    <!-- 会话记录 -->
    <el-dialog
      title="会话记录"
      :visible.sync="chatShow"
      :close-on-click-modal="false"
      @close="closeLog"
      width="600px"
      center>
      <div class="msgBox" ref="scroll" @scroll="scroll">
        <ul>
          <li v-for="(item,index) in chatList" :key="index">
            <!-- 时间 -->
            <div class="time" v-if="item.show_time">{{formatTime(item.show_time)}}</div>

            <!-- 系统提示 -->
            <div class="row c" v-if="item.content_type=='tips'">
              <div class="tip">{{item.content}}</div>
            </div>
            <!-- 聊天内容 -->
            <div class="row" :class="{r: item.from_id==now.from_id}" v-else>
              <div class="avatar">
                <img v-if="item.from_id==now.from_id" :src="localAvatar">
                <img v-else :src="now.avatar||require('../../assets/images/head.png')">
              </div>

              <p class="confirm" v-if="item.content_type=='confirm'">
                教练需要结束会议，你是否同意结束？
                <span v-if="item.is_confirm==-1">
                  <a class="grey">已取消</a>
                </span>
                <span v-else-if="item.is_confirm==1">
                  <a class="grey">已结束</a>
                </span>
              </p>
              <p class="contact" v-else-if="item.content_type=='card'">
                <img :src="item.avatar||require('../../assets/images/head.png')">
                <span>
                  <h3>{{item.realname}}</h3>
                  <h4>{{item.mobile||'-'}}</h4>
                  <h4>{{item.email||'-'}}</h4>
                  <h4>{{item.wx_number||'-'}}</h4>
                </span>
              </p>
              <p v-else-if="item.content_type=='report'" class="link"><a :href="item.url" target="_blank">{{item.title}}</a></p>
              <p v-else>{{item.content}}</p>
              
              <span class="txt" v-if="item.content_type=='card'">名片</span>
              <span class="txt" v-else-if="item.content_type=='report'">链接</span>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!-- 工作备忘记录 -->
    <el-dialog title="工作备忘记录" class="note" :visible.sync="noteShow" :close-on-click-modal="false" width="700px" top="8vh" center>
      <div class="table-btn" v-if="noteList.length<10">
        <div class="btn" @click="addNoteShow=true;noteShow=false">添加新备忘</div>
      </div>
      <el-timeline>
        <el-timeline-item v-for="(item,index) in noteList" :key="index" :timestamp="$formatTime(item.create_time, 'y-m-d h:m')" placement="top">
          <el-card>
            <h6>关键议题</h6>
            <p>{{item.issue}}</p>
            <h6>解决方案</h6>
            <p>{{item.solution}}</p>
            <template v-if="item.remark">
              <h6>其他备注</h6>
              <p>{{item.remark}}</p>
            </template>
          </el-card>
        </el-timeline-item>
      </el-timeline>
    </el-dialog>
    <!-- 工作备忘 -->
    <el-dialog title="添加新备忘" :visible.sync="addNoteShow" :close-on-click-modal="false" @close="closeAddNote" width="600px" top="8vh" center>
      <div class="note">
        <h5>1、关键议题</h5>
        <textarea placeholder="请输入此次服务的议题" v-model="issue"/>
        <h5>2、解决方案</h5>
        <textarea placeholder="你为该企业什么解决方案" v-model="solution	"/>
        <h5>3、其他备注（选填）</h5>
        <textarea placeholder="其他备注" v-model="remark"/>
        <div class="button" @click="addNote">提交</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      list: [],
      total: 0,
      //会话记录
      now: {},
      localAvatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
      chatShow: false,
      chatList: [],
      chatPage: 1,
      chatTotal: 0,
      noteShow: false,
      noteList: [],
      addNoteShow: false,
      issue: '',
      solution: '',
      remark: '',
    }
  },
  created(){
    this.getList(20, 1)
  },
  methods:{
    getList(per_page, page){
      let ref = this.$refs.casePage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$http.post('/Coaches/getCaseList', {
        per_page: per_page,
        page: page
      }).then( ({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else if (data.code == 101) {
          this.$router.replace({path:'/login', query:{back:this.$route.path}})
          localStorage.clear()
        } else {
          this.$message({type: 'error', message:data.msg})
        }
      }, () => {
        this.$message({type:'error', message: '网络异常'})
      })
    },
    //获取会话记录
    getLog(row) {
      this.chatShow = true
      this.now = row
      this.$request({url: '/Chat/getChatLog',
        data: {
          meeting_id: row.meeting_id,
          from_id: row.from_id, 
          to_id: row.to_id,
          per_page: 20,
          page: this.chatPage++,
        },
        success: d => {
          if (d.code == 0) {
            for (let item of d.data.data) {
              if (item.content_type == 'report') {
                let obj = JSON.parse(item.content)
                item.title = obj.title
                item.url = obj.url
              } else if (item.content_type == 'card') {
                let obj = JSON.parse(item.content)
                item.avatar = obj.avatar
                item.realname = obj.realname
                item.mobile = obj.mobile
                item.wx_number = obj.wx_number
                item.email = obj.email
              }
            }

            let ele = this.$refs.scroll, oldH = ele.scrollHeight
            this.chatList = [...this.chatList, ...d.data.data]
            this.chatTotal = d.data.total
            //初始滑动到底部，之后保持滚动条位置
            this.$nextTick(() => {
              ele.scrollTo({top: ele.scrollHeight - oldH})
            })
          }
        },
      })
    },
    closeLog() {
      this.now = {}
      this.chatList = []
      this.chatTotal = 0
      this.chatPage = 1
    },
    scroll() {
      let ele = this.$refs.scroll
      if (ele.scrollTop < 1 && this.chatList.length < this.chatTotal) {
        this.getLog(this.now)
      }
    },
    formatTime(timestamp) {
      if(!timestamp)  return ''
      let now = new Date(),
          date = String(timestamp).length<13 ? new Date(timestamp*1000) : new Date(timestamp),
          year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate(),
          hours = date.getHours(),
          minutes = date.getMinutes();
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      if (now.toDateString() == date.toDateString()) {  //今日
        return `${hours}:${minutes}`
      } else if (now.getFullYear() == year) { //今年
        return `${month}月${day}日 ${hours}:${minutes}`
      } else {
        return `${year}年${month}月${day}日 ${hours}:${minutes}`
      }
    },
    getNote(row) {
      this.$request({url: '/Coaches/getNoteList',
        data: {meeting_id: row.meeting_id},
        success: d => {
          this.now = row
          if (d.code == 0) {
            this.noteShow = true
            this.noteList = d.data
          } else if (d.code == 404) {
            window.open(this.$router.resolve({path: '/expertEvaluate', query: {mid: row.meeting_id, role: 'ex'}}).href, '_blank')
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    addNote() {
      if (!this.issue) {
        this.$message({message: '请输入关键议题', type: 'warning'})
        return
      }
      if (!this.solution) {
        this.$message({message: '请输入解决方案', type: 'warning'})
        return
      }
      this.$request({url: '/Coaches/addNote',
        data: {
          meeting_id: this.now.meeting_id,
          issue: this.issue,
          solution: this.solution,
          remark: this.remark,
        },
        success: d => {
          if (d.code == 0) {
            this.addNoteShow = false
            this.issue = ''
            this.solution = ''
            this.remark = ''
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    closeAddNote() {
      this.noteShow = true
      this.getNote(this.now)
    },
  },
}
</script>
<style lang="scss" scoped>
.case{
  .msgBox{
    height: 50vh;
    overflow-y: auto;
    border-top: 1px solid #f0f0f0;
    margin-top: -10px;
    &::-webkit-scrollbar{display: none}
    ul{
      display: flex;
      flex-direction: column-reverse;
      li{
        .time{
          font-size: 12px;
          color: #cbcdd1;
          text-align: center;
          margin: 8px 0 6px;
          user-select: none;
        }
        .row{
          display: flex;
          align-items: center;
          margin: 8px 0;
          .avatar{
            padding: 6px 12px 0;
            align-self: flex-start;
            img{
              width: 32px;
              height: 32px;
              border-radius: 50%;
              user-select: none;
              background: url('../../assets/images/head.png') no-repeat;
              background-size: 100%;
            }
          }
          p{
            padding: 10px 12px;
            border-radius: 10px;
            border-top-left-radius: 0;
            box-shadow: 0 0 4px rgba(200,200,200,.2);
            font-size: 14px;
            color: #808080;
            background: #fafbff;
            border: 1px solid #eeeff4;
            text-align: justify;
            max-width: 60%;
            display: flex;
            align-items: center;
            word-break: break-all;
            img{
              width: 18px;
              height: 18px;
              margin-right: 4px;
            }
          }
          .link{
            padding: 0;
            user-select: none;
            background: #ebeef7;
            a{
              color: #6b6be5;
              padding: 10px 12px 10px 32px;
              background: url('../../assets/images/expert/report2.png') no-repeat;
              background-position: 12px center;
              background-size: 14px auto;
            }
          }
          .contact{
            display: flex;
            background: #ebeef7;
            img{
              width: 90px;
              height: 90px;
              flex-shrink: 0;
              border-radius: 8px;
              background: #fff;
              align-self: flex-start;
              user-select: none;
            }
            h3{
              margin: 0 8px;
              color: #262626;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.2;
            }
            h4{
              color: #808080;
              margin: 0 8px;
              padding: 2px 0 2px 20px;
              font-size: 14px;
              font-weight: normal;
              background: url('../../assets/images/head.png') no-repeat;
              background-size: 18px 18px;
              background-position: 0 3px;
              &:nth-child(2){background-image: url('../../assets/images/expert/expert_icon1.png')}
              &:nth-child(3){background-image: url('../../assets/images/expert/expert_icon2.png')}
              &:nth-child(4){background-image: url('../../assets/images/expert/expert_icon3.png')}
            }
          }
          .txt{
            font-size: 11px;
            color: #d1d1d1;
            margin: 0 10px;
            white-space: nowrap;
            user-select: none;
          }
          .fail{
            margin: 0 10px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            background: url('../../assets/images/expert/sendFail.png') no-repeat;
            background-size: 100% 100%;
          }
          .tip{
            font-size: 12px;
            background: #e5e5e5;
            text-align: center;
            padding: 2px 6px;
            border-radius: 2px;
            white-space: nowrap;
            user-select: none;
            a{
              color: #6b6be5;
              cursor: pointer;
              &:hover{text-decoration: underline}
            }
          }
          .confirm{
            user-select: none;
            background: #ebeef7;
            display: block;
            color: #808080;
            span{
              display: flex;
              justify-content: flex-end;
              a{
                cursor: pointer;
                font-size: 13px;
                margin-right: 10px;
                margin-top: 10px;
                line-height: 24px;
                padding: 0 16px;
                border-radius: 14px;
                color: #6b6be5;
                border: 1px solid #6b6be5;
                &:nth-child(2){
                  background: #6b6be5;
                  color: #fff;
                  margin-right: 0;
                }
              }
              .grey{
                border-color: #ccc;
                cursor: auto;
              }
            }
          }
        }
        .c{justify-content: center;}
        .r{
          flex-direction: row-reverse;
          p{
            color: #fff;
            background: #6b6be5;
            border: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
  .note{
    textarea{
      width: 100%;
      text-align: justify;
      color: #1a1a1a;
      font-size: 14px;
      line-height: 24px;
      padding: 4px 6px;
      height: 100px;
      margin-bottom: 10px;
      resize: none;
      outline: none;
      border: none;
      background: #f9f9f9;
      border-radius: 8px;
      font-family: auto;
      &::-webkit-scrollbar{display: none}
      &::placeholder{font-size: 12px;color: #ccc;}
    }
    h5{
      padding: 10px 0;
      font-size: 14px;
    }
    .button{
      margin: 20px auto 10px;
      width: 160px;
      text-align: center;
      color: #fff;
      background: #6b6be5;
      border-radius: 4px;
      line-height: 36px;
      cursor: pointer;
    }
    .table-btn{margin-top: -10px;}
    h6{
      font-size: 13px;
    }
    p{
      text-align: justify;
      color: #66686d;
      margin-bottom: 6px;
      white-space: pre-wrap;
    }
  }
}
</style>