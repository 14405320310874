<template>
  <footer :class="theme">
    <div class="under flex">
      <dl>
        <dt>工具</dt>
        <dd>TVR团队活力识别器</dd>
        <dd>LSN精益创业导航</dd>
        <dd>ATM领导者画像</dd>
        <dd>创投项目测评</dd>
        <dd>人才发展评估</dd>
        <dd>职涯优选CBC</dd>
      </dl>
      <dl>
        <dt>专家咨询</dt>
        <dd>免费咨询</dd>
        <dd>付费预约</dd>
      </dl>
      <dl>
        <dt>关于我们</dt>
        <dd>企业介绍</dd>
        <dd>和壹科创</dd>
      </dl>
      <dl>
        <dt>合作</dt>
        <dd>问卷网</dd>
      </dl>
      <div style="flex:1"></div>
      <dl>
        <dt>联系我们</dt>
        <dd class="flex--cen"><img src="../../assets/images/index/bg_5.png">020-87070220</dd>
        <dd class="flex--cen"><img src="../../assets/images/index/bg_6.png">18027418722</dd>
      </dl>
      <div class="imgCode">
        <img src="../../assets/images/qrcode.jpg">
        <p>公众号</p>
      </div>
      <div class="imgCode" style="margin-left:20px">
        <img src="../../assets/images/code-planet.jpg">
        <p>小程序</p>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    theme: {
      type: String,
      default: 'dark',  // dark:深色, light:浅色, grey:灰色
    },
  },
  data(){
    return {}
  },
  created(){},
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
footer{
  background: #212129;
  padding: 40px 0;
  border-bottom: 1px solid rgba(92,92,92,.3);
  .under{
    max-width: 900px;
    margin: 0 auto;
    white-space: nowrap;
    font-size: 14px;
    dl{
      margin-right: 50px;
    }
    dt{
      color: #fff;
      line-height: 2;
      cursor: default;
    }
    dd{
      color: #b3b3b3;
      line-height: 2;
      cursor: pointer;
      img{
        width: 14px;
        height: 14px;
        display: block;
        margin-right: 6px;
        user-select: none;
      }
      &:hover{color: #fff}
    }
    .flex--cen{
      cursor: auto;
      user-select: all;
    }
    .imgCode{
      width: 80px;
      padding-top: 4px;
      user-select: none;
      img{
        width: 100%;
        display: block;
      }
      p{
        color: #b3b3b3;
        line-height: 2.2;
        text-align: center;
      }
    }
  }
}
.light{
  background: #fff;
  border-bottom: 1px solid #e8e8e9;
  .under{
    dt{color: #212129;}
    dd:hover{color: #212129}
  }
}
.grey{
  background: #f9f9f9;
  border-bottom: 1px solid #ededed;
  .under{
    dt{color: #312d2d;}
    dd:hover{color: #212129}
  }
}
</style>