import { render, staticRenderFns } from "./indexFooterNav.vue?vue&type=template&id=3ba33d94&scoped=true"
import script from "./indexFooterNav.vue?vue&type=script&lang=js"
export * from "./indexFooterNav.vue?vue&type=script&lang=js"
import style0 from "./indexFooterNav.vue?vue&type=style&index=0&id=3ba33d94&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ba33d94",
  null
  
)

export default component.exports