import { render, staticRenderFns } from "./expert_nav.vue?vue&type=template&id=d8e5e900&scoped=true"
import script from "./expert_nav.vue?vue&type=script&lang=js"
export * from "./expert_nav.vue?vue&type=script&lang=js"
import style0 from "./expert_nav.vue?vue&type=style&index=0&id=d8e5e900&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d8e5e900",
  null
  
)

export default component.exports