<template>
  <div class="enterChoose">
    <ul class="flex-bet-cen">
      <li @click="$router.push({path: '/userCenter'})">
        <img src="../../assets/images/login/user_self.png">
        <h1>个人端</h1>
      </li>
      <li @click="$router.push({path: '/company'})">
        <img src="../../assets/images/login/user_company.png">
        <h1>企业端</h1>
      </li>
      <li @click="$router.push({path: '/expert/manage'})">
        <img src="../../assets/images/login/user_coach.png">
        <h1>教练端</h1>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {}
  },
  created(){},
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.enterChoose{
  ul{
    li{
      background: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      padding: 80px 80px;
      margin: 0 30px;
      border-radius: 10px;
      box-shadow: 0 0 12px rgba($color: #ccc, $alpha: .2);
      &:hover{border-color: #6b6be5;}
      h1{
        color: #212121;
        font-size: 18px;
        text-align: center;
        margin-top: 20px;
        user-select: none;
      }
    }
    @media (max-width: 900px) {
      li{padding: 60px;}
    }
    @media (max-width: 800px) {
      li{
        padding: 50px;
        margin: 0 20px;
        img{width: 70px;}
        h1{font-size: 16px;}
      }
    }
    @media (max-width: 600px) {
      li{margin: 0 5px;}
    }
  }
}
</style>