<template>
  <div class="index" ref="index" id="index">
    <ul class="float">
      <li title="留言" @click="suggestShow=true"></li>
      <li title="联系电话">
        <div class="box">
          <div class="">电话：18027418722</div>
        </div>
      </li>
      <li title="小程序码">
        <div class="box">
          <img src="../../assets/images/code-planet.jpg">
        </div>
      </li>
      <li title="返回顶部" @click="top"></li>
    </ul>

    <indexHeader></indexHeader>
    <!-- <div class="banner" id="banner">
      <el-carousel :height="bannerH<307 ? '307px' : bannerH+'px'">
        <el-carousel-item v-for="item in 1" :key="item">
          <img src="../../assets/images/index/banner1.jpg">
        </el-carousel-item>
      </el-carousel>
      <img src="../../assets/images/index/banner1.jpg">
      <div class="moreBtn" @click="$router.push({path: '/product'})">了解更多</div>
    </div> -->
    <div class="banner banner2">
      <h1><span>十方实验室</span>以数据驱动人才与组织活力，<br>为科创企业培育未来领军人才！</h1>
    </div>
    <div class="banner banner3">
      <p>Coaches</p>
      <h2>报告解读&nbsp;&nbsp;&nbsp;<span>限时免费</span></h2>
      <div class="moreBtn" @click="$router.push({path: '/expertList'})">了解更多 ></div>
    </div>
    <div class="banner4">
      <h1>产品与服务</h1>
      <h2>Product and Service</h2>
      <ul class="flex-bet">
        <li @click="$router.push({path: '/p&s/1'})">
          <h3>数字化诊断评估系列</h3>
          <img src="../../assets/images/index/bg_7.png">
          <div class="more">了解更多 ></div>
        </li>
        <li @click="$router.push({path: '/p&s/2'})">
          <h3>人才发展系列</h3>
          <img src="../../assets/images/index/bg_8.png">
          <div class="more">了解更多 ></div>
        </li>
        <li @click="$router.push({path: '/p&s/3'})">
          <h3>团队发展系列</h3>
          <img src="../../assets/images/index/bg_9.png">
          <div class="more">了解更多 ></div>
        </li>
        <li @click="$router.push({path: '/p&s/4'})">
          <h3>组织发展系列</h3>
          <img src="../../assets/images/index/bg_10.png">
          <div class="more">了解更多 ></div>
        </li>
      </ul>
    </div>
    <div class="product">
      <h1>资讯中心</h1>
      <h2>Information center</h2>
      <div class="container flex-bet">
        <div class="boxL">
          <img src="../../assets/images/index/bg_1.jpg">
          <div class="btn">更多 ></div>
        </div>
        <ul class="boxR">
          <li v-for="(item,i) in news" :key="i" v-show="i<3" @click="tolink(item.originLink)">
            <div class="flex-bet"><h5>{{item.title}}</h5><span>{{$formatTime(item.newsDate, 'm-d')}}</span></div>
            <div class="flex-bet"><h6>{{item.brief}}</h6><span>></span></div>
          </li>
        </ul>
      </div>
    </div>
    <ul class="content flex-cen">
      <li>
        <img src="../../assets/images/index/bg_2.jpg">
        <h1>问卷网</h1>
        <p class="btn" @click="$router.push({path: '/service'})">了解更多 ></p>
      </li>
      <li>
        <img src="../../assets/images/index/bg_3.jpg">
        <h1>CIC</h1>
        <p class="btn" @click="$router.push({path: '/CIC'})">了解更多 ></p>
      </li>
      <li>
        <img src="../../assets/images/index/bg_4.jpg">
        <h1>关于我们</h1>
        <p class="btn" @click="$router.push({path: '/about'})">了解更多 ></p>
      </li>
    </ul>
    <ul class="partner">
      <h1>合作伙伴</h1>
      <h2 style="margin-bottom:50px">Cooperative partner</h2>
      <li>
        <img src="../../assets/images/index/partner1.jpg">
        <img src="../../assets/images/index/partner2.jpg">
        <img src="../../assets/images/index/partner3.jpg">
        <img src="../../assets/images/index/partner4.jpg">
      </li>
      <li>
        <img src="../../assets/images/index/partner5.jpg">
        <img src="../../assets/images/index/partner6.jpg">
        <img src="../../assets/images/index/partner7.jpg">
        <img src="../../assets/images/index/partner8.jpg">
      </li>
      <li>
        <img src="../../assets/images/index/partner9.jpg">
        <img src="../../assets/images/index/partner10.jpg">
        <img src="../../assets/images/index/partner11.jpg">
        <img src="../../assets/images/index/partner12.jpg">
      </li>
    </ul>
    <indexFooterNav/>
    <indexFooter :bgcolor="'#212129'"/>

    <el-dialog
      title="意见反馈"
      :visible.sync="suggestShow"
      :close-on-click-modal="false"
      @close="close"
      width="560px"
      center>
      <div class="dialog-input-body">
        <div class="input-row">
          <h4>称呼</h4>
          <div class="box" :class="{error:errorType==1}">
            <input class="input" placeholder='请输入姓名' maxlength="16"  v-model="name" @input="setError">
            <div class="input-icon icon-clear" title="清空" v-show="name" @click="name='';errorType=0"></div>
            <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row">
          <h4>联系方式</h4>
          <div class="box" :class="{error:errorType==2}">
            <input class="input" placeholder='请输入手机号/邮箱' maxlength="30" v-model="contact" @input="setError">
            <div class="input-icon icon-clear" title="清空" v-show="contact" @click="contact='';errorType=0"></div>
            <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
          </div>
        </div>
        <div class="input-row suggest">
          <h4>反馈建议</h4>
          <div class="box">
            <textarea v-model="suggest" :class="{error:errorType==3}"></textarea>
            <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
          </div>
        </div>
        <div class="bottom-btn" @click="submit">提 交</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import indexHeader from './indexHeader'
import indexFooter from './indexFooter'
import indexFooterNav from './indexFooterNav'
export default {
  components:{indexHeader, indexFooter, indexFooterNav},
  data(){
    return{
      bannerH: 0,       //轮播图的高度
      proIndex: 1,      //当前索引产品显示
      proTotal: 2,      //产品数
      proTimer: null,
      flag1: false,
      flag2: false,
      news: [],
      //意见反馈
      errorType: 0,
      errorMsg: '',
      suggestShow: false,
      name: '',
      contact: '',
      suggest: '',
    }
  },
  mounted(){
    // 不能删，banner图调整高度
    // let w = document.body.clientWidth
    // this.bannerH = w * 73 / 190
    // window.onresize = () => {
    //   return (() => {
    //     let w = document.body.clientWidth
    //     this.bannerH = w * 73 / 190
    //   })()
    // }
    
    this.$request({url: '/News/getHomeNews',
      success: d => {
        if (d.code == 0) {
          this.news = d.data
        }
      },
    })
  },
  methods: {
    no(){
      this.$message({duration:1000,message:'开发中...',type:'warning'})
    },
    tolink(link){
      window.open(link, '_blank')
    },
    top(){
      $('#index').animate({scrollTop:0},100)
    },
    toUs(){
      this.$refs.index.scrollTop = $('#us').offset().top + $('#index').scrollTop()
    },
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
      this.contact = this.contact.replace(/[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g,'')
    },
    close(){
      this.suggest = ''
      this.name = ''
      this.contact = ''
    },
    submit(){
      if (this.sending) {
        return
      } else if (!this.name) {
        this.setError(1, '请输入称呼')
      } else if (!this.contact) {
        this.setError(2, '请输入联系方式')
      } else if (!this.$checkPhone(this.contact) && !this.$checkEmail(this.contact)) {
        this.setError(2, '请输入正确的联系方式')
      } else if (!this.suggest) {
        this.setError(3, '请输入反馈意见')
      } else {
        this.sending = true
        this.$request({
          method: 'post',
          url: '/Message/addFeedback',
          data: {
            realname: this.name,
            lxfs: this.contact,
            content: this.suggest,
          },
          success: (data) => {
            if (data.code == 0) {
              this.suggestShow = false
              this.$message({duration:5000,message:'感谢您的支持，我们将尽快跟您联系',type:'success'})
            } else {
              this.$message({type:'error',message:data.msg})
            }
          },
          complete: ()=> this.sending = false
        })
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.sendBox{
  display: flex;
  font-size: 14px;
  textarea{
    text-align: justify;
    color: #666;
    resize: none;
    outline: none;
    flex: 1;
    border: 1px solid #eceef3;
    border-radius: 4px;
    height: 32px;
    box-sizing: border-box;
    padding: 7px 10px;
  }
  .sendBtn{
    width: 80px;
    line-height: 32px;
    border-radius: 4px;
    background: #6b6be5;
    color: #fff;
    text-align: center;
    cursor: pointer;
    margin-left: 10px
  }
}
#app{background: #ccc;}
.index{
  overflow: auto;
  height: 100%;
  padding-top: 50px;
  min-width: 800px;
}
.moreBtn{
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 4px;
  padding: 6px 16px;
  font-size: 16px;
  cursor: pointer;
  background: #6b6be5;
}
.banner{
  position: relative;
  height: 480px;
  img{
    width: 100%;
    max-width: 1920px;
    display: block;
  }
}
.banner2{
  background: #07071a url('../../assets/images/index/banner2.jpg') no-repeat;
  background-position: center;
  background-size: auto 100%;
  h1{
    font-size: 34px;
    color: #fff;
    padding-top: 180px;
    text-align: center;
    span{color: #9a9af8}
  }
}
.banner3{
  background: url('../../assets/images/index/banner3.jpg') no-repeat;
  background-position: center;
  background-size: 100% 100%;
  h2{
    font-size: 32px;
    color: #212121;
    margin-top: 120px;
    text-align: center;
    span{color: #ee9906}
  }
  p{
    color: #212121;
    text-align: center;
    font-size: 20px;
    padding-top: 40px;
  }
}
.banner4{
  height: 540px;
  position: relative;
  background:#000 url('../../assets/images/index/banner4.jpg') no-repeat;
  background-size: 100%;
  h1{
    padding-top: 30px;
    color: #fff;
  }
  h2{
    color: #fff;
  }
  ul{
    max-width: 1000px;
    margin: 50px auto 0;
    color: #fff;
    text-align: center;
    li{
      background: #121337;
      border: 1px solid #5f3eeb;
      margin: 0 10px;
      flex: 1;
      padding: 40px 0;
      cursor: pointer;
      h3{
        font-weight: normal;
        font-size: 16px;
      }
      img{
        display: block;
        width: 90px;
        margin: 40px auto;
      }
      .more{
        display: inline-block;
        border: 1px solid #fff;
        border-radius: 2px;
        padding: 4px 16px;
        font-size: 14px;
      }
    }
  }
}
.us{
  background: #f7f7f7;
  color: #212121;
  font-size: 16px;
  padding: 100px 10px 50px;
  min-width: 800px;
  .flex--cen{
    max-width: 1000px;
    margin: 60px auto;
    justify-content: space-between;
  }
  img{
    width: 49%;
    display: block;
    padding-left: 10px;
  }
  .content{
    width: 48%;
    text-align: justify;
    box-sizing: border-box;
    padding-right: 20px;
  }
}
h1{
  text-align: center;
  font-size: 24px;
  color: #212121;
  user-select: none;
}
h2{
  text-align: center;
  font-size: 18px;
  color: #212121;
  font-weight: normal;
  user-select: none;
}
.container{
  max-width: 800px;
  margin: 50px auto;
  .boxL{
    width: 40%;
    margin-right: 20px;
    background: #f9f9f9;
    img{
      display: block;
      width: 100%;
    }
    .btn{
      user-select: none;
      color: #fff;
      border-radius: 4px;
      padding: 4px 0;
      margin: 30px auto;
      width: 80px;
      cursor: pointer;
      text-align: center;
      background: #6b6be5;
    }
  }
  .boxR{
    border-top: 1px solid #ccc;
    width: 54%;
    line-height: 1.4;
    li{
      border-bottom: 1px solid #ccc;
      padding: 10px 4px;
      cursor: pointer;
      &:hover{background: #f9f9f9}
    }
    div{
      align-items: baseline;
      margin: 10px 0;
      white-space: nowrap;
    }
    h5{
      color: #212121;
      font-size: 18px;
      width: 82%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    h6{
      color: #a6a6a6;
      font-size: 14px;
      width: 82%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    span{
      font-size: 18px;
      color: #b3b3b3;
    }
  }
}
.product{
  padding: 100px 0 40px;
  .window{
    max-width: 1200px;
    min-height: 300px;
    margin: 80px auto;
    padding: 0 10px;
    li{
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{width: 52%;}
      & > div{
        width: 40%;
        margin: 0 20px;
        font-size: 16px;
        text-align: justify;
        line-height: 1.5;
        color: #333;
        h5{
          font-size: 20px;
          margin-bottom: 20px;
        }
        .more{
          line-height: 28px;
          color: #6b6be5;
          border: 1px solid #6b6be5;
          border-radius: 4px;
          font-size: 14px;
          width: 80px;
          text-align: center;
          margin-top: 24px;
          cursor: pointer;
        }
      }
    }
    .trigger{
      position: absolute;
      width: 200px;
      right: 0;
      .indicator{
        margin-right: 20px;
        span{
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #e5e5e5;
          margin: 0 6px;
        }
        .curr{
          background: #6b6be5;
        }
      }
      .btn{
        color: #bababa;
        cursor: pointer;
        margin: 0 10px;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        box-shadow: 0 0 6px rgba(100,100,100,.2);
        &:hover{
          color: #6b6be5;
          box-shadow: 0 0 6px rgba(107,107,299,.2);
        }
      }
    }
  }
}
.content{
  padding: 0 4px;
  li{
    margin: 0 4px;
    width: 33.3%;
    position: relative;
    user-select: none;
    cursor: pointer;
    img{
      width: 100%;
      display: block;
    }
    h1{top: 10%;}
    h2{top: 15%}
    h1, h2{
      width: 100%;
      color: #fff;
      position: absolute;
    }
    .btn{
      color: #fff;
      border-radius: 3px;
      padding: 4px 0;
      width: 100px;
      cursor: pointer;
      text-align: center;
      border: 1px solid #fff;
      position: absolute;
      left: 50%;
      bottom: 12%;
      margin-left: -50px;
    }
  }
}
.partner{
  max-width: 800px;
  margin: 100px auto;
  li{
    display: flex;
    border-bottom: 1px dashed #ccc;
    &:last-child{border: none}
    img{
      width: 25%;
      padding: 8px 0;
      display: block;
      box-sizing: border-box;
      border-right: 1px dashed #ccc;
      &:last-child{border: none}
    }
  }
}
.float{
  $width:40;
  position: fixed;
  right: 20px;
  bottom: 40px;
  width: $width+px;
  z-index: 10;
  li{
    width: $width+px;
    height: $width+px;
    background:rgba(50,50,50,.3);
    margin-top: 1px;
    cursor: pointer;
    position: relative;
    .box{
      position: absolute;
      right: 100%;
      top: 50%;
      transform: translateY(-50%);
      padding-right: 4px;
      font-size: 14px;
      display: none;
      div{
        background: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 10px;
        white-space: nowrap;
        box-shadow: 0 0 4px rgba(0,0,0,.3);
        border-radius: 4px;
        transition: transform .1s, opacity .1s;
      }
      img{
        padding: 8px;
        width: 150px;
        height: 150px;
        background: #fff;
        box-shadow: 0 0 4px rgba(0,0,0,.3);
        border-radius: 4px;
      }
      &::before{
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 5px solid #fff;
        z-index: 2;
      }
      &::after{
        content: '';
        position: absolute;
        top: 50%;
        right: -1px;
        transform: translateY(-50%);
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 5px solid rgba(200,200,200,.8);
      }
    }
    &:hover{
      background:rgba(50,50,50,.6);
      .box{
        display: block;
        animation: slide .1s;
      }
    }
    @keyframes slide {
      0%{margin-right:-10px;opacity: 0;}
      100%{margin-right: 0px;opacity: 1;}
    }
    &::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;top: 0;
      background-size: 100%;
      background-repeat: no-repeat;
    }
    &:nth-child(1)::after{
      background-image:  url('../../assets/images/index/float1.png');
    }
    &:nth-child(2)::after{
      background-image:  url('../../assets/images/index/float2.png');
    }
    &:nth-child(3)::after{
      background-image:  url('../../assets/images/index/float3.png');
    }
    &:nth-child(4)::after{
      background-image:  url('../../assets/images/index/float4.png');
    }
    &:first-child{
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child{
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.dialog-input-body .suggest{
  margin-bottom: 100px;
  .box{
    border: none;
    textarea{
      text-align: justify;
      font-size: 14px;
      color: #666;
      resize: none;
      outline: none;
      width: 305px;
      border: 1px solid #eceef3;
      border-radius: 4px;
      height: 100px;
      padding: 10px; 
      &:hover{border-color: #d6dae4}
    }
  }
  .errText{
    bottom: -80px;
  }
}


//动画
.flashL{opacity: 0;}
.flashR{opacity: 0;}
.flash{
  .flashL{
    animation: left 1s forwards;
  }
  .flashR{
    animation: right 1s forwards;
  }
}
@keyframes left {
  0%{transform: translateX(-80px);opacity: 0;}
  100%{transform: translateX(0px);opacity: 1;}
}
@keyframes right {
  0%{transform: translateX(80px);opacity: 0;}
  100%{transform: translateX(0px);opacity: 1;}
}

@media screen and (max-width: 1100px) {
  .banner .moreBtn{
    padding: 4px 10px;
    font-size: 16px;
  }
}
</style>
