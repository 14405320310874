<template>
  <!-- 导航 -->
  <el-menu 
    :default-active="this.$route.path" 
    active-text-color="#868686" 
    class="el-menu-vertical"
    @select="tolink">
    <!-- 公司信息 -->
    <div class="message">
      <div class="flex--cen">
        <el-image :src="info.avatar||require('../../assets/images/headLarge.png')" class="head"/>
        <div>
          <h2 class="flex--cen"><span>{{info.realname}}</span> <i>L{{info.level}}</i></h2>
          <h3>贡献值 <span>{{info.multi_cv}}</span></h3>
        </div>
      </div>
      <h4 v-if="info.auth_status==1">认证时间 <b>{{$formatTime(info.auth_endtime)||'-'}}</b> 到期</h4>
    </div>
    <!-- 导航 -->
    <el-menu-item index="/expert/manage">
      <i class="icon nav_time"></i>
      <span slot="title">预约管理</span>
    </el-menu-item>
    <el-menu-item index="/expert/case">
      <i class="icon nav_case"></i>
      <span slot="title">企业案例</span>
    </el-menu-item>
    <el-menu-item index="/expert/order">
      <i class="icon nav_order"></i>
      <span slot="title">订单管理</span>
    </el-menu-item>
    <!-- <div class="el-menu-item" @click="navOpen=!navOpen">
      <i class="icon nav_product"></i>
      <span slot="title">产品与服务</span>
      <i class="el-icon-arrow-down" :style="{transform: navOpen?'rotate(-180deg)':'rotate(0deg)'}"></i>
    </div>
    <div class="submenu" :style="{'height':navOpen?'250px':'0'}">
      <el-menu-item index="/tvr" :disabled="disableTVR">
        <i class="icon nav_tvr"></i>
        <span slot="title">TVR团队活力识别器</span>
      </el-menu-item>
      <el-menu-item index="/lsn" :disabled="disableLSN">
        <i class="icon nav_lsn"></i>
        <span slot="title">LSN精益创业导航</span>
      </el-menu-item>
      <el-menu-item index="/amt" :disabled="disableAMT">
        <i class="icon nav_amt"></i>
        <span slot="title">AMT领导者画像</span>
      </el-menu-item>
      <el-menu-item index="/cbc" :disabled="disableCBC">
        <i class="icon nav_cbc"></i>
        <span slot="title">职涯优选CBC</span>
      </el-menu-item>
      <el-menu-item index="/cognition" :disabled="disableCognition">
        <i class="icon nav_cd"></i>
        <span slot="title">认知差异分析</span>
      </el-menu-item>
    </div> -->
    <el-menu-item index="/expert/box">
      <i class="icon nav_box"></i>
      <span slot="title">百宝箱</span>
    </el-menu-item>
    <el-menu-item index="/expert/edit">
      <i class="icon nav_edit"></i>
      <span slot="title">信息设置</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
export default {
  data(){
    return{
      navOpen: false,
      info: {},
      disableTVR: false,
      disableLSN: false,
      disableCBC: false,
      disableAMT: false,
      disableCognition: false,
    }
  },
  created(){
    //判断点击权限
    this.$request({
      url: '/index/index',
      success: e => {
        if (e.code == 0) {
          let {tvr,amt,cbc,lsn,cd} =  e.data.data_statistics
          if (!tvr.is_auth || !tvr.total_number) this.disableTVR = true
          if (!amt.is_auth || !amt.total_number) this.disableAMT = true
          if (!cbc.is_auth || !cbc.total_number) this.disableCBC = true
          if (!lsn.is_auth || !lsn.total_number) this.disableLSN = true
          if (!cd.is_auth || !cd.total_number) this.disableCognition = true
        }
      },
    })
    this.getCoachInfo()
  },
  methods: {
    tolink(path) {
      if (this.$route.path == path) return
      this.$router.push({path: path})
    },
    getCoachInfo() {
      this.$request({
        url: '/Coaches/getLoginInfo',
        success: e => {
          if (e.code == 0) {
            this.info = e.data || {}
          }
        },
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.el-menu-vertical{
  width: 220px; 
  height: 100%;
  background: #eceff4;
  border: none;
  overflow-y: auto;
  &::-webkit-scrollbar{display: none}
  .message{
    padding: 30px 0 20px 15px;
    user-select: none;
    white-space: nowrap;
    .head{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 12px;
      flex-shrink: 0;
      box-shadow: 0 0 4px rgba(200,200,200,1);
      font-size: 20px;
    }
    h2{
      font-size: 14px;
      color: #2b2b2b;
      font-weight: normal;
      margin-bottom: 4px;
      span{
        max-width: 44px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      i{
        display: inline-block;
        background: url('../../assets/images/expert/expert_star7.png') no-repeat;
        background-size: 100%;
        margin-left: 12px;
        padding: 0 10px 0 14px;
        color: #8d5a0b;
        font-weight: bold;
        line-height: 18px;
        font-size: 12px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          left: -10px;
          bottom: 0;
          width: 24px;
          height: 24px;
          background: url('../../assets/images/expert/expert_star6.png') no-repeat;
          background-size: 100%;
        }
      }
    }
    h3{
      font-size: 12px;
      color: #868686;
      font-weight: normal;
      span{color: #d4a161}
    }
    h4{
      font-size: 12px;
      color: #868686;
      font-weight: normal;
      margin-top: 12px;
      b{color: #a06f2d;font-size: 14px;}
    }
  }
  .el-menu-item, .menu-item{
    padding-right: 20px;
    padding-left: 20px;
    height: 50px;
    color: #868686;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 14px;
    cursor: pointer;
    &:hover{
      background: #dee3ea;
    }
    &::before{
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      background: #6b6be5;
      left: 0;
      top: 0;
      display: none;
      border-radius: 2px;
    }
    span{
      height: 18px;
      line-height: 18px;
      width: 100px;
    }
    .el-icon-arrow-down{
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 12px;
      transition: transform .2s;
    }
    .icon{
      width: 18px;
      height: 18px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }
    .nav_time{background-image: url('../../assets/images/company/nav_time.png');}
    .nav_case{background-image: url('../../assets/images/company/nav_case.png');}
    .nav_order{background-image: url('../../assets/images/company/nav_order.png');}
    .nav_product{background-image: url('../../assets/images/company/nav_product.png');}
    .nav_tvr{background-image: url('../../assets/images/company/nav_tvr.png')}
    .nav_lsn{background-image: url('../../assets/images/company/nav_lsn.png')}
    .nav_amt{background-image: url('../../assets/images/company/nav_amt.png')}
    .nav_cbc{background-image: url('../../assets/images/company/nav_cbc.png')}
    .nav_cd{background-image: url('../../assets/images/company/nav_cd.png')}
    .nav_box{background-image: url('../../assets/images/company/nav_box.png')}
    .nav_edit{background-image: url('../../assets/images/company/nav_edit.png')}
  }
  .is-disabled{opacity: 1;cursor: not-allowed;}
  .is-active{
    background: #dee3ea;
    span{
      color: #2b2b2b;
      font-weight: bold;
    }
    .icon{opacity: 1 !important};
    &::before{display: block}
  }
  .submenu{
    transition: height .2s linear;
    overflow: hidden;
    background: #e5e9ef;
    .el-menu-item, .menu-item{
      color: #a6a6a6;
      .icon{opacity: .55;}
    }
  }
}
@media (max-width: 1100px) {
  .view{
    .el-menu-vertical{
      width: 180px;
      .el-menu-item .icon{
        margin-right: 10px;
      }
    }
    .pagination{padding-left: 180px}
  } 
}
</style>