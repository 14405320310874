<template>
  <div class="header">
    <ul class="nav">
      <li class="logo flex--cen" @click="toIndex">
        <img src="../../assets/images/logo-small.png">十方实验室
      </li>
      <li @click="$router.push({path: '/product'})">
        <p>产品中心</p>
        <ul class="list">
          <li @click.stop="$router.push({path: '/TVRIndex'})">TVR团队活力识别器</li>
          <li @click.stop="$router.push({path: '/AMTIndex'})">AMT领导者画像</li>
        </ul>
      </li>
      <li @click="$router.push({path: '/expertList'})"><p>专家咨询</p></li>
      <li><p>问卷网</p></li>
      <li @click="$router.push({path: '/CIC'})"><p>CIC</p></li>
      <!-- <li>
        <p>联系我们</p>
        <ul class="list">
          <li>固话：020-87070220</li>
          <li>手机：18027418722</li>
          <li>联系人：黄女士</li>
        </ul>
      </li> -->
      <li @click="$router.push({path: '/about'})"><p>关于我们</p></li>
      <li class="flex--cen" style="color:#ffd18d"><img src="../../assets/images/index/index_icon1.png">成为专家</li>
      <li style="flex:1"></li>
      <li>
        <router-link :to="{path: '/login', query: {back: $route.path}}" class="btn-login" v-if="!loginStatus">登录</router-link>
        <div class="user" v-else>
          <img :src="avatar">
          <p>{{realname || '已登录'}}</p>
          <ul class="list">
            <li @click="$router.push({path: '/company'})" v-if="authority==1002 || authority==1003 || authority==1004">企业管理中心</li>
            <li @click="$router.push({path: '/expert/manage'})" v-if="authority==1005 || authority==1004">教练用户中心</li>
            <!-- <li @click="$router.push({path: '/userCenter'})" v-if="authority!=1002">个人中心</li> -->
            <li @click="loginOut">退出登录</li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return{
      loginStatus: false,
      authority: localStorage.getItem('authority') || '',
      realname: localStorage.getItem('realname') || '',
      avatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
    }
  },
  created(){
    //获取登录信息
    this.$request({url: '/User/getUserInfo?getStatus',
      success: (data) => {
        if (data.code == 0) this.loginStatus = true
      }
    })
  },
  methods:{
    no(){
      this.$message({duration:1000,message:'开发中...',type:'warning'})
    },
    tolink(path){
      this.$router.push({path: path});
    },
    toIndex(){
      if (this.$route.path == '/')
        this.$parent.top()
      else
        this.$router.push({path: '/'})
    },
    loginOut(){
      this.$request({url:'/User/loginOut',
        success: ({data}) => {
          localStorage.clear();
          this.$message({duration: 2000,message: '退出成功',type:'success'})
          this.$router.push({path:'/login'})
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.btn-login{
  height: 30px;
  width: 90px;
  line-height: 30px;
  text-align: center;
  font-size: 14px;
  color:#fff;
  background: #6b6be5;
  border-radius: 2px;
  display: inline-block;
  cursor: pointer;
}
.header{
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: #212129;
  color: #fff;
  box-shadow: 0 0 4px rgba(0,0,0,.1);
  user-select: none;
}
.nav{
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 0 auto;
  padding: 0 20px;
  .logo{
    width: 100px;
    margin-right: 20px;
    img{
      width: 24px;
      height: 24px;
      margin-right: 4px;
    }
  }
  &>li{
    cursor: pointer;
    margin: 0 16px;
    line-height: 50px;
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    &:hover{
      .list{display: block}
    }
    // p{
    //   padding: 0 6px;
    //   user-select: none;
    //   &:hover{
    //       color: #315ffa;
    //   }
    // }
    .list{
      user-select: none;
      display: none;
      position: absolute;
      background: #fff;
      text-align: center;
      left: 50%;
      top: 100%;
      transform: translateX(-50%);
      color: #868686;
      border-radius: 4px;
      box-shadow: 0 -1px 6px 0 rgba(0,0,0,.12);
      li{
        line-height: 36px;
        padding: 0 10px;
        cursor: pointer;
        font-size: 14px;
        border-radius: 4px;
        &:hover{
          background: #f5f9ff;
          color: #2b2b2b;
        }
      }
      &::before{
        content: '';
        position: absolute;
        top: -9px;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 8px solid rgba(230,230,230,.5);
      }
      &::after{
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 8px solid #fff;
      }
    }
  }
  .navitem-active{
      color: #315ffa;
      a{
          color: #315ffa;
      }
  }
}
.product-list{
    position: fixed;
    padding: 0 28px 20px 28px;
    background: #fff;
    font-size: 18px;
    left: 9%;
    top: 60px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-top: 10px;
    color: #6e6e6e;
    box-shadow: 0 2px 8px 0 rgba(153,153,153,.15);
    li{
        width: 296px;
        height: 60px;
        line-height: 60px;
        border-radius: 5px;
        margin-top: 20px;
        cursor: pointer;
        padding: 0 28px;
        &:hover{
            background: #edf0fe;
            color: #315ffa;
        }
    }
}
.user{
  color: #2d2d2d;
  display: flex;
  height: 50px;
  align-items: center;
  margin-left: 10px;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
  &:hover{
    ul{display: block}
  }
  p{
    flex-shrink: 0;
    width: 60px;
    white-space:nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    color: #fff;
  }
  img{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 8px;
    overflow: hidden;
    box-shadow: 0 0 4px rgba(150,150,150,.4);
    flex-shrink: 0;
  }
}
</style>