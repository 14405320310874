<template>
  <div id="app">
    <div v-if="isMobile" class="mobile-tip">
      <img src="./assets/images/logo-small.png">
      <h6>十方实验室</h6>
      <p>请在电脑端打开查看</p>
    </div>
    <router-view  v-else/>
  </div>
</template>
<script>
export default {
  name: 'App',
  data(){
    return{
      isMobile: false,
    }
  },
  watch: {
    '$route' () {
      //手机打开网页跳转
      let ua = window.navigator.userAgent;
      if(ua.match(/mobile/i)){
        this.isMobile = true
      }
    }
  },
}
</script>
<style  lang="scss">
@import "assets/style/element.scss";
/********全局样式*********/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Segoe UI", "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC", "Microsoft YaHei", 微软雅黑, sans-serif;
}
a{text-decoration: none;}
a:link,a:active,a:visited{color:#6e6e6e;}
li{list-style: none;}
.clearfix::after{content:'';display: block;clear: both;}
html,body{height: 100%;}
input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{-moz-appearance: textfield;}
#app{
  // overflow-y: auto;
  overflow: hidden;
  height: 100%;
  color: #868686;
  //聊天框
  .center .msgBox{
    &:hover::-webkit-scrollbar-thumb{background: rgba(50,50,50,0.2)}
    &::-webkit-scrollbar{width: 8px;height: 10px;}
    &::-webkit-scrollbar-thumb {border-radius: 6px;background: #f6f6f6;}
    &::-webkit-scrollbar-track {background: #fff;}
  }
}
*::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}
*::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: rgba(144,147,153,0.5);
}
*::-webkit-scrollbar-track {
  background: rgba(0,0,0,0.1);
}
.fl{float: left;}
.fr{float: right;}
.grey{color: #ccc !important;}
.red{color: #f56c6c !important;}
.blue{color: #44c7ed !important;}
.pink{color: #f97286 !important;}
.darkBlue{color: #6b6be5 !important;}

//横线flex布局
.flex{
  display: flex;
}
.flex-cen{        //垂直居中
  display: flex !important;
  justify-content: center;
}
.flex--cen{    //垂直水平居中
  display: flex !important;
  align-items: center;
}
.flex-cen-cen{    //垂直水平居中
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.flex-bet{        //两边分布
  display: flex !important;
  justify-content: space-between;
}
.flex-bet-cen{    //两边分布水平居中
  @extend .flex-bet;
  @extend .flex--cen;
}
.flex-around{     //平均分布
  display: flex !important;
  justify-content: space-around;
}
.flex-around-cen{//平均分布水平居中
  @extend .flex-around;
  @extend .flex--cen;
}


input{
  border: none;
  outline: none;
  &::-webkit-input-placeholder{color: #c0c4cc}
}
button{
  border: none;
  outline: none;
}
//请在电脑打开提示
.mobile-tip{
  position: fixed;
  background: #f6f6f6;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  text-align: center;
  img{
    width: 40px;
    height: 40px;
    margin: 40px auto 6px;
    display: block;
  }
  h6{
    font-size: 14px;
    color: #333
  }
  p{
    position: absolute;;
    left: 50%;
    top: 50%;
    font-size: 14px;
    color: #666;
    width: 200px;
    transform: translate(-50%,-50%);
  }
}
//横线logo
.logo-row{
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  img{
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 4px;
  }
}
//路径记录
.top_path{
  @extend .flex--cen;
  .link{
    cursor: pointer;
    white-space: nowrap;
    &:hover{
      color: #404040;
    }
  }
}
//导航栏的标题
.h1_title{
  font-size: 18px;
  color: #868686;
  display: flex;
  height: 50px;
  margin-top: 20px;
  align-items: center;
  border-bottom: 1px solid #eceef3;
  font-weight: normal;
  animation: down .3s;
  img{
    margin-right: 8px;
    width: 20px;
    height: 20px;
  }
}
.move-up{animation: up .3s;}
@keyframes down {
  0%{transform: translateY(-20px);opacity: .1;}
  100%{transform: translateY(0);opacity: 1;}
}
@keyframes up {
  0%{height: 20px;opacity: .1;}
  100%{height: 0px;opacity: 1;}
}
//搜索框
.search-input{
  border: 1px solid #eceef3;
  border-radius: 4px;
  height: 30px;
  padding: 0 24px 0 10px;
  color: #2b2b2b;
  flex-shrink: 1;
  background: url('./assets/images/icon-search.png') no-repeat;
  background-color: #fff;
  background-size: 14px 14px;
  background-position: right 10px center;
}
//操作按钮
.table-btn{
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  .text{
    flex: 1;
    color: #2b2b2b;
    line-height: 28px;
  }
  .btn{
    flex-shrink: 0;
    padding: 0 16px;
    margin-left: 20px;
    line-height: 28px;
    height: 28px;
    background: #6b6be5;
    color: #fff;
    font-size: 13px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
  }
  .btn-disable{
    background: #9898ed;
    cursor: not-allowed;
    &:focus{background-color: #9898ed;color: #fff}
    &:hover{background-color: #9898ed;color: #fff}
  }
  .del{background: #f56c6c}
}

.table-head{
  display: flex;
  align-items: center;
  img{
    width: 24px;
    height: 24px;
    overflow: hidden;
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 4px;
    box-shadow: 0 0 2px rgba(50,50,50,.3);
  }
  i{
    width: 24px;
    height: 24px;
    background: url('./assets/images/head-gray.png') no-repeat cover;
    flex-shrink: 0;
    margin-right: 4px;
  }
  span{
    text-align: left;
    overflow: hidden;
    margin: 0 2px;
    text-overflow: ellipsis;
    line-height: 24px;
  }
}
//区号输入按钮
.el-select-code{
    width: 56px;
    .el-input{
      height: 100%;
      width: 65px;
      padding-left: 10px;
      .el-input__inner{
          padding-left: 0px;
          border: none;
          padding-right: 20px;
          color: #868686;
          background: none;
          text-align: center;
      }
      .el-input__suffix{
        right: 0;
      }
      span{transform: scale(.9,.9) translateY(1px)}
    }
}

.dialog-input-body{
  margin: 0 auto; 
  min-height: 200px;
  width: 400px;
  user-select: none;
  .top-nav{
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    span{
      width: 150px;
      line-height: 40px;
      border: 1px solid #eceef3;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      white-space: nowrap;
      &:hover{
          border-color: #d6dae4;
          color: #868686;
      }
    }
    .current{
      border-color: #6b6be5;
      color: #6b6be5;
      cursor: default;
      &:hover{
        cursor: default;
        border-color: #6b6be5;
        color: #6b6be5;
      }
    }
  }
  .top-step{
    color: #868686;
    font-size: 14px;
    .dashed{
      // border: 1px dashed #868686;
      width: 50px;
      height: 2px;
      background-image: linear-gradient(to right,#868686 0%,#868686 50%,transparent 50%);
      background-size: 4px 2px;
      background-repeat: repeat-x;
    }
    .curr{color: #6b6be5}
  }
  .input-row{
    display: flex;
    align-items: center;
    margin: 25px 0;
    white-space: nowrap;
    h4{
      width: 80px;
      margin-right: 15px;
      color: #868686;
      text-align-last: justify;
      font-weight: normal;
      padding-left: 2px;
      position: relative;
      flex-shrink: 0;
    }
    .text-right{
      @extend .flex--cen;
      justify-content: flex-end;
    }
    .must::before{
      content: '*';
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-48%);
      color: #ff4747;
    }
    .box{
      flex: 1;
      display: flex;
      border: 1px solid #eceef3;
      height: 40px;
      position: relative;
      border-radius: 4px;
      &:hover{
          border-color: #d6dae4;
          .input-icon{display: block;}
      }
      .input{
        padding: 0 10px;
        flex: 1;
        height: 100%;
        color: #666;
        border-radius: 4px;
      }
      .btn{
        width: 40px;
        height: 38px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: none;
        color: #C0C4CC;
        border-left: 1px solid #eceef3;
      }
      .btnCode{
        position: absolute;
        height: 40px;
        width: 120px;
        border: 1px solid #6b6be5;
        color: #6b6be5;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        right: -1px;
        top: -1px;
        background: none;
        outline: none;
        overflow: hidden;
        span{
            line-height: 40px;
            display: block;
            position: relative;
            z-index: 2;
        }
        &:hover{
            cursor: pointer;
            background: #fafaff;
        }
        b{
            position: absolute;
            width: 100%;
            height: 100%;
            background: #f5f5ff;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 0;
            transition-property: width;
            transition-timing-function: linear;
            opacity: 0;
        }
      }
      .btnRect{
        @extend .flex-cen-cen;
        width: 40px;
        height: 38px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        background: none;
        border-left: 1px solid #eceef3;
        color: #C0C4CC;
        &:hover{
          cursor: pointer;
          background: #fafaff;
        }
      }
      .disabled{
        border-color: #eceef3;color: #b5b5b5;
        &:hover{cursor: not-allowed;background: none;}
        b{width: 0;opacity: 1;}
      }
    }
    //选择下拉框
    .select-init{
        padding-left: 0;
        height: 38px;
        flex: 1;
        .el-input--suffix,input{height: 100%;}
        .el-input--suffix{
            .el-input__suffix{right: 8px;}
            input{
                border: none;
                padding: 0 40px 0 10px;
            }
        }
    }
    .gray{
      padding: 0 10px;
      user-select: none;
      background: #f8f8f8;
      color: #ccc;
      line-height: 38px;
      cursor: not-allowed;
    }
    .error{
        border-color: #ff6265 !important;
        &:hover{
            border-color: #ff6265;
        }
        .btnCode{
          color: #C0C4CC;
          border-color: #ff6265 #ff6265 #ff6265 #eceef3;
        }
    }
    .errText{
        position: absolute;
        bottom: -18px;
        font-size: 12px;
        color: #ff6265;
        left: 50%;
        user-select: none;
        white-space: nowrap;
        transform: translateX(-50%);
    }
  }
  .tips{
    text-align: center;
    color: #b5b5b5;
  }
  //管理员管理 从员工列表添加
  .adminAddMember{
    .header{
      display: flex;
      height: 48px;
      background: #f8f9fb;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }
    //员工列表
      .addTable{
          max-height: 160px;
          overflow: auto;
          user-select: none;
          margin-bottom: 6px;
          .el-table__header-wrapper{display: none;}
          .el-table__body-wrapper{
              border: 1px solid #f8f9fb;
              td{padding: 4px 0;border: none;}
              .el-table-column--selection{
                  .cell{padding: 0;}
                  padding: 4px 10px 4px 20px;
              }
          }
      }
      .el-tag{
          height: 28px;
          line-height: 25px;
          color: #6b6be5;
          background: #ebebff;
          border-color: #c2c2f5;
          margin: 5px 10px 5px 0;
          i{color: #6b6be5;}
          i:hover{
            background: #6b6be5;
            color: #fff;
          }
      }
  }
}
.bottom-btn{
  width: 100px;
  line-height: 32px;
  border-radius: 4px;
  background: #6b6be5;
  margin: 40px auto 10px;
  color: #fff;
  text-align: center;
  cursor: pointer;
}

//企业用户消息中心
.news .message .el-dialog{
  min-height: 400px;
  min-width: 600px;
  overflow: auto;
}


//大的dialog
.dialog-large{
  .el-dialog{
    width: 80%;
    min-height: 500px;
    height: 720px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 10vh !important;
    .el-dialog__body{
      position: relative;
      flex: 1;
      padding-bottom: 49px;
      padding-top: 0
    }
  }
}
@media (max-height: 840px) {
  .dialog-large .el-dialog{
    height: 660px;
    // .el-table{height: 468px;}
  }
}
@media (max-height: 800px) {
  .dialog-large .el-dialog{
    height: 580px;
  }
}
@media (max-height: 680px) {
  .dialog-large .el-dialog{
    height: 500px;
  }
}
//中的dialog
.dialog-middle{
  .el-dialog{
    width: 90%;
    max-width: 700px;
    min-width: 500px;
    min-height: 424px;
    height: 50%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .el-dialog__body{
      position: relative;
      flex: 1;
      padding-bottom: 49px;
      padding-top: 0
    }
  }
}

.input-icon{
  position: absolute;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: none;
  cursor: pointer;
}
.icon-clear{background-image: url('./assets/images/icon-close.png');}
.icon-show{background-image: url('./assets/images/icon-show.png');}
.icon-hide{background-image: url('./assets/images/icon-hide.png');}


.upload-box{
  .upload{
    height: 44px;
    position: relative;
    .el-upload{
      width: 100%;
      height: 100%;
      .el-upload-dragger{
        width: 100%;
        height: 100%;
        border: 1px solid #eceef3;
        &:hover{border-color: #d6dae4;}
        .el-upload__text{
          line-height: 44px;
          color: #b5b5b5;
          em{color: #6b6be5;}
        }
      }
    }
    .el-upload-list{
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 100%;
      .el-upload-list__item{
        background: #fff;
        border-radius: 6px;
        border: 1px solid #eceef3;
        overflow: hidden;
        margin: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        padding-left: 4px;
        .el-icon-close{top: 14px;}
        &:hover{background: #f2f9ff;}
        &:focus{outline: none;}
      }
    }
  }
  .uploadFailText{
    position: absolute;
    left: 66px;
    right: 0;
    top: 100%;
    height: 80px;
    padding-top: 10px;
    font-size: 13px;
    text-align: center;
    color: #ff6265;
  }
  // .fail .el-upload{
  //   .el-upload-list .el-upload-list__item{border-color: #ff6265;}
  // }
}


.loading-dot{
  display: flex;
  align-items: center;
  justify-content: center;
  i{
    margin-left: 4px;
    animation-name: dot;
    animation-duration: .8s;
    animation-iteration-count: infinite;
    &:nth-child(3){animation-delay: .4s}
    &:nth-child(2){animation-delay: .2s}
  }
}

.star{
  width: 20px;
  height: 20px;
  background-image: url('./assets/images/company/star1.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.star1{background-image: url('./assets/images/company/star2.png')}
.star2{background-image: url('./assets/images/company/star3.png')}

@keyframes dot {0%{opacity: 0};50%{opacity: 1};100%{opacity: 0}}
</style>
