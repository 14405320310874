
<template>
  <!-- 分页 -->
  <div class="pagination" :class="position">
      <div class="content">
        <el-pagination
          @size-change="changeSize"
          @current-change="changePage"
          :current-page="page"
          :page-sizes="[10, 20, 50, 100, 500]"
          :page-size="per_page"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </div>
</template>
<script>
export default {
  props: ['total','position'],
  data(){
    return {
      per_page: 20,     // 每页显示条数
      page: 1,      // 当前页码数
    }
  },
  methods:{
    changeSize(per_page){
      this.per_page = per_page
      this.$emit('change', per_page, this.page)
    },
    changePage(page){
      this.page = page
      this.$emit('change', this.per_page, page)
    },
  },
}
</script>

<style lang="scss" scoped>
.page-botttom{
  padding: 0;
  position: absolute;
}
</style>