<template>
  <div class="chat">
    <dl class="dialog">
      <dt>
        <i :class="'online'+online"/>
        {{role=='cp'?coachInfo.realname:userInfo.companyname}}
        <span>（{{online>=2?'在线中':online==1?'未上线':'未连接'}}）</span>
      </dt>
      <dd class="flex">
        <div class="left">
          <div class="clock flex-cen-cen">
            <img src="../../assets/images/expert/expert_clock.png">
            时长 {{setChatTime(totalSecond)}}
          </div>
          <template v-if="role=='cp'">
            <img class="head" :src="coachInfo.avatar||require('../../assets/images/head.png')">
            <h1>{{coachInfo.realname}}</h1>
            <h2>详情介绍</h2>
            <h3>{{coachInfo.profile}}</h3>
          </template>
          <template v-else>
            <img class="head" :src="userInfo.avatar||require('../../assets/images/head.png')">
            <h1>{{userInfo.companyname}}</h1>
            <p>管理员<span>{{userInfo.realname}}</span></p>
            <p>所在地<span>{{userInfo.city||'-'}}</span></p>
            <p :title="userInfo.industry">所属行业<span>{{userInfo.industry||'-'}}</span></p>
            <div class="tools">
              <h4>已使用工具</h4>
              <ul>
                <li v-for="(item,index) in toolList" :key="index">{{item}}</li>
              </ul>
            </div>
          </template>
        </div>
        <div class="center">
          <div class="msgBox" ref="scroll" @scroll="scroll">
            <ul>
              <li v-for="(item,index) in chatList" :key="index">
                <!-- 时间 -->
                <div class="time" v-if="item.show_time">{{formatTime(item.show_time)}}</div>

                <!-- 评价提示 -->
                <div class="row c" v-if="item.content_type=='evaluate'">
                  <div class="tip" v-if="role=='cp'">请你对本次咨询服务进行评价，<span>{{jumpTime}}秒</span>后自动去评价，也可点击<router-link :to="{path:'/expertEvaluate',query: {mid: meetingId, role: role, back: '/expert/case'}}" replace>立即评价></router-link></div>
                  <div class="tip" v-else>请你对本次企业服务进行工作记录，<span>{{jumpTime}}秒</span>后自动去记录，也可点击<router-link :to="{path:'/expertEvaluate',query: {mid: meetingId, role: role, back: '/expert/case'}}" replace>立即记录></router-link></div>
                </div>
                <!-- 系统提示 -->
                <div class="row c" v-else-if="item.content_type=='tips'">
                  <div class="tip">{{item.content}}</div>
                </div>
                <!-- 聊天内容 -->
                <div class="row" :class="{r: item.from_id==from_id}" v-else>
                  <div class="avatar">
                    <img v-if="item.from_id==from_id" :src="localAvatar">
                    <img v-else :src="role=='cp' ? coachInfo.avatar : userInfo.avatar">
                  </div>

                  <p class="confirm" v-if="item.content_type=='confirm'">
                    教练需要结束会议，你是否同意结束？
                    <span v-if="item.is_confirm==-1">
                      <a class="grey">已取消</a>
                    </span>
                    <span v-else-if="item.is_confirm==1">
                      <a class="grey">已结束</a>
                    </span>
                    <span v-else-if="item.from_id!=from_id">
                      <a @click="sendConfirmState(-1)">取消</a>
                      <a @click="sendConfirmState(1,index)">确定</a>
                    </span>
                  </p>
                  <p class="contact" v-else-if="item.content_type=='card'">
                    <img :src="item.avatar||require('../../assets/images/head.png')">
                    <span>
                      <h3>{{item.realname}}</h3>
                      <h4>{{item.mobile||'-'}}</h4>
                      <h4>{{item.email||'-'}}</h4>
                      <h4>{{item.wx_number||'-'}}</h4>
                    </span>
                  </p>
                  <p v-else-if="item.content_type=='report'" class="link"><a :href="item.url" target="_blank">{{item.title}}</a></p>
                  <p v-else>{{item.content}}</p>
                  
                  <span class="txt" v-if="item.content_type=='card'">名片</span>
                  <span class="txt" v-else-if="item.content_type=='report'">链接</span>
                  <span class="fail" v-else-if="item.fail" @click="resend(item)" title="点击重发"></span>
                </div>
              </li>
            </ul>
          </div>
          <div class="inputBox" :class="{offline: online==-1}">
            <textarea placeholder="输入消息..." v-model="inputMsg" @keydown.enter="sendMsg"></textarea>
            <div class="flex-bet-cen">
              <h5 @click="openQuick">
                快捷用语
                <div class="quick" v-show="quickShow">
                  <div>
                    <p @mousedown="pickQuick('你好，欢迎使用在线咨询服务，请问有什么需要帮你？')"><span>你好，欢迎使用在线咨询服务，请问有什么需要帮你？</span></p>
                    <p @mousedown="pickQuick('不好意思，请稍等一下。')"><span>不好意思，请稍等一下。</span></p>
                  </div>
                </div>
              </h5>
              <h6 @click="sendMsg">发送(Enter)</h6>
            </div>
          </div>
        </div>
        <div class="right" v-if="role=='cp'">
          <h1>我的报告</h1>
          <div class="report">
            <el-collapse v-model="reportActive" accordion>
              <el-collapse-item :title="item.name" v-for="(item,i) in reportList" :key="i" :name="item.id">
                <el-popconfirm :title="'确定发送“'+item.name+'”报告吗？'" v-for="(it,j) in item.report_list" :key="j" @confirm="sendReport(it,item.name)" @onConfirm="sendReport(it,item.name)">
                  <el-button slot="reference">{{it.report_name}}</el-button>
                </el-popconfirm>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="right" v-else>
          <div class="btnBox flex">
            <el-popconfirm title="确定发送名片吗？" @confirm="sendCard" @onConfirm="sendCard">
              <el-button slot="reference"><img src="../../assets/images/expert/expert_btn1.png">发送名片</el-button>
            </el-popconfirm>
            <el-popconfirm title="确定发送会议号吗？" @confirm="sendMsg('tencent')" @onConfirm="sendMsg('tencent')">
              <el-button slot="reference"><img src="../../assets/images/expert/expert_btn2.png">发送会议号</el-button>
            </el-popconfirm>
          </div>
          <el-popconfirm title="结束会话需要客户同意，是否发送结束请求？" @confirm="sendConfirm" @onConfirm="sendConfirm">
            <el-button slot="reference" class="end">结束会话</el-button>
          </el-popconfirm>
        </div>
      </dd>
    </dl>
  </div>
</template>
<script>
let times = 100, websocket;
export default {
  props: ['fid', 'tid', 'mid'],
  data(){
    return {
      role: this.$route.query.role||'cp', //ex:教练, cp:企业
      meetingId: this.$route.query.mid||'',  //会议id
      from_id:  this.$route.query.fid||'',
      to_id:  this.$route.query.tid||'',
      userInfo: {},
      coachInfo: {},
      localAvatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
      toolList: [],   //做过的工具列表
      reportActive: [],
      reportList: [],
      quickShow: false,//快捷用语显隐
      inputMsg: '',   //输入框的文本
      sending: false, //正在发送
      online: 0,      //0:未连接，-1:终结连接，1:自己在线，2:双方在线
      latestTime: 0,  //最新时间
      totalSecond: 0,
      startTime: 0,   //会议开始时间戳
      chatPage: 1,
      chatTotal: 0,
      chatList: [],
      lastId: '',     //分页时防止获取重复数据
      timer: '',
      jumpTime: 15,
    }
  },
  created(){
    this.from_id = this.from_id || this.fid
    this.to_id = this.to_id || this.tid
    this.meetingId = this.meetingId || this.mid
    if (this.role == 'cp')  //获取教练信息
      this.$request({url: '/Chat/getMessageByUser',
        data: {meeting_id: this.meetingId},
        success: d => {
          if (d.code == 0) {
            this.coachInfo = d.data.coaches_info || {}
            this.reportList = d.data.report_data
            this.connect()
            this.getLog()
          } else if (d.code == 101) {
            this.$message({message: '登录已过期', type: 'warning'})
            this.$router.replace({path:'/login', query:{back: this.$route.fullPath}})
            localStorage.clear()
          } else {
            this.online = -1
          }
        },
      })
    else if (this.role == 'ex') //获取企业信息
      this.$request({url: '/Chat/getMessageByCoaches',
        data: {meeting_id: this.meetingId},
        success: d => {
          if (d.code == 0) {
            this.userInfo = d.data.user_info
            this.coachInfo = d.data.coaches_info
            this.toolList = d.data.tool_list
            this.connect()
            this.getLog()
          } else if (d.code == 101) {
            this.$message({message: '登录已过期', type: 'warning'})
            this.$router.replace({path:'/login', query:{back: this.$route.fullPath}})
            localStorage.clear()
          } else {
            this.online = -1
          }
        },
      })
    //监听 进入/离开 页面
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'hidden') {
        this.timer && clearInterval(this.timer)
      }
      if (document.visibilityState == 'visible' && this.startTime) {
        this.runTimer(parseInt(new Date().getTime()/1000) - this.startTime)
      }
    });
  },
  methods:{
    connect() {
      websocket = new WebSocket('ws://47.115.76.110:2350')
      websocket.onclose = this.reconnect
      websocket.onopen = () => times = 100
      websocket.onmessage = ({data}) => {
        let d = JSON.parse(data)
        console.log("ws收到消息:", d)
        switch (d.type) {
          case 'init':
            if (websocket.readyState == 1)
              websocket.send(JSON.stringify({type: 'online', from_id: this.from_id, to_id: this.to_id, meeting_id: this.meetingId, role: this.role}))
            break
          case 'online':
            this.online = d.status
            
            if (d.end_time) { //结束会议：到时间自动结束
              this.totalSecond = d.end_time - d.start_time
              this.timer && clearInterval(this.timer)
              
              if (this.role == 'cp')  //获取用户是否评价
                this.$request({url: '/Chat/getEvaluation',
                  data: {meeting_id: this.meetingId},
                  success: d => {
                    if (d.code == 404) this.countDown()
                  },
                })
              else   //获取教练是否工作备忘
                this.$request({url: '/Coaches/getNoteList',
                  data: {meeting_id: this.meetingId},
                  success: d => {
                    if (d.code == 404) this.countDown()
                  },
                })
            } else if (d.start_time && !this.totalSecond) {  //判断开始时间
              this.startTime = d.start_time
              this.runTimer(parseInt(new Date().getTime()/1000) - d.start_time)
            }
            break
          case 'start': //到时间自动开始计时
            if (d.start_time && !this.totalSecond) {
              this.startTime = d.start_time
              this.runTimer(parseInt(new Date().getTime() / 1000) - d.start_time)
            }
            break
          case 'confirm':
            if (d.is_confirm == 1) {  //结束会议：手动结束
              this.online = -1
              clearInterval(this.timer)
              this.countDown()
            }
            for (let it of this.chatList) {
              if (it.content_type=='confirm' && !it.is_confirm) {
                it.is_confirm = d.is_confirm
              }
            }
            this.$forceUpdate()
            break
          case 'message':
            if (d.content_type == 'report') {
              let obj = JSON.parse(d.content)
              d.title = obj.title
              d.url = obj.url
            } else if (d.content_type == 'card') {
              let obj = JSON.parse(d.content)
              d = {...d, ...obj}
            }
            this.chatList.unshift(d)
            this.scrollBottom()
            if (d.show_time) this.latestTime = d.show_time
          default:
            break
        }
      }
    },
    reconnect() {
      if (this.online != -1) {
        this.online = 0
        if (times-- > 1) setTimeout(() => this.connect(), 5000)
      }
    },
    getLog() {
      this.$request({url: '/Chat/getChatLog',
        data: {
          meeting_id: this.meetingId,
          last_id: this.lastId,
          from_id: this.from_id, 
          to_id: this.to_id,
          per_page: 20,
          page: this.chatPage++,
        },
        success: d => {
          if (d.code == 0) {
            for (let item of d.data.data) {
              if (!this.latestTime && item.show_time) {//判断显示最新时间
                this.latestTime = item.show_time
              }
              if (item.content_type == 'report') {
                let obj = JSON.parse(item.content)
                item.title = obj.title
                item.url = obj.url
              } else if (item.content_type == 'card') {
                let obj = JSON.parse(item.content)
                item.avatar = obj.avatar
                item.realname = obj.realname
                item.mobile = obj.mobile
                item.wx_number = obj.wx_number
                item.email = obj.email
              }
            }

            let ele = this.$refs.scroll, oldH = ele.scrollHeight
            this.chatList = [...this.chatList, ...d.data.data]
            this.chatTotal = d.data.total
            this.lastId = d.data.last_id
            //初始滑动到底部，之后保持滚动条位置
            this.$nextTick(() => ele.scrollTo({top: ele.scrollHeight - oldH}))
          }
        },
      })
    },
    scroll() {
      let ele = this.$refs.scroll
      if (ele.scrollTop < 1 && this.chatList.length < this.chatTotal) {
        this.getLog()
      }
    },
    setChatTime(time) {
      if (time < 0) {
        return '00:00'
      } else if (time>=3600) {
        return '60分钟'
      } else {
        // let m = parseInt(time / 60 % 60)
        // m = m < 10 ? '0' + m : m
        // let s = parseInt(time % 60)
        // s = s < 10 ? '0' + s : s
        return new Date(time * 1000).toISOString().substr(14, 5)
      }
    },
    runTimer(start) {  //开始计时，满1小时停止
      if (start < 3600) {
        this.totalSecond = start
        this.timer = setInterval(() => {
          if (++this.totalSecond >= 3600) {
            this.send({total_time: this.totalSecond}, 'end')
          } else if (this.totalSecond == 3000) {
            this.chatList.unshift({type: 'message', from_id: this.from_id, to_id: this.to_id, content_type: 'tips', content: '离会议结束还有10分钟'})
            this.scrollBottom()
          } else if (this.totalSecond == 3540) {
            this.chatList.unshift({type: 'message', from_id: this.from_id, to_id: this.to_id, content_type: 'tips', content: '离会议结束还有最后1分钟'})
            this.scrollBottom()
          }
        }, 1000)
      } else {
        this.timer && clearInterval(this.timer)
        this.totalSecond = 3600
      }
    },
    resend(obj) {
      if (this.online > 0) {
        obj.fail = false
        websocket.send(JSON.stringify(obj))
      }
    },
    send(obj, type = 'message') {
      if (this.online == -1) {
        this.$message({message: '会议已结束', type: 'warning'})
        return
      } else if (!this.totalSecond) {
        this.$message({message: '会议还未开始', type: 'warning'})
        return
      }
      obj = {...{type, from_id: this.from_id, to_id: this.to_id, meeting_id: this.meetingId}, ...obj}
      let now = parseInt(new Date().getTime() / 1000)
      if (this.online < 1) obj.fail = true  //未连接
      if (now - this.latestTime > 300) obj.show_time = this.latestTime = now //超过5分钟加上时间
      console.warn('send：', obj)
      websocket.send(JSON.stringify(obj))
      if (type == 'message') {
        this.chatList.unshift(obj)
        this.scrollBottom()
      }
    },
    sendMsg(ty) { // 发送普通信息、会议号
      event.preventDefault()
      if (ty == 'tencent') {
        this.send({content: `会议号：${this.coachInfo.tencent_id}`, content_type: 'normal'})
      } else if (event.ctrlKey) {
        this.inputMsg += '\n'
      } else if (this.inputMsg && !this.sending) {
        this.sending = true
        this.$request({url: '/Chat/checkWord',
          data: {content: this.inputMsg},
          success: d => {
            if (d.code == 0) {
              this.send({content: this.inputMsg, content_type: 'normal'})
              this.inputMsg = ''
            } else {
              this.$message({message: d.msg, type: 'warning'})
            }
          },
          complete: () => this.sending = false
        })
      }
    },
    sendReport(e, title='') { //发送报告
      let url = this.$mobileUrl + '/#/'
      if (e.tool_id==5 && e.type==1) {  //tvr团队报告
        url += `vigourGroup/${e.report_id}`
      } else if (e.tool_id==5 && e.type==2) {  //tvr层级报告
        url += `vigourTier/${e.report_id}`
      } else if (e.tool_id==9) {    //cbc个人报告
        url += `professionTend/${e.exam_id}/1?origin=pc`
      } else if (e.tool_id==10) {   //amt个人报告
        url += `AMT/report?exam_id=${e.exam_id}`
      } else if (e.tool_id==12) {   //lsn团队报告
        url += `lsnTeam/${e.report_id}`
      } else if (e.tool_id==14) {   //创投团队报告
        url += `VC/report?exam_id=${e.exam_id}&origin=pc`
      }
      this.send({content: `{"url":"${url}","title":"${title}"}`, content_type: 'report', title, url},)
    },
    sendCard(e) { //发送名片
      this.send({...{content: JSON.stringify(this.coachInfo), content_type: 'card'}, ...this.coachInfo})
    },
    sendConfirm() { //发送结束确认框
      this.send({...{content: '教练需要结束会议，你是否同意结束？', content_type: 'confirm', is_confirm: 0}})
    },
    sendConfirmState(is_confirm, i) {
      this.send({is_confirm, total_time: this.totalSecond}, 'confirm')
    },
    scrollBottom() {
      this.$nextTick(() => {
        let ele = this.$refs.scroll
        ele && ele.scrollTo({top: ele.scrollHeight})
      })
    },
    formatTime(timestamp) {
      if(!timestamp)  return ''
      let now = new Date(),
          date = String(timestamp).length<13 ? new Date(timestamp*1000) : new Date(timestamp),
          year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate(),
          hours = date.getHours(),
          minutes = date.getMinutes();
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      if (now.toDateString() == date.toDateString()) {  //今日
        return `${hours}:${minutes}`
      } else if (now.getFullYear() == year) { //今年
        return `${month}月${day}日 ${hours}:${minutes}`
      } else {
        return `${year}年${month}月${day}日 ${hours}:${minutes}`
      }
    },
    openQuick() {
      this.quickShow = true
      document.onmousedown = e => {
        this.quickShow = false
        document.onmousedown = null
      }
    },
    pickQuick(msg) {
      this.inputMsg = msg
    },
    countDown() {
      this.chatList.unshift({content_type: 'evaluate'})
      this.timer = setInterval(() => {
        if (--this.jumpTime < 1) {
          clearInterval(this.timer)
          this.$router.replace({path:'/expertEvaluate',query: {mid: this.meetingId, role: this.role, back: '/expert/case'}})
        }
      }, 1000)
    }
  },
  destroyed() {
    if (websocket) {
      websocket.onclose = () => console.log('断开连接')
      websocket.close()
    }
  },
}
</script>
<style lang="scss">
.chat{
  padding: 40px 10px 10px;
  overflow: auto;
  height: 100vh;
  &::-webkit-scrollbar{display: none}
  .dialog{
    max-width: 1000px;
    min-width: 600px;
    height: 74vh;
    min-height: 500px;
    margin: 0 auto;
    background: #fff;
    box-shadow: 0 0 12px rgba(50,50,50,.2);
    font-size: 14px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    dt{
      background: #262626;
      text-align: center;
      color: #fff;
      line-height: 50px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ccc;
        margin-right: 10px;
      }
      .online1{background: #f23c3c;}
      .online2, .online3, .online4, .online5{background: #2eeb5b;}
      span{
        font-size: 12px;
        color: #999;
      }
    }
    dd{height: calc(100% - 50px);}
    .left{
      width: 240px;
      overflow-y: auto;
      &::-webkit-scrollbar{display: none}
      .clock{
        border-bottom: 1px solid #f1f1f1;
        color: #1a1a1a;
        height: 40px;
        font-size: 15px;
        img{
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
      .head{
        display: block;
        width: 50px;
        height: 50px;
        margin: 40px auto 0;
        user-select: none;
        border-radius: 50%;
        overflow: hidden;
      }
      h1{
        font-weight: normal;
        text-align: center;
        font-size: 14px;
        color: #1a1a1a;
        margin: 10px 30px 30px;
      }
      p{
        color: #999;
        padding: 10px 0;
        margin: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        border-bottom: 1px solid #eceef3;
        white-space: nowrap;
        user-select: none;
        span{
          margin-left: 10px;
          color: #ccc;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
      h2{
        user-select: none;
        font-weight: normal;
        text-align: center;
        font-size: 14px;
        color: #808080;
        line-height: 50px;
        border-bottom: 1px solid #f1f1f1;
        margin: 0 30px;
      }
      h3{
        color: #999;
        padding: 20px 30px;
        font-size: 14px;
        text-align: justify;
        font-weight: normal;
        white-space: pre-wrap;
        height: calc(100% - 241px);
        overflow-y: auto;
        &::-webkit-scrollbar{display: none}
      }
      .tools{
        color: #999;
        font-size: 14px;
        margin: 0 30px;
        user-select: none;
        height: calc(100% - 313px);
        h4{
          font-weight: normal;
          padding: 12px 0 4px;
        }
        ul{
          height: calc(100% - 36px);
          padding-bottom: 10px;
          overflow-y: auto;
          &::-webkit-scrollbar{display: none}
          li{
            border-radius: 4px;
            margin-top: 8px;
            font-size: 13px;
            display: inline-block;
            padding: 3px 5px;
            background: #f6f6ff;
            border: 1px solid #d8d8ff;
            color: #9f9ffe;
          }
        }
      }
    }
    .center{
      flex: 1;
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
      .msgBox{
        height: calc(100% - 160px);
        overflow-y: scroll;
      }
      ul{
        display: flex;
        flex-direction: column-reverse;
        li{
          .time{
            font-size: 12px;
            color: #cbcdd1;
            text-align: center;
            margin: 8px 0 6px;
            user-select: none;
          }
          .row{
            display: flex;
            align-items: center;
            margin: 8px 0;
            .avatar{
              padding: 6px 12px 0;
              align-self: flex-start;
              img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                user-select: none;
              }
            }
            p{
              padding: 10px 12px;
              border-radius: 10px;
              border-top-left-radius: 0;
              box-shadow: 0 0 4px rgba(200,200,200,.2);
              font-size: 14px;
              color: #808080;
              background: #fafbff;
              border: 1px solid #eeeff4;
              text-align: justify;
              max-width: 60%;
              display: flex;
              align-items: center;
              word-break: break-all;
              white-space: pre-wrap;
              img{
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
            }
            .link{
              padding: 0;
              user-select: none;
              background: #ebeef7;
              a{
                color: #6b6be5;
                padding: 10px 12px 10px 32px;
                background: url('../../assets/images/expert/report2.png') no-repeat;
                background-position: 12px center;
                background-size: 14px auto;
              }
            }
            .contact{
              display: flex;
              background: #ebeef7;
              white-space: normal;
              img{
                width: 90px;
                height: 90px;
                flex-shrink: 0;
                border-radius: 8px;
                background: #fff;
                align-self: flex-start;
                user-select: none;
              }
              h3{
                margin: 0 8px;
                color: #262626;
                font-size: 14px;
                font-weight: normal;
                line-height: 1.2;
              }
              h4{
                color: #808080;
                margin: 0 8px;
                padding: 2px 0 2px 20px;
                font-size: 14px;
                font-weight: normal;
                background: url('../../assets/images/head.png') no-repeat;
                background-size: 18px 18px;
                background-position: 0 3px;
                &:nth-child(2){background-image: url('../../assets/images/expert/expert_icon1.png')}
                &:nth-child(3){background-image: url('../../assets/images/expert/expert_icon2.png')}
                &:nth-child(4){background-image: url('../../assets/images/expert/expert_icon3.png')}
              }
            }
            .txt{
              font-size: 11px;
              color: #d1d1d1;
              margin: 0 10px;
              white-space: nowrap;
              user-select: none;
            }
            .fail{
              margin: 0 10px;
              cursor: pointer;
              width: 20px;
              height: 20px;
              flex-shrink: 0;
              background: url('../../assets/images/expert/sendFail.png') no-repeat;
              background-size: 100% 100%;
            }
            .tip{
              font-size: 12px;
              background: #e5e5e5;
              text-align: center;
              padding: 2px 6px;
              border-radius: 2px;
              white-space: nowrap;
              user-select: none;
              a{
                color: #6b6be5;
                cursor: pointer;
                &:hover{text-decoration: underline}
              }
              span{color: #6b6be5}
            }
            .confirm{
              user-select: none;
              background: #ebeef7;
              display: block;
              color: #808080;
              white-space: normal;
              span{
                display: flex;
                justify-content: flex-end;
                a{
                  cursor: pointer;
                  font-size: 13px;
                  margin-right: 10px;
                  margin-top: 10px;
                  line-height: 24px;
                  padding: 0 16px;
                  border-radius: 14px;
                  color: #6b6be5;
                  border: 1px solid #6b6be5;
                  &:nth-child(2){
                    background: #6b6be5;
                    color: #fff;
                    margin-right: 0;
                  }
                }
                .grey{
                  border-color: #ccc;
                  cursor: auto;
                }
              }
            }
          }
          .c{justify-content: center;}
          .r{
            flex-direction: row-reverse;
            p{
              color: #fff;
              background: #6b6be5;
              border: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 0;
            }
          }
        }
      }
      .inputBox{
        height: 160px;
        border-top: 1px solid #f1f1f1;
        position: relative;
        textarea{
          width: 100%;
          text-align: justify;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 24px;
          height: 72px;
          resize: none;
          outline: none;
          border: none;
          margin: 20px 0 16px;
          padding: 0 20px;
          font-family: auto;
          &::-webkit-scrollbar{display: none}
          &::placeholder{font-size: 12px;color: #ccc;}
        }
        h5{
          color: #6b6be5;
          font-size: 14px;
          margin-left: 20px;
          user-select: none;
          position: relative;
          cursor: pointer;
          .quick{
            position: absolute;
            bottom: 120%;
            left: 0;
            width: 40vw;
            min-width: 240px;
            max-width: 500px;
            box-shadow: 0 0 8px rgba(50,50,50,.2);
            border: 1px solid #f1f1f1;
            background: #fff;
            border-radius: 4px;
            color: #808080;
            div{
              max-height: 300px;
              overflow-y: auto;
              &::-webkit-scrollbar{display: none}
            }
            &::before, &::after{
              content: '';
              position: absolute;
              left: 14px;
              bottom: -10px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 10px solid #fff;
              z-index: 2;
            }
            &::after{
              border-top: 10px solid rgba(50,50,50,.1);
              filter: blur(2px);
              bottom: -13px;
              z-index: 1;
            }
            p{
              padding: 0 10px;
              text-align: justify;
              font-weight: normal;
              span{
                display: block;
                padding: 4px 2px;
                border-bottom: 1px solid #f1f1f1;
              }
              &:last-child span{border: none}
              &:hover{background: #fafafa}
            }
          }
        }
        h6{
          font-size: 14px;
          color: #fff;
          background: #6b6be5;
          border-radius: 4px;
          width: 100px;
          line-height: 30px;
          text-align: center;
          margin-right: 20px;
          user-select: none;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .offline::after{
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: not-allowed;
      }
    }
    .right{
      width: 200px;
      user-select: none;
      position: relative;
      .btnBox{
        border-bottom: 1px solid #f1f1f1;
        flex-wrap: wrap;
        span{
          width: 50%;
          text-align: center;
          border-right: 1px solid #f1f1f1;
          font-size: 12px;
          color: #999;
          cursor: pointer;
          img{
            display: block;
            margin: 0 auto 8px;
            width: 40px;
          }
          &:last-child{border: none}
        }
        .el-button{
          font-size: 12px;
          width: 100%;
          color: #afb0b3;
          border: none;
          border-radius: 0;
        }
      }
      .end{
        color: #ff5e4d;
        position: absolute;
        width: 120px;
        line-height: 30px;
        border: 1px solid #ff5e4d;
        border-radius: 4px;
        text-align: center;
        bottom: 30px;
        left: 40px;
        cursor: pointer;
        padding: 0;
        &:hover{background: #fbf4f3}
        &:active{background: #fbf4f3}
        &:focus{background: #fbf4f3}
      }
      h1{
        background: #ef5e5e;
        line-height: 36px;
        color: #fff;
        text-align: center;
        font-size: 12px;
      }
      .report{
        height: calc(100% - 40px);
        overflow-y: auto;
        &::-webkit-scrollbar{display: none}
        .el-collapse-item__header{
          height: 38px;
          line-height: 38px;
          padding: 0 4px 0 10px;
        }
        .el-collapse-item__content{
          padding-bottom: 0;
          span{display: block}
        }
        .el-button{
          font-size: 12px;
          width: 200px;
          color: #afb0b3;
          border: none;
          border-top: 1px solid #f1f1f1;
          padding: 0 8px 0 26px;
          line-height: 32px;
          background: url('../../assets/images/expert/report1.png') no-repeat;
          background-size: 16px 16px;
          background-position: 7px 7px;
          border-radius: 0;
          span{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            white-space: pre;
          }
          &:hover{background-color: #f9f9f9}
        }
      }
    }
    @media (max-width: 850px) {
      .left{
        width: 0px;
        padding: 1px 0;
        overflow: hidden;
      }
    }
  }
}
</style>