<template>
  <div class="consult">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_index.png">预约管理
    </h1>
    <div class="move-up"></div>
    <nav>
      <h2 :class="{curr:tab==0}" @click="change(0)">待确认预约</h2>
      <h2 :class="{curr:tab==1}" @click="change(1)">进行中预约</h2>
      <h2 :class="{curr:tab==-1}" @click="change(-1)">已取消预约</h2>
    </nav>
    <el-table :data="list" tooltip-effect="dark" height="calc(100vh - 260px)" style="width: 100%">
      <el-table-column prop="companyname" label="企业名称" min-width="120" align="center" show-overflow-tooltip/>
      <el-table-column label="管理员" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../assets/images/head.png')">
            <span>{{scope.row.realname}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="meeting_time_str" label="预约时间" min-width="164" align="center"/>
      <el-table-column label="类型" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.type==1 ? '付费' : '免费'}}</span>
        </template>
      </el-table-column>
      <!-- 待确认操作 -->
      <el-table-column label="操作" width="160" align="center" v-if="tab==0">
        <template slot-scope="scope">
          <span class="btn red" @click="setStatus(scope.$index,-1)">取消预约</span>
          <span class="btn" @click="setStatus(scope.$index,1)">确认预约</span>
        </template>
      </el-table-column>
      <!-- 取消状态 -->
      <template v-if="tab==-1">
        <el-table-column label="取消时间" min-width="140" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.cancel_time, 'y-m-d h:m')}}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" min-width="70" align="center">
          <template>
            <span class="red">已取消</span>
          </template>
        </el-table-column>
      </template>
      <!-- 进行中操作 -->
      <el-table-column label="操作" width="160" align="center" v-if="tab==1">
        <template slot-scope="scope">
          <span class="btn" @click="startChat(scope.row)">发起咨询</span>
        </template>
      </el-table-column>
      
    </el-table>
    <pagination ref="managePage" @change="getList" :total="total"/>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      tab: 0, //0:待确认，1:进行中，-1:已取消
      list: [],
      total: 0,
    }
  },
  created(){
    this.getList(20, 1)
  },
  methods:{
    change(e){
      if (this.tab == e) return
      this.tab = e
      this.getList(20, 1)
    },
    getList(per_page, page){
      let ref = this.$refs.managePage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$http.post('/Coaches/getMeetingList', {
        status: this.tab,
        per_page: per_page,
        page: page
      }).then( ({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else if (data.code == 101) {
          this.$router.replace({path:'/login', query:{back:this.$route.path}})
          localStorage.clear()
        } else {
          this.$message({type: 'error', message:data.msg})
        }
      }, () => {
        this.$message({type:'error', message: '网络异常'})
      })
    },
    setStatus(i, status) {
      let txt = status==1 ? `预约时间是 <b>${this.list[i].meeting_time_str}</b>，是否确认预约？` : '取消后该用户3天内将无法再次预约您，是否取消预约？'
      this.$alert(txt, '提示', {dangerouslyUseHTMLString: true}).then((e) => {
        this.$http.post('/Coaches/setMeetingStatus', {
          meeting_id: this.list[i].meeting_id,
          status,
        }).then(({data}) => {
          if (data.code == 0) {
            this.tab = status
            this.getList(20, 1)
          } else {
            this.$message({type: 'error', message: data.msg})
          }
        }, () => this.$message({type:'error', message: '网络异常'}))
      }).catch(() => {})
    },
    startChat(e) {
      var timestamp = Date.parse(new Date()) / 1000
      if (e.meeting_startime - timestamp <= 900) {  //15分钟前可进入聊天
        var query = {fid: e.from_id, tid: e.to_id, role: 'ex', mid: e.meeting_id,}
        window.open(this.$router.resolve({path: '/chat', query}).href, '_blank')
      } else {
        this.$message({message: '会话还未开始，最多可提前15分钟进入聊天', type: 'warning'})
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.consult{
  nav{
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #eceef3;
    user-select: none;
    h2{
      font-weight: normal;
      font-size: 14px;
      margin: 0 10px 0 26px;
      line-height: 50px;
      padding: 0 2px;
      position: relative;
      cursor: pointer;
    }
    .curr{
      color: #6b6be5;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: #6b6be5;
        left: 0;
        bottom: 0;
      }
    }
    .unread::after{
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff4747;
      right: -6px;
      top: 14px;
    }
  }
  .dialog{
    max-width: 1000px;
    height: 70vh;
    min-height: 500px;
    margin: 0 auto 20px;
    background: #fff;
    box-shadow: 0 0 8px rgba(50,50,50,.1);
    font-size: 14px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    dt{
      background: #262626;
      text-align: center;
      color: #fff;
      line-height: 50px;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #ccc;
        margin-right: 10px;
      }
      .online1{background: #f23c3c;}
      .online2{background: #2eeb5b;}
      span{
        font-size: 12px;
        color: #999;
      }
    }
    dd{height: calc(100% - 50px);}
    .left{
      width: 240px;
      padding: 1px 30px;
      img{
        display: block;
        width: 50px;
        height: 50px;
        margin: 40px auto 0;
        user-select: none;
      }
      h1{
        font-weight: normal;
        text-align: center;
        font-size: 14px;
        color: #1a1a1a;
        margin: 10px 0 40px;
      }
      p{
        color: #999;
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        border-bottom: 1px solid #eceef3;
        white-space: nowrap;
        user-select: none;
        span{
          margin-left: 10px;
          color: #ccc;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .center{
      flex: 1;
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
      .msgBox{
        height: calc(100% - 160px);
        overflow-y: auto;
        &::-webkit-scrollbar{display: none}
      }
      ul{
        display: flex;
        flex-direction: column-reverse;
        li{
          .time{
            font-size: 12px;
            color: #cbcdd1;
            text-align: center;
            margin: 8px 0 6px;
            user-select: none;
          }
          .row{
            display: flex;
            align-items: center;
            margin: 8px 0;
            .avatar{
              padding: 8px 12px 0;
              align-self: flex-start;
              img{
                width: 32px;
                height: 32px;
                border-radius: 50%;
                user-select: none;
              }
            }
            p{
              padding: 10px 12px;
              border-radius: 10px;
              border-top-left-radius: 0;
              box-shadow: 0 0 4px rgba(200,200,200,.2);
              font-size: 14px;
              color: #808080;
              background: #fafbff;
              border: 1px solid #eeeff4;
              text-align: justify;
              max-width: 60%;
              display: flex;
              align-items: center;
              word-break: break-all;
              img{
                width: 18px;
                height: 18px;
                margin-right: 4px;
              }
            }
            .link{
              background: #ebeef7;
              color: #6b6be5;
              cursor: pointer;
              user-select: none;
              b{
                font-weight: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .contact{
              display: flex;
              background: #ebeef7;
              img{
                width: 80px;
                height: 80px;
                flex-shrink: 0;
                border-radius: 8px;
                background: #fff;
                align-self: flex-start;
                user-select: none;
              }
              h3{
                margin: 0 10px;
                color: #262626;
                font-size: 12px;
                font-weight: normal;
                line-height: 1.3;
              }
              h4{
                color: #808080;
                margin: 0 10px;
                padding: 3px 0 3px 18px;
                font-size: 12px;
                font-weight: normal;
                cursor: pointer;
                background: url('../../assets/images/head.png') no-repeat;
                background-size: 16px 16px;
                background-position: 0 4px;
              }
            }
            .txt{
              font-size: 11px;
              color: #d1d1d1;
              margin: 0 10px;
              white-space: nowrap;
              user-select: none;
            }
            .fail{
              margin: 0 10px;
              cursor: pointer;
              width: 20px;
              height: 20px;
              flex-shrink: 0;
              background: url('../../assets/images/expert/sendFail.png') no-repeat;
              background-size: 100% 100%;
            }
            .tip{
              font-size: 12px;
              background: #e5e5e5;
              text-align: center;
              padding: 2px 6px;
              border-radius: 2px;
              white-space: nowrap;
              user-select: none;
            }
          }
          .c{justify-content: center;}
          .r{
            flex-direction: row-reverse;
            p{
              color: #fff;
              background: #6b6be5;
              border: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 0;
            }
          }
        }
      }
      .inputBox{
        height: 160px;
        border-top: 1px solid #f1f1f1;
        position: relative;
        textarea{
          width: 100%;
          text-align: justify;
          color: #1a1a1a;
          font-size: 14px;
          line-height: 24px;
          height: 72px;
          resize: none;
          outline: none;
          border: none;
          margin: 20px 0 16px;
          padding: 0 20px;
          font-family: auto;
          &::-webkit-scrollbar{display: none}
          &::placeholder{font-size: 12px;color: #ccc;}
        }
        h5{
          color: #6b6be5;
          font-size: 14px;
          margin-left: 20px;
          user-select: none;
          position: relative;
          cursor: pointer;
          .quick{
            position: absolute;
            bottom: 120%;
            left: 0;
            width: 40vw;
            min-width: 240px;
            box-shadow: 0 0 5px rgba(50,50,50,.2);
            border: 1px solid #f1f1f1;
            background: #fff;
            border-radius: 8px;
            color: #808080;
            padding: 0 10px;
            div{
              max-height: 300px;
              overflow-y: auto;
              &::-webkit-scrollbar{display: none}
            }
            &::before, &::after{
              content: '';
              position: absolute;
              left: 14px;
              bottom: -10px;
              border-left: 8px solid transparent;
              border-right: 8px solid transparent;
              border-top: 10px solid #fff;
              z-index: 2;
            }
            &::after{
              border-top: 10px solid rgba(50,50,50,.1);
              filter: blur(2px);
              bottom: -12px;
              z-index: 1;
            }
            p{
              border-bottom: 1px solid #f1f1f1;
              padding: 4px 2px;
              text-align: justify;
              font-weight: normal;
              &:last-child{border: none}
            }
          }
        }
        h6{
          font-size: 14px;
          color: #fff;
          background: #6b6be5;
          border-radius: 4px;
          width: 100px;
          line-height: 30px;
          text-align: center;
          margin-right: 20px;
          user-select: none;
          font-weight: normal;
          cursor: pointer;
        }
      }
      .offline::after{
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: not-allowed;
      }
    }
    .right{
      width: 200px;
      user-select: none;
      h1{
        background: #ef5e5e;
        line-height: 36px;
        color: #fff;
        text-align: center;
        font-size: 12px;
      }
      .report{
        height: calc(100% - 40px);
        overflow-y: auto;
        &::-webkit-scrollbar{display: none}
      }
      .el-collapse-item__header{
        height: 38px;
        line-height: 38px;
        padding: 0 4px 0 10px;
      }
      .el-collapse-item__content{
        padding-bottom: 0;
        span{display: block}
      }
      .el-button{
        font-size: 12px;
        width: 200px;
        color: #afb0b3;
        border: none;
        border-top: 1px solid #f1f1f1;
        padding: 0 8px 0 26px;
        line-height: 32px;
        background: url('../../assets/images/expert/report1.png') no-repeat;
        background-size: 16px 16px;
        background-position: 8px center;
        border-radius: 0;
        span{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:hover{background-color: #f9f9f9}
      }
    }
    @media (max-width: 1000px) {
      .left{
        width: 0px;
        padding: 1px 0;
        overflow: hidden;
      }
    }
  }
}
</style>