<template>
  <div class="order">
    <h1 class="h1_title">
      <img src="../../assets/images/company/nav_order.png">订单管理
    </h1>
    <div class="move-up"></div>
    <nav>
      <h2 :class="{curr:tab=='getList'}" @click="change('getList')">产品订单</h2>
      <h2 :class="{curr:tab=='getMeetingOrder'}" @click="change('getMeetingOrder')">咨询订单</h2>
    </nav>
    <el-table v-if="tab=='getList'" :data="orderList" tooltip-effect="dark" height="calc(100vh - 270px)" class="orderTable">
      <el-table-column prop="order_num" label="订单编号" min-width="160" show-overflow-tooltip/>
      <el-table-column prop="product_name" label="产品名" min-width="150" show-overflow-tooltip align="center"/>
      <el-table-column prop="product_type" label="类型" align="center" min-width="76"/>
      <el-table-column prop="total_number" label="账号数" align="center"/>
      <el-table-column prop="create_time" label="下单时间" min-width="130" align="center"/>
      <el-table-column prop="purchase_time" label="购买时长" align="center" min-width="80"/>
      <el-table-column prop="expire_time" label="到期时间" min-width="130" align="center"/>
      <el-table-column prop="original_money" label="原价" min-width="96" align="center"/>
      <el-table-column prop="payment_money" label="实付金额" min-width="96" align="center"/>
      <el-table-column  label="状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span class="blue" v-if="scope.row.payment_status=='已支付'">已支付</span>
          <span class="red" v-else>未支付</span>
        </template>
      </el-table-column>
    </el-table>

    <el-table v-if="tab=='getMeetingOrder'" :data="orderList" tooltip-effect="dark" height="calc(100vh - 270px)" class="orderTable">
      <el-table-column prop="order_num" label="订单编号" min-width="158" align="center" show-overflow-tooltip/>
      <el-table-column prop="content" label="名称" min-width="130" align="center" show-overflow-tooltip/>
      <el-table-column prop="coaches_name" label="咨询教练" min-width="80" align="center"  show-overflow-tooltip/>
      <el-table-column label="下单时间" min-width="130" align="center">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.create_time, 'y-m-d h:m')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.is_free ? '免费' : '付费'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_money" label="原价" min-width="70" align="center" show-overflow-tooltip/>
      <el-table-column prop="payment_money" label="实付" min-width="70" align="center" show-overflow-tooltip/>
      <el-table-column label="状态" min-width="70" align="center">
        <template slot-scope="scope">
          <span class="blue" v-if="scope.row.payment_status==1">已支付</span>
          <span class="blue" v-else-if="scope.row.payment_status==2">已退款</span>
          <span class="red" v-else>未支付</span>
        </template>
      </el-table-column>
    </el-table>

    <pagination ref="orderPage" @change="getOrder" :total="total"></pagination>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      tab: 'getList', //getList:产品订单,getMeetingOrder:咨询订单
      orderList: [],
      total: 0,
    }
  },
  mounted(){
    this.getOrder(20, 1)
  },
  methods: {
    change(e) {
      if (this.tab == e) return
      this.tab = e
      this.getOrder(20, 1)
    },
    getOrder(per_page, page) {
      let ref = this.$refs.orderPage
      per_page = per_page || ref ? ref.per_page : 20
      page = page || ref ? ref.page : 1
      this.$request({
        url: '/Order/'+this.tab,
        data: {
          per_page: per_page,
          page: page,
        },
        success: d => {
          if (d.code == 0) {
            this.orderList = d.data.data
            this.total = d.data.total
          } else if (d.code == 101) {
            localStorage.clear()
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.order{
  .orderTable{margin-top: 30px;}
}

@media (max-width: 1100px) {
  .admin .head span{width: 60px}
}
</style>