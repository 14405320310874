<template>
  <div class="consult">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_report.png">咨询服务
    </h1>
    <div class="move-up"></div>
    <nav>
      <h2 :class="{curr:tab==0}" @click="change(0)">当前预约</h2>
      <h2 :class="{curr:tab==1}" @click="change(1)">历史咨询</h2>
      <h2 :class="{curr:tab==-1}" @click="change(-1)">已取消预约</h2>
    </nav>
    <div class="table-btn">
      <!-- <router-link to="/expertReserve" class="btn" target='_blank'>免费咨询</router-link> -->
      <a class="btn" @click="toFree">免费咨询</a>
    </div>
    <el-table
      v-loading="loading"
      :data="list"
      tooltip-effect="dark"
      height="calc(100vh - 307px)"
      style="width: 100%">
      <el-table-column label="预约专家" min-width="120" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.coaches_name || '未预约专家'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="meeting_time_str" label="预约时间" min-width="180" align="center"/>
      <el-table-column label="类型" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.type==1 ? '付费' : '免费'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" min-width="80" align="center">
        <template slot-scope="scope">
          <span class="blue" v-if="scope.row.status==0">待确认</span>
          <span class="blue" v-else-if="scope.row.status==1">进行中</span>
          <span class="red" v-else-if="scope.row.status==-1">已取消</span>
          <span class="red" v-else-if="scope.row.status==-2">已过期</span>
          <span class="blue" v-else>已完成</span>
        </template>
      </el-table-column>
      <el-table-column label="取消时间" min-width="140" align="center" v-if="tab==-1">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.cancel_time, 'y-m-d h:m')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center" v-if="tab==0">
        <template slot-scope="scope">
          <span class="btn red" @click="cancel(scope.row)">取消预约</span>
          <span class="btn" @click="startChat(scope.row)">发起会话</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="160" align="center" v-if="tab==1">
        <template slot-scope="scope" v-if="scope.row.status==2">
          <span class="btn" @click="toEvaluate(scope.row)">我的评价</span>
          <span class="btn" @click="getLog(scope.row)">会话记录</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <pagination ref="chatPage" @change="getReserve" :total="total"/>
    <!-- 评价弹窗 -->
    <el-dialog
      title="我的评价"
      :visible.sync="evaluateShow"
      :close-on-click-modal="false"
      width="500px"
      center>
      <div class="dialog-input-body">
        <h6>教练评星（1~5星，星级越高代表评价越高）</h6>
        <div class="starBox">
          <i class="star" v-for="i in 5" :key="i" :class="{star5: i<=star}"/>
        </div>
        <div class="ctx">{{ctx||'无更多评价'}}</div>
      </div>
    </el-dialog>
    <!-- 会话记录 -->
    <el-dialog
      title="会话记录"
      :visible.sync="chatShow"
      :close-on-click-modal="false"
      @close="closeLog"
      width="600px"
      center>
      <div class="msgBox" ref="scroll" @scroll="scroll">
        <ul>
          <li v-for="(item,index) in chatList" :key="index">
            <!-- 时间 -->
            <div class="time" v-if="item.show_time">{{formatTime(item.show_time)}}</div>

            <!-- 系统提示 -->
            <div class="row c" v-if="item.content_type=='tips'">
              <div class="tip">{{item.content}}</div>
            </div>
            <!-- 聊天内容 -->
            <div class="row" :class="{r: item.from_id==now.from_id}" v-else>
              <div class="avatar">
                <img v-if="item.from_id==now.from_id" :src="localAvatar">
                <img v-else :src="now.avatar||require('../../assets/images/head.png')">
              </div>

              <p class="confirm" v-if="item.content_type=='confirm'">教练需要结束会议，你是否同意结束？
                <span v-if="item.is_confirm==-1">
                  <a class="grey">已取消</a>
                </span>
                <span v-else-if="item.is_confirm==1">
                  <a class="grey">已结束</a>
                </span>
              </p>
              <p class="contact" v-else-if="item.content_type=='card'">
                <img :src="item.avatar||require('../../assets/images/head.png')">
                <span>
                  <h3>{{item.realname}}</h3>
                  <h4>{{item.mobile||'-'}}</h4>
                  <h4>{{item.email||'-'}}</h4>
                  <h4>{{item.wx_number||'-'}}</h4>
                </span>
              </p>
              <p v-else-if="item.content_type=='report'" class="link"><a :href="item.url" target="_blank">{{item.title}}</a></p>
              <p v-else>{{item.content}}</p>
              
              <span class="txt" v-if="item.content_type=='card'">名片</span>
              <span class="txt" v-else-if="item.content_type=='report'">链接</span>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      tab: sessionStorage.consultTab||0,  //0：当前预约，1：历史咨询，-1：已取消咨询
      loading: false,
      list: [],
      total: 0,
      stepText: ['','待生成报告','选择专家','待确认','已确认待咨询','咨询'],
      evaluateShow: false,
      star: 0,
      ctx: '',
      now: {},
      localAvatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
      chatShow: false,
      chatList: [],
      chatPage: 1,
      chatTotal: 0,
    }
  },
  mounted(){
    this.getReserve(20, 1)
  },
  methods:{
    change(e){
      if (this.tab == e) return
      this.tab = e
      sessionStorage.consultTab = e
      this.getReserve(20, 1)
    },
    getReserve(per_page, page){
      this.loading = true
      let ref = this.$refs.chatPage
      per_page = per_page || ref ? ref.per_page : 20
      page = page || ref ? ref.page : 1
        this.$request({
          url: '/Company/getMeetingList',
          data: {
            status: this.tab,
            per_page: per_page,
            page: page,
          },
          success: d => {
            if (d.code == 0) {
              this.list = d.data.data
              this.total = d.data.total
            } else if (d.code == 101) {
              localStorage.clear()
              this.$router.replace({path:'/login',query:{back:this.$route.path}})
            } else {
              this.$message({message: d.msg, type: 'error'})
            }
          },
          complete: () => this.loading = false
        })
    },
    startChat(e) {
      if (e.status == 0) {
        this.$message({message: '专家还未确认', type: 'warning'})
        return
      }
      // var query = {fid: e.from_id, tid: e.to_id, role: 'cp', mid: e.meeting_id,}
      // window.open(this.$router.resolve({path: '/chat', query}).href, '_blank');return

      var timestamp = Date.parse(new Date()) / 1000
      if (e.meeting_startime - timestamp <= 900) {  //15分钟前可进入聊天
        var query = {fid: e.from_id, tid: e.to_id, role: 'cp', mid: e.meeting_id,}
        window.open(this.$router.resolve({path: '/chat', query}).href, '_blank')
      } else {
        this.$message({message: '会话还未开始，最多可提前15分钟进入聊天', type: 'warning', duration: 2000})
      }
    },
    cancel(row) {
      let tip = row.type==1 ? '是否取消此次预约？' : '每个企业只有3次取消机会，取消成功后可重新开始预约，是否取消此次预约？'
      this.$alert(tip, '提示', {dangerouslyUseHTMLString: true}).then((e) => {
        this.$request({url: '/Company/meetingCancel',
          data: {meeting_id: row.meeting_id},
          success: d => {
            if (d.code == 0) {
              this.tab = -1
              this.getReserve(20, 1)
              this.$message({message: '取消成功', type: 'success'})
            } else {
              this.$message({message: d.msg, type: 'error'})
            }
          },
        })
      }).catch(() => {})
    },
    //免费咨询
    toFree() {
      this.$request({url: '/CoachesMeeting/getPendingRow',
        success: d => {
          if (d.code == 0) {
            window.open(this.$router.resolve({path: '/expertReserve'}).href, '_blank')
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    //我的评价
    toEvaluate(row) {
      if (row.is_evaluate == 1) {
        this.$request({url: '/Chat/getEvaluation',
          data: {meeting_id: row.meeting_id},
          success: d => {
            if (d.code == 0) {
              this.evaluateShow = true
              this.star = d.data.star
              this.ctx = d.data.content
            } else {
              this.$message({message: d.msg, type: 'error'})
            }
          },
        })
      } else {
        window.open(this.$router.resolve({path: '/expertEvaluate', query: {mid: row.meeting_id}}).href, '_blank')
      }
    },
    //会话记录
    getLog(row) {
      this.chatShow = true
      this.$request({url: '/Chat/getChatLog',
        data: {
          meeting_id: row.meeting_id,
          from_id: row.from_id, 
          to_id: row.to_id,
          per_page: 20,
          page: this.chatPage++,
        },
        success: d => {
          if (d.code == 0) {
            this.now = row
            for (let item of d.data.data) {
              if (item.content_type == 'report') {
                let obj = JSON.parse(item.content)
                item.title = obj.title
                item.url = obj.url
              } else if (item.content_type == 'card') {
                let obj = JSON.parse(item.content)
                item.avatar = obj.avatar
                item.realname = obj.realname
                item.mobile = obj.mobile
                item.wx_number = obj.wx_number
                item.email = obj.email
              }
            }

            let ele = this.$refs.scroll, oldH = ele.scrollHeight
            this.chatList = [...this.chatList, ...d.data.data]
            this.chatTotal = d.data.total
            //初始滑动到底部，之后保持滚动条位置
            this.$nextTick(() => {
              ele.scrollTo({top: ele.scrollHeight - oldH})
            })
          }
        },
      })
    },
    closeLog() {
      this.now = {}
      this.chatList = []
      this.chatTotal = 0
      this.chatPage = 1
    },
    scroll() {
      let ele = this.$refs.scroll
      if (ele.scrollTop < 1 && this.chatList.length < this.chatTotal) {
        this.getLog(this.now)
      }
    },
    formatTime(timestamp) {
      if(!timestamp)  return ''
      let now = new Date(),
          date = String(timestamp).length<13 ? new Date(timestamp*1000) : new Date(timestamp),
          year = date.getFullYear(),
          month = date.getMonth() + 1,
          day = date.getDate(),
          hours = date.getHours(),
          minutes = date.getMinutes();
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      if (now.toDateString() == date.toDateString()) {  //今日
        return `${hours}:${minutes}`
      } else if (now.getFullYear() == year) { //今年
        return `${month}月${day}日 ${hours}:${minutes}`
      } else {
        return `${year}年${month}月${day}日 ${hours}:${minutes}`
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.consult{
  h6{
    padding: 20px 0 10px;
    margin-top: 20px;
    border-top: 1px solid #f6f6f6;
    font-size: 14px;
  }
  .starBox{
    display: flex;
    margin-left: -4px;
    margin-bottom: 26px;
    padding-bottom: 16px;
    border-bottom: 1px solid #f6f6f6;
    .star{
      box-sizing: content-box;
      background-image: url('../../assets/images/expert/expert_star4.png');
      padding: 2px 4px;
      background-size: 20px 20px;
      background-position: center;
    }
    .star5{background-image: url('../../assets/images/expert/expert_star5.png')}
  }
  .ctx{
    width: 100%;
    text-align: justify;
    color: #1a1a1a;
    font-size: 14px;
    line-height: 24px;
    padding: 8px;
    background: #f9f9f9;
    border-radius: 8px;
  }
  .msgBox{
    height: 50vh;
    overflow-y: auto;
    border-top: 1px solid #f0f0f0;
    margin-top: -10px;
    ul{
      display: flex;
      flex-direction: column-reverse;
      li{
        .time{
          font-size: 12px;
          color: #cbcdd1;
          text-align: center;
          margin: 8px 0 6px;
          user-select: none;
        }
        .row{
          display: flex;
          align-items: center;
          margin: 8px 0;
          .avatar{
            padding: 6px 12px 0;
            align-self: flex-start;
            img{
              width: 32px;
              height: 32px;
              border-radius: 50%;
              user-select: none;
              box-shadow: 0 0 6px rgba(200,200,200,.5)
            }
          }
          p{
            padding: 10px 12px;
            border-radius: 10px;
            border-top-left-radius: 0;
            box-shadow: 0 0 4px rgba(200,200,200,.2);
            font-size: 14px;
            color: #808080;
            background: #fafbff;
            border: 1px solid #eeeff4;
            text-align: justify;
            max-width: 60%;
            display: flex;
            align-items: center;
            word-break: break-all;
            white-space: pre-wrap;
            img{
              width: 18px;
              height: 18px;
              margin-right: 4px;
            }
          }
          .link{
            padding: 0;
            user-select: none;
            background: #ebeef7;
            a{
              color: #6b6be5;
              padding: 10px 12px 10px 32px;
              background: url('../../assets/images/expert/report2.png') no-repeat;
              background-position: 12px center;
              background-size: 14px auto;
            }
          }
          .contact{
            display: flex;
            background: #ebeef7;
            white-space: normal;
            img{
              width: 90px;
              height: 90px;
              flex-shrink: 0;
              border-radius: 8px;
              background: #fff;
              align-self: flex-start;
              user-select: none;
            }
            h3{
              margin: 0 8px;
              color: #262626;
              font-size: 14px;
              font-weight: normal;
              line-height: 1.2;
            }
            h4{
              color: #808080;
              margin: 0 8px;
              padding: 2px 0 2px 20px;
              font-size: 14px;
              font-weight: normal;
              background: url('../../assets/images/head.png') no-repeat;
              background-size: 18px 18px;
              background-position: 0 3px;
              &:nth-child(2){background-image: url('../../assets/images/expert/expert_icon1.png')}
              &:nth-child(3){background-image: url('../../assets/images/expert/expert_icon2.png')}
              &:nth-child(4){background-image: url('../../assets/images/expert/expert_icon3.png')}
            }
          }
          .txt{
            font-size: 11px;
            color: #d1d1d1;
            margin: 0 10px;
            white-space: nowrap;
            user-select: none;
          }
          .fail{
            margin: 0 10px;
            cursor: pointer;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
            background: url('../../assets/images/expert/sendFail.png') no-repeat;
            background-size: 100% 100%;
          }
          .tip{
            font-size: 12px;
            background: #e5e5e5;
            text-align: center;
            padding: 2px 6px;
            border-radius: 2px;
            white-space: nowrap;
            user-select: none;
            a{
              color: #6b6be5;
              cursor: pointer;
              &:hover{text-decoration: underline}
            }
          }
          .confirm{
            user-select: none;
            background: #ebeef7;
            display: block;
            color: #808080;
            white-space: normal;
            span{
              display: flex;
              justify-content: flex-end;
              a{
                cursor: pointer;
                font-size: 13px;
                margin-right: 10px;
                margin-top: 10px;
                line-height: 24px;
                padding: 0 16px;
                border-radius: 14px;
                color: #6b6be5;
                border: 1px solid #6b6be5;
                &:nth-child(2){
                  background: #6b6be5;
                  color: #fff;
                  margin-right: 0;
                }
              }
              .grey{
                border-color: #ccc;
                cursor: auto;
              }
            }
          }
        }
        .c{justify-content: center;}
        .r{
          flex-direction: row-reverse;
          p{
            color: #fff;
            background: #6b6be5;
            border: none;
            border-top-left-radius: 10px;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}
</style>