<template>
  <div class="barChart">
    <svg></svg>
    <p>点击柱状图可查看对应成员</p>
    
    <!-- 查看成员评估记录弹窗 -->
    <el-dialog
      :title="title"
      class="dialog-large"
      :visible.sync="memberShow"
      :close-on-click-modal="false"
      center>
      <el-table
        :data="memberList"
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column label="姓名" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head" style="width:102px">
              <img :src="scope.row.avatar || require('../images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="手机号" min-width="108">
          <template slot-scope="scope">
            <span>{{scope.row.mobile}}</span>
          </template>
        </el-table-column>

        <el-table-column label="加入时间" min-width="130">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.join_time,'y-m-d h:m') || '-'}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="评估状态" min-width="76">
          <template slot-scope="scope">
            <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>
        
        <el-table-column label="完成时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.complete_time,'y-m-d h:m') || '-'}}</span>
          </template>
        </el-table-column>

        <el-table-column label="领导思维类型" min-width="240" show-overflow-tooltip v-if="type==2">
          <template slot-scope="scope">
            <span v-if="scope.row.leader_ship">{{scope.row.leader_ship.join(',')}}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="领导思维清晰度" width="120" v-else>
          <template slot-scope="scope">
            <span class="flex--cen" v-if="scope.row.definition">
              <i v-for="i in 5" :key="i" class="star" :class="{
                star1: i <= scope.row.definition, 
                star2: scope.row.definition < i && scope.row.definition > i-1
              }"></i>
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>

        <el-table-column label="操作" min-width="180" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.exam_status==2">
              <span class="btn" @click="preview(scope.row)">预览报告</span>
              <span class="btn" @click="downloadReport(scope.row)">下载报告</span>
            </div>
            <div v-else>
              <span class="btn-disable">预览报告</span>
              <span class="btn-disable">下载报告</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="memberList.length>2" ref="memberPage" @change="view" :total="memberTotal" :position="'page-botttom'"></pagination>
    </el-dialog>
  </div>
</template>
<script>
import * as d3 from 'd3'
import pagination from './page'
export default {
  components: {pagination},
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    color: {
      type: Array,
      default: () => {
        return ['#595fd1']
      }
    },
    type: {
      type: Number
    },
  },
  data(){
    return {
      el: null,
      svg: null,
      title: '',
      memberShow: false,
      memberList: [],
      memberTotal: 0,
      memberLoading: false,
    }
  },
  mounted(){
    let el = this.el = d3.select(this.$el)
    this.svg = el.select('svg').style('dominant-baseline','middle').style('text-anchor','middle')
    if (this.data.length) {
      this.draw()
    } else {
      this.$watch('data', () => {
        setTimeout(()=>{
          this.draw()
        },200)
      })
    }
  },
  methods:{
    draw(){
      let svg = this.svg,
          width = this.el.node().getBoundingClientRect().width,
          height = width  * .6,
          fontSize = width * .028 > 16 ? 16 : width * .028, //字体大小
          left = width * .06,                             //左间距
          right = fontSize  * 8,                          //右间距（7个字的长度）
          top = fontSize * 2,                             //顶部距离
          bottom = height * .14,                          //底部距离
          pieW = (width - left - right) / this.data.length,
          barW = pieW * .3,
          startX = left + pieW / 2;
      //xy轴
      svg.attr('width',width)
         .attr('height',height)
         .append('polyline')
         .attr('fill', 'none')
         .attr('stroke', '#d6d6d6')
         .attr('stroke-width', 1)
         .attr('points', `${left},${top} ${left},${height-bottom} ${width-right},${height-bottom}`)
      //y轴文字
      svg.append('text')
         .attr('font-size', fontSize) 
         .attr('fill', '#333')
         .attr('x', left)
         .attr('y', fontSize)
         .text('数量/人')
      //x轴文字
      svg.append('text')
         .attr('font-size', fontSize) 
         .attr('fill', '#333')
         .attr('x', width - fontSize * 4)
         .attr('y',  height - bottom)
         .text(this.type==1?'领导思维清晰度':'领导思维类型')
      //数据
      let data = this.data,
          scale = [],
          vH = height - top - bottom,
          max = Math.max.apply(Math, data.map(item => item.number)),
          min = Math.min.apply(Math, data.map(item => item.number)),
          box = svg.append('g');
      scale[0] = (min - 5) > 0 ? min - 5 : 0;
      scale[1] = max < 10 ? 10 : max + 8;
      for (let i in data) {
        let eachH = (data[i].number - scale[0]) / (scale[1] - scale[0]) * vH
        let x = startX + pieW * i
        box.append('rect')
           .style('cursor','pointer')
           .attr('stroke', 'none')
           .attr('x', x - barW/2)
           .attr('y',height - bottom - eachH)
           .attr('width', barW)
           .attr('height', eachH)
           .attr('fill', ()=>{
              return this.color[i % this.color.length]
           })
          .on("click",()=>{
            if (this.type == 1) {
              this.title = data[i].definition + '星 成员'
            } else {
              this.title = data[i].leadership + ' 成员'
            }
            this.view(20,1,data[i])
          })
        box.append('text')
           .attr('font-size', fontSize)
           .attr('fill', '#333')
           .attr('x', x)
           .attr('y',  height - bottom - eachH - fontSize)
           .text(data[i].number)
        let text = box.append('text')
                      .attr('font-size', fontSize*.9)
                      .attr('fill', '#333')
                      .attr('y',  height - bottom/2 - 2);
        if (this.type == 1) {
          text.append('tspan').attr('x', x).text(data[i].definition+'星')
        } else {
          text.append('tspan').attr('x', x).text(data[i].leadership)
          text.append('tspan').attr('x', x).attr('dy', '1.1em').text(`${data[i].description}`)
        }
      }
    },
    view(per_page, page, row){
      this.memberLoading = true
      this.memberShow = true
      let ref = this.$refs.memberPage
      per_page = ref ? ref.per_page : per_page || 20
      page = ref ? ref.page : page || 1
      this.$request({
        url:'/Amt/getAmtStatisticsInfo',
        data: {
          per_page: per_page,
          page: page,
          definition: row.definition || '',
          first_leadership_id: row.leadership_id || '',
        },
        success: (data) => {
          if (data.code == 0) {
            this.memberList = data.data.data
            this.memberTotal = data.data.total
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.memberLoading = false
      })
    },
    preview(row){
      let examId = row.exam_id || row.id
      let src = `${this.$mobileUrl}/#/AMT/report?exam_id=${examId}`
      window.open(src)
    },
    downloadReport(row){
      let examId = row.exam_id || row.id
      window.open(`${this.$baseRoot}/Amt/downReport?exam_id=${examId}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.barChart{
  flex: 1;
  *{transition: all .2s;}
  p{
    font-size: 12px;
    color: #333;
    margin-top: 10px;
  }
}
</style>