<template>
  <div>
    <div class="row flex--cen">
      <h3>头像</h3>
      <div class="upBtn flex--cen" @click="headShow=true">
        <img :src="avatar" class="head">
        <h4>选择照片上传</h4>
      </div>
    </div>
    <div class="row flex--cen">
      <h3>真实姓名</h3>
      <input class="input" type="text" v-model="realname">
    </div>
    <div class="bottom-btn" @click="save">保存</div>
    
    <!-- 修改头像 -->
    <el-dialog
      title="修改头像"
      :visible.sync="headShow"
      :close-on-click-modal="false"
      @close="closeLogo"
      center>
      <p>仅支持JPG,GIF,PNG格式上传<br>建议图片尺寸为80px*80px</p>
      <div class="cut_box">
        <canvas ref="canvas" width="80" height="80"></canvas>
        <div class="left flex-cen-cen">
          <img ref="source" :src="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
          <div class="up" v-if="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
            <div class="clip" :style="{width:`${clipsize}px`,height:`${clipsize}px`,left:logo.x+'px',top:logo.y+'px'}"  @mousedown="mousedown" @mousewheel="mousewheel"></div>
          </div>
        </div>
        <div class="right">
          <div class="preview">
            <p>预览</p>
            <div class="img">
              <img v-if="logo.src" :src="logo.src" :style="{
                width:`${logo.viewScale*logo.width}px`,
                height:`${logo.viewScale*logo.height}px`,
                transform: `translate(-${logo.viewScale*logo.x}px,-${logo.viewScale*logo.y}px)`
              }">
            </div>
          </div>
          <div class="chooseBtn">
            {{logo.src ? '重新选择' : '选择图片'}}
            <input type="file" accept="image/png,image/bmp,image/jpeg,image/jpg,image/gif" id="imgBtn" @change="changeLogo">
          </div>
        </div>
      </div>
      <div class="bottom-btn" @click="uploadLogo">确定</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data(){
    return {
      headShow: false,
      realname: localStorage.getItem('realname') || '',
      avatar: localStorage.getItem('avatar') || require('../../assets/images/head.png'),
      logo: {
        image: '',      //要上传的图片
        src: '',
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        size: 250,      //左框的大小
        viewScale: 1,
      },
      clipsize: 0,      //裁剪框的宽高
    }
  },
  created(){

  },
  methods:{
    closeLogo(){
      this.logo = {src:'',width:0,height:0,x:0,y:0,size:250,viewScale:1}
    },
    //选择图片
    changeLogo(ev){
      this.closeLogo()
      let file = ev.target.files[0]
      // this.logo.src = URL.createObjectURL(file);return;//预览
      if (!file) {
        return
      } else if (file.size / 1024 / 1024 > 2) {
        this.$message({duration:2000, message:'文件过大，请重新选择', type:'warning'})
        return
      }
      let img = new Image()
      img.src = this.logo.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width < img.height) {
          let scale = this.logo.size / img.height
          this.logo.height =  this.logo.size
          this.logo.width = this.clipsize = this.logo.max = Math.floor(scale * img.width)
        } else {
          let scale = this.logo.size / img.width
          this.logo.width =  this.logo.size
          this.logo.height = this.clipsize = this.logo.max = Math.floor(scale * img.height)
        }
        this.logo.viewScale = 80 / this.clipsize
        this.logo.name = file.name
        this.logo.image = img
      }
      ev.target.value = ''
    },
    //上传头像
    uploadLogo(){
      if (this.logo.image) {
        let canvas = this.$refs.canvas, 
            ctx = canvas.getContext('2d'),
            logo = this.logo;
        ctx.clearRect(0, 0, 80, 80)
        ctx.drawImage(
          logo.image,
          -logo.viewScale*logo.x, -logo.viewScale*logo.y,
          logo.viewScale*logo.width, logo.viewScale*logo.height
        );
        canvas.toBlob((blob) => {
          let formData = new FormData()
          let file = new File([blob], logo.name)
          formData.append('avatar', file)
          this.$request({
            method: 'post',
            url: '/Coaches/uploadAvatar',
            data: formData,
            success: d => {
              if (d.code == 0) {
                this.headShow = false 
                this.avatar = d.data.save_path
              } else {
                this.$message({duration: 2000, message: d.msg, type:'error'})
              }
            },
          })
        })
      } else {
        this.$message({duration:2000,message:'请先选择图片',type:'warning'})
      }
    },
    mousedown(e) {
      if (this.logo.src) {
        let startx = this.logo.x, starty = this.logo.y;
        document.onmousemove = ev => {
          let moveX = ev.screenX - e.screenX + startx, 
              moveY = ev.screenY - e.screenY + starty;
          this.logo.x = moveX < 0 ? 0 : moveX > (this.logo.width - e.target.offsetWidth) ? this.logo.width - e.target.offsetWidth : moveX
          this.logo.y = moveY < 0 ? 0 : moveY > (this.logo.height - e.target.offsetHeight) ? this.logo.height - e.target.offsetHeight : moveY
        }
        document.onmouseup = () => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    mousewheel(e) {
      let res = this.clipsize + e.wheelDelta / 20
      this.clipsize = res > this.logo.max ? this.logo.max : res < 80 ? 80 : res
      this.logo.viewScale = 80 / this.clipsize
      if ((e.target.offsetLeft + this.clipsize) > this.logo.width)  //控制left
        this.logo.x = this.logo.width - this.clipsize
      if ((e.target.offsetTop + this.clipsize) > this.logo.height)  //控制top
        this.logo.y = this.logo.height - this.clipsize
    },
    save() {
      this.$request({url: '/Coaches/setConfigData', data: {}, 
        success: d => {
          if (d.code == 0) {
            this.$message({message: '保存成功', type: 'success'})
            if (this.tab == 'baseinfo') {
              localStorage.realname = this.$parent.$refs.header.realname = this.realname
              localStorage.avatar = this.$parent.$refs.header.avatar = this.avatar
            }
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
  .row{
    margin-top: 40px;
    white-space: nowrap;
    user-select: none;
  }
  h3{
    font-size: 14px;
    color: #868686;
    font-weight: normal;
    width: 90px;
    text-align: right;
    margin-right: 20px;
    user-select: none;
    flex-shrink: 0;
  }
  .upBtn{
    cursor: pointer;
    margin: 0 20px;
    .head{
      margin-right: 20px;
      width: 90px;
      height: 90px;
      overflow: hidden;
      border-radius: 50%;
    }
    h4{
      font-size: 14px;
      color: #6b6be5;
      font-weight: normal;
    }
  }
  .input{
    border: 1px solid #eceef3;
    border-radius: 4px;
    padding: 12px 16px;
    color: #1a1a1a;
    width: 300px;
    min-width: 300px;
  }
  .cut_box{
    display: flex;
    margin-top: 20px;
    user-select: none;
    canvas{display: none; opacity: 0;}
    .left{
      width: 250px;
      height: 250px;
      background: #eceef3;
      position: relative;
      flex-shrink: 0;
      outline: 1px solid #eceef3;
      overflow: hidden;
      & *{transition: all 0s;}
      img{
        background: #f6f6f6;
      }
      .up{
        position: absolute;
        .clip{
          position: absolute;
          border: 1px dashed #6b6be5;
          cursor: move;
          box-shadow: 0 0 0 300px rgba(0,0,0,.4);
          transition: width .1s, height .1s;
        }
      }
    }
    .right{
      flex: 1;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      .preview{
        border: 1px solid #eceef3;
        flex: 1;
        p{
          font-size: 14px;
          line-height: 50px;
          text-align: center;
        }
        .img{
          width: 80px;
          height: 80px;
          display: block;
          margin: 20px auto 0;
          border-radius: 50%;
          background: #eceef3;
          overflow: hidden;
          border: 1px solid #eceef3;
          img{
            width: 100%;
            transition: width .1s, height .1s;
          }
        }
      }
      .chooseBtn{
        background: #6b6be5;
        border-radius: 4px;
        line-height: 32px;
        height: 32px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
        position: relative;
        input{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
</style>