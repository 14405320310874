<template>
  <div class="amt">
    <indexHeader/>
    <img class="banner" src="../../assets/images/index/bannerAMT.jpg">
    <div class="content">
      <section class="title" style="padding:0 10px">
        <h1>领导者的行为反应</h1>
        <p>由其思维倾向和行为方式决定，明确自己的思维倾向和行为方式，有助于领导者做出最佳反应；但领导者的思维倾向和行为方式，会随着工作岗位以及阶段的变化而发生改变。因此，在不同的阶段需要及时了解、明确自己，帮助自己做出最佳的行为反应，与工作岗位更好的匹配。</p>
      </section>
      <h1>关于AMT</h1>
      <section class="flex-bet-cen" style="height:260px;margin:60px auto 20px;">
        <div style="margin-left:30px">
          <h2 class="right"><b>WHY</b><br>为什么AMT这么重要</h2>
          <p class="right">这三者之间的协调、一致<br>是个人、团队、组织<br>创造良好绩效或成果最重要的因素</p>
        </div>
        <div class="pic" style="margin-left:160px">
          <ul>
            <li>角色认知</li>
            <li>领导行为</li>
            <li>领导思维</li>
          </ul>
        </div>
      </section>
      <section class="flex-bet-cen margin">
        <img style="width:32%;margin: 0 100px 0 140px" src="../../assets/images/index/pro_amt_1.png">
        <div style="width:42%;">
          <h2><b>HOW</b><br>如何实现</h2>
          <p>基于五种领导思维：<br><br>E型领导（企业家思维）、M型领导（管理思维）、P型领导（专家思维）、F型领导（前线思维）、S型领导（战略思维），让领导者更加简单、轻松的理解自己的思维倾向和行为方式，以便在工作中做出最佳行为反应。</p>
        </div>
      </section>
      <section class="flex-bet-cen margin">
        <div style="width:42%">
          <h2 class="right"><b>WHAT</b><br>『AMT领导者画像』是什么</h2>
          <p>AMT是基于实际工作场景，反馈领导者思维倾向和行为方式的评估工具，它将领导者的思维倾向和行为方式分为五种，轻松的理解自己的思维倾向和行为方式，以便在工作中做出最佳行为反应。</p>
        </div>
        <img style="width:32%;margin:0 70px 0 100px" src="../../assets/images/index/pro_amt_2.png">
      </section>
      <div style="background:#f4f4f4;padding:1px 0 40px;min-width:760px">
        <section class="flex-bet-cen margin" style="margin-top:80px">
          <img style="width:42%;margin-right:90px" src="../../assets/images/index/pro_amt_3.png">
          <div style="width:38%">
            <h2>AMT的意义</h2>
            <p>帮助你了解当下工作场景中，惯用的领导思维。<br>这些惯用的思维将决定一个人的领导行为、沟通方式、并影响互动关系与管理效率。<br>哪一种领导思维并不重要，最重要的是，你要知道哪一种思维最能让你发挥出最佳的领导力水平。</p>
          </div>
        </section>
        <section class="flex-bet-cen margin">
          <div style="width:27%;margin:0 60px 0 80px">
            <h2 class="right">AMT的应用</h2>
            <p class="right"><span>自我觉察——</span><br>分析自己当下的角色定位与思维的匹配度<br>找出改善空间，提升领导力<br><br><span>觉察他人——</span><br>理解他人与自己的思维差异<br>换位思考，调整沟通方式<br>提升核心管理团队之间的合作效率</p>
          </div>
          <img style="width:36%;" src="../../assets/images/index/pro_amt_4.png">
        </section>
        <section class="flex-bet-cen margin" style="margin-top:200px">
          <img style="width:40%;margin: 0 110px 0 0px" src="../../assets/images/index/pro_amt_5.png">
          <div style="width:38%">
            <h2>AMT说明</h2>
            <p>帮助你了解当下工作场景中，惯用的领导思维。<br>这些惯用的思维将决定一个人的领导行为、沟通方式、并影响互动关系与管理效率。<br>哪一种领导思维并不重要，最重要的是，你要知道哪一种思维最能让你发挥出最佳的领导力水平。</p>
          </div>
        </section>
        <section class="flex-bet-cen margin" style="margin-top:240px;height:360px">
          <div style="width:380px">
            <h2 class="right">AMT报告样例</h2>
            <p class="right">领导思维清晰度<br>领导思维类型详述<br>领导思维的相互关系<br>解释说明</p>
          </div>
          <div class="r1" style="margin: 0 80px 0 80px">
            <div class="r2">
              <div class="phone">
                <div class="box">
                  <div class="report"><img src="https://api.app.startuplanet.huayanspace.com/uploads/cardimage/AMTReport/2022-10-20/AMTReport_671.jpg"></div>
                </div>
                <b></b>
              </div>
            </div>
          </div>
        </section>
      </div>
      <h1>不同的领导思维展现不同的领导行为</h1>
      <section class="thinking">
        <ul class="flex-bet" v-for="(item,i) in thinking" :key="i">
          <li v-for="(it,j) in item" :key="j">
            <div class="icon" v-if="it.icon"><img :src="it.icon"></div>
            <h5>{{it.label}}</h5>
            <p v-html="it.text"></p>
          </li>
        </ul>
      </section>
    </div>
    <indexFooter/>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
export default {
  components:{indexHeader,indexFooter},
  data(){
    return {
      thinking: [
        [
          {icon:require('../../assets/images/index/pro_amt_6.png'),label:'企业家思维',text:'A.擅长创造梦想，把想法 转化为结果。<br>B.注重想法和实际行动。<br>C.关注行动的有效性及目标达成。'},
          // {icon:require('../../assets/images/index/pro_amt_7.png'),label:'运营思维',text:'A.能在混乱中创造秩序，把目标转化为可行的计划，使项目有序推进。<br>B.注重落地实施的有效性，注重组织结构、流程、规章制度、标准等是否规范与完整。'},
          {icon:require('../../assets/images/index/pro_amt_8.png'),label:'管理思维',text:'A.善于组织和 安排工作， 能够与人协作，关心、激励他人。<br>B.关注人，也重视流程，相信人的创造性与潜能。'},
          {icon:require('../../assets/images/index/pro_amt_9.png'),label:'专家思维',text:'A.崇尚专业，力求在专业领域做到最好，凭借强硬的专业、过人预见和判断，影响、引领他人。<br>B.关注想法和人，有较高的思考层次和能力，热衷于探索本质。'},
        ],
        [
          {icon:require('../../assets/images/index/pro_amt_10.png'),label:'前线思维',text:'A.乐于在前线战斗。专注于客户和市场，以完成任务、实现目标为导向。<br>B.关注目标、计划的有效落地，同时，也会激励大家，更快更好完成目标。'},
          {icon:require('../../assets/images/index/pro_amt_11.png'),label:'战略思维',text:'A.注重战略布局，着眼全局，注重中长期，并以此制定愿景和实施战略。<br>B.思考比较周全，关注整体性、前瞻性、长期性，善于将各种资源有效整合，把控过程，让战略有效落地实施。'},
          {}
        ]
      ]
    }
  },
}
</script>
<style lang="scss" scoped>
.banner{
  padding-top: 50px;
  width: 100%;
  display: block;
  min-width: 800px;
}
.amt{
  height: 100%;
  overflow-y: auto;
  .content{
    margin: 0 auto;
    color: #212121;
    font-size: 15px;
    p{text-align: justify;}
    h1{
      font-size: 22px;
      margin: 100px 20px 30px;
      text-align: center;
    }
    h2{
      font-size: 24px;
      margin-bottom: 20px;
      b{font-size: 28px;}
    }
    .right{
      text-align: right !important;
      span{color: #6b6be5;font-weight: bold}
    }
    .title{
      p{
        width: 640px;
        margin: 0 auto;
        text-align: justify;
        margin-bottom: 160px;
      }
    }
    section{
      padding: 0 20px;
      max-width: 1100px;
      margin: 0 auto;
      justify-content: center;
    }
    .pic{
      margin-right: 30px;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      border: 1px dashed #99a5ff;
      position: relative;
      background: #f6f6ff;
      user-select: none;
      ul{
        position: absolute;
        background: #d4d4ff;
        border-radius: 50%;
        width: 114px;
        height: 114px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        li{
          transition: all .3s;
          position: absolute;
          color: #fff;
          width: 100px;
          height: 100px;
          line-height: 100px;
          text-align: center;
          border-radius: 50%;
          background-image: linear-gradient(to bottom,#7663f3 0%,#4f86f5 100%);
          box-shadow: -6px 0 10px rgba($color: #6f53fc, $alpha: .2);
          &:nth-child(1){top: -80px;left: 10px;}
          &:nth-child(2){top: 62px;left: -70px;}
          &:nth-child(3){top: 60px;right: -80px;}
        }
      }
    }
    .margin{
      margin: 120px auto 60px;
    }
    .r1{
      width: 300px;
      height: 300px;
      border-radius: 50%;
      border: 1px solid #99a5ff;
      background: #f6f6ff;
      position: relative;
      margin-top: 100px;
      .r2{
        width: 260px;
        height: 260px;
        box-sizing: border-box;
        border-radius: 50%;
        border: 1px solid #99a5ff;
        background: #d2d1ff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
      .phone{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 24px;
        width: 160px;
        height: 340px;
        border-radius: 16px;
        border: 6px solid #fff;
        box-sizing: border-box;
        scrollbar-width: none;
        .box{
          border-radius: 16px;
          width: 100%;
          height: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar{display: none}
        }
        &::before{
          content: '可滚动页面内容查看';
          position: absolute;
          color: #7067f5;
          font-size: 12px;
          width: 100%;
          top: -30px;
          left: 0;
          text-align: center;
        }
        &::after{
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: -6px;
          background: #fff;
          width: 82px;
          height: 20px;
          border-radius: 8px;
        }
        .report{
          position: relative;
          img{width: 100%;}
          &:after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .thinking{
      margin-bottom: 160px;
      padding: 0 50px;
      ul{
        margin: 70px 0 90px;
        li{
          .icon{
            width: 60px;
            height: 60px;
            margin: 12px auto;
            border-radius: 50%;
            background: #6b6be5;
            img{width: 100%}
          }
          h5{
            text-align: center;
            color: #222;
            font-size: 20px;
            margin-bottom: 24px;
          }
          p{
            width: 244px;
            font-size: 14px;
            color: #6d6b6b;
            line-height: 1.6;
          }
          &:nth-child(2){margin: 0 10px;}
        }
      }
    }
  }
}
@media screen and (max-width: 1000px) {
  .amt .content{
      .pic{
        ul{
        width: 80px;
        height: 80px;
          li{
            width: 80px;
            height: 80px;
            line-height: 80px;
            &:nth-child(1){top: -60px;left: 5px;}
            &:nth-child(2){top: 42px;left: -58px;}
            &:nth-child(3){top: 44px;right: -63px;}
          }
      }
    }
  }
}
</style>