<template>
  <div class="reportCenter">
    <h1 class="h1_title">
      <img  class="head" src="../../../assets/images/company/nav_report.png">报告管理
    </h1>
    <div class="move-up"></div>
    <nav>
      <h2 :class="{curr:tab==1}" @click="changeReport(1)">团队报告</h2>
      <h2 :class="{curr:tab==2}" @click="changeReport(2)">层级报告</h2>
    </nav>

      <div class="table-btn">
        <div class="btn" @click="confirm" v-if="teamReport.length">批量下载</div>
        <div class="btn btn-disable" v-else>批量下载</div>
      </div>
      <el-table
        v-loading="loading"
        ref="table"
        class="reportTable"
        :data="teamReport"
        tooltip-effect="dark"
        height="calc(100vh - 307px)"
        style="width: 100%">
        <el-table-column type="selection" width="50" :selectable="checkSelect"></el-table-column>
        <el-table-column label="报告名称" min-width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="latest" v-if="scope.row.id==newId">新</span>
            <span class="istop"  v-if="scope.row.istop">顶</span>
            <span>{{scope.row.report_name}}</span>
          </template>
        </el-table-column>
        <el-table-column label="生成时间" min-width="132">
          <template slot-scope="scope">
            <span>{{scope.row.create_time}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="structure" label="所属架构" min-width="150" show-overflow-tooltip/>
        <el-table-column prop="organization_name" label="所属团队" min-width="140" show-overflow-tooltip/>
        <el-table-column label="人数" min-width="60" align="center">
          <template slot-scope="scope">
            <span class="blue">{{scope.row.total_number}}{{scope.row.state}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="260" align="center">
          <template slot-scope="scope">
            <span class="btn" @click="view(scope.row)" :id="'view'+scope.row.id">预览报告</span>
            <!-- <span class="btn" @click="confirm(scope.row)">下载报告</span> -->
            <span class="btn red" v-if="scope.row.istop" @click="setTop(scope.row,0)">取消置顶</span>
            <span class="btn" v-else @click="setTop(scope.row,1)">置顶报告</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination ref="teamPage" @change="getReport" :total="teamTotal"></pagination>

    <!-- 弹窗 -->
    <el-dialog
      title="下载报告"
      class="el-dialog-confirm"
      :visible.sync="downloadShow"
      :close-on-click-modal="false"
      center>

        <span v-if="downloadList.length==1">确定要下载 [{{downloadList[0].report_name}}] 报告吗？</span>
        <span v-else>确定要下载这{{downloadList.length}}份报告吗？</span>
        <span slot="footer" class="dialog-footer">

        <el-button class="downloadBtn" v-if="downloading" type="primary">下载中...</el-button>
        <el-button class="downloadBtn" v-else type="primary" @click="download">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 预览报告 -->
    <el-drawer
      title="团队报告"
      :visible.sync="reportShow"
      show-close
      @close="drawerClose"
      :with-header="true">
      <!-- <i></i> -->
      <img class="loading" src="../../../assets/images/company/loading.gif">
      <iframe 
        :src="iframeSrc" 
        frameborder="0" 
        width="100%"
        height="100%"
        >
      </iframe>
      <!-- <div class="qrcode flex-bet-cen">
        <img src="../../../assets/images/code-planet.jpg">
        可扫描小程序码在手机端查看并保存
      </div> -->
    </el-drawer>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination,},
  data(){
    return {
      loading: false,
      tab: sessionStorage.reportTab || 1, //1：团队报告，2：层级报告
      newId: '',//判断报告是否为新
      downloadShow: false,
      downloadList: [],
      downloading: false,
      
      teamReport: [],
      teamTotal: 0,
      tierTotal: 0,

      upTipHide: false,                 //置顶按钮提示点击过后不再显示
      downTipHide: false,               //取消置顶按钮提示点击过后不再显示
      reportShow: false,                //报告预览显隐
      iframeSrc: '',
    }
  },
  mounted(){
    this.getReport()
  },
  methods: {
    getReport(per_page, page){
      this.loading = true
      let ref = this.$refs.teamPage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
        this.$request({
          method: 'post',
          url: '/Tvs/getReportList',
          data: {
            report_type: this.tab,
            per_page: per_page,
            page: page,
            // keyword: '',
          },
          success: (data) => {
            if (!data.code) {
              this.teamReport = data.data.data
              this.teamTotal = data.data.total
            } else if (data.code == 101) {
              this.$router.replace({path:'/login',query:{back:this.$route.path}})
              localStorage.clear();
            } else {
              this.$message({duration:2000,message:data.msg,type:'error'})
            }
          },
          complete: ()=> this.loading = false
        })
    },
    checkSelect(row){
      return !(row.template_id == 5 && row.template_version == '1.0')
    },
    changeReport(e){
      if(this.tab==e) return;
      this.tab = e
      this.downloadList = []
      this.teamReport = []
      this.getReport(20,1)
    },
    //操作确认
    confirm(row){
      if (row.id) {
        this.downloadList = [row]
        this.downloadShow = true
      } else if (this.$refs.table.selection.length) {
        this.downloadList = this.$refs.table.selection
        this.downloadShow = true
      } else {
        this.$message({duration: 1000,message: '请先选择'})
      }
    },
    //预览
    view(row){
      this.iframeSrc = `${this.$mobileUrl}/#/vtsTeam/${row.id}?hide=1`
      this.reportShow = true
      return
      if (this.load) {
        return
      } else if (row.template_id == 5 && row.template_version == '1.0') {
        this.iframeSrc = `${this.$mobileUrl}/#/vigourGroup/${row.id}?hide=1`
        this.reportShow = true
      } else {
        let pdfLoading = this.$loading({
          lock: true,//lock的修改符--默认是false
          spinner: 'el-icon-loading',//自定义加载图标类名
          background: '#fff',//遮罩层颜色
          target: document.querySelector('#view' + row.id)
        });
        this.load = true
        let type = this.tab == 1 ? 'tvr': 'tier'
        this.checkPdf(row.id).then(e => {
          pdfLoading.close()
          this.load = false
          if (e == 0) {
            window.open(`${this.$reportUrl}/tvr/#/${type}/${row.id}`,'_blank')
          } else {
            this.$message.error(e)
          }
        })
      }
    },
    checkPdf(id){
      return new Promise((resolve) => {
        this.$request({
          url: '/Tvs/isCreateReport',
          data: {id: id},
          success: (data) => {
            if (data.code == 0) {
              resolve(0)
            } else if (data.code == 2) {
              this.$request({
                method: 'post',
                url: '/Tvs/createViewReport',
                data: {id: id},
                success: (res) => {
                  if (res.code == 0)
                    run()
                  else
                    resolve(res.msg)
                },
                error: () => resolve('网络错误，无法生成报告')
              })
            } else if (data.code == 3) {
              run()
            } else {
              resolve(data.msg)
            }
          },
          error: () => resolve('网络错误，无法生成报告')
        })
        let run = () => {
          let time = 0
          let timer = setInterval(() => {
            this.$request({
              url: '/Tvs/isCreateReport',
              data: {id: id},
              success: (data) => {
                if (data.code == 0) {
                  resolve(0)
                  clearInterval(timer)
                  return
                }
              },
              error: () => {
                resolve('网络错误')
                clearInterval(timer)
              },
              complete: () => {
                if (time++ > 30) {
                  resolve('报告生成超时')
                  clearInterval(timer)
                } 
              }
            })
          }, 1500)
        }
      })
    },
    //下载报告
    download(){
      this.downloading = true
      if (this.downloadList[0].template_id == 5 && this.downloadList[0].template_version == '1.0') {  //下载单个团队报告1.0
        let reportId = this.downloadList[0].id
        this.$request({
          method: 'post',
          url: '/TeamReport/getReportImage',
          data: {report_id: reportId},
          success: (data) => {
            if (data.code == 0) {
              window.open(`${this.$baseRoot}/Tvs/downloadReport?id=${reportId}`,'_blank')
            }
          },
          complete: () => this.downloadEnd()
        })
      } else if (this.downloadList.length == 1) { //下载单个团队/层级报告
        let type = this.tab == 1 ? 'tvr': 'tier', id = this.downloadList[0].id
        this.checkPdf(id).then((e) => {
          if (e == 0) window.open(`${this.$baseRoot}/Tvs/downloadReport?id=${id}#/${type}/${id}`,'_blank')
          else this.$message.error(e)
          this.downloadEnd()
        })
      } else {  //下载多个团队/层级报告
        let reportId = ''
        this.downloadList.forEach(item => reportId += `${item.id},`)
        reportId = reportId.substring(0, reportId.length-1)
        this.$request({
          url: '/Tvs/getReportStatus',
          data: {id: reportId},
          success: (data) => {
            if (data.code == 0) {
              window.open(`${this.$baseRoot}/Tvs/batchDownloadReport?id=${reportId}`,'_blank')
              this.downloadEnd()
            } else if (data.code == 1) {  //未生成
              let idStr = data.data.join(',')
              this.$request({
                method: 'post',
                url: '/Tvs/batchCreateReport',
                data: {id: idStr},
                success: () => {
                  let timer = setInterval(() => {
                    this.$request({
                      url: '/Tvs/getReportStatus',
                      data: {id: idStr},
                      success: (e) => {
                        if (e.code == 0) {
                          this.downloadEnd()
                          clearInterval(timer)
                          window.open(`${this.$baseRoot}/Tvs/batchDownloadReport?id=${reportId}`,'_blank')
                        } else if (e.code == 102) {
                          clearInterval(timer)
                          this.$message.error(e.msg)
                        }
                      },
                      error: () => {
                        this.$message.error('网络错误')
                        this.downloadEnd()
                        clearInterval(timer)
                      }
                    })
                  }, 1500)
                },
              })
            } else if (data.code == 2) {  //生成中
              let timer = setInterval(() => {
                this.$request({
                  url: '/Tvs/getReportStatus',
                  data: {id: reportId},
                  success: (e) => {
                    if (e.code == 0) {
                      clearInterval(timer)
                      this.downloadEnd()
                      window.open(`${this.$baseRoot}/Tvs/batchDownloadReport?id=${reportId}`,'_blank')
                    } else if (e.code == 102) {
                      clearInterval(timer)
                      this.$message.error(e.msg)
                    }
                  },
                  error: () => {
                    this.$message.error('网络错误')
                    this.downloadEnd()
                    clearInterval(timer)
                  }
                })
              }, 1500)
            } else {
              this.$message.error(data.msg)
            }
          },
          error: () => this.$message.error('网络错误')
        })
      }
    },
    downloadEnd(){
      this.$refs.table.clearSelection();
      this.downloadShow = false
      this.downloading = false
    },
    setTop(row, istop){
      this.loading = true
      this.$request({
        url: '/Tvs/istopReportSet',
        data: {
          report_id: row.id,
          istop: istop,
        },
        success: (e) => {
          this.loading = false
          if (e.code == 0) {
            this.getReport()
          } else {
            this.$message.error(e.msg)
          }
        },
        error: () => {
          this.loading = false
          this.$message({type:'error', message:'网络异常'})
        }
      })
      // this.$http.post('/Tvs/istopReportSet', {
      //   report_id: row.id,
      //   istop: istop,
      // }).then( ({data}) => {
      //   this.loading = false
      //   if (!data.code) {
      //     this.getReport()
      //   } else {
      //     this.$message({duration: 1500, message: data.msg, type:'error'})
      //   }
      // }, () => {
      //   this.loading = false
      //   this.$message({duration:1000, type:'error', message:'网络异常'})
      // })
    },
    drawerClose(){
      this.iframeSrc = ''
    },
  },
  destroyed(){
    sessionStorage.removeItem('reportTab')
  },
}
</script>

<style lang="scss" scoped>
.reportCenter{
  nav{
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #eceef3;
    user-select: none;
    h2{
      font-weight: normal;
      font-size: 14px;
      margin: 0 10px 0 26px;
      line-height: 50px;
      padding: 0 2px;
      position: relative;
      cursor: pointer;
    }
    .curr{
      color: #6b6be5;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: #6b6be5;
        left: 0;
        bottom: 0;
      }
    }
    .unread::after{
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff4747;
      right: -6px;
      top: 14px;
    }
  }
  .total{
    font-size: 14px;
    text-align: right;
    margin-top: 20px;
  }
  h6{
    font-size: 14px;
    white-space: nowrap;
    margin: -30px 0 20px;
    color: #999;
    font-weight: normal;
    text-align: center;
  }
  .latest{
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      line-height: 18px;
      text-align: center;
      background: #ff4747;
      color: #fff;
      font-size: 12px;
  }
  .istop{
      display: inline-block;
      width: 18px;
      height: 18px;
      border-radius: 2px;
      line-height: 18px;
      text-align: center;
      background: #f97286;
      color: #fff;
      font-size: 12px;
  }
  .label{
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;
    height: 18px;
    span{
      width: 18px;
      height: 18px;
      border-radius: 2px;
      margin-right: 4px;
    }
    .new{background: #ff4747;}
    .up{background: #f97286;}
  }
  .loading{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    z-index: 0;
  }
  iframe{
    position: relative;
    z-index: 1;
    padding-bottom: 10px;
    // padding-bottom: 100px;
  }
  .qrcode{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100px;
    border-top: 1px solid #e5e5e5;
    bottom: 0;
    left: 0;
    background: #fff;
    padding: 0 40px;
    font-size: 14px;
    img{
      width: 80px;
      height: 80px;
    }
  }
  .downloadBtn{
    width: 100px;
  }
}
</style>