<template>
  <div class="news">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_news.png">消息中心
    </h1>
    <div class="move-up"></div>
    <nav>
      <h2 :class="{curr:tab=='all'}" @click="toggle('all')">全部消息</h2>
      <h2 :class="{curr:tab=='unread',unread:unreadNumber}" @click="toggle('unread')">未读消息</h2>
    </nav>

    <div class="table-btn">
      <div class="btn" @click="confirm('',1)">标记已读</div>
      <div class="btn del" @click="confirm('')">删除</div>
    </div>
    <el-table
      v-loading="newsLoading"
      ref="table"
      :data="allNews"
      tooltip-effect="dark"
      height="calc(100vh - 306px)"
      style="width: 100%">

      <el-table-column type="selection" width="50"></el-table-column>

      <el-table-column label="标题内容" min-width="160" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :class="{title:scope.row.status==0}" class="hold" @click="openDetail(scope)">{{scope.row.title}}</span>
        </template>
      </el-table-column>

      <el-table-column label="时间" show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.create_time,'y-m-d h:m')}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="type_name" label="类型"></el-table-column>
      
      <el-table-column label="操作" width="130" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="openDetail(scope)">详情</span>
          <span class="btn red" @click="confirm(scope.row)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="newsPage" @change="getNews" :total="newsTotal"></pagination>

    <!-- 删除消息弹窗 -->
    <el-dialog
      title="删除消息"
      class="el-dialog-confirm"
      :visible.sync="delShow"
      :close-on-click-modal="false"
      center>
      <span v-if="delArr.length==1">确定要删除《{{delArr[0].title}}》消息吗？</span>
      <span v-else>确定要删除选中的{{delArr.length}}条消息吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="del">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 标记已读弹窗 -->
    <el-dialog
      title="标记已读"
      class="el-dialog-confirm"
      :visible.sync="signShow"
      :close-on-click-modal="false"
      center>
      <span v-if="signArr.length==1">确定要标记《{{signArr[0].title}}》已读吗？</span>
      <span v-else>确定要标记选中的{{signArr.length}}条消息为已读吗？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sign">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 详情 -->
    <el-dialog
      class="message"
      :title="article.title"
      :visible.sync="messageShow"
      :close-on-click-modal="false"
      width="600"
      center>
      <h6 class="news-time">{{$formatTime(article.create_time,'y-m-d h:m')}}</h6>
      <div class="content" v-html="article.content"></div>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      tab: 'all',
      delShow: false,
      delArr: [],
      signShow: false,
      signArr: [],
      messageShow: false,

      allNews: [],
      newsTotal: 0,
      newsLoading: false,
      unreadNumber: 0,

      article: {title:'',content:'',create_time:''},
    }
  },
  mounted(){
    this.getNews()
  },
  methods: {
    getNews(per_page, page){
      this.newsLoading = true
      let ref = this.$refs.newsPage;
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/Message/getList',
        data: {
          per_page: per_page,
          page: page,
          type: this.tab
        },
        success: (data) => {
          if (data.code == 0) {
            this.allNews = data.data.data
            this.newsTotal = data.data.total
            this.$parent.$refs.header.getUnread().then((num)=>{
              this.unreadNumber = num
            })
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back:this.$route.path}})
            localStorage.clear();
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.newsLoading = false
      })
    },
    toggle(e){
      if(this.tab==e) return;
      this.tab = e
      this.delArr = []
      this.getNews()
    },
    //操作确认
    confirm(e,type=0){    // type=0，删除；type=1：标记已读
      if(type==0) //删除
        if (e) {
          this.delArr = [e]
          this.delShow = true
        } else if (this.$refs.table.selection.length) {
          this.delArr = this.$refs.table.selection
          this.delShow = true
        } else {
          this.$message({duration: 1000,message: '请先选择'})
        }
      else //标记已读
        if (e) {
          this.signArr = [e]
          this.signShow = true
        } else if (this.$refs.table.selection.length) {
          this.signArr = this.$refs.table.selection
          this.signShow = true
        } else {
          this.$message({duration: 1000,message: '请先选择'})
        }
    },
    //删除信息
    del(){
      let id = ''
      this.delArr.forEach( item => id += `${item.id},`)
      this.$request({
        url:'/Message/del',
        data: {message_id: id.substring(0,id.length-1)},
        success: () => {
          this.delShow = false
          this.getNews()
          this.$message.success('删除成功')
        },
      })
    },
    //标记信息为已读
    sign(){
      this.read(this.signArr)
      this.signShow = false
    },
    read(arr){
      let id = ''
      arr.forEach( item => id += `${item.id},`)
      this.$request({
        url:'/Message/read',
        data: {message_id: id.substring(0,id.length-1)},
        success: ({data}) => {
          this.getNews()
        },
      })
    },
    //查看信息详情
    openDetail({row,$index}){
      this.$request({
        url:'/Message/getDetail',
        data: {message_id: row.id},
        success: ({data}) => {
          this.messageShow = true
          this.article = data
          this.read([row])
        },
      })
    },
  },
}
</script>

<style lang="scss">
//消息中心
.news{
  .content{
    height: 400px;
  }
  .title{
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff4747;
      top: 8px;
      left: -10px;
    }
  }
  .news-time{
    font-size: 14px;
    white-space: nowrap;
    margin: -30px 0 20px;
    color: #999;
    font-weight: normal;
    text-align: center;
  }
  .hold{cursor: pointer;}
}
@media (max-height: 620px) {
  .news .content{
    height: 300px;
  }
}
@media (max-height: 480px) {
  .message .el-dialog{
    margin-top: 5vh !important;
  }
}
</style>