<template>
  <!-- 导航 -->
  <el-menu 
    :default-active="this.$route.path" 
    active-text-color="#868686" 
    class="el-menu-vertical"
    @select="tolink">
    <!-- 公司信息 -->
    <div class="message-header">
      <div class="message">
        <el-image :src="logo_path" class="head flex-cen-cen">
          <div slot="error" class="image-slot">
            <i class="el-icon-picture"></i>
          </div>
        </el-image>
        <div>
          <h2>{{companyname}}</h2>
          <!-- <h2>积分：{{rate}}</h2> -->
        </div>
      </div>
      <div class="percent">
        <h3>完善信息</h3>
        <div class="bar"><b :style="{width:rate+'%'}"></b></div>
        <h4>{{rate}}%</h4>
        <i @click="tolink('/company/edit')" title="编辑" ></i>
      </div>
    </div>
    <!-- 导航 -->
    <el-menu-item index="/company">
      <i class="icon nav_index"></i>
      <span slot="title">控制台首页</span>
    </el-menu-item>
    <el-menu-item index="/company/consult">
      <i class="icon nav_consult"></i>
      <span slot="title">咨询</span>
    </el-menu-item>
    <div class="el-menu-item" @click="navOpen=!navOpen">
      <i class="icon nav_product"></i>
      <span slot="title">产品与服务</span>
      <i class="el-icon-arrow-down" :style="{transform: navOpen?'rotate(-180deg)':'rotate(0deg)'}"></i>
    </div>
    <div class="submenu" :style="{'height':navOpen?'300px':'0'}">
      <el-menu-item index="/tvs" :disabled="disableTVR">
        <i class="icon nav_tvr"></i>
        <span slot="title">组织活力扫描</span>
      </el-menu-item>
      <el-menu-item index="/tvr" :disabled="disableTVR">
        <i class="icon nav_tvr"></i>
        <span slot="title">TVR团队活力识别器</span>
      </el-menu-item>
      <el-menu-item index="/lsn" :disabled="disableLSN">
        <i class="icon nav_lsn"></i>
        <span slot="title">LSN精益创业导航</span>
      </el-menu-item>
      <el-menu-item index="/amt" :disabled="disableAMT">
        <i class="icon nav_amt"></i>
        <span slot="title">AMT领导者画像</span>
      </el-menu-item>
      <el-menu-item index="/cbc" :disabled="disableCBC">
        <i class="icon nav_cbc"></i>
        <span slot="title">职涯优选CBC</span>
      </el-menu-item>
      <el-menu-item index="/cognition" :disabled="disableCognition">
        <i class="icon nav_cd"></i>
        <span slot="title">认知差异分析</span>
      </el-menu-item>
    </div>
    <el-menu-item index="/company/order">
      <i class="icon nav_order"></i>
      <span slot="title">订单</span>
    </el-menu-item>
    <el-menu-item index="/company/info">
      <i class="icon nav_edit"></i>
      <span slot="title">个人信息</span>
    </el-menu-item>
    <el-menu-item index="/company/admin" v-if="manage_type==1">
      <i class="icon nav_admin"></i>
      <span slot="title">管理员管理</span>
    </el-menu-item>
  </el-menu>
</template>
<script>
export default {
  data(){
    return{
      navOpen: true,
      companyInfo: null,
      companyname: '',
      logo_path: '',
      rate: '',
      manage_type: 0,   //1超级管理员，2普通管理员，3账号管理员
      disableTVR: false,
      disableLSN: false,
      disableCBC: false,
      disableAMT: false,
      disableCognition: false,
    }
  },
  created(){
    this.getTeamInfo()
    //判断点击权限
    this.$request({
      url: '/index/index',
      success: e => {
        if (e.code == 0) {
          let {tvr,amt,cbc,lsn,cd} =  e.data.data_statistics
          if (!tvr.is_auth || !tvr.total_number) this.disableTVR = true
          if (!amt.is_auth || !amt.total_number) this.disableAMT = true
          if (!cbc.is_auth || !cbc.total_number) this.disableCBC = true
          if (!lsn.is_auth || !lsn.total_number) this.disableLSN = true
          if (!cd.is_auth || !cd.total_number) this.disableCognition = true
        }
      },
    })
  },
  methods: {
    tolink(path){
      if (this.$route.path == path) return
      this.$router.push({path: path})
    },
    getTeamInfo(){
      this.$request({
        method: 'post',
        url: '/Company/getBaseInfo?nav',
        success: (data) => {
          if (data.code == 0) {
            let company =  data.data
            this.companyname = company.company_info.companyname
            this.logo_path = company.company_info.logo_path
            this.rate = company.company_info.rate
            this.manage_type = company.manage_type
          } else if (data.code == 102) {
            this.$router.replace({path: '/userCenter'})
          }
        },
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.el-menu-vertical{
  width: 220px;
  height: 100%;
  background: #eceff4;
  border: none;
  overflow-y: auto;
  &::-webkit-scrollbar{display: none}
  .message-header{
    padding: 30px 0 20px 32px;
    user-select: none;
    .message{
      display: flex;
      align-items: center;
      .head{
        width: 40px;height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        flex-shrink: 0;
        box-shadow: 0 0 4px rgba(200,200,200,1);
        font-size: 20px;
        .image-slot{display: inline-block}
      }
      h2{
        font-size: 14px;
        color: #2b2b2b;
        line-height: 1.4;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
        width: 120px;
      }
    }
    .percent{
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-right: 28px;
      height: 40px;
      h3{
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
      .bar{
        flex: 1;
        margin: 0 8px;
        height: 6px;
        border-radius: 3px;
        background: #d4d9e2;
        position: relative;
        b{
          position: absolute;
          height: 100%;
          width: 0;
          border-radius: 3px;
          left: 0;
          top: 0;
          background-image: linear-gradient(to right,#8f94fb,#4e54c8)
        }
      }
      h4{
        color: #6b6be5;
        font-size: 12px;
      }
      i{
        width: 12px;
        height: 12px;
        background: url('../../assets/images/company/edit.png') no-repeat center;
        background-size: 100%;
        cursor: pointer;
        margin-left: 6px;
      }
    }
  }
  .el-menu-item, .menu-item{
    padding-right: 20px;
    padding-left: 20px;
    height: 50px;
    color: #868686;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    font-size: 14px;
    cursor: pointer;
    &:hover{
      background: #dee3ea;
    }
    &::before{
      content: '';
      position: absolute;
      height: 100%;
      width: 4px;
      background: #6b6be5;
      left: 0;
      top: 0;
      display: none;
      border-radius: 2px;
    }
    span{
      height: 18px;
      line-height: 18px;
      width: 100px;
    }
    .el-icon-arrow-down{
      position: absolute;
      right: 10px;
      top: 20px;
      font-size: 12px;
      transition: transform .2s;
    }
    .icon{
      width: 18px;
      height: 18px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }
    .nav_index{background-image: url('../../assets/images/company/nav_index.png');}
    .nav_product{background-image: url('../../assets/images/company/nav_product.png');}
    .nav_staff{background-image: url('../../assets/images/company/nav_staff.png');}
    .nav_structure{background-image: url('../../assets/images/company/nav_structure.png');}
    .nav_team{background-image: url('../../assets/images/company/nav_team.png');}
    .nav_report{background-image: url('../../assets/images/company/nav_report.png');}
    .nav_order{background-image: url('../../assets/images/company/nav_order.png');}
    .nav_admin{background-image: url('../../assets/images/company/nav_admin.png');}
    .nav_invite{background-image: url('../../assets/images/company/nav_invite.png');}
    .nav_tvr{background-image: url('../../assets/images/company/nav_tvr.png')}
    .nav_lsn{background-image: url('../../assets/images/company/nav_lsn.png')}
    .nav_amt{background-image: url('../../assets/images/company/nav_amt.png')}
    .nav_cbc{background-image: url('../../assets/images/company/nav_cbc.png')}
    .nav_cd{background-image: url('../../assets/images/company/nav_cd.png')}
    .nav_edit{background-image: url('../../assets/images/company/nav_edit.png')}
    .nav_consult{background-image: url('../../assets/images/company/nav_consult.png')}
  }
  .is-disabled{opacity: 1;cursor: not-allowed;}
  .is-active{
    background: #dee3ea;
    span{
      color: #2b2b2b;
      font-weight: bold;
    }
    .icon{opacity: 1 !important};
    &::before{display: block}
  }
  .submenu{
    transition: height .2s linear;
    overflow: hidden;
    background: #e5e9ef;
    .el-menu-item, .menu-item{
      color: #a6a6a6;
      .icon{opacity: .55;}
    }
  }
}
@media (max-width: 1100px) {
  .view{
    .el-menu-vertical{
      width: 180px;
      .el-menu-item .icon{
        margin-right: 10px;
      }
    }
    .message-header{
      .message h2{width: 86px;}
      .percent .bar{flex: 0;margin: 0 3px;}
    }
    .pagination{padding-left: 180px}
  } 
}
</style>