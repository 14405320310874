import { render, staticRenderFns } from "./cognition_basic.vue?vue&type=template&id=174d1467&scoped=true"
import script from "./cognition_basic.vue?vue&type=script&lang=js"
export * from "./cognition_basic.vue?vue&type=script&lang=js"
import style0 from "./cognition_basic.vue?vue&type=style&index=0&id=174d1467&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "174d1467",
  null
  
)

export default component.exports