
<!-- 登录模块 -->
<template>
  <div class="loginBox">
    <h2>登录</h2>
    <nav>
      <span :class="{current:loginType==1}" :title="loginType==2?'切换手机短信登录':''" @click="toggle(1)">短信登录</span>
      <span :class="{current:loginType==2}" :title="loginType==1?'切换使用账号密码登录':''" @click="toggle(2)">密码登录</span>
    </nav>

    <div v-show="loginType==1">
      <div class="input-box" :class="{error:errorType==1}">
        <img style="margin-right:0" src="../../assets/images/login/icon-phone.png">
        <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
          <el-option
            v-for="(item,code) in $country"
            :key="code"
            :label="item.label"
            :value="code">
          </el-option>
        </el-select>
        <input 
          class="input" 
          placeholder='请输入手机号' 
          ref="phone" 
          v-model="phone" 
          @input="inputChange($event,'phone')" 
          maxlength="11">
        <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
        <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
      </div>
      <div class="input-box">
        <img src="../../assets/images/login/icon-message.png">
        <input class="input"  placeholder='短信验证码' v-model="code" @input="inputChange($event,'code')" ref="code">
        <button 
          @click="getCode" 
          class="btnCode" 
          :class="{disabled: disabled}"
          :disabled='disabled'>
          <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
          <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
        </button>
        <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
      </div>
    </div>
    <div v-show="loginType==2">
      <div class="input-box" :class="{error:errorType==3}">
        <img src="../../assets/images/login/icon-phone.png">
        <input 
          class="input" 
          placeholder='手机号/企业账户' 
          v-model="account" 
          @input="inputChange($event,'account')" 
          ref="account"
          maxlength="24"
        >
        <div class="input-icon icon-clear" title="清空" v-show="account" @click="account=''"></div>
        <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
      </div>
      <div class="input-box" :class="{error:errorType==4}">
        <img src="../../assets/images/login/icon-password.png">
        <input 
          class="input"
          :type="passwordShow?'text':'password'"
          placeholder='请输入密码'
          v-model="password"
          @input="inputChange($event,'password')"
          ref="password"
          maxlength="30"
        >
        <div class="input-icon" :class="passwordShow?'icon-hide':'icon-show'" :title="passwordShow?'隐藏密码':'显示密码'" v-show="password" @click="passwordShow=!passwordShow"></div>
        <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
      </div>
    </div>
    <!-- <div class="box" :class="{boxRotate:loginType==2}">
      <div class="input-box box1" :class="{error:errorType==1}">
          <img style="margin-right:0" src="../../assets/images/login/icon-phone.png">
          <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
            <el-option
              v-for="(item,code) in $country"
              :key="code"
              :label="item.label"
              :value="code">
            </el-option>
          </el-select>
          <input 
            class="input" 
            placeholder='请输入手机号' 
            ref="phone" 
            v-model="phone" 
            @input="inputChange($event,'phone')" 
            maxlength="11">
          <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
          <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
      </div>
      <div class="input-box box2" :class="{error:errorType==3}">
          <img src="../../assets/images/login/icon-phone.png">
          <input
            class="input" 
            placeholder='手机号/企业账户' 
            v-model="account" 
            @input="inputChange($event,'account')" 
            ref="account"
            maxlength="24"
          >
          <div class="input-icon icon-clear" title="清空" v-show="account" @click="account=''"></div>
          <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
      </div>
    </div>
    <div class="box" :class="{boxRotate:loginType==2}">
      <div class="input-box box1">
          <img src="../../assets/images/login/icon-message.png">
          <input class="input"  placeholder='短信验证码' v-model="code" @input="inputChange($event,'code')" ref="code">
          <button 
            @click="getCode" 
            class="btnCode" 
            :class="{disabled: disabled}"
            :disabled='disabled'>
            <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
            <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
          </button>
          <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
      </div>
      <div class="input-box box2" :class="{error:errorType==4}">
          <img src="../../assets/images/login/icon-password.png">
          <input 
            class="input" 
            :type="passwordShow?'text':'password'"
            placeholder='请输入密码' 
            v-model="password" 
            @input="inputChange($event,'password')" 
            ref="password" 
            maxlength="30"
          >
          <div class="input-icon" :class="passwordShow?'icon-hide':'icon-show'" :title="passwordShow?'隐藏密码':'显示密码'" v-show="password" @click="passwordShow=!passwordShow"></div>
          <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
      </div>
    </div> -->

    <!-- 微信登录 -->
    <div class="other" :style="{bottom:wechatShow?'0%':'-100%'}">
      <div class="wechat">
        <div class="iframe" id="qrcode">
          <img :src="qrcode" v-show="qrcode">
          <div class="refresh" v-if="qrcodeError" @click="getQrcode">
            <p>二维码失效 点击重试</p>
          </div>
        </div>
      </div>
      <div class="text">请打开手机微信扫一扫，扫描上方二维码进行登录</div>
      <h5 @click="cancelWxLogin">返回</h5>
    </div>

    <div class="button" @click="Login">登录</div>
    <!-- <router-link to="/login/userRegister" replace class="p fr">一秒免费注册 ></router-link> -->
    <div class="p fr">
      一秒免费注册 >
      <p>
        <router-link to="/login/userRegister" replace>个人注册</router-link>
        <router-link to="/login/companyRegister" replace>企业入驻</router-link>
      </p>
    </div>

    <ul class="more">
      <li @click="getQrcode">
        <img src="../../assets/images/login/wechat.png">
        <span>微信快捷登录</span>
      </li>
    </ul>
  </div>
</template>
<script>
// import indexFooter from '../index/indexFooter'
export default {
  // components:{indexFooter,},
  data(){
    return{
      back: this.$route.query.back,
      loginType: 1,             // 1：短信登录，2：账号密码登录
      logined: false,           // 防止多次点击登录按钮

      country_code: '86',
      reg: this.$CNReg,         // 当前区号的手机号正则

      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 0：正常无错误，1：手机号错误，2：验证码错误，3：账号为空，4：密码为空
      phone: '',                // 手机号码

      code: '',                 // 验证码
      timer: '',                // 验证码定时器
      disabled: false,          // 发送验证码按钮是否可点击
      totalTime: 60,            // 设置多少秒内可以重发验证码
      time: 0,                  // 验证码重发的剩余时间

      account: '',              // 账号
      password: '',             // 密码
      passwordShow: false,      // 显示密码

      wechatShow: false,        //微信登录框显隐
      qrcode: null,             //登录二维码
      qrcodeTimer: null,
      qrcodeError: false,
    }
  },
  created(){
    document.onkeyup = e => {
      if (e.keyCode == 13 && !this.wechatShow) this.Login()
    }
    //微信登录前请求相关参数
    this.$request({url: '/sns/getSnsConfig',
      success: ({data}) => {
        this.app_id = data.app_id || 'wx9d34727115452b1f'
        this.state = data.state || 'huayan'
        this.scope = data.scope || 'snsapi_login'
        this.redirect_uri = data.redirect_uri || encodeURIComponent(`${this.$baseRoot}/sns/callback/name/weixin`)
      },
    })
  },
  beforeDestroy(){
    this.timer && clearInterval(this.timer);
    document.onkeyup = null
  },
  methods: {
    //切换 短信登录/账号密码登录
    toggle(type){
      if (this.loginType == type)  return;
      this.loginType = type
      this.errorType = 0
      this.phone = ''
      this.code = ''
      this.account = ''
      this.password = ''
    },
    //获取登录二维码
    getQrcode(){
      this.qrcode = null
      this.qrcodeError = false
      this.wechatShow = true
      this.qrcodeTimer && clearInterval(this.qrcodeTimer)
      this.$request({
        url: '/Wechat/createQrcode',
        success: d => {
          if (d.code == 0) {
            this.qrcode = d.data.qrcode_url
            this.qrcodeTimer = setInterval(() => {
              this.$request({
                url: '/Wechat/getTicketinfo',
                data: {ticket: d.data.ticket},
                success: e => {
                  if (e.code == 0) {//扫描成功需要手机验证码确认
                    clearInterval(this.qrcodeTimer)
                  } else if (e.code == 2) {
                    clearInterval(this.qrcodeTimer)
                    localStorage.user_id = e.data.user_id
                    localStorage.mobile = e.data.mobile
                    localStorage.realname = e.data.realname || ''
                    localStorage.avatar = e.data.avatar || ''
                    this.toCenter()
                  } else if (e.code == 4) {
                    clearInterval(this.qrcodeTimer)
                    this.qrcodeError = true
                  }
                }
              })
            }, 2000)
          }
        },
        error: () => {}
      })
      // new WxLogin({
        //   id: 'qrcode',
        //   appid: this.app_id,
        //   scope: this.scope,
        //   redirect_uri: this.redirect_uri,
        //   state: this.state,
        //   style: 'black',
        //   href: 'data:text/css;base64,LmltcG93ZXJCb3ggLnFyY29kZSB7d2lkdGg6IDIyMHB4O21hcmdpbi10b3A6IDA7fQ0KLmltcG93ZXJCb3ggLndycF9jb2Rle3dpZHRoOiAyMjBweDt9DQouaW1wb3dlckJveCAudGl0bGUge2Rpc3BsYXk6IG5vbmU7fQ0KLmltcG93ZXJCb3ggLmluZm8ge3dpZHRoOiAxMDAlO21hcmdpbi10b3A6IDEwcHg7fQ0KLnN0YXR1c19pY29uIHtkaXNwbGF5OiBub25lfQ0KLmltcG93ZXJCb3ggLnN0YXR1cyB7dGV4dC1hbGlnbjogY2VudGVyO30NCi5wYW5lbENvbnRlbnR7d2lkdGg6IDIyMHB4O30NCi5zdGF0dXN7YmFja2dyb3VuZDogI2ZmZjt9DQouanNfd3hfZGVmYXVsdF90aXB7ZGlzcGxheTogbm9uZTt9'//此目录下qrcode.css转成base64
      // });
    },
    cancelWxLogin() {
      this.wechatShow = false
      this.qrcodeTimer && clearInterval(this.qrcodeTimer)
    },
    //选择区号和手机号正则
    selectCountry(val){
      this.reg = this.$country[val].reg
      this.country_code = val
    },
    //短信登录监听输入内容
    inputChange(e, type = 'phone'){
      if (type == 'phone') {
        this.phone = e.target.value.replace(/\D/g,'')
      } else if (type == 'account') {
        this.account = e.target.value.replace(/[^\w\/]/g,'')
      } else if (type == 'password') {
        this.password = e.target.value.replace(/[^\w\/]/g,'')
      } else {
        this.code = e.target.value.replace(/\D/g,'')
      }
      this.errorType = 0;
    },
    //短信登录检验手机号
    phoneCheck(e){
      return new Promise((resolve)=>{
        if (!this.phone) {
          this.errorMsg = '请先输入手机号码';
          this.errorType = 1;
          this.$refs.phone.focus()
          resolve(0)
        } else if (!this.reg.test(this.phone)){
          this.errorMsg = '手机号码格式不对';
          this.errorType = 1;
          this.$refs.phone.focus()
          resolve(0)
        } else {
          this.$request({
            url: '/User/checkMobile',
            data: {
              country_code: this.country_code,
              mobile: this.phone,
            },
            success: (data) => {
              if (data.code == 0) {
                resolve(2)
              } else if (data.msg == '手机号未注册') {
                resolve(1)
              } else {
                resolve(0)
              }
            },
            error: () => resolve(-1)
          })
        }
      })
    },
    //获取验证码
    getCode(){
      this.phoneCheck().then((state)=>{
        if (state==2) { // 成功
          this.$request({
            method: 'post',
            url: '/Sms/getSmsCaptcha',
            data: {
              type: 'login',
              country_code: this.country_code,
              mobile: this.phone,
            },
            success: (data) => {
              if (!data.code) {
                this.$message({duration: 3000,message: '发送成功，请注意查收',type: 'success'})
                this.disabled = true
                this.time = this.totalTime;
                this.timer = setInterval( ()=> {
                  this.time--;
                  if (this.time < 0) {
                    clearInterval(this.timer)
                    this.disabled = false
                  }
                }, 1000)
              } else {
                this.$message({duration:1000,message:data.msg,type: 'error'})
              }
            },
          })
        } else if (state==1) { //手机号未注册
          this.errorMsg = '此手机号请先注册';
          this.errorType = 1;
        }
      })
    },
    //登录
    Login(){
      if (this.logined) {
      } else if (this.loginType == 1) {  //短信登录
        this.phoneCheck().then((state) => {
          if (state == 2) {
            if (!this.code) {
              this.errorMsg = '请先输入验证码';
              this.errorType = 2
              this.$refs.code.focus()
            } else if (this.code.length < 4){
              this.errorMsg = '验证码有误';
              this.errorType = 2
              this.$refs.code.focus()
            } else {
              this.logined = true
              //需要先退出之前账号
              this.$request({url:'/User/loginOut', 
                complete: ()=>{
                  // localStorage.clear()
                  this.$request({
                    method: 'post',
                    url: '/User/mobileLogin',
                    data: {
                      captcha: this.code,
                      country_code: this.country_code,
                      mobile: this.phone,
                    },
                    success: (data) => {
                      if (data.code == 0) {
                        this.$message({duration:2000,message:'登录成功',type:'success'})
                        localStorage.user_id = data.data.user_id
                        localStorage.mobile = data.data.mobile
                        localStorage.realname = data.data.realname || ''
                        localStorage.avatar = data.data.avatar || ''
                        this.errorType = 0
                        this.toCenter()
                        //获取session
                        // if (location.host == 'localhost')
                        //   this.$http.get('/User/getSession').then(({data})=>{
                        //     localStorage.localSession = data.data.session_id
                        //   });
                      } else {
                        this.$message({duration:1000,message:data.msg,type: 'error'})
                      }
                    },
                    complete: () => this.logined = false
                  })
                }
              })
            }
          } else if (state == 1) { //手机号未注册
            this.errorMsg = '此手机号请先注册';
            this.errorType = 1;
          }
        })
      } else {  //账号密码登录
        if (!this.account) {
          this.errorMsg = '请先输入账号';
          this.errorType = 3
          this.$refs.account.focus()
        } else if (!this.password){
          this.errorMsg = '请先输入密码';
          this.errorType = 4
          this.$refs.password.focus()
        } else {
          this.logined = true
          this.$request({url:'/User/loginOut',
            complete: () => {
              this.$request({
                method: 'post',
                url: '/User/accountLogin',
                data: {
                  account: this.account,
                  password: this.password,
                },
                success: (data) => {
                  if (!data.code) {
                    this.$message({duration: 2000, message: '登录成功', type: 'success'})
                    this.errorType = 0
                    localStorage.user_id = data.data.user_id
                    localStorage.mobile = data.data.mobile
                    localStorage.realname = data.data.realname || ''
                    localStorage.avatar = data.data.avatar || ''
                    this.toCenter()
                  } else if (data.code == 2) {
                    this.errorType = 3
                    this.errorMsg = '该账号未注册'
                  } else if (data.code == 1) {
                    this.errorType = 4
                    this.errorMsg = '密码错误，请检查账号与密码是否正确'
                  } else {
                    this.$message({duration: 3000,message: data.msg})
                  }
                },
                complete: ()=> this.logined = false
              })
            }
          })
        }
      }
    },
    //根据权限跳转相应页面
    toCenter(){
      let path = ''
      this.$request({url: '/User/getAuthority',
        success: d => {
          localStorage.authority = d.code
          if (d.code == 1001) {  //只有个人权限
            path = '/userCenter'
          } else if (d.code==1002 || d.code==1003) { //有企业权限
            path = '/company'
          } else if (d.code==1004) {  //个人/企业/教练 权限都有
            path = '/login/choose'
          } else if (d.code==1005) {  //个人/教练 权限都有
            path = '/expert/manage'
          }
        },
        error: () => {path = '/userCenter'},
        complete: () => {
          if (this.back && this.back != '/login') {
            this.$router.replace({path: this.back})
          } else {
            this.$router.replace({path: path})
          }
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .iframe{
    width: 220px;
    height: 220px;
    img{
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 2;
    }
    &::before{
      content: '';
      position: absolute;
      left: 85px;
      top: 85px;
      width: 50px;
      height: 50px;
      background: url('../../assets/images/login/icon-refresh.png') no-repeat;
      animation: load 2s infinite linear;
    }
    @keyframes load {
      100%{transform: rotate(360deg)}
    }
    .refresh{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(0,0,0,.8);
      background-image: url('../../assets/images/login/icon-refresh.png');
      background-repeat: no-repeat;
      background-position: center 40%;
      background-size: 50px;
      p{
        color: #fff;
        text-align: center;
        font-size: 14px;
        margin-top: 140px;
      }
    }
    iframe{
      width: 100%;
      height: 100%;
      .impowerBox .qrcode{
        width: 220px;
        height: 220px;
      }
    }
  }
  .p{
    position: relative;
    &:hover p{display: block}
    p{
      display: none;
      background: #fff;
      position: absolute;
      top: 100%;
      left: 0;
      border-radius: 4px;
      box-shadow: 0 0 10px rgba(50,50,50,.2);
      a{
        display: block;
        padding: 10px 30px;
        white-space: nowrap;
        &:hover{background: #f6f6f6;}
      }
    }
  }
</style>


