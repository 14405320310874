
<!-- 企业入驻 -->
<template>
  <div class="loginBox">
    <h2>企业入驻</h2>
    <div>
      <div class="input-box" :class="{error:errorType==5}">
        <img src="../../assets/images/login/icon-companyname.png">
        <input class="input" placeholder='企业名称' ref="name" v-model="companyname" @input="inputChange">
        <div class="input-icon icon-clear" title="清空" v-show="companyname" @click="companyname=''"></div>
        <div class="errText" v-show="errorType==5">{{errorMsg}}</div>
      </div>
      <div class="input-box" :class="{error:errorType==4}">
        <img src="../../assets/images/login/icon-account.png">
        <input class="input" placeholder='企业账号（4~16位的字母，数字，下划线组合）' ref="account" v-model="account" maxlength="20" @input="inputChange('account')">
        <div class="input-icon icon-clear" title="清空" v-show="account" @click="account=''"></div>
        <div class="errText" v-show="errorType==4">{{errorMsg}}</div>
      </div>
      <div class="input-box" :class="{error:errorType==3}">
        <img src="../../assets/images/login/icon-password.png">
        <input class="input" placeholder='管理密码（6~20位的字母，数字，下划线，小数点组合）' ref="password" v-model="password" maxlength="20" @input="inputChange('password')">
        <div class="input-icon icon-clear" title="清空" v-show="password" @click="password=''"></div>
        <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
      </div>
      
      <div class="button" @click="improve" v-if="type=='improve'">提交</div>
      <template v-else>
        <div class="input-box" :class="{error:errorType==1}">
          <img src="../../assets/images/login/icon-phone.png">
          <el-select v-model="country_code" @change="select" class="el-select-code">
            <el-option
              v-for="(item,key) in $countryList"
              :key="key"
              :label="item.label"
              :value="item.country_code">
            </el-option>
          </el-select>
          <input 
            class="input" 
            placeholder='手机号码' 
            ref="phone" 
            v-model="phone" 
            @input="inputChange('phone')" 
            maxlength="11"
          >
          <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
          <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
        </div>
        <div class="input-box">
            <img src="../../assets/images/login/icon-message.png">
            <input class="input"  placeholder='短信验证码' v-model="code" @input="inputChange('code')" ref="code">
            <button 
              @click="getCode" 
              class="btnCode" 
              :data-time='totalTime'
              :class="{disabled: disabled}"
              :disabled='disabled'
            >
              <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
              <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
            </button>
            <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
        </div>
        <div class="button" @click="register">注册</div>
        <router-link to="/login/userRegister" replace class="p fl">切换至个人注册</router-link>
        <router-link to="/login/userLogin" replace class="p fr">已有账号，去登录 ></router-link>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      back: this.$route.query.back||'',
      type: this.$route.query.ty||'',
      logined: false,           // 防止多次点击注册按钮
      country_code: '+86',
      reg: this.$CNReg,         // 当前区号的手机号正则
      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 0：正常无错误，1：手机号错误，2：验证码错误，3：账号为空，4：密码为空
      companyname: '',
      account: '',
      password: '',
      phone: '',// 手机号码

      code: '',                 // 验证码
      timer: '',                // 验证码定时器
      disabled: false,          // 发送验证码按钮是否可点击
      totalTime: 60,            // 设置多少秒内可以重发验证码
      time: 0,                  // 验证码重发的剩余时间
    }
  },
  created(){
    this.keyup()
  },
  destroyed() {
    this.timer && clearInterval(this.timer);
    document.removeEventListener("keyup",this.keyup,false)
  },
  methods: {
    keyup(){
      document.onkeyup = e => {
        if (e.keyCode == 13) {
          this.register()
        }
      }
    },
    //选择区号筛选手机号正则
    select(key){
      this.$countryList.forEach((item) => {
        if(item.country_code==key){
          this.reg = this[item.key]
          return
        }
      })
    },
    //监听输入内容
    inputChange(type){
      if (type == 'account') {
        this.account = event.target.value.replace(/[^\w]/ig, '')
      } else if (type == 'password') {
        this.password = event.target.value.replace(/[^\w\.]/ig, '')
      } else if (type == 'phone') {
        this.phone = event.target.value.replace(/\D/g, '')
      } else if (type == 'code') {
        this.code = event.target.value.replace(/\D/g, '')
      }
      this.errorType = 0
    },
    //短信登录检验手机号
    phoneCheck(e){
      // return true //测试发送验证码
      if (!this.phone) {
        this.errorMsg = '请先输入手机号码'
        this.errorType = 1
        this.$refs.phone.focus()
        return false
      } else if (!this.reg.test(this.phone)){
        this.errorMsg = '手机号码格式不对'
        this.errorType = 1
        this.$refs.phone.focus()
        return false
      } else {
        return true
      }
    },
    //获取验证码
    getCode(){
      if (this.phoneCheck()) {// 成功
        this.$request({
          method: 'post',
          url: '/Sms/getSmsCaptcha',
          data: {
            type: 'register',
            country_code: this.country_code,
            mobile: this.phone,
          },
          success: (data) => {
            if (!data.code) {
              this.$message({duration: 2000, message: '发送成功，请注意查收', type: 'success'})
              this.disabled = true
              this.time = this.totalTime
              this.timer = setInterval(() => {
                this.time--;
                if (this.time < 0) {
                  clearInterval(this.timer)
                  this.disabled = false
                }
              }, 1000)
            } else {
              this.$message.error({message: date.msg})
            }
          },
        })
      }
    },
    //注册
    register() {
      if (this.logined) return
      if (!this.companyname) {
        this.errorMsg = '请输入企业名称'
        this.errorType = 5
        this.$refs.name.focus()
      } else if (!/^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)])+$).{4,16}$/.test(this.account)) {
        this.errorMsg = '请输入4到16位（字母，数字，下划线至少两种）'
        this.errorType = 4
        this.$refs.account.focus()
      } else if (!/^[a-zA-Z0-9_.]{6,20}$/.test(this.password)) {
        this.errorMsg = '请输入6到20位的密码（仅限字母，数字，下划线，小数点）'
        this.errorType = 3
        this.$refs.password.focus()
      } else if (this.phoneCheck()) {
        if (!this.code) {
          this.errorMsg = '请先输入验证码';
          this.errorType = 2
          this.$refs.code.focus()
        } else if (this.code.length < 4){
          this.errorMsg = '验证码有误';
          this.errorType = 2
          this.$refs.code.focus()
        } else {
          this.logined = true
          this.$request({
            method: 'post',
            url: '/company/register',
            data: {
              companyname: this.companyname,
              account: this.account,
              password: this.password,
              country_code: this.country_code,
              mobile: this.phone,
              captcha: this.code,
            },
            success: d => {
              if (d.code == 0) {
                this.$message({message: '注册成功', type: 'success'})
                localStorage.user_id = d.data.user_id
                localStorage.mobile = d.data.mobile
                localStorage.realname = d.data.realname || ''
                localStorage.avatar = d.data.avatar || ''

                //1001有个人权限，1002有企业权限，1003有个人和企业权限，1004有个人和企业及教练权限，1005有个人和教练权限
                let auth = d.data.authority
                if (auth.company.is_auth && auth.coache.is_auth) 
                  localStorage.authority = 1004
                else if (auth.coache.is_auth)
                  localStorage.authority = 1005
                else if (auth.company.is_auth)
                  localStorage.authority = 1003
                  
                this.$router.replace({path: this.back || '/company'})
              } else if (d.msg.indexOf('已经注册')>-1) {
                this.errorMsg = '此手机号已注册';
                this.errorType = 1;
              } else {
                this.$message.error({message: d.msg})
              }
            },
            complete: () => this.logined = false
          })
        }
      }
    },
    improve() {
      if (this.logined) return
      if (!this.companyname) {
        this.errorMsg = '请输入企业名称'
        this.errorType = 5
        this.$refs.name.focus()
      } else if (!/^[a-zA-Z0-9_]{6,20}$/.test(this.account)) {
        this.errorMsg = '请输入6到20位的账号（仅限字母，数字，下划线）'
        this.errorType = 4
        this.$refs.account.focus()
      } else if (!/^[a-zA-Z0-9_.]{6,20}$/.test(this.password)) {
        this.errorMsg = '请输入6到20位的密码（仅限字母，数字，下划线）'
        this.errorType = 3
        this.$refs.password.focus()
      } else {
        this.logined = true
        this.$request({
          method: 'post',
          url: '/User/addCompany',
          data: {
            companyname: this.companyname,
            account: this.account,
            password: this.password,
          },
          success: d => {
            if (d.code == 0) { 
              //1001有个人权限，1002有企业权限，1003有个人和企业权限，1004有个人和企业及教练权限，1005有个人和教练权限
              let auth = d.data.authority
              if (auth.company.is_auth && auth.coache.is_auth) 
                localStorage.authority = 1004
              else if (auth.coache.is_auth)
                localStorage.authority = 1005
              else if (auth.company.is_auth)
                localStorage.authority = 1003
              this.$goback()
            } else if (d.code == 104) {
              this.errorMsg = '账号已存在，请重新输入'
              this.errorType = 4
              this.$refs.account.focus()
            } else {
              this.$message.error({message: d.msg})
            }
          },
          complete: () => this.logined = false
        })
      }
    },
  },
}
</script>




