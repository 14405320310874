<template>
  <div class="company">
    <!-- 顶部条 -->
    <company_header ref="header"></company_header>
    <div class="view">
      <!-- 导航 -->
      <company_nav ref="nav"></company_nav>
      <!-- 内容 -->
      <router-view class="main"></router-view>
    </div>
  </div>
</template>

<script>
import company_header from './header'
import company_nav from './nav'
export default {
  components: {company_header,company_nav},
  created() {//判断有没有企业权限
    let auth = localStorage.authority
    if (auth==1001) 
      this.$router.replace({path: '/'})
    else if (auth==1005)
      this.$router.replace({path: '/expert/manage'})
  },
}
</script>
<style lang="scss">
.company{
  height: 100%;
  // overflow-y: auto;
}
.view{
  height: 100%;
  overflow: hidden;
  display: flex;
  padding-top: 48px;
  *{transition: width .1s linear;}
  // 导航上的头部信息
  .main{
    flex: 1;
    min-width: 400px;
    padding: 0 40px 30px;
    position: relative;
    overflow-y: auto;
  }
  nav{
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #eceef3;
    user-select: none;
    h2{
      font-weight: normal;
      font-size: 14px;
      margin: 0 10px 0 26px;
      line-height: 50px;
      padding: 0 2px;
      position: relative;
      cursor: pointer;
    }
    .curr{
      color: #6b6be5;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: #6b6be5;
        left: 0;
        bottom: 0;
      }
    }
    .unread::after{
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff4747;
      right: -6px;
      top: 14px;
    }
  }
}
@media (max-width: 1200px) {
  .view .main{padding-left: 30px;padding-right: 30px;}
}
</style>
