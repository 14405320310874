<template>
  <div class="p_s">
    <indexHeader/>
    <section v-if="id==1">
      <img src="../../assets/images/index/bg_11.jpg">
      <div class="underBox" style="background:#0f0d1b">
        <div class="btn-c">购买</div>
      </div>
      <indexFooter :bgcolor="'#1c1a2a'"/>
    </section>
    <section v-else-if="id==3">
      <img src="../../assets/images/index/bg_12.jpg">
      <div class="underBox">
        <div class="btn-c" @click="buy({type: 'pro', product_id: 28, number: 1, total_money: '29800.00'})">购买</div>
      </div>
      <indexFooter :bgcolor="'#110e1f'"/>
    </section>
    <section v-else-if="id==4">
      <img src="../../assets/images/index/bg_13.jpg">
      <div class="underBox">
        <div class="btn-c" @click="buy({type: 'pro', product_id: 28, number: 1, total_money: '29800.00'})">购买</div>
      </div>
      <indexFooter :bgcolor="'#110e1f'"/>
    </section>
    <div class="tool" v-else-if="id==2">
      <h1>数字化诊断评估系列</h1>
      <h2>“个人评估”测评工具</h2>
      <h3>对个体的潜力和特征等进行客观的评估和量化。测评工具运用先进的数字技术和评估方法，通过收集、分析和解释个体在特定领域的数据，为个人提供了一个全面而深入的个人评估体验。</h3>
      <ul class="desc">
        <li class="flex">
          <span>测评内容场景化&多样化</span>
          <p class="flex--cen">聚焦工作场景，涵盖个人认知、情绪智力、人格特征、职业兴趣等多个方面。通过对这些不同维度的评估，个人可以获得一个全面而准确的自我认知。</p>
        </li>
        <li class="flex">
          <span>数字化技术支持</span>
          <p class="flex--cen">运用先进的数字化技术，使评估过程更加灵活和便捷。</p>
        </li>
        <li class="flex">
          <span>客观性和科学性</span>
          <p class="flex--cen">工具的设计基于大量的数据研究，以确保其科学性和可靠性。</p>
        </li>
        <li class="flex">
          <span>个性化报告和建议</span>
          <p class="flex--cen">通过分析个人的回答和表现数据，生成个性化的报告和建议。这些报告有助于个人更好地了解自己，从 而做出更明智的决策。</p>
        </li>
        <li class="flex">
          <span>帮助个人发展和职业规划</span>
          <p class="flex--cen">工具旨在帮助个人发展和职业规划。通过了解自己的潜力和特征等，个人可以更加明确自己的目标和方向，选择适合自己的职业道路，并制定相应的学习和发展计划。</p>
        </li>
        <li class="flex">
          <span>私密性和安全性</span>
          <p class="flex--cen">注重保护个人的隐私和数据安全。评估结果通常只提供给个人本人或指定的授权人员，以确保数据的机密性和安全性。</p>
        </li>
      </ul>
      <ul class="personal">
        <li class="flex">
          <div class="word">
            <h4>个人工作能量</h4>
            <p>“个人工作能量”是一份综合评估报告，它包含了“原动力”和“职业素质”两个方面的测评结果。这份报告旨在帮助个人了解和发展其在工作环境中的能量和资源，以支持个人的职业发展和提升工作效能。</p>
            <div class="btn">购买</div>
          </div>
          <div class="pic flex-cen-cen">
            <img src="../../assets/images/index/bg_15.png">
          </div>
        </li>
        <li class="flex">
          <div class="pic flex-cen-cen">
            <img src="../../assets/images/index/bg_16.png">
          </div>
          <div class="word">
            <h4>职涯优选CBC</h4>
            <p>基于理论结合实践研究、聚焦成就实现、自我成长、寻求变化、自主空间、经济收入、权力地位、兴趣特长、安稳舒适8个价值方向，帮助个体明确自己内在真正的价值需求。</p>
            <div class="btn">购买</div>
          </div>
        </li>
        <li class="flex">
          <div class="word">
            <h4>人才发展评估</h4>
            <p>“人才发展评估”着重于评估和发展个人的潜力、能力和职业发展。该工具通过结合先进的数字化技术、组织行为和人才发展的原理，提供了一个全面而深入的人才发展评估体验。</p>
            <div class="btn">购买</div>
          </div>
          <div class="pic flex-cen-cen">
            <img src="../../assets/images/index/bg_17.png">
          </div>
        </li>
      </ul>
      <div class="org">
        <h2>“组织评估”测评工具</h2>
        <h3>“组织评估”旨在帮助组织了解自身的健康情况。结合先进的数字化技术和组织行为学原理，提供了一个全面而深入的组织评估体验。它可以让组织可以获得对其内部各个方面的客观评估，如：团队&组织活力、风险预警、领导能量作等报告。它的优势在于其客观性、科学性和实用性。通过数字化技术的应用，评估过程更加标准化和一致，减少了主观性和人为偏见的影响。同时，它基于组织行为学原理和最佳实践，可以提供准确的评估结果和建议，帮助组织了解其当前状况并制定改进计划。</h3>
        <img src="../../assets/images/index/bg_18.png">
        <div class="btn-c" @click="buy({type: 'pro', product_id: 28, number: 1, total_money: '29800.00'})">购买</div>
      </div>
      <h2>“创业评估”测评工具</h2>
      <h3>“创业测评”是帮助创业者快速诊断项目、链接创业资源的工具，同时也是天使投资人发掘优秀项目的重要路径。融合了国内外天使投资人多年的投资经验及分析研究，通过对项目的创始团队、股权、项目信息、收益、融资等方面进行多层次的衡量，全面分析、快速诊断，打通创业者与天使投资人，助力资源对接与四配。</h3>
      <div class="business">
        <div class="flex">
          <span @click="tab=0" :class="{curr:tab==0}">对于创业者而言</span>
          <span @click="tab=1" :class="{curr:tab==1}">对于投资人或投资机构而言</span>
        </div>
        <div class="flex-bet-cen" style="margin-top:40px;height:310px">
          <div v-if="tab==0">
            <div>通过“创业测评”，创业者可以获得以下好处：</div>
            <p>了解创业能力和潜力</p>
            <p>确定改进和发展方向</p>
            <p>识别市场潜力和商业可行性</p>
            <p>个性化的建议和指导</p>
            <p>融资筹备和投资者关系</p>
          </div>
          <div v-else>
            <div>使用“创业测评”， 投资人或投资机构可以获得以下好处：</div>
            <p>评估创业者的认如水平</p>
            <p>评估创业者的准备程度</p>
            <p>识别创业者的潜力和能力</p>
            <p>评估项目风险和机会</p>
            <p>提高决策的科学性和准确性</p>
            <p>优化投资组合</p>
            <p>促进投资者与创业者之间的合作</p>
          </div>
          <img src="../../assets/images/index/bg_19.png">
        </div>
      </div>
      <div class="btn-c" style="margin-bottom:100px">购买</div>
      <indexFooter :bgcolor="'#1c1a2a'"/>
    </div>
    <!-- 购买 -->
    <el-dialog title="组织活力扫描" :visible.sync="payShow" :close-on-click-modal="false" @close="closeDialog" width="800px" center>
      <el-tabs type="card" @tab-click="changeTab">
        <el-tab-pane label="套餐购买" type="pro" total_money="29800.00">
          <div class="box" style="border-top-left-radius:0">
            <h5>订单信息</h5>
            <div class="txt flex-bet">
              <span>组织活力扫描</span>
              <span>{{orderInfo.date}}</span>
              <span>200个账号（工作能量评估）<br>团队报告</span>
            </div>
            <div class="price">总计 <span>{{orderInfo.total_money}}</span> 元</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="账号购买" type="add" number="1" price="100" total_money="100.00" v-if="addShow">
          <div class="box" style="border-top-left-radius:0">
            <h5>订单信息</h5>
            <div class="txt flex-bet">
              <span>组织活力扫描</span>
              <span>账号（工作能量评估）</span>
              <span>{{orderInfo.date}}</span>
              <span>¥ 100/个</span>
            </div>
            <div class="num flex--cen">
              <label>数量</label>
              <el-input-number size="small" v-model="orderInfo.number" :min="1" :max="999" step-strictly @change="changeNum"/>
            </div>
            <div class="price">总计 <span>{{orderInfo.total_money}}</span> 元</div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div class="box flex" style="height:230px">
        <div class="l">
          <h5>支付方式</h5>
          <ul class="flex">
            <li class="flex-cen-cen" :class="{curr:payType=='wxpayQrcode'}" @click="createOrder('wxpayQrcode')"><img src="../../assets/images/expert/pay_wx.png">微信支付</li>
            <li class="flex-cen-cen" :class="{curr:payType=='alipayQrcode'}" @click="createOrder('alipayQrcode')"><img src="../../assets/images/expert/pay_ali.png">支付宝支付</li>
          </ul>
        </div>
        <div class="r" v-show="payType">
          <p class="p1">请在<b>15分钟</b>内使用{{payType=='wxpayQrcode'?'微信':'支付宝'}}扫码完成支付</p>
          <div class="loadBg" :class="{overtime:timeNumber>300}" ref="iBox"></div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
export default {
  components:{indexHeader,indexFooter},
  data(){
    return {
      id: this.$route.params.id || '',
      tab: 0,
      payShow: false,
      orderInfo: {
        type: '',         //pro:套餐购买, add:加购账号
        product_id: '',   //11:AMT, 13:LSN, 14:CBC, 28:组织活力扫描
        number: 1,        //购买数量
        price: 0,         //单价
        total_money: 0,   //总金额
        date: '',         //过期时间
      },
      orderId: '',
      orderTitle: '',
      payType: 0,         //wxpayQrcode, alipayQrcode
      timeNumber: 1,
      timer: null,
      addShow: false,
    }
  },
  mounted(){
    
  },
  methods: {
    changeNum(e) {
      this.orderInfo.number = e
      this.orderInfo.total_money = (e * this.orderInfo.price).toFixed(2)
    },
    changeTab(tab) {
      this.buy(tab.$attrs)
    },
    buy(data) { //打开购买弹窗
      for (let i in data) this.orderInfo[i] = data[i]
      this.orderInfo.date = `1年有效期（${this.$formatTime(Date.now()+31536000000)}到期）`
      this.payType = 0
      this.addShow = false
      this.orderId = ''
      this.orderTitle = ''
      this.$request({  //检测可否加购
        url: '/Order/checkBuyNumber',
        data: {product_id: 28},
        success: d => {
          if (d.code == 101) {
            this.$router.push({path: '/login', query: {back: this.$route.path}})
            return
          } else if (d.code == 0) {
            this.addShow = true
          }
          this.payShow = true
        },
      })
    },
    createOrder(ty) {
      if (this.orderId) { //检查订单是否过期
        this.$request({url: '/Order/check',
          data: {order_num: this.orderId},
          success: d => {
            if (d.code == 9) {
              this.getQrcode(ty)
            } else if (d.code == 10) {
              this.orderId = ''
              this.createOrder()
            } else {
              this.$message({type: 'error', message: d.msg})
            }
          },
        })
      } else {  //创建订单号
        this.$request({
          url: '/Order/shopping',
          data: this.orderInfo,
          success: d => {
            if (d.code == 0) {
              this.orderId = d.data.order_num
              this.orderTitle = d.data.order_title
              this.getQrcode(ty)
            } else if (d.code == 101) {
              this.$router.push({path: '/login', query: {back: this.$route.path}})
            } else {
              this.$message({type: 'error', message: d.msg})
            }
          },
        })
      }
    },
    getQrcode(type) {
      if (this.timeNumber > 300) {
        this.$message({type: 'warning', message: '订单已超时，请重新下单'})
        return
      }
      this.payType = type
      if (this.$refs.iBox) this.$refs.iBox.innerHTML = ''
      this.timer && clearInterval(this.timer)
      this.$request({
        url: '/Order/'+type,
        data: {
          order_num: this.orderId || '',
          order_title: this.orderTitle || '组织活力扫描',
          total_money: '0.01' || this.orderInfo.total_money,
        },
        success: d => {
          if (typeof(d) == 'object') {
            this.$message({type: 'warning', message: d.msg})
          } else {
            let i = document.createElement('iframe')
            i.setAttribute("srcdoc", d)
            i.setAttribute("scrolling", 'no')
            i.setAttribute("frameborder", 0)
            i.setAttribute("marginwidth", 0)
            i.setAttribute("marginheight", 0)
            this.$refs.iBox.appendChild(i)
            this.setTimer()
          }
        },
      })
    },
    setTimer() {
      this.timeNumber = 1
      this.timer = setInterval(() => {
        if (this.timeNumber++ < 300) {  // 3秒(定时器) * 300次 / 60 = 15分钟
          this.$request({url: '/Order/check',
            data: {order_num: this.orderId},
            success: d => {
              if (d.code == 0) {
                clearInterval(this.timer)
                this.payShow = false
                this.$confirm('', '支付成功，可前往企业用户中心查看', { 
                  confirmButtonText: '前往用户中心',
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'success',
                  center: true,
                }).then(() => {
                  this.$router.push({path: '/tvs'})
                }).catch(() => {})
              } else if (d.code == 10) {
                clearInterval(this.timer)
                this.payShow = false
                this.$message({type: 'warning', message: '订单已超时，请重新下单'})
              }
            },
          })
        } else {
          clearInterval(this.timer)
        }
      }, 3000)
    },
    addPay() {
      if (!this.payType) {
        this.$message({type: 'warning', message: '请选择支付方式'})
        return
      }
      this.$request({
        url: '/Order/shopping',
        data: this.orderInfo,
        success: d => {
          if (d.code == 0) {
            this.payShow = false
            this.codeShow = true
            this.orderId = d.data.order_num
            this.$request({
              url: '/Order/' + this.payType,
              data: {
                order_num: d.data.order_num,
                order_title: d.data.order_title,
                total_money: '0.01' || this.orderInfo.total_money,
              },
              success: e => {
                if (typeof(e) == 'object') {
                  this.$message({type: 'warning', message: e.msg})
                  this.codeShow = false
                } else {
                  this.$refs.iframe.setAttribute("srcdoc", e)
                  this.setTimer()
                }
              },
            })
          } else {
            this.$message({type: 'error', message: d.msg})
          }
        },
      })
    },
    closeDialog() {
      this.timer && clearInterval(this.timer)
    },
  }
}
</script>
<style lang="scss">
.p_s{
  position: relative;
  height: 100vh;
  overflow-x: hidden;
  min-width: 700px;
  background: #0f0d1b;
  section{
    position: absolute;
    min-width: 1200px;
    width: 100%;
    height: calc(100vh - 50px);
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    img{
      display: block;
      width: 100%;
      min-height: calc(100% - 88px);
    }
  }
  .underBox{
    padding: 80px 0 100px;
    background: #1d1a2b;
  }
  .btn-c{
    width: 180px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    line-height: 40px;
    font-size: 14px;
    background: #6b6be5;
    border-radius: 2px;
    cursor: pointer;
  }
  .tool{
    color: #fff;
    background: url('../../assets/images/index/bg_14.jpg') #110e1f  no-repeat;
    background-size: 100% auto;
    font-weight: normal;
    padding-top: 50px;
    h1{
      font-size: 40px;
      text-align: center;
      letter-spacing: 20px;
      font-weight: normal;
      padding: 200px 0 40px 20px;
    }
    h2{
      font-size: 30px;
      text-align: center;
      font-weight: normal;
      padding-top: 120px;
    }
    h3{
      font-size: 16px;
      text-align: center;
      font-weight: normal;
      padding-top: 100px;
      max-width: 900px;
      margin: 0 auto 100px;
    }
    .desc{
      max-width: 900px;
      margin: 0 auto;
      font-size: 14px;
      li{
        margin: 12px 0;
      }
      span{
        line-height: 60px;
        width: 200px;
        text-align: center;
        background: linear-gradient(to right,#5760fd,#6296fd)
      }
      p{
        flex: 1;
        height: 60px;
        padding: 0 20px;
        background: rgba(37,29,93,.4);
        box-shadow: inset 0 0 10px rgba(107,126,250,.6);
        text-align: justify;
      }
    }
    .personal{
      max-width: 1000px;
      margin: 100px auto 140px;
      font-size: 14px;
      li{
        margin: 6px 0;
        div:nth-child(1){background: #171522;}
        div:nth-child(2){background: #1c1a2a;}
        .word{
          padding: 60px;
          width: 50%;
        }
        .pic{
          width: 50%;
          img{
            width: 60%;
            min-width: 280px;
          }
        }
        h4{
          font-size: 16px;
          margin-bottom: 40px;
        }
        .btn{
          width: 120px;
          text-align: center;
          color: #fff;
          line-height: 32px;
          background: #6b6be5;
          border-radius: 2px;
          cursor: pointer;
          margin-top: 40px;
        }
      }
    }
    .org{
      background: #1c1a2a;
      padding-bottom: 80px;
      h4{
        font-size: 34px;
        text-align: center;
        margin-bottom: 80px;
        font-weight: normal;
      }
      img{
        display: block;
        max-width: 900px;
        width: 100%;
        margin: 60px auto 100px;
      }
    }
    .business{
      background: linear-gradient(130deg, #11142d, #0f1924);
      max-width: 1000px;
      margin: 60px auto;
      padding: 40px 100px;
      white-space: nowrap;
      span{
        background: #0f1024;
        width: 220px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        margin-right: 20px;
        cursor: pointer;
        border: 1px solid #0f1024;
      }
      .curr{
        border-color: #766bfb;
        background: #10142c
      }
      img{
        max-width: 360px;
      }
      p{
        margin: 18px 0;
        padding-left: 30px;
        position: relative;
        font-size: 14px;
        &::before{
          content: '';
          position: absolute;
          left: 2px;
          top: 4px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          border: 2px solid #b9b3ed;
        }
      }
    }
  }

  .box{
    background: #fff;
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 10px;
    white-space: nowrap;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
    h5{
      font-size: 15px;
      color: #212121;
    }
    .txt{
      font-size: 14px;
      color: #212121;
      margin: 20px 0;
      line-height: 1.8;
    }
    .num{
      justify-content: flex-end;
      margin-bottom: 20px;
      label{margin-right: 10px}
    }
    .price{
      text-align: right;
      margin: 10px 0;
      span{
        color: #ff5a00;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .l{
      flex: 1;
      li{
        height: 40px;
        border: 1px solid #f4f4f4;
        color: #808080;
        border-radius: 4px;
        padding: 0 20px;
        white-space: nowrap;
        margin: 20px 20px 0 0;
        cursor: pointer;
        img{
          display: inline-block;
          margin-right: 6px;
          width: 24px;
          height: 24px;
        }
      }
      .curr{
        color: #212121;
        border-color: #6b6be5;
      }
    }
    .r{
      border-left: 1px solid #f4f4f4;
      flex: 1;
      position: relative;
      padding-bottom: 20px;
      .p1{
        font-size: 12px;
        text-align: center;
        margin: 10px 0;
      }
      .p2{
        font-size: 16px;
        text-align: center;
        margin: 10px 0;
        b{color: #ff5a00;}
      }
      .loadBg{
        width: 140px;
        height: 140px;
        margin: 0 auto;
        background: #e5e5e5;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: url('../../assets/images/login/icon-refresh.png') no-repeat;
          background-position: center;
          background-size: 30px;
          animation: rotate360 1.5s infinite linear;
        }
        @keyframes rotate360 {100%{transform: rotate(360deg)}}
      }
      .overtime::after{
        content: '超时请重新下单';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        text-align: center;
        line-height: 140px;
        z-index: 3;
        left: 0;
        top: 0;
        color: #fff;
      }
      iframe{
        width: 140px;
        height: 140px;
        position: relative;
        z-index: 2;
        border: none;
      }
    }
  }
  .el-dialog{background: #f6f6f6;}
  .el-dialog__body{padding-top: 12px;}
  .el-tabs__header{margin-bottom: 0}
  .el-tabs--card>.el-tabs__header{
    border: none;
    .el-tabs__nav{border: none}
    .el-tabs__item{
      border: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      padding: 0 30px;
      font-size: 15px;
      height: 44px;
      line-height: 44px;
    }
    .el-tabs__item:hover{color: #6b6be5}
    .is-active{
      background: #fff;
      color: #6b6be5;
    }
  }
  .el-input-number{
    width: 110px;
    .el-input__inner:hover{border-color: #DCDFE6}
    .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled){border-color: #DCDFE6}
    span{
      top: 2px;
      background: none;
      &:hover{color: #606266;}
    }
  }

  @media screen and (max-width: 1100px) {
    .tool{
      h1{
        font-size: 36px;
        padding: 120px 0 40px 20px;
      }
      h2{
        font-size: 28px;
        padding-top: 80px;
      }
      h3{
        padding-top: 80px;
        max-width: 900px;
        margin: 0 auto 80px;
      }
      .business{
        p{margin: 12px 0;}
      }
    }
  }
}
</style>