var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('indexHeader'),_c('div',{staticClass:"banner"}),_vm._m(0),_vm._m(1),_c('section',[_c('h1',[_vm._v("团队活力建设的5W 1H")]),_vm._m(2),_c('ul',{staticClass:"jigsaw"},[_c('li',{class:{hover: _vm.index==0},on:{"mouseover":function($event){return _vm.enter(0)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw1.png")}}),_vm._m(3)]),_c('li',{class:{hover: _vm.index==1},on:{"mouseover":function($event){return _vm.enter(1)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw2.png")}}),_vm._m(4)]),_c('li',{class:{hover: _vm.index==2},on:{"mouseover":function($event){return _vm.enter(2)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw3.png")}}),_vm._m(5)]),_c('li',{class:{hover: _vm.index==3},on:{"mouseover":function($event){return _vm.enter(3)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw4.png")}}),_vm._m(6)]),_c('li',{class:{hover: _vm.index==4},on:{"mouseover":function($event){return _vm.enter(4)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw5.png")}}),_vm._m(7)]),_c('li',{class:{hover: _vm.index==5},on:{"mouseover":function($event){return _vm.enter(5)},"mouseleave":_vm.leave}},[_c('img',{attrs:{"src":require("../../assets/images/index/jigsaw6.png")}}),_vm._m(8)])])]),_c('h1',{staticStyle:{"margin":"140px 0 60px"}},[_vm._v("十方实验室3D模型")]),_vm._m(9),_vm._m(10),_c('indexFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"desc"},[_vm._v("我们致力于打造智慧幸福型企业持续赋能的数字化人才发展平台。"),_c('br'),_vm._v("我们通过数据评估-数据分析-数据应用，为企业的人才决策提供专业支持。"),_c('br'),_vm._v("我们透过"),_c('b',[_vm._v("【ALC测学链核心人才赋能体系】")]),_vm._v("与各领域专家为企业提供帮助。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"alc"},[_c('li',[_c('p',[_vm._v("通过数字化评估系统，对个人和团队进行诊断与分析，发现问题")])]),_c('li',[_c('p',[_vm._v("根据评估结果，定制人才发展计划，解决个人与团队成长难题")])]),_c('li',[_c('p',[_vm._v("整合国内外学习资源，为打造智慧幸福型企业的人才培养持续赋能")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_vm._v("WHY：团队活力建设、HOW：如何实现、WHAT：团队活力识别、"),_c('br'),_vm._v("WHERE：应用场景、WHEN：何时使用、WHO：使用对象")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i'),_c('span',[_vm._v("实现企业的"),_c('br'),_vm._v("倍速增长")]),_c('i'),_c('span',[_vm._v("建设一支"),_c('br'),_vm._v("有战斗力的队伍")]),_c('i'),_c('span',[_vm._v("数字化"),_c('br'),_vm._v("团队活力建设")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down"},[_c('i'),_c('span',[_vm._v("三个层面"),_c('br'),_vm._v("&"),_c('br'),_vm._v("三个视角")]),_c('i'),_c('span',[_vm._v("三个层面"),_c('br'),_vm._v("个体：跑得动"),_c('br'),_vm._v("团队：跑得快"),_c('br'),_vm._v("管理层：跑得远")]),_c('i'),_c('span',[_vm._v("三个视角"),_c('br'),_vm._v("个体：工作能量"),_c('br'),_vm._v("团队：团队活力"),_c('br'),_vm._v("管理层：领导能量")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down"},[_c('i'),_c('span',[_vm._v("个体：工作能量"),_c('br'),_vm._v("工作价值倾向"),_c('br'),_vm._v("~原动力"),_c('br'),_vm._v("~职业素质")]),_c('i'),_c('span',[_vm._v("团队：团队活力"),_c('br'),_vm._v("~活力因子"),_c('br'),_vm._v("~团队预警")]),_c('i'),_c('span',[_vm._v("管理层：领导能量"),_c('br'),_vm._v("~六大领导行为")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i'),_c('span',[_vm._v("CEO："),_c('br'),_vm._v("站在新视角来看待组织"),_c('br'),_vm._v("的增长和绩效的创造")]),_c('i'),_c('span',[_vm._v("业务经理："),_c('br'),_vm._v("识别哪些因素"),_c('br'),_vm._v("有助于或干扰团队的成长")]),_c('i'),_c('span',[_vm._v("HR经理："),_c('br'),_vm._v("为组织发展决策"),_c('br'),_vm._v("提供数据依据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down"},[_c('i'),_c('span',[_vm._v("想要实现业绩突破/"),_c('br'),_vm._v("倍速增长")]),_c('i'),_c('span',[_vm._v("想要带领团队"),_c('br'),_vm._v("更上一层楼")]),_c('i'),_c('span',[_vm._v("想要激发创新变革，"),_c('br'),_vm._v("加速组织转型")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"down"},[_c('i'),_c('span',[_vm._v("班子成员："),_c('br'),_vm._v("增强共识、默契、"),_c('br'),_vm._v("凝聚力")]),_c('i'),_c('span',[_vm._v("组织："),_c('br'),_vm._v("各机构/部门/团队/管理"),_c('br'),_vm._v("层级的人员，核心人员的"),_c('br'),_vm._v("发展性盘点")]),_c('i'),_c('span',[_vm._v("核心人才与"),_c('br'),_vm._v("团队的发展")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model flex-bet-cen"},[_c('div',{staticClass:"text"},[_c('p',{staticStyle:{"margin-bottom":"24px"}},[_vm._v("以「诊断-决策-实现」为数字化人才发展的应用模型，精准培养核心管理人才，降低人才成本，提升人才决策效率。")]),_c('p',{staticClass:"dot"},[_vm._v("诊断（Diagnosis）：数据评估"),_c('br'),_vm._v("通过工具，协助企业做好诊断工作")]),_c('p',{staticClass:"dot"},[_vm._v("决策（Decision）：数据分析"),_c('br'),_vm._v("透过评估结果，提供企业领导者做决策的参考依据")]),_c('p',{staticClass:"dot"},[_vm._v("实现（Delivery）：数据应用"),_c('br'),_vm._v("为企业的管理改善、领导力提升、组织变革等，做好专业支撑")])]),_c('img',{attrs:{"src":require("../../assets/images/index/pro_1.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v("我们的理念")]),_c('div',{staticClass:"idea flex-bet-cen"},[_c('img',{attrs:{"src":require("../../assets/images/index/pro_2.jpg")}}),_c('div',{staticClass:"text"},[_c('h6',[_vm._v("【我们相信】—— ")]),_c('p',[_vm._v("“熵减”是企业活力之源，通过激发组织活力，助力企业获得倍速增长。")]),_c('h6',[_vm._v("【我们专注】—— ")]),_c('p',[_vm._v("组织发展的数据研究与应用，赋能企业组织评估和组织发展，提供高效、便捷、科学和专业化的创新服务，助力点燃组织活力。")]),_c('h6',[_vm._v("【我们致力】—— ")]),_c('p',[_vm._v("为企业找出在核心人才、团队、组织方面带来熵减效应的活性因子，协助企业从容面对发展挑战，让组织活力助力企业远行。")])])])])
}]

export { render, staticRenderFns }