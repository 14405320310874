import Router from 'vue-router'
import index from '@/components/index/index'
//产品介绍
import product from '@/components/introduce/product'
import introduce_amt from '@/components/introduce/AMT'
import introduce_tvr from '@/components/introduce/TVR'
import productAndService from '@/components/introduce/p&s'
import CIC from '@/components/introduce/CIC'
import aboutUs from '@/components/introduce/aboutUs'
//登录
import login from '@/components/login/login'
import userLogin from '@/components/login/user_login'
import userRegister from '@/components/login/user_register'
import choose from '@/components/login/choose'
import companyRegister from '@/components/login/company_register'
//企业
import company from '@/components/company/company'
import company_admin from '@/components/company/company_admin'
import company_edit from '@/components/company/company_edit'
import company_index from '@/components/company/company_index'
import company_news from '@/components/company/company_news'
import company_order from '@/components/company/company_order'
import company_consult from '@/components/company/company_consult'
import company_info from '@/components/company/company_info'
//组织活力扫描
import tvs from '@/components/company/TVS/tvs'
import tvs_basic from '@/components/company/TVS/tvs_basic'
import tvs_report from '@/components/company/TVS/tvs_report'
import tvs_team from '@/components/company/TVS/tvs_team'
import tvs_invite from '@/components/company/TVS/tvs_invite'
import tvs_structure from '@/components/company/TVS/tvs_structure'
import tvs_staff from '@/components/company/TVS/tvs_staff'
// import tvs_buy from '@/components/company/TVS/buy'
//TVR
import tvr from '@/components/company/TVR/tvr'
import tvr_basic from '@/components/company/TVR/tvr_basic'
import tvr_report from '@/components/company/TVR/tvr_report'
import tvr_team from '@/components/company/TVR/tvr_team'
import tvr_invite from '@/components/company/TVR/tvr_invite'
import tvr_structure from '@/components/company/TVR/tvr_structure'
import tvr_staff from '@/components/company/TVR/tvr_staff'
//AMT
import amt from '@/components/company/AMT/amt'
import amt_basic from '@/components/company/AMT/amt_basic'
import amt_member from '@/components/company/AMT/amt_member'
import amt_invite from '@/components/company/AMT/amt_invite'
//CBC
import cbc from '@/components/company/CBC/cbc'
import cbc_basic from '@/components/company/CBC/cbc_basic'
import cbc_member from '@/components/company/CBC/cbc_member'
import cbc_invite from '@/components/company/CBC/cbc_invite'
//cognition认知差异分析
import cognition from '@/components/company/cognition/cognition'
import cognition_basic from '@/components/company/cognition/cognition_basic'
import cognition_report from '@/components/company/cognition/cognition_report'
import cognition_team from '@/components/company/cognition/cognition_team'
import cognition_invite from '@/components/company/cognition/cognition_invite'
import cognition_staff from '@/components/company/cognition/cognition_staff'
//LSN
import lsn from '@/components/company/LSN/lsn'
import lsn_basic from '@/components/company/LSN/lsn_basic'
import lsn_member from '@/components/company/LSN/lsn_member'
import lsn_invite from '@/components/company/LSN/lsn_invite'
import lsn_report from '@/components/company/LSN/lsn_report'
//专家服务
import expert_list from '@/components/expert/expertList'
import expert_reserve from '@/components/expert/expertReserve'
import expert from '@/components/expert/expert'
import expert_manage from '@/components/expert/expert_manage'
import expert_case from '@/components/expert/expert_case'
import expert_order from '@/components/expert/expert_order'
import expert_box from '@/components/expert/expert_box'
import expert_edit from '@/components/expert/expert_edit'
import expert_chat from '@/components/expert/chat'
import expert_evaluate from '@/components/expert/evaluate'
//个人中心
import userCenter from '@/components/userCenter/index'

export default new Router({
  routes: [
    //首页 及 产品介绍页
    {path: '/', component: index},
    {path: '/p&s/:id', component: productAndService},
    {path: '/AMTIndex', component: introduce_amt},
    {path: '/TVRIndex', component: introduce_tvr},
    {path: '/product', component: product},
    {path: '/CIC', component: CIC},
    {path: '/about', component: aboutUs},
    //登录
    {
      path: '/login',component: login, children: [
        {path:'/',component: userLogin,},
        {path:'userLogin',component: userLogin,},
        {path:'userRegister',component: userRegister,},
        {path:'choose',component: choose,},
        {path:'companyRegister',component: companyRegister,},
      ],
    },
    //企业管理
    {
      path: '/company', component: company, children: [
        {path: '/', component: company_index, name:'企业用户中心'},
        {path: 'index', component: company_index, name:'控制台'},
        {path: 'news', component: company_news, name:'消息'},
        {path: 'admin', component: company_admin, name:'管理员管理'},
        {path: 'order', component: company_order, name:'订单'},
        {path: 'edit', component: company_edit, name:'编辑企业信息'},
        {path: 'consult', component: company_consult, name:'咨询服务'},
        {path: 'info', component: company_info, name:'个人信息'},
      ],
    },
    //组织活力扫描
    {
      path: '/tvs', component: tvs, children: [
        {path:'/',component: tvs_basic, name:'组织活力扫描'},
        {path:'basic',component: tvs_basic,name:'概况'},
        {path:'staff',component: tvs_staff,name:'员工管理'},
        {path:'structure',component: tvs_structure,name:'架构管理'},
        {path:'invite',component: tvs_invite,name:'邀请管理'},
        {path:'team',component: tvs_team,name:'团队管理'},
        {path:'report',component: tvs_report,name:'报告管理'},
      ],
    },
    // {path: '/TVS/buy', component: tvs_buy, name: ''},
    //TVR
    {
      path: '/tvr', component: tvr, children: [
        {path:'/',component: tvr_basic, name:'TVR团队活力识别器'},
        {path:'basic',component: tvr_basic,name:'概况'},
        {path:'staff',component: tvr_staff,name:'员工管理'},
        {path:'structure',component: tvr_structure,name:'架构管理'},
        {path:'invite',component: tvr_invite,name:'邀请管理'},
        {path:'team',component: tvr_team,name:'团队管理'},
        {path:'report',component: tvr_report,name:'报告管理'},
      ],
    },
    //AMT
    {
      path: '/amt', component: amt, children: [
        {path:'/',component:amt_basic,name:'AMT领导者画像'},
        {path:'basic',component:amt_basic,name:'概况'},
        {path:'member',component:amt_member,name:'成员管理'},
        {path:'invite',component:amt_invite,name:'邀请管理'},
      ],
    },
    //CBC
    {
      path: '/cbc', component: cbc, children: [
        {path:'/',component:cbc_basic,name:'职涯优选CBC'},
        {path:'basic',component:cbc_basic,name:'概况'},
        {path:'member',component:cbc_member,name:'成员管理'},
        {path:'invite',component:cbc_invite,name:'邀请管理'},
      ],
    },
    //cognition认知差异分析
    {
      path: '/cognition', component: cognition, children: [
        {path:'/',component: cognition_basic, name:'认知差异分析'},
        {path:'basic',component: cognition_basic,name:'概况'},
        {path:'staff',component: cognition_staff,name:'员工管理'},
        {path:'invite',component: cognition_invite,name:'邀请管理'},
        {path:'team',component: cognition_team,name:'团队管理'},
        {path:'report',component: cognition_report,name:'报告管理'},
      ],
    },
    //LSN
    {
      path: '/lsn', component: lsn, children: [
        {path:'/',component:lsn_basic,name:'LSN精益创业导航'},
        {path:'basic',component:lsn_basic,name:'概况'},
        {path:'member',component:lsn_member,name:'成员管理'},
        {path:'invite',component:lsn_invite,name:'邀请管理'},
        {path:'report',component:lsn_report,name:'报告管理'},
      ],
    },
    //专家服务
    {path: '/expertList', component: expert_list, name: '专家列表'},
    {path: '/expertReserve', component: expert_reserve, name: '专家预约'},
    {path:'/chat', component: expert_chat, name: ''},
    {path:'/expertEvaluate', component: expert_evaluate, name: ''},
    {
      path: '/expert', component: expert, children: [
        {path:'manage', component: expert_manage, name: '预约管理'},
        {path:'case', component: expert_case, name: '企业案例'},
        {path:'order', component: expert_order, name: '订单管理'},
        {path:'box', component: expert_box, name: '百宝箱'},
        {path:'edit', component: expert_edit, name: '信息设置'},
      ],
    },
    //个人中心
    {
      path: '/userCenter', component: userCenter,
    },
  ]
})