<template>
  <div class="tvr">
    <company_header :btnShow="true"></company_header>
    <div class="view">
      <!-- 导航 -->
      <el-menu 
        :default-active="this.$route.path" 
        active-text-color="#868686" 
        class="el-menu-vertical"
        @select="tolink">
          <!-- 公司信息 -->
          <div class="message-header">
            <div class="message">
              <el-image :src="logo_path" class="head flex-cen-cen">
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture"></i>
                </div>
              </el-image>
              <div>
                <h2>{{companyname}}</h2>
                <!-- <h2>积分：{{rate}}</h2> -->
              </div>
            </div>
            <div class="percent">
              <h3>完善信息</h3>
              <div class="bar"><b :style="{width:rate+'%'}"></b></div>
              <h4>{{rate}}%</h4>
            </div>
          </div>
          <el-menu-item v-for="(item,i) in nav" :key="i" :index="item.path">
            <i :class="item.icon"></i>
            <span slot="title">{{item.name}}</span>
          </el-menu-item>
      </el-menu>
      <!-- 内容 -->
      <router-view class="main"/>
    </div>
  </div>
</template>

<script>
import company_header from '../header'
export default {
  components: {company_header},
  data(){
    return{
      logo_path:'',
      companyname: '',
      rate: 20,
      nav: [
        {name:'概况',path:'/cbc',icon:'nav_index'},
        {name:'成员管理',path:'/cbc/member',icon:'nav_staff'},
        {name:'邀请管理',path:'/cbc/invite',icon:'nav_invite'},
      ],
    }
  },
  created(){
    this.getInfo()
  },
  methods: {
    tolink(path){
      if(this.$route.path==path) return;
      this.$router.push({path: path});
    },
    // 获取企业基本信息（子页面需要调用）
    getInfo(){
      this.$request({method: 'post',url: '/Company/getBaseInfo?cbc',
        success: (data) => {
          if (data.code == 0) {
            let info =  data.data.company_info
            this.companyname = info.companyname
            this.logo_path = info.logo_path
            this.rate = info.rate
          } else if (data.code == 102) {
            this.$router.replace({path: '/userCenter'})
          } else {
            this.$message({duration: 2000,message: data.msg,type:'error'})
          }
        },
      })
    },
  },
}
</script>

<style lang="scss">
.tvr{
  height: 100%;
  @media (max-width: 1000px) {
    .view{
      .el-menu-vertical{width: 180px;}
      .message-header{
        .message h2{width: 86px;}
        .percent .bar{flex: 0;margin: 0 3px;}
      }
      .pagination{padding-left: 180px}
    } 
  }
}
.view{
  height: 100%;
  display: flex;
  *{transition: width .1s linear;}
  // 导航上的头部信息
  .message-header{
    padding: 30px 0 20px 32px;
    user-select: none;
    .message{
      display: flex;
      align-items: center;
      .head{
        width: 40px;height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        flex-shrink: 0;
        box-shadow: 0 0 4px rgba(200,200,200,1);
        .image-slot{display: inline-block}
      }
      h2{
        font-size: 14px;
        color: #2b2b2b;
        line-height: 1.4;
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow:ellipsis;
        width: 120px;
      }
    }
    .percent{
      display: flex;
      align-items: center;
      font-size: 12px;
      padding-right: 28px;
      height: 40px;
      h3{
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
      .bar{
        flex: 1;
        margin: 0 8px;
        height: 6px;
        border-radius: 3px;
        background: #d4d9e2;
        position: relative;
        b{
          position: absolute;
          height: 100%;
          width: 0;
          border-radius: 3px;
          left: 0;
          top: 0;
          background-image: linear-gradient(to right,#8f94fb,#4e54c8)
        }
      }
      h4{
        color: #6b6be5;
        font-size: 12px;
      }
      i{
        width: 12px;
        height: 12px;
        background: url('../../../assets/images/company/edit.png') no-repeat center;
        background-size: 100%;
        cursor: pointer;
        margin-left: 6px;
      }
    }
  }
  // 导航
  .el-menu-vertical{
    width: 220px;
    height: 100%;
    background: #eceff4;
    border: none;
    .el-menu-item{
      padding-right: 20px;
      padding-left: 0px;
      height: 50px;
      height: 50px;
      color: #868686;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      &:hover{
        background: #e1e7f0;
      }
      &::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 4px;
        background: #6b6be5;
        left: 0;
        top: 0;
        display: none;
        border-radius: 2px;
      }
      span{
        height: 18px;
        line-height: 18px;
        width: 100px;
      }
      i{
        width: 18px;
        height: 18px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 20px;
      }
      .nav_index{background-image: url('../../../assets/images/company/nav_index.png');}
      .nav_staff{background-image: url('../../../assets/images/company/nav_staff.png');}
      .nav_structure{background-image: url('../../../assets/images/company/nav_structure.png');}
      .nav_team{background-image: url('../../../assets/images/company/nav_team.png');}
      .nav_report{background-image: url('../../../assets/images/company/nav_report.png');}
      .nav_order{background-image: url('../../../assets/images/company/nav_order.png');}
      .nav_admin{background-image: url('../../../assets/images/company/nav_admin.png');}
      .nav_invite{background-image: url('../../../assets/images/company/nav_invite.png');}
    }
    .is-active{
      background: #d9dfe8;
      span{
        color: #2b2b2b;
        font-weight: bold;
      }
      &::before{display: block}
    }
  }
  .main{
    flex: 1;
    min-width: 400px;
    padding: 0 40px 49px;
    position: relative;
    overflow-y: hidden;
    // overflow: auto;
  }
}

</style>
