<template>
  <div class="about">
    <indexHeader/>
    <div class="banner flex-cen-cen">关于我们</div>
    <section class="summary flex">
      <img src="../../assets/images/aboutus/about_bg2.jpg">
      <div class="word">
        <h6>概述</h6>
        <p>十方实验室是一个专注于创业者成长的共生增长平台，致力于通过提供全方位的支持和资源，帮助创业者实现快速而稳健的发展。 我们通过导师指导、社区互助、资源对接等多元化方式，助力创业者实现快速而稳健的成长。</p>
        <p>我们的宗旨是助力创业者实现梦想，促进持续的创新和进步。我们提供专业的指导和支持，帮助创业者将创意转化为现实。我们的目标是成为创业者值得信赖的增长伙伴，同时在这一过程中促进创业生态系统的整体发展与繁荣 。</p>
      </div>
    </section>
    <section class="service">
      <h1>我们的服务</h1>
      <ul class="flex">
        <li>
          <h5>全面的导师指导</h5>
          <p>我们的导师团队由优秀的创业教练、行业专家和投资人组成，他们提供一对一的指导，分享他们的知识、经验和见解，帮助创业者克服挑战，制定有效的商业策略。</p>
        </li>
        <li>
          <h5>社区互助的力量</h5>
          <p>我们鼓励创业者之间的社区互助，促进合作和知识共享。创业者可以在这里与其他志同道合的人交流想法、分享经验，互相支持和鼓励。通过社区的力量，创业者可以获得更多的机会和资源，共同成长。</p>
        </li>
        <li>
          <h5>广泛的资源对接</h5>
          <p>我们致力于整合各方资源，包括资金、技术、市场渠道等，为创业者提供更全面的支持。通过与合作伙伴和投资者的合作，我们能够为创业者提供更多的机会和资源，推动创业者的业务发展和创新。</p>
        </li>
        <li>
          <h5>新颖的共创培训</h5>
          <p>定期举办各种工作坊和培训课程，涵盖领导力、产品画布分析、商业模式梳理、等关键领域，帮助创业者提升技能和知识。</p>
        </li>
        <li>
          <h5>定制化咨询服务</h5>
          <p>根据创业者的特定需求提供定制化的咨询服务，解决创业者在发展过程中遇到的独特挑战。 </p>
        </li>
      </ul>
    </section>
    <section class="model">
      <h1>我们的模型</h1>
      <h2><b>S</b>urvival，<b>G</b>rowth，<b>E</b>cology</h2>
      <img src="../../assets/images/aboutus/about_bg5.png">
    </section>
    <section class="target">
      <h1>我们的目标</h1>
      <h2>透过SGE模型，以数据驱动的陪跑服务，帮助企业用最有效、最简单的方式，实现倍速增长</h2>
      <div class="box flex">
        <nav>
          <p :class="{curr:showIndex==0}" @mouseenter="showIndex=0">实现路径1</p>
          <p :class="{curr:showIndex==1}" @mouseenter="showIndex=1">实现路径2</p>
          <p :class="{curr:showIndex==2}" @mouseenter="showIndex=2">实现路径3</p>
        </nav>
        <div class="flex:1">
          <img src="../../assets/images/aboutus/about_bg6.jpg" v-show="showIndex==0">
          <img src="../../assets/images/aboutus/about_bg7.jpg" v-show="showIndex==1">
          <img src="../../assets/images/aboutus/about_bg8.jpg" v-show="showIndex==2">
        </div>
      </div>
    </section>
    <indexFooterNav :theme="'grey'"/>
    <indexFooter :bgcolor="'#f9f9f9'"/>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
import indexFooterNav from '../index/indexFooterNav'
export default {
  components:{indexHeader, indexFooter, indexFooterNav},
  data(){
    return {
      showIndex: 0,
    }
  },
  mounted(){
    console.log(1230)
  },
  methods:{},
}
</script>
<style lang="scss" scoped>
.about{
  height: 100%;
  padding-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 800px;
  h1{
    font-size: 24px;
    text-align: center;
    color: #212129;
  }
  h2{
    text-align: center;
    font-size: 18px;
    margin-top: 6px;
    color: #212129;
    font-weight: normal;
  }
  .banner{
    height: 300px;
    background: url('../../assets/images/aboutus/about_bg1.jpg') no-repeat;
    background-size: auto 100%;
    background-position: center top;
    color: #fff;
    font-size: 32px;
    letter-spacing: 15px;
    padding-left: 15px;
    position: relative;
    max-width: 1794px;
    margin: 0 auto;
    &::before, &::after{
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      background: url('../../assets/images/aboutus/about_bg1.jpg') no-repeat;
      background-size: auto 100%;
      transform: scaleX(-1);
    }
    &::before{right: 100%;}
    &::after{left: 100%;}
  }
  .summary{
    margin: 60px auto;
    max-width: 1000px;
    img{
      width: 25%;
      transition: .3s;
    }
    .word{
      flex: 1;
      padding: 50px 10px 0;
      background: #f3f7ff;
      transition: .3s;
    }
    h6{
      font-size: 16px;
      color: #212129;
      max-width: 540px;
      margin: 0 auto;
    }
    p{
      font-size: 15px;
      max-width: 540px;
      color: #212121;
      margin: 30px auto 0;
      text-align: justify;
      line-height: 1.6;
      transition: .3s;
    }
  }
  .service{
    padding: 80px 0 50px;
    background: url('../../assets/images/aboutus/about_bg3.jpg') no-repeat;
    background-size: 100% 100%;
    transition: .3s;
    h1{color: #fff;}
    ul{
      max-width: 1000px;
      margin: 60px auto;
      transition: .3s;
      li{
        background: #fff;
        padding: 12px 18px;
        color: #212129;
        margin-right: 18px;
        flex: 1;
        transition: .3s;
        &::before{
          content: '';
          display: block;
          width: 50px;
          height: 50px;
          margin: 0 auto 10px;
          border-radius: 50%;
          background: #dce6fa url('../../assets/images/aboutus/about_bg4.png') no-repeat;
          background-size: auto 50px;
          transition: .3s;
        }
        h5{
          font-size: 16px;
          text-align: center;
        }
        p{
          font-size: 14px;
          text-align: justify;
          margin: 10px 0;
        }
        &:last-child{margin-right: 0;}
        &:nth-child(2)::before{background-position: -50px 0;}
        &:nth-child(3)::before{background-position: -100px 0;}
        &:nth-child(4)::before{background-position: -150px 0;}
        &:nth-child(5)::before{background-position: -200px 0;}
      }
    }
  }
  .model{
    padding: 80px 0;
    background: #dce4f5;
    transition: .3s;
    img{
      display: block;
      max-width: 1000px;
      width: 100%;
      margin: 30px auto 0;
    }
  }
  .target{
    padding: 80px 0;
    transition: .3s;
    h2{
      font-size: 16px;
      margin-top: 10px;
    }
    .box{
      max-width: 1000px;
      margin: 50px auto 0;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid #dce4f5;
      nav{
        background: #dce4f5;
        padding: 40px 24px;
        p{
          width: 160px;
          text-align: center;
          font-size: 18px;
          color: #212129;
          line-height: 44px;
          border-radius: 6px;
          background: #fff;
          box-shadow: 0 0 10px rgba(0,56,150,.1);
          user-select: none;
          margin-bottom: 16px;
          cursor: pointer;
        }
        .curr{
          background: #1a478b;
          color: #fff;
        }
      }
      img{
        width: 100%;
        animation: aboutImgShow .3s;
      }
      @keyframes aboutImgShow {
        0%{opacity: 0;}
        100%{opacity: 1;}
      }
    }
  }
  
  @media screen and (max-width: 900px) {
    .summary{
      img{width: 22%;}
      .word{padding-top: 28px;}
      h6{max-width: 560px;}
      p{margin-top: 10px;max-width: 560px;}
    }
    .service{
      padding: 60px 0 20px;
      ul{
        margin: 40px auto;
        li{
          margin-right: 10px;
          padding: 12px 12px;
          h5{font-size: 14px;}
          p{font-size: 13px;}
        }
      }
    }
    .model{padding: 60px 0;}
    .target{padding: 60px 0;}
  }
}
</style>