<template>
  <div class="inviteDetail">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_invite.png">成员管理
    </h1>
    <div class="move-up"></div>
    <div class="table-btn">
      <input type="text" placeholder="搜索" class="search-input" v-model="keyword" @input="searchMember">
      <b style="flex:1"></b>
      <div class="btn" @click="inviteShow=true">邀请成员</div>
    </div>
    <el-table
      v-loading="loading"
      :data="inviteList"
      tooltip-effect="dark"
      ref="inviteTable"
      height="calc(100vh - 238px)"
      style="width:100%">

      <el-table-column label="姓名" min-width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div class="table-head">
            <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
            <span>{{scope.row.realname}}</span>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="手机号" min-width="100">
        <template slot-scope="scope">
          <span>{{$mobileToStar(scope.row.mobile)}}</span>
        </template>
      </el-table-column>
      
      <el-table-column prop="date" label="最新激活时间" min-width="132">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.join_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column label="层级" min-width="92" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.tier_name">{{scope.row.tier_name}}</span>
          <span v-else>-</span>
        </template>
      </el-table-column>

      <el-table-column label="邀请状态" min-width="90" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.exam_status==2" class="blue">已完成</span>
          <span v-else-if="scope.row.exam_status==1" class="red">进行中</span>
          <span v-else class="red">未激活</span>
        </template>
      </el-table-column>
      
      <el-table-column prop="date" label="最新完成时间" min-width="132">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.end_time,'y-m-d h:m')||'-'}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="other_exam_count" label="最新他评人数" min-width="110" align="center"></el-table-column>

      <el-table-column prop="other_exam_complete_count" label="最新他评完成人数" min-width="136" align="center"></el-table-column>
      
      <el-table-column label="操作" min-width="310" align="center">
        <template slot-scope="scope">
          <span class="btn" @click="view(scope.row)" :id="'view'+scope.row.exam_id">预览报告</span>
          <span class="btn" @click="confirm(scope.row)">下载报告</span>
          <span class="btn" @click="viewAssess(null, 1, scope.row)">评估记录</span>
          <span class="btn" @click="getOtherDetail(null, 1, scope.row)" v-if="scope.row.other_exam_count">他评详情</span>
          <el-tooltip v-else effect="dark" class="gray" content="没有他评人数" placement="top" :open-delay="400">
            <el-button>他评详情</el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="memberPage" @change="getMember" :total="inviteTotal"></pagination>

    <!-- 邀请成员弹窗 -->
    <el-dialog
      title="邀请成员"
      :visible.sync="inviteShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      width="560px"
      center>
      <div class="dialog-input-body">
        <nav class="top-nav">
          <span :class="{current:inviteType==0}" @click="toggle(0)">手机号邀请</span>
          <span :class="{current:inviteType==1}" @click="toggle(1)">小程序码邀请</span>
        </nav>
        <template v-if="inviteType==0">
          <div class="input-row">
            <h4>姓名</h4>
            <div class="box" :class="{error:errorType==1}">
              <input 
                class="input"
                placeholder='请输入真实姓名' 
                maxlength="16"
                v-model="inviteName"
                @input="setError"
                ref="inviteName">
              <div class="input-icon icon-clear" title="清空" v-show="inviteName" @click="inviteName='';errorType=0"></div>
              <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
            </div>
          </div>
          <div class="input-row">
            <h4>手机号</h4>
            <div class="box" :class="{error:errorType==2}">
              <el-select v-model="country_code" @change="selectCountry" class="el-select-code">
                <el-option
                  v-for="(item,code) in $country"
                  :key="code"
                  :label="item.label"
                  :value="code">
                </el-option>
              </el-select>
              <input 
                class="input"
                style="padding-top:2px"
                placeholder='请输入手机号'
                maxlength="11"
                v-model="phone"
                @input="inputChange($event,'phone')"
                ref="phone">
              <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone='';errorType=0"></div>
              <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：邀请有效期为72小时</p>
          <div class="bottom-btn" @click="mobileInvite">确认</div>
        </template>
        <template v-else-if="inviteType==1">
          <div class="input-row" style="margin:60px 0">
            <h4>数量</h4>
            <div class="box" :class="{error:errorType==3}">
              <input 
                class="input"
                placeholder='请输入数量' 
                maxlength="6"
                @input="inputChange($event,'sendNumber')"
                @blur="sendNumber=sendNumber.replace(/\b(0+)/gi,'')"
                v-model="sendNumber">
              <div class="input-icon icon-clear" title="清空" v-show="sendNumber" @click="sendNumber='';errorType=0"></div>
              <div class="errText" v-show="errorType==3">{{errorMsg}}</div>
            </div>
          </div>
          <p class="tips">提示：邀请有效期为72小时</p>
          <div class="bottom-btn loading-dot" v-if="creating">生成中<i>.</i><i>.</i><i>.</i></div>
          <div class="bottom-btn" @click="createQrcode" v-else>生成小程序码</div>
        </template>
        <template v-else>
          <div class="qrcode">
            <img :src="qrcode">
            <div class="downloadBtn" style="opacity:.8;cursor:not-allowed;" v-if="qrcodeDownload">已下载</div>
            <div @click="downloadCode" class="downloadBtn" v-else>下 载</div>
            <p>提示：被邀请人可通过扫描此小程序码进入评估，可在<router-link to="/cognition/invite">邀请管理</router-link>中查看邀请详情或结束此邀请</p>
          </div>
        </template>
      </div>
    </el-dialog>
    <!-- 他评详情框 -->
    <el-dialog
      v-loading="otherLoading"
      title="他评详情"
      class="dialog-middle"
      :visible.sync="otherListShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      center>
      <div style="margin-bottom:10px">自评人：{{nowMember.realname}}&nbsp;&nbsp; {{$formatTime(nowMember.end_time)}}</div>
      <el-table
        :data="otherList"
        tooltip-effect="dark"
        style="width: 100%">

        <el-table-column label="姓名" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="手机号" min-width="100" align="center">
          <template slot-scope="scope">
            <span>{{$mobileToStar(scope.row.mobile)}}</span>
          </template>
        </el-table-column>
        
        <el-table-column prop="date" label="激活时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.active_time,'y-m-d h:m')||'-'}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="relation" label="关系" min-width="48"></el-table-column>

        <el-table-column label="评估状态" min-width="90" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>

        <el-table-column prop="date" label="完成时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.end_time,'y-m-d h:m')||'-'}}</span>
          </template>
        </el-table-column>

      </el-table>

      <pagination 
        v-show="otherList.length>2" 
        ref="otherPage"  
        :position="'page-botttom'"
        @change="getOtherDetail" 
        :total="otherTotal">
      </pagination>
    </el-dialog>
    <!-- 查看成员评估记录弹窗 -->
    <el-dialog
      :title="nowMember.realname+'的评估记录'"
      class="dialog-large"
      :visible.sync="assessShow"
      :close-on-click-modal="false"
      @close="closeDialog"
      center>
      <el-table
        :data="assessList"
        tooltip-effect="dark"
        style="width: 100%">
        <el-table-column label="姓名" min-width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="table-head" style="width:102px;margin:0 auto">
              <img :src="scope.row.avatar || require('../../../assets/images/head.png')">
              <span>{{scope.row.realname}}</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="激活时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.start_time,'y-m-d h:m')}}</span>
          </template>
        </el-table-column>
        
        <el-table-column label="自评评估" min-width="80" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.status==2" class="blue">已完成</span>
            <span v-else class="red">未完成</span>
          </template>
        </el-table-column>
        
        <el-table-column label="完成时间" min-width="130" align="center">
          <template slot-scope="scope">
            <span>{{$formatTime(scope.row.end_time,'y-m-d h:m') || '-'}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="other_exam_count" label="他评人数" min-width="80" align="center"></el-table-column>

        <el-table-column prop="other_exam_complete_count" label="他评完成人数" min-width="110" align="center"></el-table-column>
        
        <el-table-column label="操作" min-width="160" align="center">
          <template slot-scope="scope">
            <span class="btn" @click="view(scope.row)" :id="'view'+scope.row.exam_id">预览报告</span>
            <span class="btn" @click="confirm(scope.row)">下载报告</span>
          </template>
        </el-table-column>
      </el-table>
      <pagination v-show="assessList.length>2" ref="assessPage" @change="viewAssess" :total="assessTotal" :position="'page-botttom'"></pagination>
    </el-dialog>
    <!-- 下载报告确认 -->
    <el-dialog
      title="下载报告"
      class="el-dialog-confirm"
      :visible.sync="downloadShow"
      :close-on-click-modal="false"
      center>
        <span>确定要下载 [{{nowMember.realname}}] 的报告吗？</span>
        <span slot="footer" class="dialog-footer">
          <el-button class="downloadBtn" v-if="downloading" type="primary">下载中...</el-button>
          <el-button class="downloadBtn" v-else type="primary" @click="download">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '../../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      loading: false,
      reg: this.$CNReg,           // 当前区号的手机号正则
      country_code: '86',         // 区号
      errorType: 0,
      errorMsg: '',

      keyword: '',
      inviteShow: false,
      inviteType: 0,              // 邀请页面（0：手机号邀请，1：小程序码邀请）
      inviteName: null,
      phone: null,
      sendNumber: '',             // 发出邀请数
      creating: false,            // 正在生成小程序码
      qrcode: '',                 // 生成的小程序码
      qrcodeDownload: false,      // 是否已经下载了小程序码
      
      inviteList: [],
      inviteTotal: 0,

      assessShow: false,
      assessList: [],
      assessTotal: [],
      
      otherLoading: false,
      otherListShow: false,
      otherList: [],
      otherTotal: 0,
      downloadShow: false,
      downloading: false,
      nowMember: {},
    }
  },
  mounted(){
    this.getMember(20,1)
  },
  methods: {
    // 获取成员列表
    getMember(per_page, page){
      this.loading = true
      let ref = this.$refs.memberPage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$request({
        url:'/CognitiveDifferences/getEmployeeList',
        data: {
          per_page: per_page,
          page: page,
          keyword: this.keyword,
        },
        success: (data) => {
          if (data.code == 0) {
            this.inviteList = data.data.data
            this.inviteTotal = data.data.total
          } else if (data.code == 101) {
            this.$router.replace({path:'/login',query:{back: this.$route.path}})
            localStorage.clear()
          } else {
            this.$message({duration:2000,message:data.msg,type:'error'})
          }
        },
        complete: () => this.loading = false
      })
    },
    // 获取他评详情
    getOtherDetail(per_page, page, row){
      this.otherLoading = true
      this.otherListShow = true
      let otherPage = this.$refs.otherPage
      per_page = otherPage ? otherPage.per_page : per_page || 20
      page = otherPage ? otherPage.page : page || 1
      this.$request({
        url:'/CognitiveDifferences/getEmployeeOtherExam',
        data: {
          exam_id: row ? row.exam_id : this.nowMember.exam_id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.otherList = data.data.other_exam_list.data
            this.otherTotal = data.data.other_exam_list.total
            this.nowMember = row
          } else {
            this.$message({duration: 2000, message: data.msg})
          }
        },
        complete: () => this.otherLoading = false
      })
    },
    // 获取成员的评估记录
    viewAssess(per_page, page, row){
      let ref = this.$refs.assessPage
      per_page = ref ? ref.per_page : per_page || 20
      page = ref ? ref.page : page || 1
      this.$request({
        url:'/CognitiveDifferences/getEmployeeExam',
        data: {
          user_id: row ? row.user_id : this.nowMember.user_id,
          per_page: per_page,
          page: page,
        },
        success: (data) => {
          if (data.code == 0) {
            this.assessList = data.data.data
            this.assessTotal = data.data.total
            this.assessShow = true
            this.nowMember = row
          } else {
            this.$message({duration: 2000,message: data.msg})
          }
        },
      })
    },
    searchMember(e){
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.keyword = e.target.value
        this.getMember(null, 1)
        this.$refs.memberPage.page = 1
      }, 600)
    },
    // 选择区号和手机号正则
    selectCountry(val){
      this.reg = this.$country[val].reg
      this.country_code = val
    },
    inputChange(e,key){
      let num = e.target.value.replace(/\D/g,'')
      this[key] = num
      this.errorType = 0
    },
    setError(type = 0, msg = ''){
      this.errorType = type
      this.errorMsg = msg
    },
    // 切换手机号邀请/小程序码邀请
    toggle(type){
      if (this.inviteType==type)  return;
      else if (this.inviteType==2 && type==1) return
      this.inviteType = type
      this.errorType = 0
      this.inviteName = ''
      this.phone = ''
      this.sendNumber = null
    },
    // 手机号邀请新成员
    mobileInvite(){
      if (!this.inviteName) {
        this.setError(1,'请先输入姓名')
        this.$refs.inviteName.focus()
      } else if (!this.phone) {
        this.setError(2,'请先输入手机号')
        this.$refs.phone.focus()
      } else if (!this.reg.test(this.phone)) {
        this.setError(2,'手机号码格式不对')
        this.$refs.phone.focus()
      } else {
        this.$request({
          url:'/CognitiveDifferences/baseMobileInvite',
          data: {
            realname: this.inviteName,
            mobile: this.phone,
            country_code: this.country_code,
          },
          success: (data) => {
            if (data.code == 0) {
              this.$message({duration: 1000,message: '邀请成功', type:'success'})
              this.inviteShow = false
              this.getMember(null, 1)
            } else {
              this.$message({duration: 2000, message: data.msg})
            }
          },
          complete: () => this.otherLoading = false
        })
      }
    },
    // 小程序码邀请
    createQrcode(){
      if (!this.sendNumber) {
        this.setError(3, '请输入邀请数')
        return
      }
      this.creating = true
      this.$request({
        url:'/CognitiveDifferences/codeOrgInvite',
        data: {invite_num: this.sendNumber},
        success: (data) => {
          if (data.code == 0) {
            this.$request({
              url: this.$planetUrl+'/Common/createQrcode',
              data: {
                param: data.data.id + '=' + data.data.code,
                page: 'pages/CD/accept/accept',
                width: 280,
              },
              success: (e) => {
                if (e.code == 0) {
                  this.inviteType = 2
                  this.qrcode = e.data.qrcode_path
                } else {
                  this.$message.error(e.msg)
                }
              },
              complete: () => this.creating = false
            })
          } else {
            this.creating = false
            this.$message.error(data.msg)
          }
        },
        error: ()=>{
          this.creating = false
          this.$message.error('网络错误，请稍后再试')
        }
      })
    },
    // 下载小程序码
    downloadCode(){
      if (this.qrcodeDownload == false) {
        window.open(`${this.$baseRoot}/CognitiveDifferences/downQrcode?qrcode_path=${this.qrcode}`)
        this.qrcodeDownload = true
      }
    },
    //操作确认
    confirm(row){
      if (row.other_exam_complete_count < 3) {
        this.$message.warning('至少3人完成他评才能下载报告')
      } else {
        this.nowMember = row
        this.downloadShow = true
      }
    },
    //预览
    view(row){
      if (this.load) {
        return
      } else if (row.other_exam_complete_count < 3) {
        this.$message.warning('至少3人完成他评才能预览报告')
      } else {
        let pdfLoading = this.$loading({
          lock: true,//lock的修改符--默认是false
          spinner: 'el-icon-loading',//自定义加载图标类名
          background: '#fff',//遮罩层颜色
          target: document.querySelector('#view' + row.exam_id)
        });
        this.load = true
        this.checkPdf(row.exam_id).then((e) => {
          pdfLoading.close()
          this.load = false
          if (e == 0) {
            window.open(`${this.$reportUrl}/cognition/#/self/${row.exam_id}`,'_blank')
          } else {
            this.$message.error(e)
          }
        })
      }
    },
    checkPdf(id){
      return new Promise((resolve) => {
        this.$request({
          url: '/PersonalReport/getReportStatus',
          data: {exam_id: id},
          success: (data) => {
            if (data.code == 0) {
              resolve(0)
            } else if (data.code == 4) {  //报告未生成
              this.$request({
                method: 'post',
                url: '/PersonalReport/createReport',
                data: {exam_id: id},
                success: (res) => {
                  if (res.code == 0)
                    run()
                  else
                    resolve(res.msg)
                },
                error: () => resolve('网络错误，请稍后再试')
              })
            } else if (data.code == 3) {  //报告生成中
              run()
            } else {  //参数错误
              resolve(data.msg)
            }
          },
          error: () => resolve('网络错误，请稍后再试')
        })
        var run = () => {
          let time = 0
          let timer = setInterval(() => {
            this.$request({
              url: '/PersonalReport/getReportStatus',
              data: {exam_id: id},
              success: (data) => {
                if (data.code == 0) {
                  resolve(0)
                  clearInterval(timer)
                  return
                } else if (data.code == 2) {
                  resolve('参数错误')
                  clearInterval(timer)
                }
              },
              error: () => {
                resolve('网络错误')
                clearInterval(timer)
              },
              complete: () => {
                if (time++ > 30) {
                  resolve('报告生成超时')
                  clearInterval(timer)
                } 
              }
            })
          }, 1500)
        }
      })
    },
    //下载报告
    download(){
      this.downloading = true
      let exam_id = this.nowMember.exam_id
      this.checkPdf(exam_id).then((e) => {
        if (e == 0) {
          window.open(`${this.$baseRoot}/PersonalReport/download?exam_id=${exam_id}`,'_blank')
        } else {
          this.$message.error(e)
        }
        this.downloadShow = false
        this.downloading = false
      })
    },
    closeDialog(){
      this.nowMember = {}
      this.inviteName = ''
      this.phone = ''
      this.setError()
      this.inviteType = 0
      this.qrcodeDownload = false
      this.sendNumber = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.inviteDetail{
  .qrcode{
    text-align: justify;
    img{
      width: 136px;
      height: 136px;
      display: block;
      margin: 0 auto;
      background: #e5e5e5;
    }
    .downloadBtn{
      border-radius: 4px;
      width: 100px;
      color: #fff;
      background: #6b6be5;
      text-align: center;
      line-height: 30px;
      height: 30px;
      margin: 20px auto;
      cursor: pointer;
    }
    p{
      color: #868686;
      a{color: #6b6be5;cursor: pointer;}
    }
  }
  .qrcode-old{
    padding-top: 30px;
    img{margin: 0 auto;}
    p{margin: 40px 0 10px;}
  }
}

</style>