<template>
  <div class="step">
    <indexHeader/>
    <nav class="flex-bet" v-if="step!=5">
      <div :class="{curr:Math.floor(step)<=1}"><p>完成一份评测报告</p></div>
      <div :class="{curr:Math.floor(step)==2}"><p>预约专家</p></div>
      <div :class="{curr:Math.floor(step)==3}"><p>预约确认</p></div>
      <div :class="{curr:Math.floor(step)==4}"><p>在线解读</p></div>
    </nav>
    <div class="container">
      <template v-if="step===0">
        <h1 class="h1">根据你的需求选择一款工具进行测评，完成后将为你推送对应的专家进行报告解读</h1>
        <ul class="assess flex">
          <li class="flex" v-for="(item,i) in toolList" :key="i" @click="toolIndex=i" :class="{['tool'+item.tool_id]:toolIndex===i}">
            <img :src="require('../../assets/images/expert/tools_'+item.tool_id+'.png')">
            <div>
              <h2>{{item.name}}</h2>
              <h3>{{item.desc}}</h3>
              <h4>{{item.tips}}</h4>
            </div>
          </li>
        </ul>
        <div class="button" @click="step1_activeTool">下一步</div>
      </template>
      <div class="scan" v-else-if="step==1">
        <h1>微信扫码进入小程序</h1>
        <img v-if="toolId" :src="require('../../assets/images/expert/qrcode_manage_'+toolId+'.jpg')">
        <p v-if="toolId==10">方法：<br>1 登录完成后在页面左下角点击“自评”；<br>2 完成评估后即可预约专家。</p><!-- AMT -->
        <p v-else-if="toolId==12">方法：<br>1 登录完成后邀请至少3位成员完成评估；<br>2 生成团队报告后即可预约专家。</p><!-- LSN -->
        <p v-else-if="toolId==14">方法：<br>1 登录完成后点击右下角“项目自评”开始评估；<br>2 完成评估后即可预约专家。</p>
        <p v-html="reportText"></p><!-- VC -->
        <div class="btn" @click="step1_checkExam">已完成评估，立即预约<span v-show="tipShow">未完成评估</span></div>
      </div>
      <template v-else-if="step==2">
        <h1 class="h1">以下是系统为你推送的专家，请选择进行预约</h1>
        <ul class="expert flex">
          <li v-for="(item,i) in coachesList" :key="i">
            <div class="content">
              <img class="pic" :src="item.image">
              <h1>{{item.realname}}</h1>
              <h2>{{item.title}}</h2>
              <div class="row flex--cen">
                <div class="flex">
                  <div class="star3" v-for="i in 5" :key="i" :class="{star1: i <= item.multi_eval, star2: item.multi_eval < i && item.multi_eval > i-1}"/>
                </div>
                <h3>{{item.multi_eval}}</h3>
                <h4 class="s"><span>{{item.service_price}}元</span>/小时</h4>
              </div>
              <div class="btn" @click="step2_getCoacheTime(item.id)">预约</div>
            </div>
          </li>
        </ul>
      </template>
      <div class="reserve flex-bet" v-else-if="step==2.5">
        <div class="info">
          <div class="back" @click="reChoose"><i class="el-icon-arrow-left"/> 重新选择</div>
          <div class="flex">
            <img class="pic" :src="coachInfo.image">
            <div>
              <h1>{{coachInfo.realname}}</h1>
              <h2>{{coachInfo.title}}</h2>
              <div class="row flex--cen">
                <div class="flex">
                  <div class="star3" v-for="i in 5" :key="i" :class="{star1: i <= coachInfo.multi_eval, star2: coachInfo.multi_eval < i && coachInfo.multi_eval > i-1}"/>
                </div>
                <h3>{{coachInfo.multi_eval}}</h3>
              </div>
              <h4><span>{{coachInfo.service_price}}</span>/小时</h4>
            </div>
          </div>
          <h6>详情介绍</h6>
          <p>{{coachInfo.profile}}</p>
        </div>
        <div class="appoint" v-if="dateList.length">
          <h1>近1周可预约时间</h1>
          <div class="time">
            <template v-if="dateList.length>5">
              <span class="flex-cen-cen" @click="dateNext(-88)">&lt;</span>
              <span class="flex-cen-cen" @click="dateNext(88)">></span>
            </template>
            <ul class="date flex" @wheel="dateScroll" ref="date">
              <li v-for="(item,i) in dateList" :key="i" :class="{disable:item.is_used, active:pickIndex[0]==i && pickText}" @click="datePick(i)">{{item.week}}<br>{{item.date}}</li>
            </ul>
          </div>
          <div class="time">
            <ul class="slot flex">
              <li v-for="(item,j) in dateList[pickIndex[0]].timelist" :key="j" :class="{disable:item.is_used||item.locked, active:pickIndex[1]==j}" @click="timePick(j)" :title="item.locked==1?'仅能预约2小时后的时间段':item.locked==2?'该时段只能在前一天20:00前预约':''">{{item.timerange}}</li>
            </ul>
          </div>
          <p class="text"><span v-if="pickText">已选 “<b>{{pickText}}</b>”</span></p>
          <div class="btnBox flex-cen">
            <span class="btn" :class="{btn0:pickIndex.length<2}" @click="step2_submit">确定预约</span>
          </div>
        </div>
        <div class="appoint flex-cen-cen" v-else>
          <p class="no">暂无可预约时间，<span @click="reChoose">换个专家试试</span></p>
        </div>
      </div>
      <div class="wait" v-else-if="step==3">
        <template v-if="meetInfo.status==-2">
          <img src="../../assets/images/expert/expert_cancel.png">
          <h1 class="red">预约已取消</h1>
        </template>
        <template v-else-if="meetInfo.status==-1">
          <img src="../../assets/images/expert/expert_cancel.png">
          <h1 class="red">预约已取消</h1>
          <p>你预约的专家已被取消，你可以点击<a @click="step3_remark">重新预约</a>其他专家</p>
        </template>
        <template v-else-if="meetInfo.status==1">
          <img src="../../assets/images/expert/expert_success.png">
          <h1 class="greed">预约成功</h1>
          <p>你已成功预约，请在 <b>{{$formatTime(meetInfo.meeting_startime, 'y-m-d h:m')}}</b> 进行在线解读</p>
        </template>
        <template v-else>
          <img src="../../assets/images/expert/expert_wait.png">
          <h1>等待确认</h1>
          <p>你的预约信息已提交，等待专家确认后，系统将会通过站内信及短信方式通知你<br>预约结果可点击<router-link to="/company">用户中心</router-link>前往查看</p>
        </template>
      </div>
      <chat v-else-if="step==4" :fid="meetInfo.from_id" :tid="meetInfo.to_id" :mid="meetInfo.meeting_id"/>
    </div>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import chat from './chat'
export default {
  components: {indexHeader, chat},
  data(){
    return {
      step: '',
      toolIndex: '',    //选择的产品索引
      toolList: [],     //三个产品列表
      toolId: '',       //工具id，用于区别小程序使用方法
      reportText: '',   //进度提示语
      tipShow: false,   //未完成评估提示显隐
      coachesList: [],
      coachInfo: {},
      pickIndex: [0],  //0:日期索引，1：时间段索引
      pickText: '',
      dateList: [],
      meetInfo: {},
    }
  },
  created(){
    this.$request({url: '/CoachesMeeting/getPendingRow',
      success: d => {
        if (d.code == 0) {
          let nowStep = this.step = d.data.step
          this.meetInfo = d.data
          if (nowStep == 0) { //获取产品列表
            this.$request({url: '/CoachesMeeting/getFreeTool',
              success: e => {
                if (e.code == 0) this.toolList = e.data
              },
            })
          } else if (nowStep == 1) {  //检测评估详情
            this.step1_checkExam()
          } else if (nowStep == 2) {  //获取专家列表
            this.step2_getCoaches(d.data.tool_id)
          } else if (nowStep == 3 && d.data.status == 0) {
            this.step3_timer()
          }
        } else {
          this.$message({message: d.msg, type: 'error'})
        }
      },
    })
  },
  methods: {
    step1_activeTool() {
      if (this.toolIndex === '') {
        this.$message({duration: 1000, message: '请选择工具', type: 'warning'})
        return
      }
      let tool = this.toolList[this.toolIndex]
      if (!tool.exam_num && !tool.report_num) {
        this.$message({duration: 3000, message: '无评估数量或评估数量已过期', type: 'error'})
        return
      }
      this.$request({url: '/CoachesMeeting/create',
        data: {
          tool_id: tool.tool_id,
          step: tool.report_num ? 2 : 1,  //判断去哪一步
        },
        success: d => {
          if (d.code == 0) {
            this.meetInfo = d.data
            if (tool.report_num) {
              this.step = 2
              this.step2_getCoaches(tool.tool_id)
            } else {  //进入第一步（未完成评估）
              this.step = 1
              this.reportText = '进度：<br>请先完成评估'
              this.toolId = tool.tool_id
            }
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    step1_checkExam() {
      this.$request({url: '/CoachesMeeting/getExamProcess',
        data: {meeting_id: this.meetInfo.meeting_id},
        success: e => {
          if (e.code == 0) {
            this.reportText = e.data.text
            this.toolId = e.data.tool_id
            if (e.data.is_click) {  //完成评估可进入下一步预约专家
              this.$request({url: '/CoachesMeeting/update',
                data: {
                  meeting_id: this.meetInfo.meeting_id,
                  step: 2,
                },
                success: d => {
                  if (d.code == 0) {
                    this.step = 2
                    this.step2_getCoaches(e.data.tool_id)
                  } else {
                    this.$message({message: d.msg, type: 'error'})
                  }
                },
              })
            } else {
              this.tipShow = true
              this.timer && clearTimeout(this.timer)
              this.timer = setTimeout(() => this.tipShow = false, 2000)
            }
          }
        },
      })
    },
    step2_getCoaches(id) {
      this.$request({url: '/CoachesMeeting/getCoachesByTool',
        data: {tool_id: id},
        success: e => {
          if (e.code == 0) {
            this.coachesList = e.data
          }
        },
      })
    },
    step2_getCoacheTime(id) {
      this.$request({url: '/Coaches/getInfo',
        data: {
          type: 2,
          coaches_id: id,
        },
        success: d => {
          if (d.code == 0) {
            this.step = 2.5
            this.coachInfo = d.data.info
            this.dateList = d.data.datelist
          }
        },
      })
    },
    step2_submit() {
      let [i, j] = this.pickIndex
      this.$request({url: '/CoachesMeeting/update',
        data: {
          meeting_id: this.meetInfo.meeting_id,
          step: 3,
          coaches_id: this.coachInfo.id,
          date_id: this.dateList[i].date_id,
          timerange: this.dateList[i].timelist[j].timerange
        },
        success: d => {
          if (d.code == 0) {
            this.meetInfo = d.data
            this.step = 3
            this.step3_timer()
          } else {
            this.$message({message: d.msg, type: 'error'})
            if (d.code == 6) {
              this.dateList[i].timelist[j].locked = 1
              this.pickIndex = [i]
              this.pickText = `${this.dateList[i].date}`
            }
          }
        },
      })
    },
    step3_timer() {
      this.timer = setInterval(() => {
        this.$request({url: '/CoachesMeeting/checkStatus',
          data: {meeting_id: this.meetInfo.meeting_id},
          success: d => {
            if (d.code == 0) {
              if (d.data.status !== 0) {
                clearInterval(this.timer)
                this.meetInfo.status = d.data.status
                this.$forceUpdate()
              }
            }
          },
        })
      }, 10000)
    },
    step3_remark() {
      this.$request({url: '/CoachesMeeting/remake',
        data: {meeting_id: this.meetInfo.meeting_id},
        success: d => {
          if (d.code == 0) {
            this.reChoose()
            this.meetInfo = d.data
            this.step2_getCoaches(d.data.tool_id)
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    getDate() {
      // const week = ['日', '一', '二', '三', '四', '五', '六']; // 星期
      // const dateList = Array.from({length: 7}, (v, i) => i).map(d => {
      //   let now = new Date()
      //   now.setDate(now.getDate() + d)
      //   let year = now.getFullYear(),
      //       month = now.getMonth()+1,
      //       day = now.getDate(),
      //       obj = {}
      //   obj.long = `${year}年${month}月${day}日`
      //   obj.short = `${month}月${day}日`
      //   obj.week = `星期${week[now.getDay()]}`

      //   return obj
      // })
      // this.dateList = dateList
    },
    datePick(i) {
      if (this.dateList[i].is_used) return
      this.pickIndex = [i]
      this.pickText = this.dateList[i].date
    },
    timePick(j) {
      let i = this.pickIndex[0]
      if (this.dateList[i].timelist[j].is_used || this.dateList[i].timelist[j].locked) return
      this.pickIndex = [i, j]
      this.pickText = `${this.dateList[i].date} ${this.dateList[i].timelist[j].timerange}`
    },
    dateScroll() {
      event.preventDefault()
      let dx = event.deltaY > 0 ? 30 : -30
      this.$refs.date.scrollLeft += dx
    },
    dateNext(n) {
      let ele = this.$refs.date
      ele && ele.scrollTo({left: Math.ceil(ele.scrollLeft/n+0.01) * n, behavior: 'smooth'})
    },
    reChoose() {
      this.step = 2
      this.pickIndex = [0]
      this.pickText = ''
    },
  },
  destroyed() {
    this.timer && clearInterval(this.timer)
  },
}
</script>
<style lang="scss">
.step{
  height: 100%;
  overflow-y: auto;
  padding-top: 50px;
  box-sizing: border-box;
  background: #f9f9f9;
  min-width: 700px;
  nav{
    max-width: 1000px;
    margin: 50px auto 60px;
    div{
      white-space: nowrap;
      width: 24%;
      &:last-child{margin: 0}
      p{
        background: #f0f0f0;
        line-height: 30px;
        font-size: 14px;
        margin-right: 24px;
        padding-left: 24px;
        color: #808080;
        text-align: center;
        position: relative;
        user-select: none;
        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 24px solid #f9f9f9;
        }
        &::after{
          content: '';
          position: absolute;
          left: 100%;
          top: 0;
          border-top: 15px solid transparent;
          border-bottom: 15px solid transparent;
          border-left: 24px solid #f0f0f0;
        }
      }
    }
    .curr p{
      background: #6b6be5;
      color: #fff;
      &::after{border-left-color: #6b6be5}
    }
  }
  .container{
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 20px;
    .h1{
      color: #6b6be5;
      margin-bottom: 30px;
      font-size: 16px;
    }
    .button{
      width: 260px;
      text-align: center;
      color: #fff;
      background: #6b6be5;
      border-radius: 4px;
      margin: 80px auto 0;
      line-height: 40px;
      cursor: pointer;
    }
    //步骤0
    .assess{
      li{
        cursor: pointer;
        background: #fff;
        border-radius: 8px;
        height: 132px;
        padding: 20px 8px 0;
        margin-right: 16px;
        user-select: none;
        border: 2px solid #fff;
        flex: 1;
        box-shadow:  0 2px 8px rgba(171,68,212,.05);
        &:nth-child(2){box-shadow: 0 2px 8px rgba(103,55,185,.05);}
        &:nth-child(3){box-shadow: 0 2px 8px rgba(228,134,78,.05);margin: 0}
        img{
          width: 70px;
          height: 70px;
          margin-right: 6px;
        }
        h2{
          font-size: 18px;
          color: #212121;
          padding-top: 13px;
          white-space: nowrap;
          line-height: 1.6;
        }
        h3{
          font-size: 14px;
          color: #8f8f8f;
          font-weight: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 1.2;
        }
        h4{
          font-size: 14px;
          margin-top: 8px;
          color: #ab44d4;
          font-weight: normal;
        }
      }
      .tool10{border-color: #794fc6};
      .tool12{border-color: #b250d9};
      .tool14{border-color: #f47531};
      @media (max-width: 900px) {
        li{
          height: 100px;
          padding: 10px 4px 0;
          margin-right: 8px;
          img{
            width: 60px;
            height: 60px;
          }
          h2{
            padding-top: 10px;
            font-size: 16px;
          }
          h3, h4{font-size: 12px;}
        }
      }
    }
    //步骤1
    .scan{
      h1{
        color: #6b6be5;
        font-size: 16px;
        margin: 0 0 10px;
        text-align: center;
        user-select: none;
      }
      img{
        width: 160px;
        height: 160px;
        display: block;
        margin: 0 auto 30px;
        border-radius: 4px;
        box-shadow: 0 0 6px rgba(200,200,200,.2);
      }
      p{
        font-size: 14px;
        color: #212121;
        text-align: justify;
        width: 250px;
        line-height: 1.6;
        margin: 8px auto;
        white-space: nowrap;
        span{color: #6b6be5;}
      }
      .btn{
        line-height: 36px;
        background: #6b6be5;
        text-align: center;
        color: #fff;
        border-radius: 4px;
        cursor: pointer;
        width: 250px;
        font-size: 14px;
        margin: 60px auto 30px;
        position: relative;
        white-space: nowrap;
        span{
          position: absolute;
          transform: translateX(-50%);
          left: 50%;
          bottom: 90%;
          color: #ef5e5e;
          font-size: 12px;
        }
      }
    }
    //步骤2
    .expert{
      margin: 0 auto;
      max-width: 1220px;
      flex-wrap: wrap;
      li{
        width: 25%;
        box-sizing: border-box;
        padding: 6px;
        white-space: nowrap;
        .content{
          border-radius: 8px;
          background: #fff;
          overflow: hidden;
          &:hover{box-shadow: 0 0 4px rgba(255,90,0,.1)}
        }
        .pic{
          display: block;
          width: 100%;
          margin-bottom: 12px;
        }
        h1{
          color: #1a1a1a;
          font-size: 16px;
          padding: 0 20px;
          line-height: 2;
        }
        h2{
          font-size: 14px;
          color: #999;
          padding: 0 20px;
          line-height: 2;
        }
        .row{
          padding: 3px 20px;
          .star3{
            width: 18px;
            height: 18px;
            background-image: url('../../assets/images/expert/expert_star3.png');
            background-size: 100% 100%;
          }
          .star1{background-image: url('../../assets/images/expert/expert_star1.png');}
          .star2{background-image: url('../../assets/images/expert/expert_star2.png'); }
          h3{
            font-size: 12px;
            color: #ff5a00;
            font-weight: normal;
            margin: 0 6px;
          }
          h4{
            font-size: 12px;
            color: #999;
            flex: 1;
            text-align: right;
            font-weight: normal;
            span{
              font-size: 16px;
              color: #ff5a00;
            }
          }
          .s{
            text-decoration: line-through;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            position: relative;
            span{color: #999;}
            &:before{
              content: '限时免费';
              position: absolute;
              color: #ff5a00;
              font-weight: bold;
              font-size: 12px;
              top: 100%;
              right: 4%;
            }
          }
        }
        .btn{
          margin: 24px 20px 30px;
          line-height: 32px;
          font-weight: bold;
          background: #f5f5f5;
          text-align: center;
          color: #ff5a00;
          border-radius: 4px;
          font-size: 14px;
          cursor: pointer;
        }
        @media (max-width: 850px) {
          .row{
            flex-wrap: wrap;
            align-items: flex-start;
            h4{flex: 0;margin-top: 10px;}
            .s:before{
              bottom: 4%;
              left: 110%;
            }
          }
        }
      }
    }
    //步骤2.5
    .reserve{
      user-select: none;
      .info{
        background: #fff;
        border-radius: 10px;
        margin-right: 20px;
        padding: 20px 10px 20px 30px;
        box-shadow: 0 0 6px rgba(200,200,200,.1);
        flex: 1;
        .back{
          font-size: 14px;
          width: 80px;
          color: #999;
          margin: -6px 0 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .pic{
          width: 200px;
          height: 200px;
          margin-right: 20px;
          border-radius: 8px;
        }
        h1{
          color: #1a1a1a;
          font-size: 16px;
          line-height: 2;
          margin: 20px 0 4px;
        }
        h2{
          font-size: 14px;
          color: #999;
          line-height: 1.3;
        }
        .row{
          padding: 16px 0;
          .star3{
            width: 18px;
            height: 18px;
            background-image: url('../../assets/images/expert/expert_star3.png');
            background-size: 100% 100%;
          }
          .star1{background-image: url('../../assets/images/expert/expert_star1.png');}
          .star2{background-image: url('../../assets/images/expert/expert_star2.png'); }
          h3{
            font-size: 14px;
            color: #ff5a00;
            font-weight: normal;
            margin: 2px 6px 0;
          }
        }
        h4{
          font-size: 12px;
          color: #999;
          font-weight: normal;
          span{
            font-size: 16px;
            color: #ff5a00;
          }
        }
        .s{
          position: relative;
          display: flex;
          align-items: center;
          text-decoration: line-through;
          span{color: #999;}
          &:before{
            content: '限时免费';
            position: absolute;
            color: #ff5a00;
            font-weight: bold;
            font-size: 12px;
            top: 110%;
            left: 2%;
          }
        }
        h6{
          padding: 20px 0;
          font-size: 16px;
          color: #212121;
          border-bottom: 1px solid #f6f6f6;
          margin-top: 20px;
        }
        p{
          font-size: 14px;
          color: #212121;
          margin: 20px 0;
          white-space: pre-wrap;
        }
      }
      @media (max-width: 940px) {
        .info{
          padding-left: 20px;
          .pic{width: 160px;height: 160px;margin-right: 16px;}
          h1{margin-top: 10px;}
          .row{padding: 10px 0;}
        }
      }
      @media (max-width: 890px) {
        .info{
          & > .flex{flex-direction: column;}
          h6, p{display: none;}
        }
      }
      @media (max-width: 764px) {
        .info{
          .pic{width: 124px;height: 124px;margin-right: 0;}
        }
      }
      .appoint{
        background: #fff;
        border-radius: 10px;
        padding: 20px 10px;
        box-shadow: 0 0 6px rgba(200,200,200,.1);
        h1{
          font-size: 14px;
          color: #212121;
          font-weight: normal;
          margin:  10px 4px 30px; 
        }
        .time{
          font-size: 12px;
          color: #212121;
          padding: 0 30px;
          position: relative;
          margin-bottom: 40px;
          span{
            position: absolute;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background: #f6f6f6;
            font-family: cursive;
            color: #b3b3b3;
            cursor: pointer;
            top: 12px;
            &:nth-child(1){left: 0px;}
            &:nth-child(2){right: 0px;}
          }
          .date{
            width: 440px;
            overflow-x: auto;
            &::-webkit-scrollbar{display: none}
            li{
              flex-shrink: 0;
              border: 1px solid #ccc;
              border-radius: 4px;
              padding: 4px 6px;
              text-align: center;
              width: 80px;
              margin: 0 4px;
              cursor: pointer;
            }
          }
          .slot{
            width: 440px;
            flex-wrap: wrap;
            li{
              flex-shrink: 0;
              border: 1px solid #ccc;
              border-radius: 4px;
              width: 102px;
              text-align: center;
              line-height: 36px;
              margin: 0 4px 16px;
              cursor: pointer;
            }
          }
          .slot, .date{
            .disable{
              background: #f5f5f5;
              color: #b3b3b3;
              opacity: .8;
              cursor: not-allowed;
            }
            .active{border-color: #ff5a00}
          }
        }
        .text{  
          text-align: center;
          font-size: 12px;
          color: #212121;
          height: 40px;
          line-height: 40px;
        }
        .btnBox{
          position: relative;
        }
        .btn{
          width: 200px;
          color: #fff;
          line-height: 36px;
          background: #ff5a00;
          border-radius: 4px;
          text-align: center;
          margin: 0 10px 10px;
          font-size: 14px;
          cursor: pointer;
        }
        .btn0{background: #fcad81;cursor: not-allowed}
        .no{
          width: 440px;
          text-align: center;
          font-size: 14px;
          color: #808080;
          font-weight: normal;
          span{
            cursor: pointer;
            color: #6b6be5;
            &:hover{text-decoration: underline}
          }
        }
      }
    }
    //步骤3
    .wait{
      text-align: center;
      max-width: 600px;
      margin: 0 auto;
      user-select: none;
      img{
        display: block;
        margin: 80px auto 10px;
        width: 80px;
        height: 80px;
      }
      h1{
        font-size: 22px;
        color: #6b6be5;
      }
      .red{color: #ef5e5e;}
      .greed{color: #5abd6d;}
      p{
        font-size: 14px;
        color: #8f8f8f;
        margin-top: 30px;
        a{color: #6b6be5;cursor: pointer;}
        a:hover{text-decoration: underline;}
      }
    }
    //步骤4
    .chat{
      height: auto;
      padding-top: 0;
      margin-top: -10px;
    }
    //步骤5
    .evaluate{margin: 40px auto 20px;}
  }
}
.el-popper[x-placement^=bottom]{margin-top: 0px}
.el-popper[x-placement^=top]{margin-bottom: 0px}
.el-popconfirm__main{margin: 10px 0 20px;}
</style>