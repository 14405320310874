<template>
  <div class="product">
    <indexHeader></indexHeader>
    <div class="banner"></div>
    <p class="desc">我们致力于打造智慧幸福型企业持续赋能的数字化人才发展平台。<br>我们通过数据评估-数据分析-数据应用，为企业的人才决策提供专业支持。<br>我们透过<b>【ALC测学链核心人才赋能体系】</b>与各领域专家为企业提供帮助。</p>
    <ul class="alc">
      <li><p>通过数字化评估系统，对个人和团队进行诊断与分析，发现问题</p></li>
      <li><p>根据评估结果，定制人才发展计划，解决个人与团队成长难题</p></li>
      <li><p>整合国内外学习资源，为打造智慧幸福型企业的人才培养持续赋能</p></li>
    </ul>
    <section>
      <h1>团队活力建设的5W 1H</h1>
      <h2>WHY：团队活力建设、HOW：如何实现、WHAT：团队活力识别、<br>WHERE：应用场景、WHEN：何时使用、WHO：使用对象</h2>
      <ul class="jigsaw">
        <li @mouseover="enter(0)" @mouseleave="leave" :class="{hover: index==0}">
          <img src="../../assets/images/index/jigsaw1.png">
          <div>
            <i></i>
            <span>实现企业的<br>倍速增长</span>
            <i></i>
            <span>建设一支<br>有战斗力的队伍</span>
            <i></i>
            <span>数字化<br>团队活力建设</span>
          </div>
        </li>
        <li @mouseover="enter(1)" @mouseleave="leave" :class="{hover: index==1}">
          <img src="../../assets/images/index/jigsaw2.png">
          <div class="down">
            <i></i>
            <span>三个层面<br>&<br>三个视角</span>
            <i></i>
            <span>三个层面<br>个体：跑得动<br>团队：跑得快<br>管理层：跑得远</span>
            <i></i>
            <span>三个视角<br>个体：工作能量<br>团队：团队活力<br>管理层：领导能量</span>
          </div>
        </li>
        <li @mouseover="enter(2)" @mouseleave="leave" :class="{hover: index==2}">
          <img src="../../assets/images/index/jigsaw3.png">
          <div class="down">
            <i></i>
            <span>个体：工作能量<br>工作价值倾向<br>~原动力<br>~职业素质</span>
            <i></i>
            <span>团队：团队活力<br>~活力因子<br>~团队预警</span>
            <i></i>
            <span>管理层：领导能量<br>~六大领导行为</span>
          </div>
        </li>
        <li @mouseover="enter(3)" @mouseleave="leave" :class="{hover: index==3}">
          <img src="../../assets/images/index/jigsaw4.png">
          <div>
            <i></i>
            <span>CEO：<br>站在新视角来看待组织<br>的增长和绩效的创造</span>
            <i></i>
            <span>业务经理：<br>识别哪些因素<br>有助于或干扰团队的成长</span>
            <i></i>
            <span>HR经理：<br>为组织发展决策<br>提供数据依据</span>
          </div>
        </li>
        <li @mouseover="enter(4)" @mouseleave="leave" :class="{hover: index==4}">
          <img src="../../assets/images/index/jigsaw5.png">
          <div class="down">
            <i></i>
            <span>想要实现业绩突破/<br>倍速增长</span>
            <i></i>
            <span>想要带领团队<br>更上一层楼</span>
            <i></i>
            <span>想要激发创新变革，<br>加速组织转型</span>
          </div>
        </li>
        <li @mouseover="enter(5)" @mouseleave="leave" :class="{hover: index==5}">
          <img src="../../assets/images/index/jigsaw6.png">
          <div class="down">
            <i></i>
            <span>班子成员：<br>增强共识、默契、<br>凝聚力</span>
            <i></i>
            <span>组织：<br>各机构/部门/团队/管理<br>层级的人员，核心人员的<br>发展性盘点</span>
            <i></i>
            <span>核心人才与<br>团队的发展</span>
          </div>
        </li>
      </ul>
    </section>
    <h1 style="margin:140px 0 60px">十方实验室3D模型</h1>
    <div class="model flex-bet-cen">
      <div class="text">
        <p style="margin-bottom:24px">以「诊断-决策-实现」为数字化人才发展的应用模型，精准培养核心管理人才，降低人才成本，提升人才决策效率。</p>
        <p class="dot">诊断（Diagnosis）：数据评估<br>通过工具，协助企业做好诊断工作</p>
        <p class="dot">决策（Decision）：数据分析<br>透过评估结果，提供企业领导者做决策的参考依据</p>
        <p class="dot">实现（Delivery）：数据应用<br>为企业的管理改善、领导力提升、组织变革等，做好专业支撑</p>
      </div>
      <img src="../../assets/images/index/pro_1.png">
    </div>
    <section>
      <h1>我们的理念</h1>
       <div class="idea flex-bet-cen">
        <img src="../../assets/images/index/pro_2.jpg">
        <div class="text">
          <h6>【我们相信】—— </h6>
          <p>“熵减”是企业活力之源，通过激发组织活力，助力企业获得倍速增长。</p>
          <h6>【我们专注】—— </h6>
          <p>组织发展的数据研究与应用，赋能企业组织评估和组织发展，提供高效、便捷、科学和专业化的创新服务，助力点燃组织活力。</p>
          <h6>【我们致力】—— </h6>
          <p>为企业找出在核心人才、团队、组织方面带来熵减效应的活性因子，协助企业从容面对发展挑战，让组织活力助力企业远行。</p>
        </div>
      </div>
    </section>
    <indexFooter></indexFooter>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
export default {
  components:{indexHeader,indexFooter},
  data(){
    return {
      timer: null,
      index: 0,
    }
  },
  mounted(){
    this.leave()
  },
  methods:{
    leave(){
      this.timer = setInterval(()=>{
        this.index = (++this.index) % 6
      }, 3000)
    },
    enter(i){
      this.index = i
      clearInterval(this.timer)
    },
  },
}
</script>
<style lang="scss" scoped>
.product{
  padding-top: 50px;
  height: 100%;
  overflow: auto;
  color: #212121;
  .banner{
    height: 310px;
    background: url('../../assets/images/index/bannerProduct.png') no-repeat;
    background-position: center;
    background-size: auto 100%;
    min-width: 1000px;
  }
  .desc{
    text-align: center;
    line-height: 1.8;
    margin: 120px auto 60px;
    max-width: 1200px;
    min-width: 1000px;
    letter-spacing: 3px;
  }
  .alc{
    display: flex;
    padding: 0 20px;
    max-width: 1200px;
    min-width: 1000px;
    margin: 120px auto 150px;
    justify-content: space-between;
    li{
      width: 290px;
      padding: 80px 0 40px;
      border: 1px solid #ccc;
      position: relative;
      p{
        width: 230px;
        margin: 0 auto;
      }
      &::before{
        content: '';
        position: absolute;
        left: 50%;
        top: -35px;
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background: #ccc;
        transform: translateX(-50%);
        color: #fff;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
        line-height: 68px;
      }
      &:nth-child(1){
        border-color: #6b6be5;
        &::before{
          content: '测';
          box-shadow: 2px 0 10px rgba(111,83,252,.6);
          background-image: linear-gradient(#4141f9,#7777ea)
        }
      }
      &:nth-child(2){
        border-color: #3b90fb;
        &::before{
          content: '学';
          box-shadow: 2px 0 10px rgba(100,154,253,.6);
          background-image: linear-gradient(#3f7ffa,#3898fe)
        }
      }
      &:nth-child(3){
        border-color: #ea6963;
        &::before{
          content: '链';
          box-shadow: 2px 0 10px rgba(239,132,132,.6);
          background-image: linear-gradient(#eb6365,#ec7360)
        }
      }
    }
  }
  section{
    background: #fcfcfc;
    padding: 100px 0px;
    min-width: 1000px;
  }
  h1{
    font-size: 30px;
    text-align: center;
    margin: 20px 0;
    min-width: 1000px;
  }
  h2{
    text-align: center;
    line-height: 1.8;
    font-size: 16px;
    font-weight: normal;
    margin: 40px 0 10px;
  }
  .jigsaw{
    width: 480px;
    height: 450px;
    margin: 0 auto 80px;
    position: relative;
    transform: scale(.82);
    li{
      position: absolute;
      z-index: 2;
      img{
        cursor: pointer;
        transition: transform .2s;
        width: 100%;
        height: 100%;
        position: relative;
      }
      div{
        width: 0;
        overflow: hidden;
        position: absolute;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-align: center;
        font-size: 17px;
        line-height: 1.2;
        transition: all .2s;
        i{
          width: 32px;
          height: 23px;
          background-image: url('../../assets/images/index/row1.png');
          background-repeat: no-repeat;
          background-size: 100%;
          flex-shrink: 0;
          // &:nth-child(3){transform: rotate(90deg);}
        }
        span{
          padding: 0 10px;
          color: #908f8f;
          position: relative;
          flex-shrink: 0;
          margin: 20px 0;
        }
      }
      .down{
        width: auto;
        flex-direction: column;
        height: 0;
        i{transform: rotate(90deg);}
      }
      &:nth-child(1){
        width: 206px;
        height: 159px;
        top: 0;
        left: 136px;
        div{top: 0px;left: 94%;}
        // &:hover{filter: drop-shadow(0px 0 2px rgba(119,119,235,.9));}
      }
      &:nth-child(2){
        width: 196px;
        height: 159px;
        top: 79px;
        right: 8px;
        div{
          top: 0px;
          left: 100%;
          i{background-image: url('../../assets/images/index/row2.png')}
        }
        // &:hover{filter: drop-shadow(0 0 2px rgba(236,107,98,.9));}
      }
      &:nth-child(3){
        width: 195px;
        height: 195px;
        top: 202px;
        right: 8px;
        div{
          top: -122px;
          left: 100%;
          i{background-image: url('../../assets/images/index/row3.png')}
        }
        // &:hover{filter: drop-shadow(0 0 2px rgba(71,192,191,.9));}
      }
      &:nth-child(4){
        width: 186px;
        height: 193px;
        top: 283px;
        left: 148px;
        div{
          top: 128px;
          left: 90%;
        }
      }
      &:nth-child(5){
        width: 198px;
        height: 193px;
        top: 203px;
        left: 7px;
        div{
          top: -122px;
          right: 108%;
          i{
            background-image: url('../../assets/images/index/row3.png');
          }
        }
      }
      &:nth-child(6){
        width: 196px;
        height: 159px;
        top: 79px;
        left: 8px;
        div{
          top: 0px;
          right: 100%;
          i{
            background-image: url('../../assets/images/index/row2.png');
          }
        }
      }
    }
    .hover{
      z-index: 3;
      div{width: 700px}
      .down{
        width: auto;
        height: 700px;
      }
      img{transform: scale(1.04)}
    }
  }
  .model{
    max-width: 1200px;
    min-width: 1000px;
    padding: 40px 16px 120px;
    margin: 0 auto;
    .text{
      width: 38%;
      padding-left: 10px;
      p{
        line-height: 1.6;
        position: relative;
        text-align: justify;
      }
      .dot{
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          left: -16px;
          top: 8px;
          background: #3c3c3c;
        }
      }
    }
    img{width: 56%;}
  }
  .idea{
    max-width: 1200px;
    margin: 80px auto 40px;
    img{
      width: 51%;
      padding-left: 10px;
    }
    .text{
      width: 510px;
      line-height: 1.6;
      padding-right: 10px;
      h6{font-size: 16px;}
      p{
        position: relative;
        text-align: justify;
        margin-bottom: 20px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .product{
    .jigsaw{transform: scale(.68)}
    .idea .text{
      line-height: 1.4;
      p{margin-bottom: 12px}
    }
  }
}
</style>