
<!-- 登录模块 -->
<template>
  <div class="loginBox">
    <h2>注册</h2>
    <nav>
      <span :class="{current:loginType==1}" :title="loginType==2?'切换手机短信登录':''" @click="toggle(1)">手机注册</span>
      <span :class="{current:loginType==2}" :title="loginType==1?'切换使用账号密码登录':''" @click="toggle(2)">微信快捷注册</span>
    </nav>

    <div v-show="loginType==1">
      <div class="input-box" :class="{error:errorType==1}">
          <img src="../../assets/images/login/icon-phone.png">
          <el-select v-model="country_code" @change="select" class="el-select-code">
            <el-option
              v-for="(item,key) in $countryList"
              :key="key"
              :label="item.label"
              :value="item.country_code">
            </el-option>
          </el-select>
          <input 
            class="input" 
            placeholder='请输入手机号' 
            ref="phone" 
            v-model="phone" 
            @input="inputChange($event,'phone')" 
            maxlength="11"
          >
          <div class="input-icon icon-clear" title="清空" v-show="phone" @click="phone=''"></div>
          <div class="errText" v-show="errorType==1">{{errorMsg}}</div>
      </div>
      <div class="input-box">
          <img src="../../assets/images/login/icon-message.png">
          <input class="input"  placeholder='短信验证码' v-model="code" @input="inputChange($event,'code')" ref="code">
          <button 
            @click="getCode" 
            class="btnCode" 
            :data-time='totalTime'
            :class="{disabled: disabled}"
            :disabled='disabled'
          >
            <b :style="{'transition-duration': disabled ? totalTime+'s' : '0s'}"></b>
            <span>{{disabled ? `重新发送（${time}s）` : '发送验证码'}}</span>
          </button>
          <div class="errText" v-show="errorType==2">{{errorMsg}}</div>
      </div>
      <div class="button" @click="register">注册</div>
      <router-link to="/login/companyRegister" replace class="p fl">切换至企业入驻</router-link>
      <router-link to="/login/userLogin" replace class="p fr">已有账号，去登录 ></router-link>
    </div>

    <div v-show="loginType==2" class="mini-code">
      <img src="../../assets/images/code-planet.jpg">
      <div class="text">请打开手机微信扫一扫，扫描上方二维码进行注册</div>
      <router-link to="/login/userLogin" replace class="p">已有账号，去登录 ></router-link>
    </div>
    
  </div>
</template>
<script>
// import indexFooter from '../index/indexFooter'
export default {
  // components:{indexFooter,},
  data(){
    return{
      loginType: 1,             // 1：手机注册，2：微信小程序注册
      logined: false,           // 防止多次点击注册按钮
      country_code: 86,
      reg: this.$CNReg,         // 当前区号的手机号正则
      errorMsg: '',             // 输入框错误信息
      errorType: 0,             // 0：正常无错误，1：手机号错误，2：验证码错误，3：账号为空，4：密码为空
      phone: '',// 手机号码

      code: '',                 // 验证码
      timer: '',                // 验证码定时器
      disabled: false,          // 发送验证码按钮是否可点击
      totalTime: 60,            // 设置多少秒内可以重发验证码
      time: 0,                  // 验证码重发的剩余时间
    }
  },
  created(){
    this.keyup()
  },
  destroyed() {
    this.timer && clearInterval(this.timer);
    document.removeEventListener("keyup",this.keyup,false)
  },
  methods: {
    keyup(){
      document.onkeyup = e => {
        if (e.keyCode == 13 && this.loginType == 1) {
          this.register()
        }
      }
    },
    //切换 短信登录/账号密码登录
    toggle(type){
      if (this.loginType == type)  return;
      this.loginType = type
      this.errorType = 0
      this.phone = ''
      this.code = ''
    },
    //选择区号筛选手机号正则
    select(key){
      this.$countryList.forEach((item) => {
        if(item.country_code==key){
          this.reg = this[item.key]
          return
        }
      })
    },
    //短信登录监听输入内容
    inputChange(e, type = 'phone'){
      if (type == 'phone') {
        this.phone = e.target.value.replace(/\D/g,'')
      } else {
        this.code = e.target.value.replace(/\D/g,'')
      }
      this.errorType = 0;
    },
    //短信登录检验手机号
    phoneCheck(e){
      // return true //测试发送验证码
      if (!this.phone) {
        this.errorMsg = '请先输入手机号码';
        this.errorType = 1;
        this.$refs.phone.focus()
        return false
      } else if (!this.reg.test(this.phone)){
        this.errorMsg = '手机号码格式不对';
        this.errorType = 1;
        this.$refs.phone.focus()
        return false
      } else {
        return true
      }
    },
    //获取验证码
    getCode(){
      if (this.phoneCheck()) {// 成功
        this.$request({
          method: 'post',
          url: '/Sms/getSmsCaptcha',
          data: {
            type: 'register',
            country_code: this.country_code,
            mobile: this.phone,
          },
          success: (data) => {
            if (!data.code) {
              this.$message({duration: 2500,message: '发送成功，请注意查收',type: 'success'})
              this.disabled = true
              this.time = this.totalTime;
              this.timer = setInterval(() => {
                this.time--;
                if (this.time < 0) {
                  clearInterval(this.timer)
                  this.disabled = false
                }
              }, 1000)
            } else {
              this.$message({duration:1000,message:data.msg,type: 'error'})
            }
          },
        })
      }
    },
    //注册
    register(type){
      if (this.logined) {
      } else if (this.loginType == 1) {  //手机注册
        if ( this.phoneCheck() ) {
          if (!this.code) {
            this.errorMsg = '请先输入验证码';
            this.errorType = 2
            this.$refs.code.focus()
          } else if (this.code.length < 4){
            this.errorMsg = '验证码有误';
            this.errorType = 2
            this.$refs.code.focus()
          } else {
            this.logined = true
            this.$request({
              method: 'post',
              url: '/User/register',
              data: {
                captcha: this.code,
                country_code: this.country_code,
                mobile: this.phone,
              },
              success: (data) => {
                if (data.code == 0) {
                  this.$message({duration: 1000,message: '注册成功',type:'success'})
                  localStorage.user_id = data.data.user_id
                  localStorage.mobile = data.data.mobile
                  localStorage.realname = data.data.realname || ''
                  localStorage.avatar = data.data.avatar || ''
                  this.$router.replace({path: '/userCenter'})
                } else if (data.msg.indexOf('已经注册')>-1) {
                  this.errorMsg = '此手机号已注册';
                  this.errorType = 1;
                } else {
                  this.$message.error({duration: 2000,message:  data.msg})
                }
                this.logined = false
              },
              complete: ()=> this.logined = false
            })
          }
        }
      }
    },
  },
}
</script>




