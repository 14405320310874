<template>
  <div class="consult">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_order.png">订单管理
    </h1>
    <div class="move-up"></div>
    <el-table :data="list" tooltip-effect="dark" height="calc(100vh - 208px)" style="width:100%;margin-top:40px">
      <el-table-column prop="order_num" label="订单编号" min-width="158" align="center" show-overflow-tooltip/>
      <el-table-column prop="content" label="产品" min-width="80" align="center" show-overflow-tooltip/>
      <el-table-column label="下单用户" min-width="80" align="center"  show-overflow-tooltip>
        <template slot-scope="scope">
          <span>{{scope.row.realname}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="companyname" label="所属公司" min-width="120" align="center" show-overflow-tooltip/>
      <el-table-column label="下单时间" min-width="130" align="center">
        <template slot-scope="scope">
          <span>{{$formatTime(scope.row.create_time, 'y-m-d h:m')}}</span>
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="60" align="center">
        <template slot-scope="scope">
          <span>{{scope.row.is_free ? '免费' : '付费'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="total_money" label="原价" min-width="70" align="center" show-overflow-tooltip/>
      <el-table-column prop="payment_money" label="实付" min-width="70" align="center" show-overflow-tooltip/>
      <el-table-column label="状态" min-width="70" align="center">
        <template slot-scope="scope">
          <span class="blue" v-if="scope.row.payment_status==1">已支付</span>
          <span class="blue" v-else-if="scope.row.payment_status==2">已退款</span>
          <span class="red" v-else>未支付</span>
        </template>
      </el-table-column>
      
    </el-table>
    <pagination ref="casePage" @change="getList" :total="total"/>
  </div>
</template>
<script>
import pagination from '../../assets/part/page'
export default {
  components: {pagination},
  data(){
    return {
      list: [],
      total: 0,
    }
  },
  created(){
    this.getList(20, 1)
  },
  methods:{
    getList(per_page, page){
      let ref = this.$refs.casePage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$http.post('/Coaches/getOrderList', {
        per_page: per_page,
        page: page
      }).then( ({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else if (data.code == 101) {
          this.$router.replace({path:'/login', query:{back:this.$route.path}})
          localStorage.clear()
        } else {
          this.$message({type: 'error', message:data.msg})
        }
      }, () => {
        this.$message({type:'error', message: '网络异常'})
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.consult{
  nav{
    display: flex;
    margin: 20px 0;
    border-bottom: 1px solid #eceef3;
    user-select: none;
    h2{
      font-weight: normal;
      font-size: 14px;
      margin: 0 10px 0 26px;
      line-height: 50px;
      padding: 0 2px;
      position: relative;
      cursor: pointer;
    }
    .curr{
      color: #6b6be5;
      &::before{
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background: #6b6be5;
        left: 0;
        bottom: 0;
      }
    }
    .unread::after{
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #ff4747;
      right: -6px;
      top: 14px;
    }
  }
}
</style>