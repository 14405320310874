<template>
  <div class="expertBox">
    <h1 class="h1_title">
      <img  class="head" src="../../assets/images/company/nav_box.png">百宝箱
    </h1>
    <div class="move-up"></div>
  </div>
</template>
<script>
// import pagination from '../../assets/part/page'
export default {
  // components: {pagination},
  data(){
    return {
      list: [],
      total: 0,
    }
  },
  created(){
  },
  methods:{
    getList(per_page, page){
      let ref = this.$refs.casePage
      per_page = per_page ? per_page : ref ? ref.per_page : 20;
      page = page ? page : ref ? ref.page : 1;
      this.$http.post('/Coaches/getCaseList', {
        per_page: per_page,
        page: page
      }).then( ({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else if (data.code == 101) {
          this.$router.replace({path:'/login', query:{back:this.$route.path}})
          localStorage.clear()
        } else {
          this.$message({type: 'error', message:data.msg})
        }
      }, () => {
        this.$message({type:'error', message: '网络异常'})
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.expertBox{
}
</style>