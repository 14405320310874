<template>
  <div class="tvr">
    <indexHeader></indexHeader>
    <div class="banner"></div>
    <section class="title">
      <h1>关于团队活力</h1>
      <p>透过活力识别，帮你捕捉团队健康的基本指标提供对团队活力的快速和准确的洞察，并为改进路径提供清晰的反馈和指引帮助你识别哪些因素有助于或干扰团队的成长帮助企业站在新的视角来看待团队的增长和绩效的创造。</p>
    </section>
    <h1>团队活力建设的关键要素</h1>
    <section class="flex-bet-cen">
      <div style="width:39%">
        <h2 style="text-align:right">团队活力因子</h2>
        <p>从凝聚力、承担力、协作力、适应力、自驱力、目标感、价值感7个层面探索团队活力激发程度，综合反映了团队文化、管理机制、管理层的领导风格和能力、以及团队成员的心态和能力水平。</p>
      </div>
      <img style="width:54%" src="../../assets/images/index/pro_tvr_1.png">
    </section>
    <!-- 预警、影响 -->
    <section class="flex-bet-cen" style="margin-top:320px;margin-bottom:100px">
      <div class="risk">
        <div class="flex-cen-cen" :class="{curr:riskIndex==i}" v-for="(item,i) in risk" :key="i" @mouseenter="riskHover(i)" v-html="item.title"></div>
        <ul>
          <li>
            <h6>{{risk[riskIndex].h6}}</h6>
            <p v-html="risk[riskIndex].text"></p>
          </li>
        </ul>
      </div>
      <div style="width:35%;margin-right:140px;">
        <h2>四大预警&可能影响</h2>
        <p>4大团队预警，避免团队发展可能面临的阻碍：状态低迷、人员流失、互动障碍、执行不力。</p>
      </div>
    </section>
    <!-- 领导行为 -->
    <section class="flex-bet-cen" style="margin-top:280px;margin-bottom:120px">
      <div style="width:34%;margin-left:46px">
        <h2 style="text-align:right">六项领导行为提示</h2>
        <p>从以身作则、共启愿景、突破创新、激励沟通、推动执行、确保成效6个领导行为分析管理者的角色认知、领导力认知水平，了解管理者在当下的领导行为是否匹配组织的需要。</p>
      </div>
      <div class="sector">
        <div v-for="(item,i) in leadership" :key="i" @mouseenter="leaderHover(i)" :class="{curr:leadershipIndex==i}">
          <span v-html="item.title"></span>
          <img :src="require(`../../assets/images/index/sector${i}.png`)">
        </div>
        <b>领导行为</b>
        <ul>
          <li>
            <h6>{{leadership[leadershipIndex].h6}}</h6>
            <p v-html="leadership[leadershipIndex].text"></p>
          </li>
        </ul>
      </div>
    </section>
    <!-- TVR分析报告样例 -->
    <section class="sample">
      <h1>TVR分析报告样例</h1>
      <ul>
        <li class="flex" v-for="(item,i) in report" :key="i" :class="'li'+(i+reportIndex)%3">
          <div class="pro flex-cen-cen"></div>
          <div class="text">
            <h5>{{item.title}}</h5>
            <p>{{item.text}}</p>
          </div>
        </li>
        <div class="btn" @click="next"></div>
      </ul>
    </section>
    <!-- TVR在管理中的应用 -->
    <section>
      <h1>TVR在管理中的应用</h1>
      <div class="whw"><img src="../../assets/images/index/pro_tvr_6.png"></div>
    </section>
    <indexFooter :bgcolor="'#f6f6f6'"></indexFooter>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
export default {
  components:{indexHeader,indexFooter},
  data(){
    return {
      risk: [
        {h6:'状态低迷',title:'状态<br>低迷',text:'<b>可能影响</b>：短期内影响个人和团队绩效达成，不利于创新和目标实现；长此以往会影响成员的归属感，对团队的信任和依赖，影响团队稳定性。'},
        {h6:'人员流失',title:'人员<br>流失',text:'<b>可能影响</b>：导致人才成本过高，人才结构失衡，影响企业长期发展；另外，削弱团队士气，引发其他成员的不稳定；部分特殊岗位人才流失，可能对的企业形象、核心能力造成威胁。'},
        {h6:'互动障碍',title:'互动<br>障碍',text:'<b>可能影响</b>：引发团队内部矛盾和消耗，形成部门壁垒，工作成效降低，企业和管理成本增加。'},
        {h6:'执行不力',title:'执行<br>不力',text:'<b>可能影响</b>：工作成效低下，绩效和目标难以完成， 战略实施受阻。'},
      ],
      riskIndex: 0,
      leadership: [
        {h6:'以身作则',title:'以身<br>作则',text:'<b>提示</b>：领导者对自己的行为疏于管理，不能给成员传递正确的工作信念和行为标准，难以在团队中建立威信，树立榜样，削弱领导权威。'},
        {h6:'共启愿景',title:'共启<br>愿景',text:'<b>提示</b>：领导者没有向成员明确未来发展目标，也没有引领大家达成共识。使得团队成员看不清未来的方向和愿景，不能将个人发展与组织发展紧密结合。'},
        {h6:'突破创新',title:'突破<br>创新',text:'<b>提示</b>：领导者受到固有经验或模式的束缚，不会轻易尝试新的方法或策略，新的组织能力长不出来，发展受限。'},
        {h6:'激励沟通',title:'激励<br>沟通',text:'<b>提示</b>：领导者不重视与成员的沟通激励，成员在工作中找不到价值和意义，干劲不足，得过且过。'},
        {h6:'推动执行',title:'推动<br>执行',text:'<b>提示</b>：领导者没有明确的分工及责任划分，会让成员没有明确的方向和目标，也不能及时得到有效的辅导和支持。'},
        {h6:'确保成效',title:'确保<br>成效',text:'<b>提示</b>：领导者不注重结果，忽略对结果的要求，使得团队成员没有责任感、目标感、工作效率不高，影响结果的产出。'},
      ],
      leadershipIndex: 0,
      report: [
        {title: '团队活力分析',text:'从7个方面探索团队活力激发程度，综合反映了团队文化、管理机制、管理层的领导风格和能力、以及团队成员的心态和能力水平。团队活力因子数据分布在五个区间，不同区间反映不同的状态。'},
        {title: '领导能量分析',text:'从6个领导行为分析管理者的角色认知、领导力认知水平，了解管理者在当下的领导行为是否匹配组织的需要，领导行为数据将分布在五个区间，不同区间反映不同的状态。'},
        {title: '管理认知',text:'管理者对管理的理解、态度以及对人的看法，形成一套固有的认知，这直接影响管理行为、决策；了解管理认知能够帮助团队提高管理效率。'},
      ],
      reportIndex: 0,
    }
  },
  mounted(){
    this.riskInterval()
    this.leaderInterval()
    this.repotInterval()
  },
  methods:{
    //风险
      riskInterval(){
        this.run1 = setInterval(()=>{
          this.riskIndex = (++this.riskIndex) % 4
        }, 8000)
      },
      riskHover(i){
        this.riskIndex = i
        clearInterval(this.run1)
      },
    //领导
      leaderInterval(){
        this.run2 = setInterval(()=>{
          this.leadershipIndex = (++this.leadershipIndex) % 6
        }, 8000)
      },
      leaderHover(i){
        this.leadershipIndex = i
        clearInterval(this.run2)
      },
    //报告
      repotInterval(){
        this.run3 = setInterval(()=>{
          this.reportIndex += 2
        }, 10000)
      },
      next(){
        this.reportIndex += 2
        clearInterval(this.run3)
      },
  },
}
</script>
<style lang="scss" scoped>
.tvr{
  color: #212121;
  height: 100%;
  padding-top: 50px;
  overflow-y: auto;
  .banner{
    height: 280px;
    min-width: 800px;
    background: url('../../assets/images/index/bannerTVR.jpg') no-repeat;
    background-size: auto 100%;
    background-position: center top;
  }
  section{
    padding: 0 20px;
    max-width: 1000px;
    min-width: 800px;
    margin: 100px auto;
  }
  h1{
    font-size: 22px;
    margin: 100px 20px 30px;
    text-align: center;
    min-width: 700px;
  }
  h2{
    font-size: 22px;
    margin-bottom: 20px;
    b{font-size: 26px;}
  }
  h6{
    color: #555ffd;
    font-size: 16px;
    font-size: 22px;
  }
  p{text-align: justify;}
  .title{
      p{
        width: 400px;
        margin: 0 auto;
        text-align: center;
        margin-bottom: 160px;
      }
  }
  .risk{
    $width: 220;
    width: $width + px;
    height: $width + px;
    border: 6px solid #9ac7ff;
    border-radius: 50%;
    position: relative;
    margin-left: 60px;
    div{
      width: 60%;
      height: 60%;
      background-image: linear-gradient(#5c46fc,#3d8cf9);
      border-radius: 50%;
      position: absolute;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      transition: all .1s;
      &:nth-child(1){
        left: -15%;
        top: -15%;
        transform-origin: right bottom;
      }
      &:nth-child(2){
        right: -15%;
        top: -15%;
        transform-origin: left bottom;
      }
      &:nth-child(3){
        left: -15%;
        bottom: -15%;
        transform-origin: right top;
      }
      &:nth-child(4){
        right: -15%;
        bottom: -15%;
        transform-origin: left top;
      }
    }
    .curr{transform: scale(1.1)}
    &::after{
      content: '';
      position: absolute;
      width: 50%;
      height: 50%;
      border-radius: 50%;
      background: #ff6757 url('../../assets/images/index/pro_tvr_2.png') no-repeat;
      background-size: 100%;
      background-position: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
    ul{
      position: absolute;
      left: -32%;
      top: -76%;
      width: 178%;
      color: #8f8f8f;
    }
  }
  .sector{
    width: 388px;
    height: 388px;
    position: relative;
    margin-right: 56px;
    div{
      position: absolute;
      cursor: pointer;
      transition: all .1s;
      &:nth-child(1){
        left: 0;
        top: 58.5px;
        width: 188px;
        transform-origin: right bottom;
        span{left: 32px;top: 50px}
      }
      &:nth-child(2){
        left: 0;
        bottom: 58.5px;
        width: 188px;
        transform-origin: right top;
        span{left: 32px;bottom: 50px}
      }
      &:nth-child(3){
        left: 58.5px;
        bottom: 0;
        width: 133px;
        transform-origin: right top;
        span{left: 58px;bottom: 28px}
      }
      &:nth-child(4){
        right: 58.5px;
        bottom: 0;
        width: 133px;
        transform-origin: left top;
        span{right: 58px;bottom: 28px}
      }
      &:nth-child(5){
        right: 0;
        bottom: 58.5px;
        width: 188px;
        transform-origin: left top;
        span{right: 32px;top: 30px}
      }
      &:nth-child(6){
        right: 0;
        top: 58.5px;
        width: 188px;
        transform-origin: left bottom;
        span{right: 32px;top: 50px}
      }
      img{width: 100%;display: block;}
      span{
        position: absolute;
        color: #fff;
        font-size: 18px;
      }
    }
    .curr{transform: scale(1.05);z-index: 10;}
    b{
      position: absolute;
      width: 240px;
      line-height: 240px;
      border-radius: 50%;
      font-size: 32px;
      top: 74px;
      left: 74px;
      text-align: center;
      color: #555ffd;
      background: #f6f0ff;
      z-index: 11;
    }
    ul{
      position: absolute;
      left: -8%;
      top: -12%;
      width: 114%;
      color: #8f8f8f;
    }
  }
  .whw{
    max-width: 1000px;
    display: block;
    margin: 60px auto;
    img{width: 100%}
  }
  .sample{
    background: #f6f6f6;
    max-width: 3000px;
    padding: 1px 0 80px;
    ul{
      max-width: 960px;
      height: 500px;
      margin: 60px auto 0;
      position: relative;
      li{
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 6px;
        overflow: hidden;
        box-sizing: border-box;
        box-shadow: 0 2px 20px rgba(50,50,50,.12);
        transition: transform .3s;
        &:nth-child(2) .pro{
          background-image: url('../../assets/images/index/pro_tvr_4.jpg');
        }
        &:nth-child(3) .pro{
          background-image: url('../../assets/images/index/pro_tvr_5.jpg');
        }
      }
      .li0{
        z-index: 3;
        transform: translateY(0) scale(1);
        border-top: none;
      }
      .li1{
        z-index: 2;
        transform: translateY(-25px) scale(.95);
        border-top: 16px solid #6e45e2;
        transition-delay: .1s;
      }
      .li2{
        z-index: 1;
        transform: translateY(-48px) scale(.9);
        border-top: 16px solid #b09be9;
        transition: all 0s;
      }
      .pro{
        height: 100%;
        flex: 1;
        background-color: #f2f9ff;
        background-repeat: no-repeat;
        background-image: url('../../assets/images/index/pro_tvr_3.jpg');
        img{height: 100%;}
        background-size: 100% auto;
        background-position: center;
      }
      .text{
        width: 300px;
        background: #3d388e;
        color: #fff;
        padding: 0 40px;
        cursor: default;
        h5{
          font-size: 18px;
          margin: 50px 0 40px;
          text-align: center;
        }
        p{
          font-size: 18px;
          line-height: 1.6;
        }
      }
      .btn{
        position: absolute;
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: #fff url('../../assets/images/index/row.png') no-repeat;
        background-position: center;
        box-shadow: 0 0 10px rgba(204,204,233,.5);
        right: -27px;
        top: 215px;
        z-index: 11;
        cursor: pointer;
      }
    }
    @media (max-width: 1025px) {
      ul{max-width: 800px;}
    }
  }

  @media screen and (min-width: 1600px) {
    .banner{
      background-size: 100% auto
    }
  }
}
</style>