<template>
    <div class="login">
        <div class="center">
            <router-link to="/" class="logo-row">
                <img src="../../assets/images/logo-small.png">十方实验室
            </router-link>
            <router-view></router-view>
        </div>
        <indexFooter :position="'fixed'"></indexFooter>
    </div>
</template>

<script>
import indexFooter from '../index/indexFooter'
export default {
    components:{indexFooter,},
}
</script>

<style lang="scss">
.login{
    background: url('../../assets/images/login/bg-login.jpg') no-repeat center;
    background-size: 100% 100%;
    height: 100%;
    width: 100%;
    font-size: 14px;
    position: relative;
    color:#6e6e6e;
    padding-top: 0;
    position: relative;
    overflow: hidden;
    transition: all .6s;
    *{transition: all .4s;}
    .center{
        position: absolute;
        // width: 480px;
        min-width: 300px;
        height: 660px;
        left: 50%;
        top: 45%;
        transform: translate(-50%,-50%);
        box-sizing: border-box;
        z-index: 2;
        .logo-row{
            width: 150px;
            margin: 40px auto;
            font-size: 20px;
            img{
                display: block;
                width: 32px;
                height: 32px;
                margin-right: 8px;
            }
        }
        .loginBox{
            width: 480px;
            background: #fff;
            border-radius: 4px;
            padding: 20px 50px;
            margin: 0 auto;
            height: 525px;
            box-sizing: border-box;
            box-shadow: 0 2px 20px rgba($color: #9e9e9e, $alpha: 0.18);
            overflow: hidden;
            position: relative;
            animation: login .4s;
            h2{
                text-align: center;
                color: #2b2b2b;
                font-size: 20px;
                line-height: 40px;
                margin-bottom: 20px;
                user-select: none;
            }
            nav{
                display: flex;
                justify-content: space-between;
                span{
                    width: 176px;
                    border: 1px solid #eceef3;
                    border-radius: 4px;
                    color: #b5b5b5;
                    line-height: 40px;
                    text-align: center;
                    cursor: pointer;
                    white-space: nowrap;
                    &:hover{
                        border-color: #d6dae4;
                        color: #868686;
                    }
                }
                .current{
                    border-color: #6b6be5;
                    color: #6b6be5;
                    cursor: default;
                    &:hover{
                        cursor: default;
                        border-color: #6b6be5;
                        color: #6b6be5
                    }
                }
            }
            .errText{
                position: absolute;
                bottom: -20px;
                font-size: 12px;
                color: #ff6265;
                left: 50%;
                user-select: none;
                transform: translateX(-50%);
                white-space: nowrap;
            }
            .input-box{
                display: flex;
                align-items: center;
                height: 42px;
                border: 1px solid #eceef3;
                border-radius: 4px;
                margin: 24px 0;
                position: relative;
                -webkit-transform-style: preserve-3d;
                &:hover{
                    border-color: #d6dae4;
                    .disabled{border-color: #d6dae4;}
                    .input-icon{display: block;}
                }
                img{
                    width: 18px;
                    height: 18px;
                    margin: 0 10px;
                }
                .input{
                    flex: 1;
                    border: none;
                    height: 100%;
                    outline: none;
                    margin: 0 6px;
                    box-sizing: border-box;
                    padding-top: 2px;
                    color: #666;
                }
                .btnCode{
                    position: absolute;
                    height: 42px;
                    width: 140px;
                    border: 1px solid #6b6be5;
                    color: #6b6be5;
                    border-top-right-radius: 4px;
                    border-bottom-right-radius: 4px;
                    right: -1px;
                    top: -1px;
                    background: none;
                    outline: none;
                    overflow: hidden;
                    span{
                        line-height: 40px;
                        display: block;
                        position: relative;
                        z-index: 2;
                    }
                    &:hover{
                        cursor: pointer;
                        background: #fafaff;
                    }
                    b{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background: #f5f5ff;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 0;
                        transition-property: width;
                        transition-timing-function: linear;
                        opacity: 0;
                    }
                }
                .disabled{
                    border-color: #eceef3;color: #b5b5b5;
                    &:hover{cursor: not-allowed;background: none;}
                    b{width: 0;opacity: 1;}
                }
            }
            .error{
                border-color: #ff6265 !important;
                &:hover{
                    border-color: #ff6265 !important;
                }
            }
        }
        .button{
            line-height: 40px;
            border-radius: 4px;
            background: #6b6be5;
            color: #fff;
            text-align: center;
            margin: 40px 0 10px;
            cursor: pointer;
        }
        .p{
            color: #6b6be5;
            font-size: 12px;
            margin: 10px 10px 0;
            padding-bottom: 5px;
            cursor: pointer;
            // &:hover{text-decoration: underline;}
        }
        .more{
            display: flex;
            justify-content: space-around;
            border-top: 1px solid #eceef3;
            margin: 70px 0 10px;
            li{
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                margin-top: 20px;
                border-radius: 4px;
                padding: 4px;
                img{
                    width: 40px;
                    height: 40px;
                    margin-bottom: 4px;
                }
                span{
                    color: #b5b5b5;
                    white-space: nowrap;
                }
                &:hover{
                    background: #f2f2f2;
                }
            }
        }
        .other{
            width: 100%;
            position: absolute;
            height: 450px;
            left: 0;
            bottom: -100%;
            background: #fff;
            z-index: 3;
            .wechat{
                position: relative;
                width: 220px;
                height: 220px;
                margin: 40px auto 0;
                box-sizing: border-box;
                background: #f6f8fa;
                box-shadow: 0 0 6px 0 rgba($color: #bebeee, $alpha: 0.3);
                .qrcode{
                    width: 220px;
                    display: block;
                    padding: 20px;
                    background: #fff;
                }
                .line{
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #ccc;
                    top: 12%;
                    animation: codeScan 8s infinite linear;
                }
                .line{
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #ccc;
                    top: 12%;
                    animation: codeScan 4s infinite linear;
                }
                i{
                    position: absolute;
                    width: 20px;
                    height: 20px;
                }
                i:nth-child(1){
                    top: 0;
                    border-top: 2px solid #ccc;
                    left: 0;
                    border-left: 2px solid #ccc;
                }
                i:nth-child(2){
                    top: 0;
                    border-top: 2px solid #ccc;
                    right: 0;
                    border-right: 2px solid #ccc;
                }
                i:nth-child(3){
                    bottom: 0;
                    border-bottom: 2px solid #ccc;
                    left: 0;
                    border-left: 2px solid #ccc;
                }
                i:nth-child(4){
                    bottom: 0;
                    border-bottom: 2px solid #ccc;
                    right: 0;
                    border-right: 2px solid #ccc;
                }
            }
            h5{
                position: absolute;
                left: 50%;
                bottom: 30px;
                transform: translateX(-50%);
                padding: 2px 6px;
                font-size: 14px;
                cursor: pointer;
                font-weight: normal;
                color: #ccc;
                &:hover{text-decoration: underline}
            }
        }
        .text{  //二维码下面文字说明
            font-size: 14px;
            color: #999;
            text-align: center;
            margin: 30px auto;
            width: 220px;
        }
    }
}
@keyframes codeScan {
    0%{top: 12%}
    50%{top: 88%}
    100%{top: 12%}
}
@keyframes login {
    0%{transform: scale(.6,.6);opacity: .1;}
    100%{transform: scale(1,1);opacity: 1;}
}
@media (max-width: 1000px) {
    .login{
        background-size: 120% 100%;
    }
}
@media (max-width: 400px) {
    .login{
        .center{
            width: 100%;
            .loginBox{
                width: 100%;
                padding: 20px 0;
                .input{width: 40%;}
                nav span{width:140px;}
                .input-box .btnCode{width: 100px;}
            }
        }
    }
}

@media (max-height: 680px) {
    .login{
        .center{
            top: 51%;
            .logo-row{margin-bottom: 16px}
        }
    }
    .footer{display: none}
}


//用户注册
.mini-code{
    width: 220px;
    height: 220px;
    margin: 40px auto 0;
    text-align: center;
    .p{text-align: center;}
}
</style>
