<template>
  <div class="expert">
    <!-- 顶部条 -->
    <company_header ref="header"/>
    <div class="view">
      <!-- 导航 -->
      <expert_nav ref="nav"/>
      <!-- 内容 -->
      <router-view class="main"/>
    </div>
  </div>
</template>
<script>
import company_header from '../company/header'
import expert_nav from './expert_nav'
export default {
  components: {company_header,expert_nav},
  created() {
    if (localStorage.authority < 1004) {//没有教练权限
      this.$router.replace({path: '/'})
    }
  },
}
</script>
<style lang="scss">
.expert{
  height: 100%;
  overflow-y: auto;
  .view{
    height: 100%;
    display: flex;
    padding-top: 48px;
    .main{
      flex: 1;
      min-width: 400px;
      padding: 0 40px 30px;
      position: relative;
      overflow-y: auto;
    }
    nav{
      display: flex;
      margin: 20px 0;
      border-bottom: 1px solid #eceef3;
      user-select: none;
      white-space: nowrap;
      h2{
        font-weight: normal;
        font-size: 14px;
        margin: 0 10px 0 26px;
        line-height: 50px;
        padding: 0 2px;
        position: relative;
        cursor: pointer;
      }
      .curr{
        color: #6b6be5;
        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          border-radius: 1px;
          background: #6b6be5;
          left: 0;
          bottom: 0;
        }
      }
      .unread::after{
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff4747;
        right: -6px;
        top: 14px;
      }
    }
  }
}
@media (max-width: 1200px) {
  .view .main{padding-left: 30px;padding-right: 30px;}
}
@media (max-width: 1100px) {
  .pagination{
    padding-left: 180px;
  }
}
</style>
