<template>
  <div class="basic">
    <div class="tip" :style="{top:expire.number ? '16px' : '-60px'}">
      <img src="../../../assets/images/company/icon-warning.png">
      <p>将有{{expire.number}}个邀请数量在{{expire.day}}天后到期，请尽快使用</p> 
      <span @click="close">X</span>
    </div>
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_index.png">概况
    </h1>
    <div class="move-up"></div>
    <h2 class="flex--cen"><i></i>详情统计</h2>
    <div class="statistics flex-bet-cen">
      <div class="ring flex--cen">
        <ring :data="rate1"></ring>
        <ul>
          <li>购买总数 <span>{{number.total_number}}</span></li>
          <li>可邀请数 <span>{{number.may_invite_num}}</span></li>
          <li>已邀请数 <span>{{number.invited_num}}</span></li>
        </ul>
        <router-link to="/tvr/team" class="number flex-cen-cen">
          <div class="pic flex-cen-cen"><img src="../../../assets/images/company/icon-team.png"></div>
          <p><b class="darkBlue">{{number.team_number}}</b>团队总数</p>
        </router-link>
      </div>
      <div class="ring flex--cen">
        <ring :data="rate2" :type="1"></ring>
        <ul>
          <li>未接受数 <span style="color:#fc7b7e">{{number.unaccepted_num}}</span></li>
          <li>已接受数 <span style="color:#fc7b7e">{{number.accepted_num}}</span></li>
        </ul>
        <router-link to="/tvr/staff" class="number flex-cen-cen">
          <div class="pic flex-cen-cen" style="background:#f97286"><img src="../../../assets/images/company/icon-staff.png"></div>
          <p><b class="pink">{{number.member_number}}</b>成员总数</p>
        </router-link>
      </div>
      <div class="ring flex--cen">
        <ring :data="rate3" :type="2"></ring>
        <ul>
          <li>未完成数 <span style="color:#6283e4">{{number.not_complete_num}}</span></li>
          <li>已完成数 <span style="color:#6283e4">{{number.complete_num}}</span></li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import ring from '../../../assets/part/companyRingChart'
export default {
  components: {ring},
  data(){
    return {
      number: {
        total_number: 0,        //购买总数
        invited_num: 0,         //已发出邀请数
        may_invite_num: 0,      //可邀请数
        accepted_num: 0,        //已接受邀请数
        unaccepted_num: 0,      //未接受数
        complete_num: 0,        //完成数
        not_complete_num: 0,    //未完成数
        team_number: 0,         //团队数
        member_number: 0,       //成员数
      },
      rate1: 0,           //使用率
      rate2: 0,           //接受率
      rate3: 0,           //完成率
      expire: {},
    }
  },
  mounted(){
    this.$request({url: '/index/getTvrStatistics',
      success: (data) => {
        if (data.code == 0) {
          let num = data.data
          this.number = num
          this.rate1 = num.total_number ? 100-Math.round(num.may_invite_num / num.total_number * 100) : 0
          this.rate2 = Math.round(num.accepted_num / num.invited_num * 100 || 0)
          this.rate3 = Math.round(num.complete_num / num.accepted_num * 100 || 0)
        } else if (data.code == 101) {
          this.$router.replace({path:'/login',query:{back:this.$route.path}})
          localStorage.clear();
        } else {
          this.$message({duration:2000,message:data.msg,type:'error'})
        }
      },
    })
    this.$request({url: '/index/checkExpireNumber',
      data: {tool_id: 5},
      success: (data) => {
        if (data.code == 9) {
          this.expire = data.data
        }
      },
    })
  },
  methods: {
    close(){
      this.expire = false
    },
  }
}
</script>

<style lang="scss" scoped>
.basic{
  overflow: auto !important;
  height: calc(100vh - 48px);
  position: relative;
  .tip{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 40px 0 48px;
    border-radius: 4px;
    border: 1px solid #e5775e;
    background: #fff0f0;
    color: #999;
    font-size: 14px;
    transition: top .4s;
    cursor: default;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    p{
      flex: 1;
      min-width: 400px;
      white-space: nowrap;
    }
    span{
      color: #e5775e;
      font-size: 16px;
      padding: 10px;
      padding-top: 9px;
      user-select: none;
      cursor: pointer;
    }
  }
  h2{
    font-size: 15px;
    color: #2b2b2b;
    margin: 25px 0;
    i{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #6b6be5;
      margin: 0 6px 0 4px;
    }
  }
  .statistics{
    margin: 30px 10px;
    .ring{
      position: relative;
      user-select: none;
      ul{
        font-size: 14px;
        margin-left: 10px;
        li{
          line-height: 1.9em;
          white-space: nowrap;
          span{color: #6b6be5}
        }
      }
      .number{
        position: absolute;
        width: 100%;
        top: 160%;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        padding-right: 8px;
        cursor: pointer;
        .pic{
          width: 22%;
          height: 60px;
          border-radius: 4px;
          background: #6b6be5;
          margin-right: 4%;
          min-width: 50px;
          img{width: 44%}
        }
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 2%;
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .admin .head span{width: 60px}
}
</style>