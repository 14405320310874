<template>
  <div class="edit">
    <h1 class="h1_title">
      <img src="../../assets/images/company/nav_edit.png">信息管理
    </h1>
    <div class="move-up"/>
    <nav>
      <h2 :class="{curr:tab=='baseinfo'}" @click="change('baseinfo')">基本资料</h2>
      <h2 :class="{curr:tab=='paytime'}" @click="change('paytime')">付费时间设置</h2>
      <h2 :class="{curr:tab=='freetime'}" @click="change('freetime')">免费时间设置</h2>
      <h2 :class="{curr:tab=='cardset'}" @click="change('cardset')">名片设置</h2>
      <h2 :class="{curr:tab=='tencent'}" @click="change('tencent')">会议号</h2>
    </nav>
    <section v-if="tab=='baseinfo'">
      <div class="row flex--cen">
        <h3>头像</h3>
        <div class="upBtn flex--cen" @click="headShow=true">
          <img :src="info.avatar||require('../../assets/images/headLarge.png')" class="head">
          <h4>选择照片上传</h4>
        </div>
        <!-- <span class="txt">仅支持JPG,GIF,PNG格式上传</span> -->
      </div>
      <div class="row flex--cen">
        <h3>咨询价格</h3>
        <input class="input" type="text" v-model="info.service_price" @input="inputChange('price')">
      </div>
      <div class="row flex--cen">
        <h3>真实姓名</h3>
        <input class="input" type="text" v-model="info.realname">
      </div>
      <div class="flex row">
        <h3 style="margin-top:12px">个人简介</h3>
        <textarea v-model="info.profile"/>
      </div>
      <div class="bottom-btn" @click="save">保存</div>
    </section>
    <section v-else-if="tab=='paytime'||tab=='freetime'" style="min-width:550px">
      <div class="row flex--cen">
        <h3>时间设置</h3>
        <el-date-picker v-model="timeset.date" type="date"  placeholder="选择日期" :picker-options="dateOptions" @change="datePick"/>
        <el-time-select v-model="timeset.time" :picker-options="timeOptions" placeholder="选择时间" @change="timePick"/>
        <div class="addBtn" @click="addDate">添加</div>
      </div>
      <div class="row flex">
        <h3 style="margin-top:10px">预约时间</h3>
        <dl class="slot">
          <div v-for="(item, i) in timeList" :key="i" class="flex">
            <dt>
              <el-tag effect="plain" closable @close="delDate(i)">{{item.date_str}}</el-tag>
            </dt>
            <dd>
              <el-tag effect="plain" closable disable-transitions v-for="(it, j) in item.timelist" :key="j" @close="delDate(i,j)" :type="it.is_used?'success':''">{{it.timerange}}</el-tag>
            </dd>
          </div>
        </dl>
      </div>
      <div class="bottom-btn" @click="save" v-show="timeList.length">保存</div>
    </section>
    <section v-else-if="tab=='cardset'" style="width:500px">
      <div class="row flex--cen">
        <h3>手机号</h3>
        <input class="input" type="text" v-model="info.mobile">
      </div>
      <div class="row flex--cen">
        <h3>微信</h3>
        <input class="input" type="text" v-model="info.wx_number">
      </div>
      <div class="row flex--cen">
        <h3>邮箱</h3>
        <input class="input" type="text" v-model="info.email">
      </div>
      <div class="bottom-btn" @click="save">保存</div>
    </section>
    <section v-else-if="tab=='tencent'" style="width:500px">
      <div class="row flex--cen">
        <h3>会议号</h3>
        <input class="input" type="text" v-model="info.tencent_id">
      </div>
      <div class="bottom-btn" @click="save">保存</div>
    </section>
    
    <!-- 修改头像 -->
    <el-dialog
      title="修改头像"
      :visible.sync="headShow"
      :close-on-click-modal="false"
      @close="closeLogo"
      center>
      <p>仅支持JPG,GIF,PNG格式上传<br>建议图片尺寸为80px*80px</p>
      <div class="cut_box">
        <canvas ref="canvas" width="80" height="80"></canvas>
        <div class="left flex-cen-cen">
          <img ref="source" :src="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
          <div class="up" v-if="logo.src" :style="{width:`${logo.width}px`,height:`${logo.height}px`}">
            <div class="clip" :style="{width:`${clipsize}px`,height:`${clipsize}px`,left:logo.x+'px',top:logo.y+'px'}"  @mousedown="mousedown" @mousewheel="mousewheel"></div>
          </div>
        </div>
        <div class="right">
          <div class="preview">
            <p>预览</p>
            <div class="img">
              <img v-if="logo.src" :src="logo.src" :style="{
                width:`${logo.viewScale*logo.width}px`,
                height:`${logo.viewScale*logo.height}px`,
                transform: `translate(-${logo.viewScale*logo.x}px,-${logo.viewScale*logo.y}px)`
              }">
            </div>
          </div>
          <div class="chooseBtn">
            {{logo.src ? '重新选择' : '选择图片'}}
            <input type="file" accept="image/png,image/bmp,image/jpeg,image/jpg,image/gif" id="imgBtn" @change="changeLogo">
          </div>
        </div>
      </div>
      <div class="bottom-btn" @click="uploadLogo">确定</div>
    </el-dialog>
  </div>
</template>
<script>
const Week = ['日', '一', '二', '三', '四', '五', '六']
export default {
  data(){
    return {
      tab: '', //baseinfo-基本信息,paytime-付费时间,freetime-免费时间,cardset-名片信息,tencent-腾讯会议号
      info: {},
      headShow: false,
      logo: {
        image: '',      //要上传的图片
        src: '',
        width: 0,
        height: 0,
        x: 0,
        y: 0,
        size: 250,      //左框的大小
        viewScale: 1,
      },
      clipsize: 0,      //裁剪框的宽高
      timeset: {date:'',time:'',timeStr:''},
      dateOptions: {
        disabledDate(time) {//只能选今天起7天内的日期
          let t = time.getTime(), 
              now = new Date(), //当前时间格式
              timestamp = now.getTime(),//当前时间戳
              start = 0,
              end = 0;
          if (now.getHours() > 19) {//超过20:00只能设置明天开始的时间段
            start = timestamp
            end = timestamp + 8.64e7*7
          } else {
            start = timestamp - 8.64e7
            end = timestamp + 8.64e7*6
          }
          return t < start || t > end
        },
      },
      timeOptions: {start: '09:00', step: '00:30', end: '22:00'},
      timeList: [],
      delDateId: [],
    }
  },
  created() {
    this.change('baseinfo')
  },
  mounted() {},
  methods: {
    change(e) {
      if (this.tab == e) return
      this.tab = e
      this.$request({url: '/Coaches/getConfigData',
        data: {type: e},
        success: d => {
          if (d.code == 0) {
            if (e == 'paytime' || e == 'freetime') {
              this.timeList = d.data
              this.timeset = {date:'',time:'',timeStr:''}
            } else {
              this.info = d.data
            }
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    datePick(e) {
      this.timeset.timeStr = ''
      this.timeset.time = ''
      if (e) {
        //获取可选时间段
        let now = new Date()
        if (now.toDateString() == e.toDateString()) {
          let hour = now.getHours(), 
              minute = now.getMinutes(),
              n = hour + (minute > 30 ? 3 : 2);
          this.timeOptions.start = `${n<10 ? '0'+n : n}:${minute>0&&minute<=30 ? '30' : '00'}`
        } else {
          this.timeOptions.start = '09:00'
        }

        let year = e.getFullYear(), month = e.getMonth() + 1, day = e.getDate()
        this.timeset.timeStr = `${year}-${month<10?'0'+month:month}-${day<10?'0'+day:day} 星期${Week[e.getDay()]}`
      }
      
    },
    timePick(e) {
      if (e) {
        let [hour, minute] = e.split(':'), after = parseInt(hour) + 1
        hour = after < 10 ? '0'+after : after
        this.timeset.time += `~${hour}:${minute}`
      }
    },
    addDate() {
      if (this.timeset.time && this.timeset.timeStr) {
        let list = this.timeList
        for (let i in list) {
          if (list[i].date_str == this.timeset.timeStr) {
            for (let it of list[i].timelist) {
              if (it.timerange == this.timeset.time) {
                return
              } else if (Math.abs(parseFloat(this.timeset.time.replace(':','')) - parseFloat(it.timerange.replace(':',''))) < 100) {
                this.$message({message: '时间间隔太近', type: 'warning'})
                return
              }
            }
            list[i].timelist.push({timerange: this.timeset.time})
            this.timeList[i].timelist = list[i].timelist.sort((a, b) => b.timerange > a.timerange ? -1 : 1)
            return
          }
        }
        list.push({date_str: this.timeset.timeStr, timelist:[{timerange: this.timeset.time}]})
        this.timeList = list.sort((a, b) => b.date_str > a.date_str ? -1 : 1)
      }
    },
    delDate(i, j) {
      if (j == undefined || this.timeList[i].timelist.length == 1) {  //删日期
        for (let item of this.timeList[i].timelist) {
          if (item.is_used) {
            this.$message({message: '该时段已有人预约', type: 'warning'})
            return
          }
        }
        if (this.timeList[i].date_id) {
          this.delDateId.push(this.timeList[i].date_id)
        }
        this.timeList.splice(i, 1)
      } else {  //删时间段
        if (this.timeList[i].timelist[j].is_used) {
          this.$message({message: '该时段已有人预约', type: 'warning'})
          return
        }
        this.timeList[i].timelist.splice(j, 1)
      }
    },
    closeLogo(){
      this.logo = {src:'',width:0,height:0,x:0,y:0,size:250,viewScale:1}
    },
    //选择图片
    changeLogo(ev){
      this.closeLogo()
      let file = ev.target.files[0]
      // this.logo.src = URL.createObjectURL(file);return;//预览
      if (!file) {
        return
      } else if (file.size / 1024 / 1024 > 2) {
        this.$message({duration:2000, message:'文件过大，请重新选择', type:'warning'})
        return
      }
      let img = new Image()
      img.src = this.logo.src = URL.createObjectURL(file)
      img.onload = () => {
        if (img.width < img.height) {
          let scale = this.logo.size / img.height
          this.logo.height =  this.logo.size
          this.logo.width = this.clipsize = this.logo.max = Math.floor(scale * img.width)
        } else {
          let scale = this.logo.size / img.width
          this.logo.width =  this.logo.size
          this.logo.height = this.clipsize = this.logo.max = Math.floor(scale * img.height)
        }
        this.logo.viewScale = 80 / this.clipsize
        this.logo.name = file.name
        this.logo.image = img
      }
      ev.target.value = ''
    },
    mousedown(e) {
      if (this.logo.src) {
        let startx = this.logo.x, starty = this.logo.y;
        document.onmousemove = ev => {
          let moveX = ev.screenX - e.screenX + startx, 
              moveY = ev.screenY - e.screenY + starty;
          this.logo.x = moveX < 0 ? 0 : moveX > (this.logo.width - e.target.offsetWidth) ? this.logo.width - e.target.offsetWidth : moveX
          this.logo.y = moveY < 0 ? 0 : moveY > (this.logo.height - e.target.offsetHeight) ? this.logo.height - e.target.offsetHeight : moveY
        }
        document.onmouseup = () => {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    mousewheel(e) {
      let res = this.clipsize + e.wheelDelta / 20
      this.clipsize = res > this.logo.max ? this.logo.max : res < 80 ? 80 : res
      this.logo.viewScale = 80 / this.clipsize
      if ((e.target.offsetLeft + this.clipsize) > this.logo.width)  //控制left
        this.logo.x = this.logo.width - this.clipsize
      if ((e.target.offsetTop + this.clipsize) > this.logo.height)  //控制top
        this.logo.y = this.logo.height - this.clipsize
    },
    //上传头像
    uploadLogo(){
      if (this.logo.image) {
        let canvas = this.$refs.canvas, 
            ctx = canvas.getContext('2d'),
            logo = this.logo;
        ctx.clearRect(0, 0, 80, 80)
        ctx.drawImage(
          logo.image,
          -logo.viewScale*logo.x, -logo.viewScale*logo.y,
          logo.viewScale*logo.width, logo.viewScale*logo.height
        );
        canvas.toBlob((blob) => {
          let formData = new FormData()
          let file = new File([blob], logo.name)
          formData.append('avatar', file)
          this.$request({
            method: 'post',
            url: '/Coaches/uploadAvatar',
            data: formData,
            success: d => {
              if (d.code == 0) {
                this.headShow = false
                this.info.avatar = d.data.save_path
              } else {
                this.$message({duration: 2000, message: d.msg, type:'error'})
              }
            },
          })
        })
      } else {
        this.$message({duration:2000,message:'请先选择图片',type:'warning'})
      }
    },
    save() {
      if (this.tab == 'paytime' || this.tab == 'freetime') {
        this.$request({url: '/Coaches/setTime', 
          data: {
            type: this.tab == 'freetime' ? 2 : 1,
            del_date: JSON.stringify(this.delDateId),
            data: JSON.stringify(this.timeList)
          }, 
          success: d => {
            if (d.code == 0) {
              this.delDateId = []
              this.$message({message: '保存成功', type: 'success'})
            } else {
              this.$message({message: d.msg, type: 'error'})
            }
          },
        })
        return
      }
      if (this.tab == 'cardset') {
        if (!this.$checkPhone(this.info.mobile)) {
          this.$message({message: '请输入正确手机号', type: 'warning'})
          return
        }
        if (!this.$checkEmail(this.info.email)) {
          this.$message({message: '请输入正确邮箱', type: 'warning'})
          return
        }
      }
      if (this.tab == 'baseinfo') {
        if (parseFloat(this.info.service_price) == 0) {
          this.$message({message: '请输入咨询价格', type: 'warning'})
          return
        }
      }
      this.$request({url: '/Coaches/setConfigData', data: this.info, 
        success: d => {
          if (d.code == 0) {
            this.$message({message: '保存成功', type: 'success'})
            if (this.tab == 'baseinfo') {
              localStorage.realname = this.$parent.$refs.header.realname = this.$parent.$refs.nav.info.realname = this.info.realname
              localStorage.avatar = this.$parent.$refs.header.avatar = this.$parent.$refs.nav.info.avatar = this.info.avatar
            }
          } else {
            this.$message({message: d.msg, type: 'error'})
          }
        },
      })
    },
    //监听输入内容
    inputChange(type){
      if (type == 'price') {
        this.info.service_price = event.target.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".","$#$").replace(/\./g,"").replace("$#$",".").replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')
      } 
    },
  },
}
</script>
<style lang="scss">
.edit{
  .row{
    margin-top: 40px;
    white-space: nowrap;
    user-select: none;
    // min-width: 540px;
  }
  h3{
    font-size: 14px;
    color: #868686;
    font-weight: normal;
    width: 90px;
    text-align: right;
    margin-right: 20px;
    user-select: none;
    flex-shrink: 0;
  }
  .upBtn{
    cursor: pointer;
    margin: 0 20px;
    .head{
      margin-right: 20px;
      width: 90px;
      height: 90px;
      overflow: hidden;
      border-radius: 50%;
    }
    h4{
      font-size: 14px;
      color: #6b6be5;
      font-weight: normal;
    }
  }
  .txt{
    font-size: 14px;
    color: #b5b5b5;
    margin-left: 60px;
  }
  .input{
    border: 1px solid #eceef3;
    border-radius: 4px;
    padding: 12px 16px;
    color: #1a1a1a;
    width: 300px;
    min-width: 300px;
  }
  textarea{
    border: 1px solid #eceef3;
    border-radius: 4px;
    padding: 12px 16px;
    color: #1a1a1a;
    flex: 1;
    resize: none;
    outline: none;
    text-align: justify;
    font-family: auto;
    line-height: 24px;
    height: 260px;
    min-width: 300px;
    &::-webkit-scrollbar {width: 6px !important}
  }
  .cut_box{
    display: flex;
    margin-top: 20px;
    user-select: none;
    canvas{display: none; opacity: 0;}
    .left{
      width: 250px;
      height: 250px;
      background: #eceef3;
      position: relative;
      flex-shrink: 0;
      outline: 1px solid #eceef3;
      overflow: hidden;
      & *{transition: all 0s;}
      img{
        background: #f6f6f6;
      }
      .up{
        position: absolute;
        .clip{
          position: absolute;
          border: 1px dashed #6b6be5;
          cursor: move;
          box-shadow: 0 0 0 300px rgba(0,0,0,.4);
          transition: width .1s, height .1s;
        }
      }
    }
    .right{
      flex: 1;
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      .preview{
        border: 1px solid #eceef3;
        flex: 1;
        p{
          font-size: 14px;
          line-height: 50px;
          text-align: center;
        }
        .img{
          width: 80px;
          height: 80px;
          display: block;
          margin: 20px auto 0;
          border-radius: 50%;
          background: #eceef3;
          overflow: hidden;
          border: 1px solid #eceef3;
          img{
            width: 100%;
            transition: width .1s, height .1s;
          }
        }
      }
      .chooseBtn{
        background: #6b6be5;
        border-radius: 4px;
        line-height: 32px;
        height: 32px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
        position: relative;
        input{
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
  .addBtn{
    background: #6b6be5;
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    width: 100px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    user-select: none;
  }
  .slot{
    div{margin-bottom: 10px}
    dt{font-weight: bold;.el-tag{color: #4d4d4d}}
    dd{display: flex;flex-wrap: wrap;}
  }
}
</style>