<!-- 传入数据说明（*代表必要元素）
  type：圆球类型（控制渐变颜色和名称）
  data*：分数
  width*：圆的宽度
-->
<template>
  <svg class="ring_chart">
    <defs>
      <linearGradient id="ring0" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#868bf4"/>
        <stop offset="100%" stop-color="#595fd1"/>
      </linearGradient>
      <linearGradient id="ring1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#fd8082"/>
        <stop offset="100%" stop-color="#ff617d"/>
      </linearGradient>
      <linearGradient id="ring2" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#76a8e4"/>
        <stop offset="100%" stop-color="#5f7fe4"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Number,
    },
    type: {
      default: 0,
    },
    animation: {
      type: Boolean,
      default: true
    }
  },
  data(){
    return{
      svg:'',
      center: 0,      //中点
      barWidth: 0,    //环形宽度
      radius: 0,      //半径
      color: ['#6b6be5','#fc7b7e','#6283e4'],
    }
  },
  mounted(){
    this.init()
    if (!this.animation) return
    if (this.data) {
      this.drawData(this.data)
    } else {
      this.$watch('data', () => {
        setTimeout(()=>{
          this.drawData(this.data)
        },200)
      })
    }
  },
  methods:{
    compute(r,score){
      let angel = score * 2 * Math.PI / 100
      return [this.center - Math.sin(angel) * r, this.center - Math.cos(angel) * r]
    },
    init(){
      let clientWidth = document.body.clientWidth, width;
      if (clientWidth > 1440) {
        width = 190
      } else if (clientWidth > 1300) {
        width = 170
      } else if (clientWidth > 1100) {
        width = 160
      } else if (clientWidth > 1000) {
        width = 150
      } else {
        width = 120
      }

      let svg = this.svg = d3.select(this.$el).attr('width',width).attr('height',width),
          bg = svg.append('g').classed('bg',true),
          center = this.center = width / 2,
          barWidth =this.barWidth = width * .06,
          R = this.radius = center - barWidth;
      bg.append('circle')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', R)
        .attr('stroke', ()=>{
          if (!this.animation) return `url(#ring${this.type})`
          else return '#f6f6f6'
        })
        .attr('stroke-width', barWidth)
        .attr('fill', 'none');
      //中心文字
      let span = bg.append('text').classed('center-text', true).attr('y', center*.84)
      span.append('tspan')
          .attr('font-size', R*0.4)
          .attr('x', center)
          .attr('id', 'span')
          .attr('fill', this.color[this.type])
          .text(()=>{
            if (!this.animation) return this.data
            else return this.data+'%'
          })
      span.append('tspan')
          .attr('font-size', R*0.2)
          .attr('x', center)
          .attr('dy', '2em')
          .text(()=>{
            if (!this.animation) return '已使用'
            if(this.type==2) return '完成率'
            else if(this.type==1) return '接受率'
            else return '使用率'
          })
    },
    //绘制环形分数
    drawData(e){
      let path = this.svg.append('path').classed('data',true)
      path.attr('fill','none')
          .attr('stroke',`url(#ring${this.type})`)
          .attr('stroke-width',this.barWidth)
          .attr('stroke-linecap','round')
          .attr('r',this.barWidth)
      let n = .4      //控制流畅度（0.1~1）
      let t = 800     //完成动画时间（毫秒）
      let nowScore = 0;
      let timer = setInterval(() => {
        if (nowScore > e) {
          clearInterval(timer)
          return
        }
        let x = this.compute(this.radius,nowScore)
        nowScore += n
        path.attr('d', `M${this.center} ${this.center-this.radius} A${this.radius} ${this.radius} 0 ${nowScore>50?1:0} 0 ${x[0]} ${x[1]}`)
      }, t / (e / n)); //t / ( this.data / n)
    },
  },
  watch:{
    data(e){
      d3.select(this.$el).select('#span').text(()=>{
        if (!this.animation) return e
        else return e+'%'
      })
    }
  }
}
</script>
<style scope lang="scss">
.ring_chart{
  tspan{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .ring{
    transition: stroke-dashoffset 1s linear;
  }
}
</style>
