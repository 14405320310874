<template>
  <div class="cic">
    <indexHeader/>
    <div class="banner flex-cen-cen">CIC教练体系</div>
    <section>
      <div class="introduce flex-bet-cen">
        <img src="../../assets/images/CIC/CIC_bg2.jpg">
        <div>
          <h1>CIC体系</h1>
          <p>CIC教练理论架构源于罗伯特迪尔茨SFM体系与斯蒂芬吉利根生生不息催眠理论体系。李冲、司佳卉、李程三位资深商业教练在中国企业中十多年践行与创新，形成一套将企业不同层级的人与企业经营活动完全融合的商业教练体系。</p>
        </div>
      </div>
    </section>
    <section class="content">
      <h1>培训内容</h1>
      <h2>CIC教练体系共有5个模块的培训内容支持成长中的教练全面掌握以上的工作方案</h2>
      <ul class="flex">
        <li>
          <h3>卓越心智建构</h3>
          <p>建构个人卓越心智</p>
          <p>激发生命内在动力</p>
          <p>建构心理和商业思维的通路</p>
          <h4>培训时长：3天<br>培训对象：教练、创始人、高管</h4>
        </li>
        <li>
          <h3>集体智慧教练 </h3>
          <p>掌握集体智慧教练基础理论</p>
          <p>掌握五套集体智慧企业解决方案</p>
          <h4>培训时长：3天<br>培训对象：教练、创始人、高管</h4>
        </li>
        <li>
          <h3>大师心智私董会教练 </h3>
          <p>掌握大师心智私董会方案</p>
          <p>深化集体智慧理论</p>
          <h4>培训时长：3天<br>培训对象：商业教练、创始人</h4>
        </li>
        <li>
          <h3>创始人/高管心智教练</h3>
          <p>掌握卓越心智教练方案</p>
          <p>掌握醒觉领导力教练方案</p>
          <h4>培训时长：3天<br>培训对象：商业教练、创始人、高管</h4>
        </li>
        <li>
          <h3>企业文化战略顾问 </h3>
          <p>掌握文化战略顾问方案</p>
          <p>掌握生生不息咨询方案</p>
          <h4>培训时长：3天<br>培训对象：商业教练、创始人、高管</h4>
        </li>
      </ul>
    </section>
    <section>
      <div class="dimension flex-bet-cen">
        <div class="l">
          <h1>核心维度</h1>
          <p>CIC教练体系以企业和组织发展的需求和场景为目标，从经营角度围绕着：企业文化与战略共识、产品/服务/营销创新、高效协同与跨部门沟通、绩效突破与执行力提升、商业模式创新与战略资源拓展五个核心维度为企业提供成熟的教练解决方案。<br>同时，从人的角度CIC教练体系支持创始人及核心团队：激发个人梦想与活力、构建企业文化战略体系、建立大师心智私董会。</p>
        </div>
        <!-- <div class="l" v-show="dimIndex==1">
          <h1>核心维度</h1>
          <p>CIC教练体系以企业和组织发展的需求和场景为目标，从经营角度围绕着：企业文化与战略共识、产品/服务/营销创供成熟的教练解决方案。<br>同时，从人的角度CIC教练体系支持创始人及核企业文化战略体系、建立大师心智私董会。</p>
        </div> -->
        <div class="r">
           <img v-show="dimIndex==0" src="../../assets/images/CIC/CIC_bg8.jpg">
           <img v-show="dimIndex==1" src="../../assets/images/CIC/CIC_bg9.jpg">
           <ul class="flex-cen">
             <li @click="dimIndex=0" :class="{curr:dimIndex==0}"/>
             <li @click="dimIndex=1" :class="{curr:dimIndex==1}"/>
           </ul>
        </div>
      </div>
    </section>
    <section class="course">
      <dl>
        <dt>课程现场</dt>
        <dd ref="ddWidth">
          <span @click="pre">&lt;</span>
          <span @click="next(1)">></span>
          <div class="win" @mouseenter="stop" @mouseleave="runTimer">
            <div class="flex" :style="{transform:`translateX(${imgWidth}px)`,transition: move ? '.6s' : '0s'}">
              <img v-for="(it,i) in imgList" :key="i" :src="it">
            </div>
          </div>
        </dd>
      </dl>
    </section>
    <indexFooterNav :theme="'light'"/>
    <indexFooter :bgcolor="'#fff'"/>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
import indexFooter from '../index/indexFooter'
import indexFooterNav from '../index/indexFooterNav'
export default {
  components:{indexHeader, indexFooter, indexFooterNav},
  data() {
    return {
      dimIndex: 0,
      imgList: [
        require('../../assets/images/CIC/CIC_course1.jpg'),
        require('../../assets/images/CIC/CIC_course2.jpg'),
        require('../../assets/images/CIC/CIC_course3.jpg'),
        require('../../assets/images/CIC/CIC_course4.jpg'),
      ],
      imgWidth: 0,  //大屏初始-252
      move: false,
      timer: null,
    }
  },
  mounted(){
    let first = this.imgList[0], last = this.imgList[this.imgList.length - 1]
    this.imgList.unshift(last)
    this.imgList.push(first)
    
    const ele = this.$refs.ddWidth
    this.imgWidth = -parseInt(ele.offsetWidth / 4)
    window.onresize = ({target}) => {
      if (target.innerWidth < 1200){
        this.imgWidth = -parseInt(ele.offsetWidth / 4)
        clearInterval(this.timer)
        this.runTimer()
      }
    } 

    this.runTimer()
  },
  methods: {
    next() {
      if (this.move) return
      clearInterval(this.timer)
      this.imgWidth *= 2
      this.move = true
      setTimeout(() => {
        this.move = false
        this.imgList.shift()
        this.imgList.push(this.imgList[1])
        this.imgWidth /= 2
        this.runTimer()
      }, 600)
    },
    pre() {
      if (this.move) return
      clearInterval(this.timer)
      let w = this.imgWidth
      this.imgWidth = 0
      this.move = true
      setTimeout(() => {
        this.move = false
        this.imgList.pop()
        this.imgList.unshift(this.imgList[this.imgList.length - 2])
        this.imgWidth = w
        this.runTimer()
      }, 600)
    },
    runTimer() {
      this.timer = setInterval(() => {
        this.imgWidth *= 2
        this.move = true
        setTimeout(() => {
          this.move = false
          this.imgList.shift()
          this.imgList.push(this.imgList[1])
          this.imgWidth /= 2
        }, 600)
      }, 5000)
    },
    stop() {
      clearInterval(this.timer)
    },
  },
}
</script>
<style lang="scss" scoped>
#app{overflow-x: auto;}
.cic{
  color: #212121;
  height: 100%;
  padding-top: 50px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 800px;
  box-sizing: border-box;
  .banner{
    height: 260px;
    background: url('../../assets/images/CIC/CIC_bg1.jpg');
    background-size: auto 100%;
    background-position: center top;
    color: #fff;
    font-size: 32px;
    letter-spacing: 15px;
    padding-left: 15px;
  }
  section{
    padding: 100px 10px;
    color: #212121;
    transition: .5s;
  }
  .introduce{
    max-width: 1000px;
    margin: 0 auto;
    img{
      width: 46%;
    }
    div{
      width: 48%;
      h1{
        font-size: 20px;
        margin-bottom: 18px;
      }
      p{
        font-size: 16px;
        line-height: 1.8;
        text-align: justify;
      }
    }
  }
  .content{
    text-align: center;
    background: #edeef7;
    h1{
      font-size: 20px;
      font-weight: normal;
    }
    h2{
      margin: 16px 0 60px;
      font-size: 16px;
      font-weight: normal;
    }
    ul{
      max-width: 1200px;
      margin: 0 auto;
      color: #fff;
      li{
        margin: 0 10px;
        flex: 1;
        position: relative;
        transition: .5s;
        padding-bottom: 170px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &:nth-child(1){background-image: url('../../assets/images/CIC/CIC_bg3.jpg')}
        &:nth-child(2){background-image: url('../../assets/images/CIC/CIC_bg4.jpg')}
        &:nth-child(3){background-image: url('../../assets/images/CIC/CIC_bg5.jpg')}
        &:nth-child(4){background-image: url('../../assets/images/CIC/CIC_bg6.jpg')}
        &:nth-child(5){background-image: url('../../assets/images/CIC/CIC_bg7.jpg')}
        h3{
          font-size: 16px;
          margin: 28px 0 26px;
          transition: .5s;
        }
        p{
          font-size: 15px;
          text-align: justify;
          margin: 0 30px;
          line-height: 1.5;
          padding-left: 10px;
          position: relative;
          transition: .5s;
          &::before{
            content: '';
            width: 3px;
            height: 3px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            left: 0px;
            top: 10px;
          }
        }
        h4{
          font-weight: normal;
          font-size: 13px;
          line-height: 1.8;
          position: absolute;
          width: 100%;
          box-sizing: border-box;
          transition: .5s;
          padding: 0 6px;
          left: 0;
          top: 220px;
          &::before{
            content: '';
            display: block;
            width: 50px;
            border-top: 1px solid #fff;
            margin: 0 auto 20px;
          }
        }
      }
    }
  }
  .dimension{
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 40px;
    .l{
      width: 45%;
      animation: ml .5s;
      h1{
        font-size: 20px;
        margin-bottom: 18px;
      }
      p{
        font-size: 16px;
        line-height: 1.8;
        text-align: justify;
      }
    }
    .r{
      width: 49%;
      position: relative;
      img{
        width: 100%;
        display: block;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: 0 4px 10px rgba(141,151,154,.3);
        animation: mr .5s;
      }
      ul{
        position: absolute;
        left: 0;
        bottom: -40px;
        width: 100%;
        li{
          width: 30px;
          height: 8px;
          border-radius: 4px;
          margin: 0 10px;
          background: #e4e4e4;
          cursor: pointer;
        }
        .curr{background: #00a8ec}
      }
    }
    @keyframes ml {
      0%{transform: translateX(-10%);opacity: 0;}
      100%{transform: translateX(0);opacity: 1;}
    }
    @keyframes mr {
      0%{transform: translateX(10%);opacity: 0;}
      100%{transform: translateX(0);opacity: 1;}
    }
  }
  .course{
    background: #ecedf6;
    user-select: none;
    dl{
      max-width: 1080px;
      padding: 0 30px 0 40px;
      margin: 0 auto;
      dt{
        font-size: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dadbe5;
        margin-right: 10px;
      }
      dd{
        margin-top: 30px;
        position: relative;
        span{
          position: absolute;
          font-size: 30px;
          font-family: serif;
          width: 20px;
          text-align: center;
          line-height: 40px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          color: #a4a4a4;
          &:nth-child(1){left: -40px;}
          &:nth-child(2){right: -30px;}
          &:hover{background: #e1e2eb;}
        }
        .win{
          overflow: hidden;
          div{
            flex-wrap: nowrap;
          }
          img{
            width: 25%;
            padding-right: 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    section{padding: 60px 8px}
    .content{
      ul li{
        margin: 0 5px;
        padding-bottom: 140px;
        h3{font-size: 15px;margin: 24px 0 22px}
        p{font-size: 14px;margin: 0 20px;}
        h4{top: 180px;}
      }
    }
    .dimension{
      .l p{line-height: 1.6;}
    }
  }
  @media screen and (max-width: 888px) {
    .content ul li h4{top: 220px;}
  }
}
</style>