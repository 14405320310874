
const status = 'formal';  //总切换 beta/formal
var path = {}
if (status == 'beta') {   //beta地址
  path = {
    baseRoot: 'http://api.beta.manage.huayanspace.com',
    pcUrl: 'http://beta.huayanspace.com',
    reportUrl: 'https://report.beta.huayanspace.com',
    mobileApiUrl: 'https://api.app.space.huayanspace.com',
    mobileUrl: 'https://app.huayanspace.com',
    planetUrl: 'https://api.app.startuplanet.huayanspace.com',
  }
} else {                  //正式地址
  path = {
    baseRoot: 'https://api.manage.huayanspace.com',
    pcUrl: 'https://huayanspace.com',
    reportUrl: 'https://report.huayanspace.com',
    mobileApiUrl: 'https://api.space.huayanspace.com',
    mobileUrl: 'https://m.huayanspace.com',
    planetUrl: 'https://api.startuplanet.huayanspace.com',
  }
}

import Vue from 'vue'
import App from './App.vue'

import VueResource from 'vue-resource'
Vue.use(VueResource)

import vueRouter from 'vue-router'
import router from './router'
Vue.use(vueRouter)
Vue.http.options.root = path.baseRoot;
Vue.http.options.emulateJSON = true;
Vue.http.options.credentials = true;//前端设置是否带cookie

import elementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(elementUI)
Vue.config.productionTip = false

Vue.prototype.$baseRoot = path.baseRoot;
Vue.prototype.$pcUrl= path.pcUrl;
Vue.prototype.$reportUrl= path.reportUrl;
Vue.prototype.$mobileUrl = path.mobileUrl;
Vue.prototype.$mobileApiUrl = path.mobileApiUrl;
Vue.prototype.$planetUrl = path.planetUrl;

Vue.prototype.$request = ({method='get', url, data, success, error, complete, headers})=> {
  url = url.indexOf('http')==-1  ?  path.baseRoot+url  :  url
  if (method.toLowerCase() === 'get') {
    Vue.http.get(url, {params:data}, {headers}).then(({data}) =>  {
      success && success(data)
    }, (e) =>  {
      if (error) {
        error(e)
      } else {
        Vue.prototype.msg = null;
        Vue.prototype.msg = Vue.prototype.$message({message:"网络错误",type:'error'})
      }
    }).then(() => complete && complete() )
  } else {
    Vue.http.post(url, data, {headers}).then(({data}) =>  {
      success && success(data);
    }, (e) => {
      if (error) {
        error(e)
      } else {
        Vue.prototype.msg = null;
        Vue.prototype.msg = Vue.prototype.$message({message:"网络错误",type:'error'})
      }
    }).then(() => complete && complete() )
  }
}
Vue.prototype.$country = {
  86: {label: '+86', country: '中国', reg: /^1[3-9]{1}[0-9]{1}[0-9]{8}$/},
  852: {label: '+852', country: '中国香港', reg: /^[0-9]{8}$/},
  60: {label: '+60', country: '马拉西亚', reg: /^0?1(1\d{8}|[02-9]\d{7})$/},
};
Vue.prototype.$countryList = [
  {label: '+86', country_code: '86', key: '$CNReg'},
  {label: '+852', country_code: '852', key: '$HKReg'},
  {label: '+60', country_code: '60', key: '$MyReg'},
];
Vue.prototype.$CNReg = /^1[3-9]{1}[0-9]{1}[0-9]{8}$/;
Vue.prototype.$HKReg =  /^[0-9]{8}$/;
Vue.prototype.$MyReg = /^0?1(1\d{8}|[02-9]\d{7})$/;
Vue.prototype.$goback = () => history.go(-1);
Vue.prototype.$mobileToStar = (e) => {
  if (e)
    return e.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')
  else
    return '-'
};
//时间戳转日期
Vue.prototype.$formatTime = (timestamp,format='y-m-d') => {
  if(!timestamp)  return ''
  let date = String(timestamp).length<13 ? new Date(timestamp*1000) : new Date(parseInt(timestamp)),
      year = date.getFullYear(),
      month = date.getMonth() + 1,
      day = date.getDate(),
      hours = date.getHours(),
      minutes = date.getMinutes(),
      seconds = date.getSeconds();
  month = month < 10 ? '0' + month : month
  day = day < 10 ? '0' + day : day
  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  seconds = seconds < 10 ? '0' + seconds : seconds
  if (format=='y-m-d h:m')
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
  else if (format=='y-m-d h:m:s')
    return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
  else if (format=='y/m/d')
    return year + '/' + month + '/' + day;
  else if (format=='m-d')
    return month + '-' + day;
  else if (format=='h:m:s')
    return hours + ':' + minutes + ':' + seconds;
  else //y-m-d:年月日
    return year + '-' + month + '-' + day;
};
Vue.prototype.$checkEmail = (email) => {
  if (/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(email)) {
    return true
  } else {
    return false
  }
};
Vue.prototype.$checkPhone = (phone) => {
  for (let i in countryList) {
    let key = countryList[i].key
    if (Vue.prototype[key].test(phone)) {
      return true
    } else {
      return false
    }
  }
};


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
