<template>
  <div class="basic">
    <h1 class="h1_title">
      <img src="../../../assets/images/company/nav_index.png">概况
    </h1>
    <div class="move-up"></div>
    <h2 class="flex--cen"><i></i>详情统计</h2>
    <h3 v-if="company.service==2">
      服务到期：
      <b style="color:#6b6be5">{{$formatTime(company.expire_time,'y-m-d h:m') || '-'}}</b>
    </h3>
    <div class="statistics flex-bet-cen">
      <div class="ring flex--cen" v-if="company.service==1">
        <ring :data="rate1"></ring>
        <ul>
          <li>购买总数 <b>{{company.total_number}}</b></li>
          <li>可邀请数 <b>{{company.may_invite_num}}</b></li>
          <li>已邀请数 <b>{{company.invited_num}}</b></li>
        </ul>
      </div>
      <div class="ring flex--cen" v-else>
        <ring :data="rate1" :animation="false"></ring>
        <ul>
          <li>可邀请数 <b>-</b></li>
          <li>已使用数 <b>{{company.invited_num}}</b></li>
        </ul>
      </div>
      <div class="ring flex--cen">
        <ring :data="rate2" :type="1"></ring>
        <ul>
          <li>未接受数 <b style="color:#fc7b7e">{{company.unaccepted_num}}</b></li>
          <li>已接受数 <b style="color:#fc7b7e">{{company.accepted_num}}</b></li>
        </ul>
      </div>
      <div class="ring flex--cen">
        <ring :data="rate3" :type="2"></ring>
        <ul>
          <li>未完成数 <b style="color:#6283e4">{{company.not_complete_num}}</b></li>
          <li>已完成数 <b style="color:#6283e4">{{company.complete_num}}</b></li>
        </ul>
      </div>
    </div>
    <div class="chart" v-show="company.service">
      <bar :type='1' :color="['#595fd1','#ff6d86']" id="bar1" :data="company.company_definition"></bar>
      <bar :type='2' :color="['#595fd1','#5f7ee4']" id="bar2" :data="company.company_leader"></bar>
    </div>
  </div>
</template>
<script>
import ring from '../../../assets/part/companyRingChart'
import bar from '../../../assets/part/barNumberChart'
export default {
  components: {ring,bar},
  data(){
    return {
      company: {
        service: 0,
        total_number: 0,        //购买总数
        invited_num: 0,         //已发出邀请数
        may_invite_num: 0,      //可邀请数
        accepted_num: 0,        //已接受邀请数
        unaccepted_num: 0,      //未接受数
        complete_num: 0,        //完成数
        not_complete_num: 0,    //未完成数
        team_number: 0,         //团队数
        member_number: 0,       //成员数
        company_definition: [], //领导星级人数
        company_leader: [],     //领导类型人数
      },
      rate1: 0,           //使用率
      rate2: 0,           //接受率
      rate3: 0,           //完成率
      title: '',
    }
  },
  mounted(){
    this.$request({url: '/Amt/statistical',
      success: (res) => {
        if (res.code == 0) {
          let data = this.company = res.data
          if (data.service == 1) {  //企业数量购买
            this.rate1 = data.total_number ? 100-Math.round(data.may_invite_num / data.total_number * 100) : 0
            this.rate2 = Math.round(data.accepted_num / data.invited_num * 100 || 0)
            this.rate3 = Math.round(data.complete_num / data.accepted_num * 100 || 0)
          } else if (data.service == 2) { //企业服务期购买
            this.rate2 = Math.round(data.accepted_num / data.invited_num * 100 || 0)
            this.rate3 = Math.round(data.complete_num / data.accepted_num * 100 || 0)
          }
        } else if (res.code == 101) {
          this.$router.replace({path:'/login',query:{back:this.$route.path}})
          localStorage.clear();
        } else {
          this.$message({duration:2000,message:res.msg,type:'error'})
        }
      },
    })
  },
}
</script>

<style lang="scss" scoped>
.basic{
  overflow: auto !important;
  height:calc(100vh - 60px);
  h2{
    font-size: 15px;
    color: #2b2b2b;
    margin: 25px 0;
    i{
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #6b6be5;
      margin: 0 6px 0 4px;
    }
  }
  h3{
    font-size: 14px;
    color: #2b2b2b;
    margin: 30px 18px 0;
  }
  .statistics{
    margin: 30px 10px;
    .ring{
      position: relative;
      user-select: none;
      ul{
        font-size: 14px;
        margin-left: 10px;
        li{
          line-height: 1.9em;
          white-space: nowrap;
          b{color: #6b6be5;}
        }
      }
      .number0{
        position: absolute;
        width: 100%;
        top: 160%;
        padding-bottom: 20px;
        border-bottom: 1px solid #ccc;
        box-sizing: border-box;
        padding-right: 8px;
        cursor: pointer;
        .pic{
          width: 22%;
          height: 60px;
          border-radius: 4px;
          background: #6b6be5;
          margin-right: 4%;
          min-width: 50px;
          img{width: 44%}
        }
        p{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: 2%;
        }
      }
    }
  }
  .chart{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    max-width: 1380px;
    min-width: 800px;
    user-select: none;
    .barChart{
      display: inline-block;
      width: 46%;
      min-width: 400px;
      margin: 0 1% 30px;
      flex-shrink: 0;
    }
  }
}
</style>