<template>
  <div class="expertList">
    <indexHeader/>
    <!-- 选择专家的信息 -->
    <div v-if="coacheId" class="reserve flex-bet">
      <div class="info">
        <div class="back" @click="reChoose"><i class="el-icon-arrow-left"/> 重新选择</div>
        <div class="flex">
          <img class="pic" :src="info.image">
          <div>
            <h1>{{info.realname}}</h1>
            <h2>{{info.title}}</h2>
            <div class="row flex--cen">
              <div class="flex">
                <div class="star3" v-for="i in 5" :key="i" :class="{star1: i <= info.multi_eval, star2: info.multi_eval < i && info.multi_eval > i-1}"/>
              </div>
              <h3>{{info.multi_eval}}</h3>
            </div>
            <h4><span>{{info.service_price}}</span>/小时</h4>
          </div>
        </div>
        <h6>详情介绍</h6>
        <p v-html="info.profile"></p>
      </div>
      <div class="appoint" v-if="dateList.length">
        <h1>近1周可预约时间</h1>
        <div class="time">
          <template v-if="dateList.length>5">
            <span class="flex-cen-cen" @click="dateNext(-88)">&lt;</span>
            <span class="flex-cen-cen" @click="dateNext(88)">></span>
          </template>
          <ul class="date flex" @wheel="dateScroll" ref="date">
            <li v-for="(item,i) in dateList" :key="i" :class="{disable:item.is_used, active:pickIndex[0]==i && pickText}" @click="datePick(i)">{{item.week}}<br>{{item.date}}</li>
          </ul>
        </div>
        <div class="time">
          <ul class="slot flex">
            <li v-for="(item,j) in dateList[pickIndex[0]].timelist" :key="j" :class="{disable:item.is_used||item.locked, active:pickIndex[1]==j}" @click="timePick(j)" :title="item.locked==1?'仅能预约2小时后的时间段':item.locked==2?'该时段只能在前一天20:00前预约':''">{{item.timerange}}</li>
          </ul>
        </div>
        <p class="text"><span v-if="pickText">已选 “<b>{{pickText}}</b>”</span></p>
        <div class="btnBox flex-cen">
          <span class="btn" :class="{btn0:pickIndex.length<2}" @click="createOrder">支付预约</span>
        </div>
      </div>
      <div class="appoint flex-cen-cen" v-else>
        <p class="no">暂无可预约时间，<span @click="reChoose">换个专家试试</span></p>
      </div>
    </div>
    <!-- 专家列表 -->
    <template v-else>
      <!-- <nav class="flex-cen">
        <p>全部</p>
        <p>榜单</p>
      </nav> -->
      <ul class="container flex">
        <li v-for="(item,i) in expertList" :key="i" @click="getExpertInfo(item.id)">
          <div class="content">
            <img class="pic" :src="item.image">
            <h1>{{item.realname}}</h1>
            <h2>{{item.title||'暂无'}}</h2>
            <div class="row flex--cen">
              <div class="flex">
                <div class="star3" v-for="i in 5" :key="i" :class="{star1: i <= item.multi_eval, star2: item.multi_eval < i && item.multi_eval > i-1}"/>
                <h3>{{item.multi_eval}}</h3>
              </div>
              <h4><span>{{item.service_price}}</span>/小时</h4>
            </div>
            <div class="btn">预约</div>
          </div>
        </li>
      </ul>
    </template>
    
    <el-dialog
      title="支付"
      :visible.sync="payShow"
      :close-on-click-modal="false" 
      width="640px"
      center>
      <div>
        <div class="box">
          <h1>订单信息</h1>
          <div class="txt flex-bet">
            <span>1小时线上咨询服务</span>
            <span>{{info.realname}}</span>
            <span>预约时间{{pickText}}</span>
          </div>
          <div class="price">总计 <span>{{info.service_price}}</span> 元</div>
        </div>
        <div class="box flex">
          <div class="l">
            <h1>支付方式</h1>
            <ul class="flex">
              <li class="flex-cen-cen" :class="{curr:payType=='wxpay'}" @click="pay('wxpay')"><img src="../../assets/images/expert/pay_wx.png">微信支付</li>
              <li class="flex-cen-cen" :class="{curr:payType=='alipay'}" @click="pay('alipay')"><img src="../../assets/images/expert/pay_ali.png">支付宝支付</li>
            </ul>
          </div>
          <div class="r">
            <h2>请在<b>15分钟</b>内使用{{payType=='wxpay'?'微信':'支付宝'}}扫码完成支付</h2>
            <div class="loadBg" :class="{overtime:timeNumber>300}" ref="iframe"></div>
            <!-- <span v-show="overtime" @click="pay(payType)">刷新</span> -->
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import indexHeader from '../index/indexHeader'
export default {
  components: {indexHeader},
  data(){
    return {
      type: 'all',  //all/rank
      expertList: [],
      coacheId: '',
      pickIndex: [0],  //0:日期索引，1：时间段索引
      pickText: '',
      dateList: [],
      info: {},
      payShow: false,
      orderId:'',
      payType: 0,     //wxpay, alipay
      timer: null,
      timeNumber: 1,
    }
  },
  created(){
    this.getExpertList()
    // this.$notify({
    //   duration: 0,
    //   offset: 50,
    //   dangerouslyUseHTMLString: true,
    //   message: '你有一个订单还未完成，<b>点击去支付</b>',
    //   onClick() {
    //     console.log(1111)
    //   }
    // })
  },
  methods:{
    getExpertList() {
      this.$request({
        url: '/Coaches/getList',
        data: {
          type: this.type,
          sort: '',
        },
        success: d => {
          if (d.code == 0) {
            this.expertList = d.data
          } else {
            this.$message({type: 'error', message: d.msg})
          }
        },
      })
    },
    getExpertInfo(id) {
      this.$request({
        url: '/Coaches/getInfo',
        data: {
          type: 1,
          coaches_id: id,
        },
        success: d => {
          if (d.code == 0) {
            this.coacheId = id
            this.info = d.data.info
            this.dateList = d.data.datelist
          } else if (d.code == 101) {
            this.$router.push({path:'/login', query:{back:this.$route.path}})
          } else {
            this.$message({type: 'error', message: d.msg})
          }
        },
      })
    },
    datePick(i) {
      if (this.dateList[i].is_used) return
      this.pickIndex = [i]
      this.pickText = this.dateList[i].date
    },
    timePick(j) {
      let i = this.pickIndex[0]
      if (this.dateList[i].timelist[j].is_used || this.dateList[i].timelist[j].locked) return
      this.pickIndex = [i, j]
      this.pickText = `${this.dateList[i].date} ${this.dateList[i].timelist[j].timerange}`
    },
    dateScroll() {
      event.preventDefault()
      let dx = event.deltaY > 0 ? 30 : -30
      this.$refs.date.scrollLeft += dx
    },
    dateNext(n) {
      let ele = this.$refs.date
      ele && ele.scrollTo({left: Math.ceil(ele.scrollLeft/n+0.01) * n, behavior: 'smooth'})
    },
    reChoose() {
      this.coacheId = ''
      this.pickIndex = [0]
      this.pickText = ''
    },
    createOrder() {
      let [i, j] = this.pickIndex
      this.payType = 0
      this.$request({
        url: '/Order/createMeetingOrder',
        data: {
          coaches_id: this.coacheId,
          date_id: this.dateList[i].date_id,
          timerange: this.dateList[i].timelist[j].timerange,
          total_money: this.info.service_price,
        },
        success: d => {
          if (d.code == 0) {
            this.payShow = true
            this.orderId = d.data.order_num
            this.pay('wxpay')
          } else if (d.code == 102) {
              this.$confirm('暂不支持个人购买，请先完善企业信息', '提示', {
                confirmButtonText: '完善信息',
                closeOnClickModal: false,
                center: true,
              }).then(() => {
                this.$router.push({path:'/login/companyRegister', query:{ ty: 'improve'}})
              }).catch(() => {})
          } else {
            this.$message({type: 'warning', message: d.msg})
            if (d.code == 6) {
              this.dateList[i].timelist[j].locked = 1
              this.pickIndex = [i]
              this.pickText = `${this.dateList[i].date}`
            } else if (d.code == 7) {
              this.dateList[i].timelist[j].locked = 2
              this.pickIndex = [i]
              this.pickText = `${this.dateList[i].date}`
            } else if (d.code == 9) {
              this.dateList[i].timelist[j].is_used = 1
              this.pickIndex = [i]
              this.pickText = `${this.dateList[i].date}`
            }
          }
        },
      })
    },
    pay(type) {
      if (this.timeNumber > 300) {
        this.$message({type: 'warning', message: '订单已超时，请重新下单'})
        return
      }
      this.payType = type
      if (this.$refs.iframe) this.$refs.iframe.innerHTML = ''
      this.timer && clearInterval(this.timer)
      this.$request({
        url: '/Webpay/'+type, // /Webpay/wxpay, /Webpay/alipay
        data: {
          order_num: this.orderId||'202306160951145197',
          total_money: this.info.service_price||'99.00',
        },
        success: d => {
          //`<iframe srcdoc="${d}" frameborder='0' marginwidth='0' marginheight='0' scrolling='no'>
          let iframe = document.createElement('iframe')
          iframe.setAttribute("srcdoc", d)
          iframe.setAttribute("scrolling", 'no')
          iframe.setAttribute("frameborder", 0)
          iframe.setAttribute("marginwidth", 0)
          iframe.setAttribute("marginheight", 0)
          this.$refs.iframe.appendChild(iframe)
          this.setTimer()
        },
      })
    },
    setTimer() {
      this.timeNumber = 1
      this.timer = setInterval(() => {
        if (this.timeNumber++ < 300) {  // 3秒 * 300次 / 60 = 15分钟
          this.$request({url: '/Webpay/queryOrderStatus',
            data: {order_num: this.orderId||'202306160951145197'},
            success: d => {
              if (d.code == 0) {
                clearInterval(this.timer)
                this.payShow = false

                let [i, j] = this.pickIndex
                this.dateList[i].timelist[j].is_used = 1
                this.pickIndex = [i]
                this.pickText = `${this.dateList[i].date}`

                this.$confirm('可前往用户中心查看预约状态', '支付成功', {
                  confirmButtonText: '前往用户中心',
                  showCancelButton: false,
                  closeOnClickModal: false,
                  type: 'success',
                  center: true,
                }).then(() => {
                  //1001有个人权限，1002有企业权限，1003有个人和企业权限，1004有个人和企业及教练权限，1005有个人和教练权限
                  let auth = localStorage.getItem('authority')
                  if (auth==1002 || auth==1003 || auth==1004)
                    this.$router.push({path:'/company/consult'})
                  else
                    this.$router.push({path:'/login/companyRegister', query:{back: '/company/consult', ty: 'improve'}})
                }).catch(() => {})
              } else if (d.code == 4) {
                clearInterval(this.timer)
                this.payShow = false
                this.$message({type: 'warning', message: '订单已超时，请重新下单'})
              }
            },
          })
        } else {
          clearInterval(this.timer)
        }
      }, 3000)
    },
  },
}
</script>
<style lang="scss">
.expertList{
  user-select: none;
  height: 100%;
  padding-top: 50px;
  box-sizing: border-box;
  background: #f9f9f9;
  min-width: 800px;
  overflow-y: auto;
  nav{
    margin: 0 auto;
    border-bottom: 1px solid #f1f1f1;
    max-width: 1200px;
    p{
      width: 110px;
      line-height: 36px;
      text-align: center;
      color: #808080;
      background: #f0f0f0;
      border-radius: 4px;
      margin: 30px 20px;
      cursor: pointer;
    }
  }
  .container{
    margin: 0 auto;
    padding: 20px 0;
    max-width: 1220px;
    flex-wrap: wrap;
    align-items: flex-start;
    li{
      width: 25%;
      box-sizing: border-box;
      padding: 10px;
      white-space: nowrap;
      cursor: pointer;
      .content{
        border-radius: 8px;
        background: #fff;
        overflow: hidden;
      }
      .pic{
        display: block;
        width: 100%;
        background: #e5e5e5;
        margin-bottom: 12px;
      }
      h1{
        color: #1a1a1a;
        font-size: 16px;
        padding: 0 20px;
        line-height: 2;
      }
      h2{
        font-size: 14px;
        color: #999;
        padding: 0 20px;
        line-height: 2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .row{
        padding: 3px 20px;
        flex-wrap: wrap;
        .star3{
          width: 18px;
          height: 18px;
          background-image: url('../../assets/images/expert/expert_star3.png');
          background-size: 100% 100%;
        }
        .star1{background-image: url('../../assets/images/expert/expert_star1.png');}
        .star2{background-image: url('../../assets/images/expert/expert_star2.png'); }
        h3{
          font-size: 12px;
          color: #ff5a00;
          font-weight: normal;
          margin: 0 6px;
          line-height: 18px;
        }
        h4{
          font-size: 12px;
          color: #999;
          flex: 1;
          text-align: right;
          font-weight: normal;
          span{
            font-size: 16px;
            color: #ff5a00;
          }
        }
        @media (max-width: 910px) {h4{flex: 0;margin-top: 4px;}}
      }
      .btn{
        margin: 18px 20px 30px;
        line-height: 32px;
        background: #f5f5f5;
        text-align: center;
        color: #ff5a00;
        border-radius: 4px;
        font-size: 14px;
      }
    }
  }
  .reserve{
    user-select: none;
    margin: 20px auto;
    max-width: 1220px;
    .info{
      background: #fff;
      border-radius: 10px;
      margin-right: 20px;
      padding: 20px 10px 20px 30px;
      box-shadow: 0 0 6px rgba(200,200,200,.1);
      flex: 1;
      .back{
        font-size: 14px;
        width: 80px;
        color: #999;
        margin: -6px 0 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .pic{
        width: 200px;
        height: 200px;
        margin-right: 20px;
        border-radius: 8px;
      }
      h1{
        color: #1a1a1a;
        font-size: 16px;
        line-height: 2;
        margin: 20px 0 4px;
      }
      h2{
        font-size: 14px;
        color: #999;
        line-height: 1.3;
      }
      .row{
        padding: 16px 0;
        .star3{
          width: 18px;
          height: 18px;
          background-image: url('../../assets/images/expert/expert_star3.png');
          background-size: 100% 100%;
        }
        .star1{background-image: url('../../assets/images/expert/expert_star1.png');}
        .star2{background-image: url('../../assets/images/expert/expert_star2.png'); }
        h3{
          font-size: 14px;
          color: #ff5a00;
          font-weight: normal;
          margin: 2px 6px 0;
        }
      }
      h4{
        font-size: 12px;
        color: #999;
        font-weight: normal;
        span{
          font-size: 16px;
          color: #ff5a00;
        }
      }
      .s{
        position: relative;
        display: flex;
        align-items: center;
        text-decoration: line-through;
        span{color: #999;}
        &:before{
          content: '限时免费';
          position: absolute;
          color: #ff5a00;
          font-weight: bold;
          font-size: 12px;
          top: 110%;
          left: 2%;
        }
      }
      h6{
        padding: 20px 0;
        font-size: 16px;
        color: #212121;
        border-bottom: 1px solid #f6f6f6;
        margin-top: 20px;
      }
      p{
        font-size: 14px;
        color: #212121;
        margin: 20px 0;
        white-space: pre-wrap;
      }
    }
    @media (max-width: 940px) {
      .info{
        padding-left: 20px;
        .pic{width: 160px;height: 160px;margin-right: 16px;}
        h1{margin-top: 10px;}
        .row{padding: 10px 0;}
      }
    }
    @media (max-width: 890px) {
      .info{
        & > .flex{flex-direction: column;}
        h6, p{display: none;}
      }
    }
    @media (max-width: 764px) {
      .info{
        .pic{width: 124px;height: 124px;margin-right: 0;}
      }
    }
    .appoint{
      background: #fff;
      border-radius: 10px;
      padding: 20px 10px;
      box-shadow: 0 0 6px rgba(200,200,200,.1);
      h1{
        font-size: 14px;
        color: #212121;
        font-weight: normal;
        margin:  10px 4px 30px; 
      }
      .time{
        font-size: 12px;
        color: #212121;
        padding: 0 30px;
        position: relative;
        margin-bottom: 40px;
        span{
          position: absolute;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #f6f6f6;
          font-family: cursive;
          color: #b3b3b3;
          cursor: pointer;
          top: 12px;
          &:nth-child(1){left: 0px;}
          &:nth-child(2){right: 0px;}
        }
        .date{
          width: 440px;
          overflow-x: auto;
          &::-webkit-scrollbar{display: none}
          li{
            flex-shrink: 0;
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 4px 6px;
            text-align: center;
            width: 80px;
            margin: 0 4px;
            cursor: pointer;
          }
        }
        .slot{
          width: 440px;
          flex-wrap: wrap;
          li{
            flex-shrink: 0;
            border: 1px solid #ccc;
            border-radius: 4px;
            width: 102px;
            text-align: center;
            line-height: 36px;
            margin: 0 4px 16px;
            cursor: pointer;
          }
        }
        .slot, .date{
          .disable{
            background: #f5f5f5;
            color: #b3b3b3;
            opacity: .8;
            cursor: not-allowed;
          }
          .active{border-color: #ff5a00}
        }
      }
      .text{  
        text-align: center;
        font-size: 12px;
        color: #212121;
        height: 40px;
        line-height: 40px;
      }
      .btnBox{
        position: relative;
      }
      .btn{
        width: 200px;
        color: #fff;
        line-height: 36px;
        background: #ff5a00;
        border-radius: 4px;
        text-align: center;
        margin: 0 10px 10px;
        font-size: 14px;
        cursor: pointer;
      }
      .btn0{background: #fcad81;cursor: not-allowed}
      .no{
        width: 440px;
        text-align: center;
        font-size: 14px;
        color: #808080;
        font-weight: normal;
        span{
          cursor: pointer;
          color: #6b6be5;
          &:hover{text-decoration: underline}
        }
      }
    }
  }
  .box{
    background: #fff;
    border-radius: 8px;
    padding: 16px 20px;
    margin-bottom: 10px;
    h1{
      font-size: 14px;
      color: #212121;
    }
    .txt{
      font-size: 14px;
      color: #212121;
      margin: 20px 0;
    }
    .price{
      text-align: right;
      margin: 30px 0 10px;
      span{
        color: #ff5a00;
        font-size: 18px;
        font-weight: bold;
      }
    }
    .l{
      border-right: 1px solid #f4f4f4;
      li{
        height: 36px;
        border: 1px solid #f4f4f4;
        color: #808080;
        border-radius: 4px;
        padding: 0 15px;
        white-space: nowrap;
        margin: 20px 20px 0 0;
        cursor: pointer;
        img{
          display: inline-block;
          margin-right: 4px;
          width: 24px;
          height: 24px;
        }
      }
      .curr{
        color: #212121;
        border-color: #6b6be5;
      }
    }
    .r{
      flex: 1;
      position: relative;
      h2{
        font-size: 12px;
        font-weight: normal;
        text-align: center;
        margin: 10px 0;
      }
      .loadBg{
        width: 140px;
        height: 140px;
        margin: 0 auto;
        background: #e5e5e5;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          background: url('../../assets/images/login/icon-refresh.png') no-repeat;
          background-position: center;
          background-size: 30px;
          animation: rotate360 1.5s infinite linear;
        }
      }
      @keyframes rotate360 {
        100%{transform: rotate(360deg)}
      }
      .overtime::after{
        content: '超时请重新下单';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.8);
        text-align: center;
        line-height: 140px;
        z-index: 3;
        left: 0;
        top: 0;
        color: #fff;
      }
      iframe{
        width: 140px;
        height: 140px;
        position: relative;
        z-index: 2;
        border: none;
      }
      span{
        position: absolute;
        top: 100px;
        right: 20px;
        font-size: 12px;
        cursor: pointer;
        color: #999;
        white-space: nowrap;
        &:hover{text-decoration: underline;}
      }
    }
  }
  .el-dialog{background: #f7f7f7;}
  .el-dialog__body{padding-top: 10px;}
}
</style>